import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import React from 'react'
import { tischendorf_JAS } from './greek text/tischendorf/tauber/JAS'
// import strongsgreek from '../dic'
// import strongsgreek from '../dictionaries/strong/morphgnt/strongsgreek.json' assert {type: 'json'}
import { strongsdictionary } from '../dictionaries/strong/morphgnt/strongsgreek'
import './Interlinear.css'

export default function Interlinear() {
  const thing = tischendorf_JAS.split('JAS ')
  thing.shift()
  const strong = strongsdictionary.strongsdictionary.entries.entry
  // console.log("🚀 ~ file: Interlinear.tsx ~ line 13 ~ Interlinear ~ prologue", strong)
  
  return (
    <Container>
      {
        thing && thing.map((v, i) => {
          const array = v.split(' ')
          const breaks = array[1]
          const mss = array [2]
          const edited_mss = array[3]
          const morph = array[4]
          const strong = array[5]
          const first_lemma = array[6]

          const strong_definition = strongsdictionary.strongsdictionary.entries.entry.find((v) => {
            if (v.strongs === strong) return true
          })

          const short_kjv_definition = strong_definition?.kjv_def
            ? strong_definition.kjv_def.toString()
                .replace(':--X exceeding,', '')
                .replace(':--X', '')
                .replace(':--', '')
                .replace('--', '')
                .split(',')[0]
            : ''

          return (
            <Box
              key={i}
              sx={{
                display: 'inline-flex',
                flexDirection: 'column',
                px: 1,
                py: 1,
                borderBottom: '1px solid lightgray',
                '& .MuiTypography-root': {
                  fontFamily: "Inter, 'Piazzola', 'Source Sans 3', Inter !important",
                  fontSize: '2rem'
                }
              }}
            >
              <Box>
                <Typography
                  sx={{
                    // fontSize: '2rem',
                  }}
                >{ edited_mss }</Typography>
              </Box>
              <Box>
                <Typography
                  sx={{
                    // fontSize: '2rem',
                    opacity: .9
                  }}
                >{ first_lemma }</Typography>
              </Box>
              <Box
                onClick={() => {console.log(strong_definition); console.log(morph)}}
              >
                <Typography
                  sx={{
                    opacity: .8
                  }}
                >
                  {
                    short_kjv_definition
                  }
                </Typography>
              </Box>
              {/* <Box>
                <Typography>{ morph }</Typography>
              </Box>
              <Box>
                <Typography>{ strong }</Typography>
              </Box> */}
            </Box>
          )
        })
      }
    </Container>
  )
}
