import Box from "@mui/material/Box";
import { PropsWithChildren } from "react";
import Tooltip from '@mui/material/Tooltip'
import FetchVerses from "./FetchVerses";

export default function BibleRef(props: PropsWithChildren<{ver?: string, parentheses?: boolean, style?: boolean}>) {

    const { children, ver, parentheses, style } = props

    return (
        // <Tooltip
        //     title={<FetchVerses reference={children?.toString() || ''}/>}
        //     enterTouchDelay={0}
        //     leaveTouchDelay={10000}
        // >
        //     <Box component='span'
        //         sx={{
        //             textDecoration: 'underline'
        //         }}
        //     >
        //             {children}
        //     </Box>
        // </Tooltip>
        <>
            {
                parentheses
                    ? 
                    <Box component='span' sx={{
                        // opacity: 0.7,
                        ...(
                            style && {
                                pt: '3px',
                                pb: '2px',
                                px: '2px',
                                borderRadius: '3px',
                                backgroundColor: 'rgb(245, 245, 245)',
                                color: 'rgb(175, 175, 175)',
                                // fontWeight: '300',
                                'a': {
                                    color: 'inherit',
                                    // fontWeight: 'inherit',
                                    textDecorationColor: 'rgb(220, 220, 220)',
                                }
                            }
                        )
                    }}>(<a
                            href={`https://www.biblegateway.com/passage/?search=${children?.toString() || ''}&version=${ver ? ver : 'RVR1960'}`}
                            target="_blank"
                            style={{
                                // color: '#7072ff',
                                // textDecorationThickness: '3px',
                                // textDecorationColor: '#7072ff69',
                            }}
                        >
                            { children }
                        </a>)</Box>
                    :
                        <a
                            href={`https://www.biblegateway.com/passage/?search=${children?.toString() || ''}&version=${ver ? ver : 'RVR1960'}`}
                            target="_blank"
                            style={{
                                // color: '#7072ff',
                                // textDecorationThickness: '3px',
                                // textDecorationColor: '#7072ff69',
                            }}
                        >
                            {children}
                        </a>
            }
        </>
    )
}