import Container from '@mui/material/Container'
import { ReactElement } from 'react'

export default function BodyLayout({ children } : { children: ReactElement }) {

    return (
            <Container>
                { children }
            </Container>
    )
}
