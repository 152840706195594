import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import React from 'react'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { iBlockType } from '../../iEditor';

export default function EditMenu({ deleteBlock, transformTo }: { deleteBlock: () => void, transformTo: (type: iBlockType) => void }) {

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                {/* <Typography sx={{ minWidth: 100 }}>Contact</Typography>
                <Typography sx={{ minWidth: 100 }}>Profile</Typography>
                <Tooltip title="Account settings"> */}
                <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                >
                    <DragIndicatorIcon sx={{
                        // width: 32, height: 32
                    }} />
                </IconButton>
                {/* </Tooltip> */}
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                elevation: 0,
                sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                    },
                    // '&:before': {
                    //     content: '""',
                    //     display: 'block',
                    //     position: 'absolute',
                    //     top: 0,
                    //     right: 14,
                    //     width: 10,
                    //     height: 10,
                    //     bgcolor: 'background.paper',
                    //     transform: 'translateY(-50%) rotate(45deg)',
                    //     zIndex: 0,
                    // },
                },
                }}
                transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            >
                <MenuItem onClick={deleteBlock}>
                    <DeleteRoundedIcon sx={{ pr: 1 }} /> Borrar
                </MenuItem>
                <MenuItem onClick={() => transformTo('h_a')}>
                    H1
                </MenuItem>
                {/* <MenuItem onClick={handleClose}>
                <Avatar /> My account
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleClose}>
                <ListItemIcon>
                    <PersonAdd fontSize="small" />
                </ListItemIcon>
                Add another account
                </MenuItem>
                <MenuItem onClick={handleClose}>
                <ListItemIcon>
                    <Settings fontSize="small" />
                </ListItemIcon>
                Settings
                </MenuItem>
                <MenuItem onClick={handleClose}>
                <ListItemIcon>
                    <Logout fontSize="small" />
                </ListItemIcon>
                Logout
                </MenuItem> */}
            </Menu>
        </>
    )
}
