import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import ToolbarGeneralOne from "../../misc/ToolbarGeneralOne";
import Container from "@mui/material/Container";
import React, { PropsWithChildren, ReactElement, useEffect, useMemo, useState } from 'react'
import Card from "@mui/material/Card";
import { Link, useNavigate } from 'react-router-dom';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import './Blog.css';
import { Helmet } from "react-helmet-async";

const title_styles = {
    fontSize: '3em',
    pb: '16px',
    pt: '16px',
}

type articulo = {
    titulo: string,
    path: string,
    tags?: string[],
    read_time?: string,
}
const articulos_destacados: articulo[] = [
    {
        titulo: 'Fe',
        path: 'fe',
        tags: ['Estudio bíblico', 'Introducción'],
        read_time: '7 min',
    },
    {
        titulo: 'Hipérbole didáctica',
        path: 'hiperbole-didactica',
        tags: ['Hermenéutica'],
        read_time: '5 min',
    },
    {
        titulo: 'Iglesia',
        path: 'iglesia',
        tags: ['Estudio bíblico', 'Introducción'],
        read_time: '6 min',
    },
]

const articulos_todos: articulo[] = [
    {
        titulo: 'Septuaginta',
        path: 'septuaginta',
        tags: ['Introducción'],
        read_time: '3 min',
    },
    {
        titulo: 'Guía de Dios',
        path: 'guia-de-dios',
        tags: ['Estudio bíblico', 'Introducción'],
        read_time: '4 min',
    },
    {
        titulo: 'Fe vs. Obras',
        path: 'fe-vs-obras',
        tags: ['Hermenéutica'],
        read_time: '20 min',
    },
    {
        titulo: 'Fe',
        path: 'fe',
        tags: ['Estudio bíblico', 'Introducción'],
        read_time: '7 min',
    },
    {
        titulo: 'Iglesia',
        path: 'iglesia',
        tags: ['Estudio bíblico', 'Introducción'],
        read_time: '6 min',
    },
    {
        titulo: 'Hipérbole didáctica',
        path: 'hiperbole-didactica',
        tags: ['Hermenéutica'],
        read_time: '7 min',
    },
    {
        titulo: 'Dardos del enemigo',
        path: 'dardos-enemigo',
        tags: ['Hermenéutica'],
        read_time: '1 min',
    },
]

function PostCard(props: {art: articulo}) {
    const { art } = props
    return (
        <Link to={`/${art.path}`}
            style={{
                textDecoration: 'transparent',
            }}
        >
            <Box
                sx={{
                    backgroundColor: '#F7F7F8',
                    border: '1px solid #f3f3f3',
                    borderRadius: '11px',
                    minWidth: '200px',
                    padding: '12px',
                    '& .Articulos_Card_ReadTime': {
                        backgroundColor: 'white',
                        color: 'black',
                    },
                    '& .Articulos_Card_Tags': {
                        backgroundColor: 'white',
                        color: 'black',
                    },
                    '&:hover': {
                        backgroundColor: 'white',
                        color: 'black',
                        border: '1px solid lightgray',
                        '& .Articulos_Card_ReadTime': {
                            // backgroundColor: '#CDF546',
                            // color: '#005840',
                        },
                        '& .Articulos_Card_Tags': {
                            // backgroundColor: '#80003A',
                            // color: '#FFB3D2',
                        }
                    }
                }}
            >
                <Typography variant='h2'
                    sx={{
                        fontSize: '2.5em',
                        pb: '12px',
                        pt: '0px',
                        px: '0px',
                        pr: '8px',
                        m: '0px',
                        color: 'black',
                    }}
                >
                    {art.titulo}
                </Typography>
                {
                    art.read_time &&
                        <Box
                        // direction='row' spacing='6px'
                            sx={{
                                mb: '6px',
                                display: 'flex',
                                // sx={{
                                    // display: 'grid',
                                    // gridTemplateColumns: {
                                    //     us: '1fr',
                                    //     md: 'repeat(2, 1fr)',
                                    //     lg: 'repeat(4, 1fr)',
                                    // }
                                // }}
                            }}
                        >
                            <Box
                                className='Articulos_Card_ReadTime'
                                sx={{
                                    // backgroundColor: 'black',
                                    // color: 'white',
                                    backgroundColor: 'white',
                                    color: 'inherit',
                                    px: '6px',
                                    py: '2px',
                                    borderRadius: '3px',
                                    display: 'flex',
                                    direction: 'row',
                                    alignItems: 'center',
                                    // maxHeight: '22px',
                                }}
                            >
                                <AccessTimeIcon
                                    sx={{
                                        fontSize: '1.2em',
                                    }}
                                />
                                <Typography variant='body2'
                                    className='testttt'
                                    sx={{
                                        // color: 'white',
                                        color: 'inherit',
                                        fontSize: '1.2em',
                                        // textDecoration: 'none !important',
                                        // '& a': {
                                        //     '&:visited': {

                                        //     }
                                        // }
                                    }}
                                >
                                    &nbsp;{art.read_time}
                                </Typography>
                            </Box>
                        </Box>
                }
                {
                    art.tags &&
                        <Stack direction='row' spacing='6px'
                            // sx={{ flexWrap: 'wrap' }}
                        >
                            {
                                art.tags.map((v, i) => {
                                    return (
                                        <Box
                                            className='Articulos_Card_Tags'
                                            sx={{
                                                // backgroundColor: 'black',
                                                // color: 'white',
                                                // backgroundColor: 'white',
                                                // color: 'black',
                                                px: '6px',
                                                py: '2px',
                                                borderRadius: '3px',
                                            }}
                                        >
                                            <Typography variant='body2'
                                                className='testttt'
                                                sx={{
                                                    // color: 'white',
                                                    color: 'inherit',
                                                    fontSize: '1.2em',
                                                    // textDecoration: 'none !important',
                                                    // '& a': {
                                                    //     '&:visited': {

                                                    //     }
                                                    // }
                                                }}
                                            >
                                                {v}
                                            </Typography>
                                        </Box>
                                    )
                                })
                            }
                        </Stack>
                }
            </Box>
        </Link>
    )
}

export default function Articulos() {
    return (
        <Container
            sx={{
                paddingTop: {
                    xs: '65px',
                    sm: '105px',
                },
                pb: '24px',
            }}

        >
            <Helmet>
                <title>Artículos | Bani Grisson</title>
            </Helmet>
            <ToolbarGeneralOne breadcrumbs={[{text: 'Artículos', path: 'articulos'}]} />
            <Typography variant='h1'
                sx={{
                    ...title_styles
                }}
            >Destacados</Typography>
            <Box
                // direction='row'
                // spacing='24px'
                className='Articulos_Cards_Wrapper'
                sx={{
                    // display: 'grid',
                    // gridTemplateColumns: {
                    //     us: '1fr',
                    //     sm: 'repeat(2, 1fr)',
                    //     md: 'repeat(2, 1fr)',
                    // },
                    // gap: '12px',
                }}
            >
                {
                    articulos_destacados.map((v, i) => {
                        return (
                            <PostCard art={v}></PostCard>
                        )
                    })
                }
            </Box>
            <Typography variant='h1'
                sx={{
                    ...title_styles,
                    pt: '21px',
                }}
            >Todos</Typography>
            <Box
                // direction='row'
                // spacing='24px'
                className='Articulos_Cards_Wrapper'
            >
                {
                    articulos_todos.map((v, i) => {
                        return (
                            <PostCard art={v}></PostCard>
                        )
                    })
                }
            </Box>
        </Container>
    )
}