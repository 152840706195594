import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import React from 'react'
import { useGlobal } from '../../contexts/GlobalContext'

export default function Author() {

    const { extended_theme } = useGlobal()
    
    return (
        <Box
            sx={{
                // fontFamily: 'inherit !important'
            }}
        >
            <Typography variant='h2'>
                Autor
            </Typography>
            <Typography
                sx={{
                    // fontFamily: extended_theme.font_display + ' !important',
                }}
            >
                Bani Grisson
            </Typography>
            <Typography
                // variant='body2'
                sx={{
                    mt: '-12px',
                    // fontSize: '0.5em',
                    opacity: .8,
                    // fontFamily: extended_theme.font_display,
                }}
            >
                <Box component='span' sx={{fontSize: '0.8em'}}>
                banigrisson@gmail.com
                </Box>
            </Typography>
        </Box>
    )
}
