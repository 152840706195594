
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database";
import { getStorage } from 'firebase/storage'

export const firebaseConfig = {
  apiKey: "AIzaSyBuWTQq4nudjuwBKB-LxJxqOqYwhfLhgkA",
  authDomain: "banigrisson-dev.firebaseapp.com",
  databaseURL: "https://banigrisson-dev-default-rtdb.firebaseio.com",
  projectId: "banigrisson-dev",
  storageBucket: "banigrisson-dev.appspot.com",
  messagingSenderId: "1061444572289",
  appId: "1:1061444572289:web:5695cf985de012b157e980",
  measurementId: "G-M4MZPY18NN"
};

// export const firebaseConfig = {
//   apiKey: "AIzaSyDu_MwEogfeDRW0i1TNBhGB6_CjPJ2Ckjw",
//   authDomain: "banigrisson-5db78.firebaseapp.com",
//   projectId: "banigrisson-5db78",
//   storageBucket: "banigrisson-5db78.appspot.com",
//   messagingSenderId: "343165374876",
//   appId: "1:343165374876:web:95894bb07571f915f1f859",
//   measurementId: "G-SSB7XYSD7N"
// };

const app = initializeApp(firebaseConfig);
export const rtdb = getDatabase(app)
export const storage = getStorage(app)
export const analytics = getAnalytics(app);