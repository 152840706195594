import Box from '@mui/material/Box'
import React, { ReactElement, PropsWithChildren } from 'react'
import { useGlobal } from '../../contexts/GlobalContext'

type iProps = {

}

export default function RecommendedBox(props: PropsWithChildren<iProps>) {

    const { children } = props
    const { extended_theme } = useGlobal()

    return (
        <Box
            sx={{
                p: '12px',
                mx: '-12px',
                backgroundColor: '#f7f7f8',
                borderRadius: '11px',
                border: '1px solid #f3f3f3',
                // ':hover': {
                    //     backgroundColor: 'white',
                    //     border: '1px solid lightgray',
                    // },
                    '& .MuiTypography-root': {
                        m: '0px',
                        fontFamily: extended_theme.font_display_variable,
                        fontFeatureSettings: '"ss02", "ss03"',
                    // fontSize: '0.9em',
                }
            }}
        >
            { children }
        </Box>
    )
}