// import { onSnapshot, updateDoc } from '@firebase/firestore'
// import { onValue, ref, update } from 'firebase/database'
// import { getDocFromServer } from 'firebase/firestore';
import React, { useContext, useState, useEffect, ReactElement } from 'react'
// import { deviceDetect } from 'react-device-detect'
// import { Auth, rtdb } from '../components/Firebase/Firebase';
// import {  } from '../components/Misc/firefs';
// import { capitalize, generateString, nameArrayToString } from '../components/Misc/utils';
// import {  } from '../interfaces/iRecord';

interface iAuthContextData {
    // identity: any,
    // claims: any,
    // record: iStudentRecord | iProfessorRecord | undefined;
    // signUp: any;
    // logIn: (email: string, password: string) => void;
    // logOut: () => void;
    // areStaff: boolean | null;
    // checkRolesFor: (rol: string) => boolean,
    // students: iStudentRecordSummary[] | undefined;
    // getFirstName: string;
    // sortStudentsByName: any;
    // sortStudentsByLastName: any;
    // getHomeRoute: () => string;
    // setRequestGroup: React.Dispatch<React.SetStateAction<string>>,
    // requestGroup: string,
    // groupContentVersions: iGroupContentVersions | undefined,
    // pendingCorrection : iCompletedAssignments[] | undefined,
    // setPendingCorrection: React.Dispatch<React.SetStateAction<iCompletedAssignments[] | undefined>>,
    // getPendingWorks: () => iCompletedAssignments[] | null,
    // getLastWork: () => string | null,
    // getUpdatedContent: (gid: iGroupID, cid: iContentID) => Promise<iAssignmentInfo | iGroupOutline | null>
    // outline: iGroupOutline | undefined,
}

//@ts-ignore
export const AuthContext = React.createContext<iAuthContextData>()

export function useAuth() {
    return useContext(AuthContext)
}

export function AuthProvider({ children }: { children: ReactElement }) {

    // const [ identity, setIdentity ] = useState<any>()
    // const [ claims, setClaims ] = useState<any>()
    // const [ record, setRecord ] = useState<iStudentRecord | iProfessorRecord>()
    const [ loading, setLoading ] = useState(false)

    // const detected_device = deviceDetect(navigator.userAgent)
    // const [ groupContentVersions, setGroupContentVersions ] = useState()

    // const [ forceUpdate, setForceUpdate ] = useState(0)

    // const [ areStaff, setAreStaff ] = useState<boolean>(false)
    // const [ students, setStudents ] = useState<iStudentRecordSummary[] | undefined>()
    // const [ requestGroup, setRequestGroup ] = useState<iGroupID>('')
    // const [ outline, setOutline ] = useState<iGroupOutline | undefined>()
    // const [ pendingCorrection, setPendingCorrection ] = useState<iCompletedAssignments[] | undefined>()
    // const [ getFirstName, setGetFirstName ] = useState('estimado')

    // useEffect(() => {
    //     // set pending correction
    //     if (!Array.isArray(students)) return
    //     if (students.length === 0) return
    //     const pending = getPendingWorks()
    //     if (Array.isArray(pending)) {
    //         setPendingCorrection(pending)
    //     }
    // }, [students])

    // useEffect(() => {
    //     // set outline
    //     if (!requestGroup) return
    //     if (!areStaff) return
    //     const unsub = onSnapshot(fireGroupOutline(requestGroup), (snapshot) => {
    //         if (!snapshot.exists()) return
    //         setOutline(snapshot.data() as iGroupOutline)
    //     })
    //     return unsub
    // }, [requestGroup, areStaff])

    // useEffect(() => {
    //     const local = localStorage.getItem('name')
    //     if (!record || !record.name) {
    //         if (local) setGetFirstName(local)
    //     } else {
    //         setGetFirstName(capitalize(record.name[0]))
    //     }
    // }, [record])

    // useEffect(() => {
    //     console.log('Auth Context: uE triggered.')
    //     const unsub = Auth.onAuthStateChanged(user => {
    //         // console.log('Auth Context: auth state changed. Is "user" null|undefined?')
    //         if (user !== null && user !== undefined) {
    //             setIdentity(user)
    //             Auth.currentUser!.getIdTokenResult().then((token) => {
    //                 // console.log('Auth Context: going to set claims', token.claims)
    //                 setClaims(token.claims)
    //                 // console.log('Auth Context: going to get the users record.')
    //                 onSnapshot(fireUser(user.uid), (snapshot) => {
    //                     if (!snapshot.exists()) {
    //                         // console.log('Record doc doesnt exist')
    //                     } else {
    //                         const data = snapshot.data()
    //                         if (data.refresh_local_storage === true) {
    //                             clearLocal(user.uid)
    //                         }
    //                         if (data.refresh === true) {
    //                             refresh(user.uid)
    //                         }
    //                         if (data.refresh_storage_and_hosting) {
    //                             zero(data.uid)
    //                         }
    //                         // console.log('Auth Context: got the user record. Going to set record as: ', data)
                            
    //                         setRecord(data as iStudentRecord | iProfessorRecord)
    //                         localStorage.setItem('name', nameArrayToString(data.name).split(' ')[0])
    //                     }
                        
    //                 })
    //                 console.log('Auth Context: uE finished. Current token: ', token)
    //             })
    //         } else {
    //             console.log('Auth Context: yes, user is null or undefined')
    //         }
    //         setLoading(false)
    //     })

    //     return unsub
    // }, [])

    // useEffect(() => {
    //     if (!record || !claims || !requestGroup || !areStaff) {
    //         console.log(`AuthContext, students uE: undef params. ${record}, ${claims}, ${requestGroup}, ${areStaff}.`)
    //         return
    //     }

    //     const unsub = onSnapshot(fireGroupState(requestGroup), (snapshot) => {
    //         if (snapshot.exists()) {
    //             const data = snapshot.data() as iGroupState
    //             console.log('fireGroupState: ', data)
    //             const studentsArray: iStudentRecordSummary[] = []
    //             if (Object.keys(data).length === 0) {
    //                 // console.log('students uE: keys length was zero. Returning.')
    //                 return
    //             }
    //             for (const [key, value] of Object.entries(data)) {
    //                 studentsArray.push(value as iStudentRecordSummary)
    //             }
    //             const filtered_students = (): iStudentRecordSummary[] => {
    //                 // console.log('students uE: will filter students')
    //                 if (claims.roles?.includes('admin')) {
    //                     // console.log('students uE: is admin, will return the entire array.')
    //                     return studentsArray
    //                 }
    //                 const roles = record.groups_state[requestGroup].roles
    //                 if (roles.includes('titular') || roles.includes('asistente') || roles.includes('admin')) {
    //                     // console.log('students uE: is titular or asistente or admin')
    //                     return studentsArray
    //                 } else {
    //                     // console.log('students uE: had no special claims. will filter')
    //                     return studentsArray.filter((student) => {
    //                         if (student.professor && student.professor.uid === record.uid) {
    //                             return true
    //                         } else {
    //                             return false
    //                         }
    //                     })
    //                 }
    //             }
    //             setStudents(filtered_students().sort((a, b) => sortByName(a, b, 2)))
    //         } else {
    //             console.error('fireStateGroupInfo doesnt exist') // todo: handle better for lost users
    //         }
    //     })
    //     return unsub
    // }, [requestGroup, areStaff, record, claims])

    // useEffect(() => {
    //     checkStaff()
    // }, [claims])

    // useEffect(() => {
    //     if (!requestGroup || !record) return
    //     const device_id = localStorage.getItem(`${requestGroup}_device_id`)
    //     if (!device_id) {
    //         const new_id = generateString(8)
    //         localStorage.setItem(`${requestGroup}_device_id`, new_id)
    //         const device_info = {
    //             meta: detected_device,
    //             size: 'not yet',
    //             uid: record.uid,
    //         }

    //         const devicesRef = ref(rtdb, `groups/${requestGroup}/devices`)

    //         const updates: {[key: string]: any} = {}
    //         updates[`/updates/${new_id}/uid`] = record.uid
    //         updates[`/info/${new_id}`] = device_info

    //         update(devicesRef, updates)

    //         setForceUpdate((p) => p + 1)
    //     }
    // }, [forceUpdate, requestGroup, record])

    // useEffect(() => {
    //     console.log('AuthContext, uE, check updates: triggered.')
    //     const device_id = localStorage.getItem(`${requestGroup}_device_id`)
    //     if (!device_id || !record || !requestGroup) {
    //         console.log(`AuthContext, uE, check updates: undefined parameters: ${device_id}, ${record}, ${requestGroup}`)
    //         return
    //     }
    //     onValue(rtdbGroupEditionsIndex(requestGroup), (s) => {
    //         const data = s.val()
    //         if (data) {
    //             setGroupContentVersions(data)
    //         }
    //         console.log('AuthContext, uE, check updates: found updates. ', data)
    //     })
    // }, [record, requestGroup])

    // function signUp(email: string, password: string) {
    //     return Auth.createUserWithEmailAndPassword(email, password)
    // }

    // function logIn(email: string, password: string) {
    //     return Auth.signInWithEmailAndPassword(email, password)
    // }

    // async function logOut() {
    //     console.log('Will sign out')
    //     await Auth.signOut();
    //     setIdentity(null);
    //     setClaims(null);
    //     setRecord(undefined)
    //     setStudents([])
    //     setRequestGroup('')
    //     setAreStaff(false)
    // }

    // function checkStaff() {
    //     console.log('checkStaff() Will check if teacher.')
    //     if (!claims) {
    //         console.log('checkStaff() Will return false.')
    //         setAreStaff(false)
    //         return
    //     } else if (claims.staff === true) {
    //         console.log('checkStaff() Will return true.')
    //         setAreStaff(true)
    //         return
    //     } else if (!claims.staff) {
    //         console.log('checkStaff() Will return false.')
    //         setAreStaff(false)
    //         return
    //     } else {
    //         console.error('Couldnt figure out whether it was teacher or not. Claims: ', claims)
    //     }
    // }

    // function checkRolesFor(rol: string) {
    //     if (!claims) return false
    //     if (!('roles' in claims)) return false
    //     if (claims.roles?.includes(rol)) return true
    //     return false
    // }

    // async function refresh(uid: string) {
    //     await updateDoc(fireUser(uid), {
    //         refresh: false,
    //     })
    //     // @ts-ignore
    //     document.location.reload(true)
    // }

    // async function clearLocal(uid: string) {
    //     await updateDoc(fireUser(uid), {
    //         refresh: false,
    //     })
    //     localStorage.clear()
    // }

    // async function zero(uid: string) {
    //     await updateDoc(fireUser(uid), {
    //         refresh_storage_and_hosting: false,
    //     })
    //     localStorage.clear()
    //     // @ts-ignore
    //     document.location.reload(true)
    // }

    // function sortStudentsByName() {
    //     setStudents((p) => {
    //         if (!p) return p
    //         return [...p].sort((a, b) => sortByName(a, b, 0))
    //     })
    // }

    // function sortStudentsByLastName() {
    //     setStudents((p) => {
    //         if (!p) return p
    //         return [...p].sort((a, b) => sortByName(a, b, 2))
    //     })
    // }

    // function sortByName(a: iStudentRecordSummary, b: iStudentRecordSummary, i: number) {
    //     let x = a.name[i].toLowerCase()
    //     let y = b.name[i].toLowerCase()
    //     if (x < y) return -1
    //     if (x > y) return 1
    //     return 0
    // }

    // function getHomeRoute() {
    //     if (!record) return ''
    //     const default_group = getDefaultGroup(record.groups_current)
    //     if (default_group) {
    //         return `/grupos/${default_group}/principal`
    //     }
    //     return `/grupos`
    // }

    // function getDefaultGroup(groups_current: any): string { // if user has only one group this will return its id
    //     const size = Object.keys(groups_current).length;
    //     if (size === 1) return Object.keys(groups_current)[0]
    //     return ''
    // }

    // function getPendingWorks(): iCompletedAssignments[] | null {
    //     if (!record || !students) {
    //         console.log(`getPendingWorks(): ${record}, ${students}.`)
    //         return null
    //     }
    //     const completed_assignments: iCompletedAssignments[] = []
    //     // loop over students, push into completed_assignments.
    //     students.forEach((student, i) => {
    //         Object.entries(student.assignments).forEach((assignment, i) => {
    //             const assignment_key = assignment[0]
    //             const assignment_state = assignment[1]
    
    //             // if (student.professor && student.professor.uid === record.uid) {
    //                 // is teacher?
    //                 if ('s' in assignment_state && assignment_state.s) { // if has steps...
    //                     Object.entries(assignment_state.s).forEach((step) => { // go over steps
    //                         const step_key = step[0]
    //                         const step_state = step[1]
    
    //                         const a = step_state.a
    //                         const b = step_state.b
    //                         if (a && (b === null || b === undefined)) { // is pending?
    //                             completed_assignments.push({
    //                                 assignment_code: assignment_key,
    //                                 step_code: step_key,
    //                                 uid: student.uid,
    //                                 professor: student.professor,
    //                                 date: step_state.d as FirestoreTimestamp,
    //                             })
    //                         }
    //                     })
    //                 } else { // if doesn't have steps...
    //                     const a = assignment_state.a
    //                     const b = assignment_state.b
    //                     if (a && (b === null || b === undefined)) { // is pending?
    //                         completed_assignments.push({
    //                             assignment_code: assignment_key,
    //                             uid: student.uid,
    //                             professor: student.professor,
    //                             date: assignment_state.d as FirestoreTimestamp,
    //                         })
    //                     }
    //                 }
    //             // }
    //         })
    //     })
    //     // sort completed assignments by date
    //     completed_assignments.sort((a, b) => {
    //         if (!b.date?.seconds && !b.date?.seconds) return 0
    //         if (!a.date?.seconds) return -1
    //         if (!b.date?.seconds) return 1
    //         if (a.date.seconds && b.date.seconds) {
    //             return a.date.seconds - b.date.seconds
    //         }
    //         return 0
    //     })
    //     console.log('Completed assignments', completed_assignments)
    //     return completed_assignments
    // }

    // function getLastWork() {
    //     console.log('getLastWork(): triggered.')
    //     if (!Array.isArray(pendingCorrection)) return null
    //     if (pendingCorrection.length === 0) return null
    //     console.log('getLastWork(), pendingCorrection: ', pendingCorrection)
    //     const assignment = pendingCorrection[0]
    //     if (!outline)
    //         return null
    //     if (!('assignments' in outline))
    //         return null
    //     const card = outline.assignments[assignment?.assignment_code];
    //     if (card && card.t === 'pdf') {
    //         return `/grupos/${requestGroup}/abrir/PDF/${card.c}/${assignment.uid}`
    //     } else if (card.t === 'orientation') {
    //         return `/grupos/${requestGroup}/abrir/orientacion/${card.c}/${assignment.uid}`
    //     }
    //     return null
    // }

    // async function getUpdatedContent(gid: iGroupID, cid: iContentID): Promise<iAssignmentInfo | iGroupOutline | null> {
    //     if (!gid || !cid) { console.log(`StudentAssignment, uE #01: undefined arguments. ${gid}, ${cid}`); return null }
    
    //     const local = localStorage.getItem(cid)
    //     const versions = groupContentVersions
    //     const server_content_version = versions?.[cid]
    //     const local_content_version = local && JSON.parse(local).v

    //     const should_update = () => {
    //         if (!local) return true
    //         if (!versions && local) return false
    //         if (!server_content_version) return true
    //         if (server_content_version > local_content_version) return true
    //         return false
    //     }

    //     if (!should_update() && local) {
    //         return JSON.parse(local) as iAssignmentInfo | iGroupOutline
    //     } else {
    //         console.log(`getUpdatedContent(): will get ${cid} from server`)
    //         const server = await getDocFromServer(fireAssignmentInfo(gid, cid))
    //         if (server.exists()) {
    //             const data = server.data() as iAssignmentInfo | iGroupOutline
    //             localStorage.setItem(cid, JSON.stringify(data))
    //             return data
    //         } else { return null }
    //     }
    // }

    const value: iAuthContextData = {
        // identity,
        // claims,
        // record,
        // setRequestGroup,
        // signUp,
        // logIn,
        // logOut,
        // areStaff,
        // checkRolesFor,
        // students,
        // getFirstName,
        // sortStudentsByName,
        // sortStudentsByLastName,
        // getHomeRoute,
        // requestGroup,
        // groupContentVersions,
        // pendingCorrection,
        // setPendingCorrection,
        // getPendingWorks,
        // getLastWork,
        // getUpdatedContent,
        // outline,
    }

    function page() {
        if (loading) return null
        // else if (record?.group === null) return 'Se le otorgará acceso a la plataforma cuando sea asignado a un grupo'
        else {
            return children
        }
    }

    return (
        <AuthContext.Provider value={value as iAuthContextData}>
            {
                page()
            }
        </AuthContext.Provider>
    )
}
