import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { GlobalProvider } from './contexts/GlobalContext'
import { AuthProvider } from './contexts/AuthContext'
import { Global } from '@emotion/react';
import ScrollToTop from './misc/ScrollToTop';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // <React.StrictMode>
    <AuthProvider>
      <GlobalProvider>
        <BrowserRouter>
          <ScrollToTop />
          {/* <CssBaseline /> */}
          <Global styles={{
            // body: {
              // fontFamily: 'none',
            // },
            html: {
              fontSize: '10px'
            }
          }}/>
          {/* <ScrollToTop /> */}
          <App />
        </BrowserRouter>
      </GlobalProvider>
    </AuthProvider>
  // </React.StrictMode>
);

reportWebVitals();
