import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

const color_one = '#DE9340'

export default function About() {
    return (
        <Box>
        <Stack>
                <Box
                    sx={{
                        backgroundColor: color_one,
                    }}
                >
                    
                </Box>
            </Stack> 
        </Box>
    )
}