export const google_translator_one = [
 {
  "word": "ἁμαρτιῶν.",
  "translation": "de pecados",
  "phonetic": "amartión."
 },
 {
  "word": "πλῆθος",
  "translation": "anfitrión",
  "phonetic": "plíthos"
 },
 {
  "word": "καλύψει",
  "translation": "encubrir",
  "phonetic": "kalýpsei"
 },
 {
  "word": "θανάτου",
  "translation": "de la muerte",
  "phonetic": "thanátou"
 },
 {
  "word": "ψυχὴν",
  "translation": "alma",
  "phonetic": "psychín"
 },
 {
  "word": "ὁδοῦ",
  "translation": "dónde",
  "phonetic": "odoú"
 },
 {
  "word": "πλάνης",
  "translation": "engaño",
  "phonetic": "plánis"
 },
 {
  "word": "ἁμαρτωλὸν",
  "translation": "pecador",
  "phonetic": "amartolón"
 },
 {
  "word": "ἐπιστρέψας",
  "translation": "cuando vuelvas",
  "phonetic": "epistrépsas"
 },
 {
  "word": "⸀γινωσκέτω",
  "translation": "⸀por supuesto",
  "phonetic": "⸀ginoskéto"
 },
 {
  "word": "αὐτόν,",
  "translation": "a él,",
  "phonetic": "aftón,"
 },
 {
  "word": "ἐπιστρέψῃ",
  "translation": "regresar",
  "phonetic": "epistrépsi"
 },
 {
  "word": "ἀληθείας",
  "translation": "de verdad",
  "phonetic": "alitheías"
 },
 {
  "word": "πλανηθῇ",
  "translation": "extraviarse",
  "phonetic": "planithí"
 },
 {
  "word": "ἐάν",
  "translation": "si",
  "phonetic": "eán"
 },
 {
  "word": "⸀μου,",
  "translation": "⸀mi,",
  "phonetic": "⸀mou,"
 },
 {
  "word": "αὐτῆς.",
  "translation": "su.",
  "phonetic": "aftís."
 },
 {
  "word": "ἐβλάστησεν",
  "translation": "brotó",
  "phonetic": "evlástisen"
 },
 {
  "word": "γῆ",
  "translation": "Tierra",
  "phonetic": "gí"
 },
 {
  "word": "ἔδωκεν⸃",
  "translation": "dio",
  "phonetic": "édoken⸃"
 },
 {
  "word": "⸂ὑετὸν",
  "translation": "⸂ὑετὸν",
  "phonetic": "⸂yetón"
 },
 {
  "word": "οὐρανὸς",
  "translation": "cielo",
  "phonetic": "ouranós"
 },
 {
  "word": "προσηύξατο,",
  "translation": "orado,",
  "phonetic": "prosífxato,"
 },
 {
  "word": "πάλιν",
  "translation": "de nuevo",
  "phonetic": "pálin"
 },
 {
  "word": "ἕξ·",
  "translation": "ex;",
  "phonetic": "éx:"
 },
 {
  "word": "μῆνας",
  "translation": "mes",
  "phonetic": "mínas"
 },
 {
  "word": "τρεῖς",
  "translation": "tres",
  "phonetic": "treís"
 },
 {
  "word": "ἐνιαυτοὺς",
  "translation": "ser",
  "phonetic": "eniaftoús"
 },
 {
  "word": "ἔβρεξεν",
  "translation": "llovió",
  "phonetic": "évrexen"
 },
 {
  "word": "βρέξαι,",
  "translation": "mojarse",
  "phonetic": "vréxai,"
 },
 {
  "word": "προσηύξατο",
  "translation": "demandado",
  "phonetic": "prosífxato"
 },
 {
  "word": "προσευχῇ",
  "translation": "oración",
  "phonetic": "prosefchí"
 },
 {
  "word": "ὁμοιοπαθὴς",
  "translation": "homeopático",
  "phonetic": "omoiopathís"
 },
 {
  "word": "ἦν",
  "translation": "era",
  "phonetic": "ín"
 },
 {
  "word": "Ἠλίας",
  "translation": "Elías",
  "phonetic": "Ilías"
 },
 {
  "word": "ἐνεργουμένη.",
  "translation": "activo.",
  "phonetic": "energouméni."
 },
 {
  "word": "δικαίου",
  "translation": "de ley",
  "phonetic": "dikaíou"
 },
 {
  "word": "δέησις",
  "translation": "usted pregunta",
  "phonetic": "déisis"
 },
 {
  "word": "ἰσχύει",
  "translation": "aplicar",
  "phonetic": "ischýei"
 },
 {
  "word": "πολὺ",
  "translation": "muy",
  "phonetic": "polý"
 },
 {
  "word": "ἰαθῆτε.",
  "translation": "ἰαθῆτε.",
  "phonetic": "iathíte."
 },
 {
  "word": "ὅπως",
  "translation": "como",
  "phonetic": "ópos"
 },
 {
  "word": "ὑπὲρ",
  "translation": "para",
  "phonetic": "ypér"
 },
 {
  "word": "⸀εὔχεσθε",
  "translation": "⸀por favor",
  "phonetic": "⸀éfchesthe"
 },
 {
  "word": "ἁμαρτίας⸃",
  "translation": "del pecado⸃",
  "phonetic": "amartías⸃"
 },
 {
  "word": "⸂τὰς",
  "translation": "Tú",
  "phonetic": "⸂tás"
 },
 {
  "word": "ἀλλήλοις",
  "translation": "otros",
  "phonetic": "allílois"
 },
 {
  "word": "⸀οὖν",
  "translation": "⸀οὖν",
  "phonetic": "⸀oún"
 },
 {
  "word": "ἐξομολογεῖσθε",
  "translation": "confesar",
  "phonetic": "exomologeísthe"
 },
 {
  "word": "αὐτῷ.",
  "translation": "a él mismo.",
  "phonetic": "aftó."
 },
 {
  "word": "ἀφεθήσεται",
  "translation": "es complacido",
  "phonetic": "afethísetai"
 },
 {
  "word": "πεποιηκώς,",
  "translation": "de modo convincente,",
  "phonetic": "pepoiikós,"
 },
 {
  "word": "ᾖ",
  "translation": "ᾖ",
  "phonetic": "í"
 },
 {
  "word": "ἁμαρτίας",
  "translation": "del pecado",
  "phonetic": "amartías"
 },
 {
  "word": "κἂν",
  "translation": "incluso",
  "phonetic": "kán"
 },
 {
  "word": "κύριος·",
  "translation": "Señor·",
  "phonetic": "kýrios:"
 },
 {
  "word": "ἐγερεῖ",
  "translation": "se eleva",
  "phonetic": "egereí"
 },
 {
  "word": "κάμνοντα,",
  "translation": "incendio,",
  "phonetic": "kámnonta,"
 },
 {
  "word": "σώσει",
  "translation": "ahorrar",
  "phonetic": "sósei"
 },
 {
  "word": "εὐχὴ",
  "translation": "desear",
  "phonetic": "efchí"
 },
 {
  "word": "κυρίου·",
  "translation": "Señor;",
  "phonetic": "kyríou:"
 },
 {
  "word": "ἐλαίῳ",
  "translation": "aceite",
  "phonetic": "elaío"
 },
 {
  "word": "⸀αὐτὸν",
  "translation": "⸀a él",
  "phonetic": "⸀aftón"
 },
 {
  "word": "ἀλείψαντες",
  "translation": "inmortales",
  "phonetic": "aleípsantes"
 },
 {
  "word": "αὐτὸν",
  "translation": "a él",
  "phonetic": "aftón"
 },
 {
  "word": "προσευξάσθωσαν",
  "translation": "agregaron",
  "phonetic": "prosefxásthosan"
 },
 {
  "word": "ἐκκλησίας,",
  "translation": "iglesia,",
  "phonetic": "ekklisías,"
 },
 {
  "word": "πρεσβυτέρους",
  "translation": "ancianos",
  "phonetic": "presvytérous"
 },
 {
  "word": "προσκαλεσάσθω",
  "translation": "estas invitado",
  "phonetic": "proskalesástho"
 },
 {
  "word": "ἀσθενεῖ",
  "translation": "él está enfermo",
  "phonetic": "astheneí"
 },
 {
  "word": "ψαλλέτω.",
  "translation": "cantando",
  "phonetic": "psalléto."
 },
 {
  "word": "τις;",
  "translation": "¿a ellos?",
  "phonetic": "tis?"
 },
 {
  "word": "εὐθυμεῖ",
  "translation": "él es feliz",
  "phonetic": "efthymeí"
 },
 {
  "word": "προσευχέσθω·",
  "translation": "orar;",
  "phonetic": "prosefchéstho:"
 },
 {
  "word": "Κακοπαθεῖ",
  "translation": "Está sufriendo",
  "phonetic": "Kakopatheí"
 },
 {
  "word": "πέσητε.",
  "translation": "caer",
  "phonetic": "pésite."
 },
 {
  "word": "κρίσιν⸃",
  "translation": "juicio⸃",
  "phonetic": "krísin⸃"
 },
 {
  "word": "⸂ὑπὸ",
  "translation": "⸂sub",
  "phonetic": "⸂ypó"
 },
 {
  "word": "οὔ,",
  "translation": "No,",
  "phonetic": "oú,"
 },
 {
  "word": "Οὒ",
  "translation": "Oὒ",
  "phonetic": "Oú"
 },
 {
  "word": "ναὶ",
  "translation": "Sí",
  "phonetic": "naí"
 },
 {
  "word": "Ναὶ",
  "translation": "Sí",
  "phonetic": "Naí"
 },
 {
  "word": "ἤτω",
  "translation": "aquí",
  "phonetic": "íto"
 },
 {
  "word": "ὅρκον·",
  "translation": "juramento;",
  "phonetic": "órkon:"
 },
 {
  "word": "τινὰ",
  "translation": "qué",
  "phonetic": "tiná"
 },
 {
  "word": "ἄλλον",
  "translation": "demás",
  "phonetic": "állon"
 },
 {
  "word": "γῆν",
  "translation": "tierra",
  "phonetic": "gín"
 },
 {
  "word": "οὐρανὸν",
  "translation": "cielo",
  "phonetic": "ouranón"
 },
 {
  "word": "μήτε",
  "translation": "ni",
  "phonetic": "míte"
 },
 {
  "word": "ὀμνύετε,",
  "translation": "te refieres a",
  "phonetic": "omnýete,"
 },
 {
  "word": "Πρὸ",
  "translation": "Pre",
  "phonetic": "Pró"
 },
 {
  "word": "οἰκτίρμων.",
  "translation": "misericordioso.",
  "phonetic": "oiktírmon."
 },
 {
  "word": "κύριος⸃",
  "translation": "Señor⸃",
  "phonetic": "kýrios⸃"
 },
 {
  "word": "πολύσπλαγχνός",
  "translation": "fresco",
  "phonetic": "polýsplanchnós"
 },
 {
  "word": "⸀εἴδετε,",
  "translation": "⸀verás,",
  "phonetic": "⸀eídete,"
 },
 {
  "word": "τέλος",
  "translation": "fin",
  "phonetic": "télos"
 },
 {
  "word": "ἠκούσατε,",
  "translation": "escuchaste",
  "phonetic": "ikoúsate,"
 },
 {
  "word": "Ἰὼβ",
  "translation": "Trabajo",
  "phonetic": "Ióv"
 },
 {
  "word": "ὑπομονὴν",
  "translation": "paciencia",
  "phonetic": "ypomonín"
 },
 {
  "word": "⸀ὑπομείναντας·",
  "translation": "⸀ὑ restante;",
  "phonetic": "⸀ypomeínantas:"
 },
 {
  "word": "μακαρίζομεν",
  "translation": "bendecimos",
  "phonetic": "makarízomen"
 },
 {
  "word": "ὀνόματι",
  "translation": "Nombre",
  "phonetic": "onómati"
 },
 {
  "word": "⸀ἐν",
  "translation": "⸀en",
  "phonetic": "⸀en"
 },
 {
  "word": "ἐλάλησαν",
  "translation": "ellos hablaron",
  "phonetic": "elálisan"
 },
 {
  "word": "οἳ",
  "translation": "el",
  "phonetic": "oí"
 },
 {
  "word": "προφήτας,",
  "translation": "profeta,",
  "phonetic": "profítas,"
 },
 {
  "word": "μακροθυμίας",
  "translation": "longanimidad",
  "phonetic": "makrothymías"
 },
 {
  "word": "κακοπαθίας",
  "translation": "negligencia profesional",
  "phonetic": "kakopathías"
 },
 {
  "word": "⸀ἀδελφοί,",
  "translation": "⸀hermanos de religion,",
  "phonetic": "⸀adelfoí,"
 },
 {
  "word": "λάβετε,",
  "translation": "recibir,",
  "phonetic": "lávete,"
 },
 {
  "word": "ὑπόδειγμα",
  "translation": "modelo",
  "phonetic": "ypódeigma"
 },
 {
  "word": "ἕστηκεν.",
  "translation": "se levantó",
  "phonetic": "éstiken."
 },
 {
  "word": "θυρῶν",
  "translation": "de puertas",
  "phonetic": "thyrón"
 },
 {
  "word": "πρὸ",
  "translation": "pre",
  "phonetic": "pró"
 },
 {
  "word": "κριτὴς",
  "translation": "juez",
  "phonetic": "kritís"
 },
 {
  "word": "κριθῆτε·",
  "translation": "juez;",
  "phonetic": "krithíte:"
 },
 {
  "word": "ἀλλήλων⸃,",
  "translation": "entre sí⸃,",
  "phonetic": "allílon⸃,"
 },
 {
  "word": "κατ’",
  "translation": "por",
  "phonetic": "kat’"
 },
 {
  "word": "⸂ἀδελφοί,",
  "translation": "⸂hermanos de religion,",
  "phonetic": "⸂adelfoí,"
 },
 {
  "word": "στενάζετε,",
  "translation": "te quejas",
  "phonetic": "stenázete,"
 },
 {
  "word": "ἤγγικεν.",
  "translation": "alcanzó",
  "phonetic": "íngiken."
 },
 {
  "word": "παρουσία",
  "translation": "presencia",
  "phonetic": "parousía"
 },
 {
  "word": "στηρίξατε",
  "translation": "apoyaste",
  "phonetic": "stiríxate"
 },
 {
  "word": "ὑμεῖς,",
  "translation": "S.M,",
  "phonetic": "ymeís,"
 },
 {
  "word": "μακροθυμήσατε",
  "translation": "eras paciente",
  "phonetic": "makrothymísate"
 },
 {
  "word": "ὄψιμον.",
  "translation": "tarde",
  "phonetic": "ópsimon."
 },
 {
  "word": "πρόϊμον",
  "translation": "producto",
  "phonetic": "próïmon"
 },
 {
  "word": "⸀λάβῃ",
  "translation": "⸀labbaῃ",
  "phonetic": "⸀lávi"
 },
 {
  "word": "⸀αὐτῷ",
  "translation": "⸀αὐτῷ",
  "phonetic": "⸀aftó"
 },
 {
  "word": "ἐπ’",
  "translation": "en",
  "phonetic": "ep’"
 },
 {
  "word": "μακροθυμῶν",
  "translation": "de longanimidad",
  "phonetic": "makrothymón"
 },
 {
  "word": "γῆς,",
  "translation": "tierra,",
  "phonetic": "gís,"
 },
 {
  "word": "καρπὸν",
  "translation": "fruta",
  "phonetic": "karpón"
 },
 {
  "word": "τίμιον",
  "translation": "honesto",
  "phonetic": "tímion"
 },
 {
  "word": "ἐκδέχεται",
  "translation": "acepta",
  "phonetic": "ekdéchetai"
 },
 {
  "word": "γεωργὸς",
  "translation": "agricultor",
  "phonetic": "georgós"
 },
 {
  "word": "κυρίου.",
  "translation": "Señor.",
  "phonetic": "kyríou."
 },
 {
  "word": "παρουσίας",
  "translation": "presencia",
  "phonetic": "parousías"
 },
 {
  "word": "ἕως",
  "translation": "hasta",
  "phonetic": "éos"
 },
 {
  "word": "ἀδελφοί,",
  "translation": "hermanos de religion,",
  "phonetic": "adelfoí,"
 },
 {
  "word": "οὖν,",
  "translation": "entonces,",
  "phonetic": "oún,"
 },
 {
  "word": "Μακροθυμήσατε",
  "translation": "anhelabas",
  "phonetic": "Makrothymísate"
 },
 {
  "word": "δίκαιον.",
  "translation": "justo.",
  "phonetic": "díkaion."
 },
 {
  "word": "ἐφονεύσατε",
  "translation": "tu mataste",
  "phonetic": "efonéfsate"
 },
 {
  "word": "κατεδικάσατε,",
  "translation": "condenaste",
  "phonetic": "katedikásate,"
 },
 {
  "word": "σφαγῆς.",
  "translation": "sacrificio.",
  "phonetic": "sfagís."
 },
 {
  "word": "ἡμέρᾳ",
  "translation": "día",
  "phonetic": "iméra"
 },
 {
  "word": "⸀ὑμῶν",
  "translation": "⸀ὑμῶν",
  "phonetic": "⸀ymón"
 },
 {
  "word": "καρδίας",
  "translation": "corazón",
  "phonetic": "kardías"
 },
 {
  "word": "ἐθρέψατε",
  "translation": "tú nutriste",
  "phonetic": "ethrépsate"
 },
 {
  "word": "ἐσπαταλήσατε,",
  "translation": "desperdiciaste",
  "phonetic": "espatalísate,"
 },
 {
  "word": "γῆς",
  "translation": "tierra",
  "phonetic": "gís"
 },
 {
  "word": "ἐτρυφήσατε",
  "translation": "fuiste mimado",
  "phonetic": "etryfísate"
 },
 {
  "word": "⸀εἰσεληλύθασιν·",
  "translation": "⸀εἰselelythasin;",
  "phonetic": "⸀eiselilýthasin:"
 },
 {
  "word": "Σαβαὼθ",
  "translation": "Sábado",
  "phonetic": "Savaóth"
 },
 {
  "word": "Κυρίου",
  "translation": "Señor",
  "phonetic": "Kyríou"
 },
 {
  "word": "ὦτα",
  "translation": "ey",
  "phonetic": "óta"
 },
 {
  "word": "θερισάντων",
  "translation": "segadores",
  "phonetic": "therisánton"
 },
 {
  "word": "βοαὶ",
  "translation": "Guau",
  "phonetic": "voaí"
 },
 {
  "word": "αἱ",
  "translation": "santos",
  "phonetic": "ai"
 },
 {
  "word": "κράζει,",
  "translation": "carcajadas,",
  "phonetic": "krázei,"
 },
 {
  "word": "⸀ἀφυστερημένος",
  "translation": "⸀ἀfraseado",
  "phonetic": "⸀afysteriménos"
 },
 {
  "word": "χώρας",
  "translation": "país",
  "phonetic": "chóras"
 },
 {
  "word": "ἀμησάντων",
  "translation": "poco entusiasta",
  "phonetic": "amisánton"
 },
 {
  "word": "ἐργατῶν",
  "translation": "trabajadores",
  "phonetic": "ergatón"
 },
 {
  "word": "μισθὸς",
  "translation": "salario",
  "phonetic": "misthós"
 },
 {
  "word": "ἡμέραις.",
  "translation": "días.",
  "phonetic": "imérais."
 },
 {
  "word": "ἐσχάταις",
  "translation": "llegas tarde",
  "phonetic": "eschátais"
 },
 {
  "word": "ἐθησαυρίσατε",
  "translation": "te abasteciste",
  "phonetic": "ethisavrísate"
 },
 {
  "word": "σάρκας",
  "translation": "carne",
  "phonetic": "sárkas"
 },
 {
  "word": "φάγεται",
  "translation": "Esta comido",
  "phonetic": "fágetai"
 },
 {
  "word": "ἔσται",
  "translation": "es",
  "phonetic": "éstai"
 },
 {
  "word": "ὑμῖν",
  "translation": "A usted",
  "phonetic": "ymín"
 },
 {
  "word": "μαρτύριον",
  "translation": "martirio",
  "phonetic": "martýrion"
 },
 {
  "word": "ἰὸς",
  "translation": "virus",
  "phonetic": "iós"
 },
 {
  "word": "κατίωται,",
  "translation": "desciende",
  "phonetic": "katíotai,"
 },
 {
  "word": "ἄργυρος",
  "translation": "plata",
  "phonetic": "árgyros"
 },
 {
  "word": "χρυσὸς",
  "translation": "oro",
  "phonetic": "chrysós"
 },
 {
  "word": "γέγονεν,",
  "translation": "evento,",
  "phonetic": "gégonen,"
 },
 {
  "word": "σητόβρωτα",
  "translation": "comedores de semillas",
  "phonetic": "sitóvrota"
 },
 {
  "word": "ἱμάτια",
  "translation": "ropa",
  "phonetic": "imátia"
 },
 {
  "word": "σέσηπεν,",
  "translation": "sesipeno,",
  "phonetic": "sésipen,"
 },
 {
  "word": "πλοῦτος",
  "translation": "poder",
  "phonetic": "ploútos"
 },
 {
  "word": "ἐπερχομέναις.",
  "translation": "estás viniendo",
  "phonetic": "eperchoménais."
 },
 {
  "word": "ταλαιπωρίαις",
  "translation": "sufrimientos",
  "phonetic": "talaiporíais"
 },
 {
  "word": "ὀλολύζοντες",
  "translation": "ὀlolyzonts",
  "phonetic": "ololýzontes"
 },
 {
  "word": "κλαύσατε",
  "translation": "lloraste",
  "phonetic": "kláfsate"
 },
 {
  "word": "πλούσιοι,",
  "translation": "rico,",
  "phonetic": "ploúsioi,"
 },
 {
  "word": "ποιοῦντι,",
  "translation": "OMS",
  "phonetic": "poioúnti,"
 },
 {
  "word": "ποιεῖν",
  "translation": "OMS",
  "phonetic": "poieín"
 },
 {
  "word": "εἰδότι",
  "translation": "si",
  "phonetic": "eidóti"
 },
 {
  "word": "πονηρά",
  "translation": "malvadamente",
  "phonetic": "ponirá"
 },
 {
  "word": "τοιαύτη",
  "translation": "Aquél",
  "phonetic": "toiáfti"
 },
 {
  "word": "καύχησις",
  "translation": "jactarse",
  "phonetic": "káfchisis"
 },
 {
  "word": "ἀλαζονείαις",
  "translation": "arrogancia",
  "phonetic": "alazoneíais"
 },
 {
  "word": "καυχᾶσθε",
  "translation": "presumir",
  "phonetic": "kafchásthe"
 },
 {
  "word": "ἐκεῖνο.",
  "translation": "eso.",
  "phonetic": "ekeíno."
 },
 {
  "word": "τοῦτο",
  "translation": "este",
  "phonetic": "toúto"
 },
 {
  "word": "⸀ζήσομεν",
  "translation": "Vivamos",
  "phonetic": "⸀zísomen"
 },
 {
  "word": "⸀θελήσῃ,",
  "translation": "⸀voluntad,",
  "phonetic": "⸀thelísi,"
 },
 {
  "word": "κύριος",
  "translation": "Señor",
  "phonetic": "kýrios"
 },
 {
  "word": "Ἐὰν",
  "translation": "Si",
  "phonetic": "Eán"
 },
 {
  "word": "λέγειν",
  "translation": "ellos dicen",
  "phonetic": "légein"
 },
 {
  "word": "ἀντὶ",
  "translation": "en cambio",
  "phonetic": "antí"
 },
 {
  "word": "ἀφανιζομένη·",
  "translation": "aniquilado;",
  "phonetic": "afanizoméni:"
 },
 {
  "word": "⸀ἔπειτα",
  "translation": "⸀después",
  "phonetic": "⸀épeita"
 },
 {
  "word": "φαινομένη,",
  "translation": "fenómeno,",
  "phonetic": "fainoméni,"
 },
 {
  "word": "ὀλίγον",
  "translation": "un poco",
  "phonetic": "olígon"
 },
 {
  "word": "πρὸς",
  "translation": "a",
  "phonetic": "prós"
 },
 {
  "word": "⸀ἡ",
  "translation": "⸀el",
  "phonetic": "⸀i"
 },
 {
  "word": "⸀ἐστε",
  "translation": "⸀eres",
  "phonetic": "⸀este"
 },
 {
  "word": "γάρ",
  "translation": "gar",
  "phonetic": "gár"
 },
 {
  "word": "ἀτμὶς",
  "translation": "Vapor",
  "phonetic": "atmís"
 },
 {
  "word": "ζωὴ",
  "translation": "zoe",
  "phonetic": "zoí"
 },
 {
  "word": "⸀ποία",
  "translation": "⸀cual",
  "phonetic": "⸀poía"
 },
 {
  "word": "⸀τὸ",
  "translation": "⸀el",
  "phonetic": "⸀tó"
 },
 {
  "word": "ἐπίστασθε",
  "translation": "confía en mí",
  "phonetic": "epístasthe"
 },
 {
  "word": "οἵτινες",
  "translation": "cuáles",
  "phonetic": "oítines"
 },
 {
  "word": "⸀κερδήσομεν·",
  "translation": "⸀vamos a ganar;",
  "phonetic": "⸀kerdísomen:"
 },
 {
  "word": "⸀ἐμπορευσόμεθα",
  "translation": "⸀podemos",
  "phonetic": "⸀emporefsómetha"
 },
 {
  "word": "⸀ἐνιαυτὸν",
  "translation": "⸀ἐνιαυτὸν",
  "phonetic": "⸀eniaftón"
 },
 {
  "word": "⸀ποιήσομεν",
  "translation": "⸀lo que",
  "phonetic": "⸀poiísomen"
 },
 {
  "word": "πόλιν",
  "translation": "ciudad",
  "phonetic": "pólin"
 },
 {
  "word": "τήνδε",
  "translation": "entonces",
  "phonetic": "tínde"
 },
 {
  "word": "⸀πορευσόμεθα",
  "translation": "⸀estamos disponibles",
  "phonetic": "⸀porefsómetha"
 },
 {
  "word": "αὔριον",
  "translation": "mañana",
  "phonetic": "ávrion"
 },
 {
  "word": "Σήμερον",
  "translation": "Hoy",
  "phonetic": "Símeron"
 },
 {
  "word": "λέγοντες·",
  "translation": "dicho;",
  "phonetic": "légontes:"
 },
 {
  "word": "νῦν",
  "translation": "ahora",
  "phonetic": "nýn"
 },
 {
  "word": "Ἄγε",
  "translation": "Ey",
  "phonetic": "Áge"
 },
 {
  "word": "⸀πλησίον;",
  "translation": "⸀cerca;",
  "phonetic": "⸀plisíon?"
 },
 {
  "word": "κρίνων⸃",
  "translation": "lirios⸃",
  "phonetic": "krínon⸃"
 },
 {
  "word": "⸂ὁ",
  "translation": "⸂El",
  "phonetic": "⸂o"
 },
 {
  "word": "εἶ,",
  "translation": "Sí",
  "phonetic": "eí,"
 },
 {
  "word": "τίς",
  "translation": "qué",
  "phonetic": "tís"
 },
 {
  "word": "ἀπολέσαι·",
  "translation": "te perdiste;",
  "phonetic": "apolésai:"
 },
 {
  "word": "δυνάμενος",
  "translation": "fuerte",
  "phonetic": "dynámenos"
 },
 {
  "word": "κριτής⸃,",
  "translation": "juez⸃,",
  "phonetic": "kritís⸃,"
 },
 {
  "word": "⸂καὶ",
  "phonetic": "⸂kaí"
 },
 {
  "word": "νομοθέτης",
  "phonetic": "nomothétis"
 },
 {
  "word": "⸀ἐστιν",
  "phonetic": "⸀estin"
 },
 {
  "word": "εἷς",
  "phonetic": "eís"
 },
 {
  "word": "κριτής.",
  "phonetic": "kritís."
 },
 {
  "word": "εἶ",
  "phonetic": "eí"
 },
 {
  "word": "κρίνεις,",
  "phonetic": "kríneis,"
 },
 {
  "word": "νόμον·",
  "phonetic": "nómon:"
 },
 {
  "word": "κρίνει",
  "phonetic": "krínei"
 },
 {
  "word": "καταλαλεῖ",
  "phonetic": "katalaleí"
 },
 {
  "word": "ἀδελφὸν",
  "phonetic": "adelfón"
 },
 {
  "word": "κρίνων",
  "phonetic": "krínon"
 },
 {
  "word": "⸀ἢ",
  "phonetic": "⸀í"
 },
 {
  "word": "ἀδελφοῦ",
  "translation": "hermano",
  "phonetic": "adelfoú"
 },
 {
  "word": "καταλαλῶν",
  "translation": "De todo tipo",
  "phonetic": "katalalón"
 },
 {
  "word": "ἀδελφοί·",
  "translation": "hermanos de religion·",
  "phonetic": "adelfoí:"
 },
 {
  "word": "ἀλλήλων,",
  "translation": "entre sí,",
  "phonetic": "allílon,"
 },
 {
  "word": "καταλαλεῖτε",
  "translation": "mimarse",
  "phonetic": "katalaleíte"
 },
 {
  "word": "ὑμᾶς.",
  "translation": "tú",
  "phonetic": "ymás."
 },
 {
  "word": "ὑψώσει",
  "translation": "Levantate",
  "phonetic": "ypsósei"
 },
 {
  "word": "⸀κυρίου,",
  "translation": "⸀Señor,",
  "phonetic": "⸀kyríou,"
 },
 {
  "word": "ἐνώπιον",
  "translation": "delante de",
  "phonetic": "enópion"
 },
 {
  "word": "ταπεινώθητε",
  "translation": "fue humillado",
  "phonetic": "tapeinóthike"
 },
 {
  "word": "κατήφειαν·",
  "translation": "sombrío;",
  "phonetic": "katífeian:"
 },
 {
  "word": "χαρὰ",
  "translation": "alegría",
  "phonetic": "chará"
 },
 {
  "word": "⸀μετατραπήτω",
  "translation": "⸀convertir",
  "phonetic": "⸀metatrapíto"
 },
 {
  "word": "πένθος",
  "translation": "luto",
  "phonetic": "pénthos"
 },
 {
  "word": "γέλως",
  "translation": "reír",
  "phonetic": "gélos"
 },
 {
  "word": "κλαύσατε·",
  "translation": "Lloraste;",
  "phonetic": "kláfsate:"
 },
 {
  "word": "πενθήσατε",
  "translation": "tu lloraste",
  "phonetic": "penthísate"
 },
 {
  "word": "ταλαιπωρήσατε",
  "translation": "tu sufriste",
  "phonetic": "talaiporísate"
 },
 {
  "word": "δίψυχοι.",
  "translation": "dos corazones",
  "phonetic": "dípsychoi."
 },
 {
  "word": "καρδίας,",
  "translation": "corazón,",
  "phonetic": "kardías,"
 },
 {
  "word": "ἁγνίσατε",
  "translation": "te purificaste",
  "phonetic": "agnísate"
 },
 {
  "word": "ἁμαρτωλοί,",
  "translation": "pecadores,",
  "phonetic": "amartoloí,"
 },
 {
  "word": "χεῖρας,",
  "translation": "mano,",
  "phonetic": "cheíras,"
 },
 {
  "word": "καθαρίσατε",
  "translation": "limpiaste",
  "phonetic": "katharísate"
 },
 {
  "word": "ὑμῖν.",
  "translation": "A usted.",
  "phonetic": "ymín."
 },
 {
  "word": "⸀ἐγγιεῖ",
  "translation": "⸀ἐγιεῖ",
  "phonetic": "⸀engieí"
 },
 {
  "word": "ἐγγίσατε",
  "translation": "tocaste",
  "phonetic": "engísate"
 },
 {
  "word": "ἀφ’",
  "translation": "si",
  "phonetic": "af’"
 },
 {
  "word": "φεύξεται",
  "translation": "se va volando",
  "phonetic": "féfxetai"
 },
 {
  "word": "διαβόλῳ,",
  "translation": "demonio",
  "phonetic": "diavólo,"
 },
 {
  "word": "ἀντίστητε",
  "translation": "resistir",
  "phonetic": "antístite"
 },
 {
  "word": "θεῷ·",
  "translation": "dios",
  "phonetic": "theó:"
 },
 {
  "word": "ὑποτάγητε",
  "translation": "entregar",
  "phonetic": "ypotágite"
 },
 {
  "word": "χάριν.",
  "translation": "gracias.",
  "phonetic": "chárin."
 },
 {
  "word": "ταπεινοῖς",
  "translation": "humildes",
  "phonetic": "tapeinoís"
 },
 {
  "word": "ἀντιτάσσεται",
  "translation": "se opone",
  "phonetic": "antitássetai"
 },
 {
  "word": "ὑπερηφάνοις",
  "translation": "orgulloso",
  "phonetic": "yperifánois"
 },
 {
  "word": "Ὁ",
  "translation": "El",
  "phonetic": "O"
 },
 {
  "word": "χάριν·",
  "translation": "por gracia;",
  "phonetic": "chárin:"
 },
 {
  "word": "δίδωσιν",
  "translation": "dar",
  "phonetic": "dídosin"
 },
 {
  "word": "μείζονα",
  "translation": "importante",
  "phonetic": "meízona"
 },
 {
  "word": "ἡμῖν;",
  "translation": "¿a nosotros?",
  "phonetic": "imín?"
 },
 {
  "word": "⸀κατῴκισεν",
  "translation": "⸀katῴkisen",
  "phonetic": "⸀katókisen"
 },
 {
  "word": "ὃ",
  "translation": "El",
  "phonetic": "ó"
 },
 {
  "word": "πνεῦμα",
  "translation": "espíritu",
  "phonetic": "pnevma"
 },
 {
  "word": "ἐπιποθεῖ",
  "translation": "encima de",
  "phonetic": "epipotheí"
 },
 {
  "word": "φθόνον",
  "translation": "envidiar",
  "phonetic": "fthónon"
 },
 {
  "word": "Πρὸς",
  "translation": "A",
  "phonetic": "Prós"
 },
 {
  "word": "λέγει·",
  "translation": "dice·",
  "phonetic": "légei:"
 },
 {
  "word": "κενῶς",
  "translation": "con la mirada vacía",
  "phonetic": "kenós"
 },
 {
  "word": "δοκεῖτε",
  "translation": "intentalo",
  "phonetic": "dokeíte"
 },
 {
  "word": "καθίσταται.",
  "translation": "se convierte",
  "phonetic": "kathístatai."
 },
 {
  "word": "ἐχθρὸς",
  "translation": "enemigo",
  "phonetic": "echthrós"
 },
 {
  "word": "κόσμου,",
  "translation": "mundo,",
  "phonetic": "kósmou,"
 },
 {
  "word": "βουληθῇ",
  "translation": "voluntad",
  "phonetic": "voulithí"
 },
 {
  "word": "οὖν",
  "translation": "oὖ",
  "phonetic": "oún"
 },
 {
  "word": "ἔχθρα",
  "translation": "odio",
  "phonetic": "échthra"
 },
 {
  "word": "κόσμου",
  "translation": "mundo",
  "phonetic": "kósmou"
 },
 {
  "word": "φιλία",
  "translation": "amistad",
  "phonetic": "filía"
 },
 {
  "word": "οἴδατε",
  "translation": "tu viste",
  "phonetic": "oídate"
 },
 {
  "word": "⸀μοιχαλίδες,",
  "translation": "⸀adúlteras,",
  "phonetic": "⸀moichalídes,"
 },
 {
  "word": "δαπανήσητε.",
  "translation": "gastar.",
  "phonetic": "dapanísete."
 },
 {
  "word": "ἡδοναῖς",
  "translation": "placeres",
  "phonetic": "idonaís"
 },
 {
  "word": "αἰτεῖσθε,",
  "translation": "por favor",
  "phonetic": "aiteísthe,"
 },
 {
  "word": "κακῶς",
  "translation": "gravemente",
  "phonetic": "kakós"
 },
 {
  "word": "διότι",
  "translation": "porque",
  "phonetic": "dióti"
 },
 {
  "word": "λαμβάνετε,",
  "translation": "recibes",
  "phonetic": "lamvánete,"
 },
 {
  "word": "αἰτεῖτε",
  "translation": "por favor",
  "phonetic": "aiteíte"
 },
 {
  "word": "ὑμᾶς·",
  "translation": "tú",
  "phonetic": "ymás:"
 },
 {
  "word": "αἰτεῖσθαι",
  "translation": "Sucede",
  "phonetic": "aiteísthai"
 },
 {
  "word": "πολεμεῖτε.",
  "translation": "luchar.",
  "phonetic": "polemeíte."
 },
 {
  "word": "μάχεσθε",
  "translation": "luchar",
  "phonetic": "máchesthe"
 },
 {
  "word": "ἐπιτυχεῖν·",
  "translation": "tener éxito;",
  "phonetic": "epitycheín:"
 },
 {
  "word": "δύνασθε",
  "translation": "puede",
  "phonetic": "dýnasthe"
 },
 {
  "word": "ζηλοῦτε,",
  "translation": "usted es celoso",
  "phonetic": "ziloúte,"
 },
 {
  "word": "φονεύετε",
  "translation": "tu matas",
  "phonetic": "fonévete"
 },
 {
  "word": "ἔχετε·",
  "translation": "usted tiene·",
  "phonetic": "échete:"
 },
 {
  "word": "ἐπιθυμεῖτε,",
  "translation": "tú deseas,",
  "phonetic": "epithymeíte,"
 },
 {
  "word": "ὑμῶν;",
  "translation": "¿de ti?",
  "phonetic": "ymón?"
 },
 {
  "word": "στρατευομένων",
  "translation": "reclutas",
  "phonetic": "stratevoménon"
 },
 {
  "word": "ἡδονῶν",
  "translation": "de los placeres",
  "phonetic": "idonón"
 },
 {
  "word": "ἐντεῦθεν,",
  "translation": "de ahora en adelante",
  "phonetic": "entefthen,"
 },
 {
  "word": "μάχαι",
  "translation": "batallas",
  "phonetic": "máchai"
 },
 {
  "word": "⸀πόθεν",
  "translation": "⸀De donde",
  "phonetic": "⸀póthen"
 },
 {
  "word": "πόλεμοι",
  "translation": "guerras",
  "phonetic": "pólemoi"
 },
 {
  "word": "Πόθεν",
  "translation": "En ningún lugar",
  "phonetic": "Póthen"
 },
 {
  "word": "εἰρήνην.",
  "translation": "paz",
  "phonetic": "eirínin."
 },
 {
  "word": "ποιοῦσιν",
  "translation": "Quiénes son",
  "phonetic": "poioúsin"
 },
 {
  "word": "σπείρεται",
  "translation": "se siembra",
  "phonetic": "speíretai"
 },
 {
  "word": "εἰρήνῃ",
  "translation": "paz",
  "phonetic": "eiríni"
 },
 {
  "word": "δικαιοσύνης",
  "translation": "justicia",
  "phonetic": "dikaiosýnis"
 },
 {
  "word": "καρπὸς",
  "translation": "fruta",
  "phonetic": "karpós"
 },
 {
  "word": "ἀνυπόκριτος·",
  "translation": "sincero·",
  "phonetic": "anypókritos:"
 },
 {
  "word": "⸀ἀδιάκριτος,",
  "translation": "⸀indiscreto,",
  "phonetic": "⸀adiákritos,"
 },
 {
  "word": "ἀγαθῶν,",
  "translation": "bienes,",
  "phonetic": "agathón,"
 },
 {
  "word": "καρπῶν",
  "translation": "frutas",
  "phonetic": "karpón"
 },
 {
  "word": "ἐλέους",
  "translation": "merced",
  "phonetic": "eléous"
 },
 {
  "word": "εὐπειθής,",
  "translation": "deferente,",
  "phonetic": "efpeithís,"
 },
 {
  "word": "ἐπιεικής,",
  "translation": "indulgente,",
  "phonetic": "epieikís,"
 },
 {
  "word": "εἰρηνική,",
  "translation": "pacífico,",
  "phonetic": "eirinikí,"
 },
 {
  "word": "ἔπειτα",
  "translation": "después",
  "phonetic": "épeita"
 },
 {
  "word": "ἁγνή",
  "translation": "Inés",
  "phonetic": "agní"
 },
 {
  "word": "μὲν",
  "translation": "a mí",
  "phonetic": "mén"
 },
 {
  "word": "πρῶτον",
  "translation": "primero",
  "phonetic": "próton"
 },
 {
  "word": "πρᾶγμα.",
  "translation": "cosa.",
  "phonetic": "prágma."
 },
 {
  "word": "φαῦλον",
  "translation": "gallina",
  "phonetic": "favlon"
 },
 {
  "word": "ἀκαταστασία",
  "translation": "desorden",
  "phonetic": "akatastasía"
 },
 {
  "word": "ἐκεῖ",
  "translation": "allá",
  "phonetic": "ekeí"
 },
 {
  "word": "ἐριθεία,",
  "translation": "furia,",
  "phonetic": "eritheía,"
 },
 {
  "word": "ζῆλος",
  "translation": "celo",
  "phonetic": "zílos"
 },
 {
  "word": "δαιμονιώδης·",
  "translation": "demoníaco;",
  "phonetic": "daimoniódis:"
 },
 {
  "word": "ψυχική,",
  "translation": "mental,",
  "phonetic": "psychikí,"
 },
 {
  "word": "ἐπίγειος,",
  "translation": "terrenal,",
  "phonetic": "epígeios,"
 },
 {
  "word": "κατερχομένη,",
  "translation": "descendido,",
  "phonetic": "katerchoméni,"
 },
 {
  "word": "ἄνωθεν",
  "translation": "arriba",
  "phonetic": "ánothen"
 },
 {
  "word": "σοφία",
  "translation": "Sofía",
  "phonetic": "sofía"
 },
 {
  "word": "ἔστιν",
  "translation": "ἔστιν",
  "phonetic": "éstin"
 },
 {
  "word": "ἀληθείας.",
  "translation": "de verdad.",
  "phonetic": "alitheías."
 },
 {
  "word": "ψεύδεσθε",
  "translation": "mentir",
  "phonetic": "psévdesthe"
 },
 {
  "word": "κατακαυχᾶσθε",
  "translation": "presumir",
  "phonetic": "katakafchásthe"
 },
 {
  "word": "καρδίᾳ",
  "translation": "corazón",
  "phonetic": "kardía"
 },
 {
  "word": "ἐριθείαν",
  "translation": "literalmente",
  "phonetic": "eritheían"
 },
 {
  "word": "πικρὸν",
  "translation": "amargamente",
  "phonetic": "pikrón"
 },
 {
  "word": "ζῆλον",
  "translation": "celo",
  "phonetic": "zílon"
 },
 {
  "word": "σοφίας.",
  "translation": "sabiduría.",
  "phonetic": "sofías."
 },
 {
  "word": "ἀναστροφῆς",
  "translation": "inversión",
  "phonetic": "anastrofís"
 },
 {
  "word": "καλῆς",
  "translation": "de bueno",
  "phonetic": "kalís"
 },
 {
  "word": "δειξάτω",
  "translation": "vamos a ver",
  "phonetic": "deixáto"
 },
 {
  "word": "ὑμῖν;",
  "translation": "¿A usted?",
  "phonetic": "ymín?"
 },
 {
  "word": "ἐπιστήμων",
  "translation": "científico",
  "phonetic": "epistímon"
 },
 {
  "word": "σοφὸς",
  "translation": "inteligente",
  "phonetic": "sofós"
 },
 {
  "word": "Τίς",
  "translation": "esto es",
  "phonetic": "Tís"
 },
 {
  "word": "ὕδωρ.",
  "translation": "agua.",
  "phonetic": "ýdor."
 },
 {
  "word": "ἁλυκὸν⸃",
  "translation": "ἱλικὸν⸃",
  "phonetic": "alykón⸃"
 },
 {
  "word": "⸂οὔτε",
  "translation": "⸂ni",
  "phonetic": "⸂oúte"
 },
 {
  "word": "σῦκα;",
  "translation": "higos?",
  "phonetic": "sýka?"
 },
 {
  "word": "ἄμπελος",
  "translation": "enredadera",
  "phonetic": "ámpelos"
 },
 {
  "word": "ποιῆσαι",
  "translation": "quién eres",
  "phonetic": "poiísai"
 },
 {
  "word": "ἐλαίας",
  "phonetic": "elaías"
 },
 {
  "word": "συκῆ",
  "phonetic": "sykí"
 },
 {
  "word": "δύναται,",
  "phonetic": "dýnatai,"
 },
 {
  "word": "πικρόν;",
  "phonetic": "pikrón?"
 },
 {
  "word": "γλυκὺ",
  "phonetic": "glyký"
 },
 {
  "word": "βρύει",
  "phonetic": "vrýei"
 },
 {
  "word": "ὀπῆς",
  "phonetic": "opís"
 },
 {
  "word": "αὐτῆς",
  "phonetic": "aftís"
 },
 {
  "word": "πηγὴ",
  "phonetic": "pigí"
 },
 {
  "word": "μήτι",
  "phonetic": "míti"
 },
 {
  "word": "γίνεσθαι.",
  "phonetic": "gínesthai."
 },
 {
  "word": "ταῦτα",
  "phonetic": "tafta"
 },
 {
  "word": "χρή,",
  "phonetic": "chrí,"
 },
 {
  "word": "κατάρα.",
  "phonetic": "katára."
 },
 {
  "word": "εὐλογία",
  "phonetic": "evlogía"
 },
 {
  "word": "ἐξέρχεται",
  "phonetic": "exérchetai"
 },
 {
  "word": "στόματος",
  "phonetic": "stómatos"
 },
 {
  "word": "γεγονότας·",
  "phonetic": "gegonótas:"
 },
 {
  "word": "ὁμοίωσιν",
  "phonetic": "omoíosin"
 },
 {
  "word": "ἀνθρώπους",
  "phonetic": "anthrópous"
 },
 {
  "word": "καταρώμεθα",
  "phonetic": "katarómetha"
 },
 {
  "word": "πατέρα,",
  "phonetic": "patéra,"
 },
 {
  "word": "⸀κύριον",
  "phonetic": "⸀kýrion"
 },
 {
  "word": "εὐλογοῦμεν",
  "phonetic": "evlogoúmen"
 },
 {
  "word": "αὐτῇ",
  "translation": "a ella",
  "phonetic": "aftí"
 },
 {
  "word": "θανατηφόρου.",
  "translation": "mortal.",
  "phonetic": "thanatifórou."
 },
 {
  "word": "ἰοῦ",
  "translation": "ἰοῦ",
  "phonetic": "ioú"
 },
 {
  "word": "μεστὴ",
  "translation": "ebrio",
  "phonetic": "mestí"
 },
 {
  "word": "κακόν,",
  "translation": "malo,",
  "phonetic": "kakón,"
 },
 {
  "word": "⸀ἀκατάστατον",
  "translation": "⸀ἀκαταστατον",
  "phonetic": "⸀akatástaton"
 },
 {
  "word": "ἀνθρώπων⸃·",
  "translation": "de la gente⸃·",
  "phonetic": "anthrópon⸃:"
 },
 {
  "word": "⸂δαμάσαι",
  "translation": "⸂daño",
  "phonetic": "⸂damásai"
 },
 {
  "word": "οὐδεὶς",
  "translation": "No",
  "phonetic": "oudeís"
 },
 {
  "word": "ἀνθρωπίνῃ·",
  "translation": "ser humano;",
  "phonetic": "anthropíni:"
 },
 {
  "word": "φύσει",
  "translation": "sopla",
  "phonetic": "fýsei"
 },
 {
  "word": "δεδάμασται",
  "translation": "vamos",
  "phonetic": "dedámastai"
 },
 {
  "word": "δαμάζεται",
  "translation": "es domesticado",
  "phonetic": "damázetai"
 },
 {
  "word": "ἐναλίων",
  "translation": "de los mares",
  "phonetic": "enalíon"
 },
 {
  "word": "ἑρπετῶν",
  "translation": "de reptiles",
  "phonetic": "erpetón"
 },
 {
  "word": "πετεινῶν",
  "translation": "de gallos",
  "phonetic": "peteinón"
 },
 {
  "word": "τε",
  "translation": "el",
  "phonetic": "te"
 },
 {
  "word": "θηρίων",
  "translation": "de bestias",
  "phonetic": "thiríon"
 },
 {
  "word": "φύσις",
  "translation": "naturaleza",
  "phonetic": "fýsis"
 },
 {
  "word": "πᾶσα",
  "translation": "todo",
  "phonetic": "pása"
 },
 {
  "word": "γεέννης.",
  "translation": "de nacimiento.",
  "phonetic": "geénnis."
 },
 {
  "word": "φλογιζομένη",
  "translation": "flameante",
  "phonetic": "flogizoméni"
 },
 {
  "word": "τροχὸν",
  "translation": "rueda",
  "phonetic": "trochón"
 },
 {
  "word": "φλογίζουσα",
  "translation": "flameante",
  "phonetic": "flogízousa"
 },
 {
  "word": "σπιλοῦσα",
  "translation": "sucio",
  "phonetic": "spiloúsa"
 },
 {
  "word": "ἡμῶν,",
  "translation": "nuestro,",
  "phonetic": "imón,"
 },
 {
  "word": "μέλεσιν",
  "translation": "estudiar",
  "phonetic": "mélesin"
 },
 {
  "word": "καθίσταται",
  "translation": "se convierte",
  "phonetic": "kathístatai"
 },
 {
  "word": "⸀ἀδικίας",
  "translation": "⸀ἀdikias",
  "phonetic": "⸀adikías"
 },
 {
  "word": "κόσμος",
  "translation": "mundo",
  "phonetic": "kósmos"
 },
 {
  "word": "πῦρ,",
  "translation": "fuego,",
  "phonetic": "pýr,"
 },
 {
  "word": "ἀνάπτει·",
  "translation": "enciende;",
  "phonetic": "anáptei:"
 },
 {
  "word": "ὕλην",
  "translation": "material",
  "phonetic": "ýlin"
 },
 {
  "word": "ἡλίκην",
  "translation": "edad",
  "phonetic": "ilíkin"
 },
 {
  "word": "πῦρ",
  "translation": "fuego",
  "phonetic": "pýr"
 },
 {
  "word": "⸀ἡλίκον",
  "translation": "⸀ἡλικον",
  "phonetic": "⸀ilíkon"
 },
 {
  "word": "Ἰδοὺ",
  "translation": "Mirad",
  "phonetic": "Idoú"
 },
 {
  "word": "αὐχεῖ⸃.",
  "translation": "ὐχεῖ⸃.",
  "phonetic": "afcheí⸃."
 },
 {
  "word": "⸂μεγάλα",
  "translation": "⸂ grande",
  "phonetic": "⸂megála"
 },
 {
  "word": "μέλος",
  "translation": "miembro",
  "phonetic": "mélos"
 },
 {
  "word": "μικρὸν",
  "translation": "micrón",
  "phonetic": "mikrón"
 },
 {
  "word": "γλῶσσα",
  "translation": "idioma",
  "phonetic": "glóssa"
 },
 {
  "word": "βούλεται⸃·",
  "translation": "quiere⸃·",
  "phonetic": "voúletai⸃:"
 },
 {
  "word": "εὐθύνοντος",
  "translation": "responsable",
  "phonetic": "efthýnontos"
 },
 {
  "word": "ὁρμὴ",
  "translation": "impulso",
  "phonetic": "ormí"
 },
 {
  "word": "⸂ἡ",
  "translation": "⸂el",
  "phonetic": "⸂i"
 },
 {
  "word": "ὅπου",
  "translation": "dónde",
  "phonetic": "ópou"
 },
 {
  "word": "πηδαλίου",
  "translation": "timón",
  "phonetic": "pidalíou"
 },
 {
  "word": "ἐλαχίστου",
  "translation": "al menos",
  "phonetic": "elachístou"
 },
 {
  "word": "μετάγεται",
  "translation": "transferido",
  "phonetic": "metágetai"
 },
 {
  "word": "ἐλαυνόμενα,",
  "translation": "agradable,",
  "phonetic": "elavnómena,"
 },
 {
  "word": "σκληρῶν⸃",
  "translation": "difícil",
  "phonetic": "sklirón⸃"
 },
 {
  "word": "⸂ἀνέμων",
  "translation": "⸂flor de viento",
  "phonetic": "⸂anémon"
 },
 {
  "word": "ὄντα",
  "translation": "seres",
  "phonetic": "ónta"
 },
 {
  "word": "τηλικαῦτα",
  "translation": "finalmente",
  "phonetic": "tilikafta"
 },
 {
  "word": "πλοῖα,",
  "translation": "buques,",
  "phonetic": "ploía,"
 },
 {
  "word": "ἰδοὺ",
  "translation": "Mirad",
  "phonetic": "idoú"
 },
 {
  "word": "μετάγομεν.",
  "translation": "nos estamos mudando.",
  "phonetic": "metágomen."
 },
 {
  "word": "αὐτῶν",
  "translation": "de ellos",
  "phonetic": "aftón"
 },
 {
  "word": "ἡμῖν,",
  "translation": "a nosotros,",
  "phonetic": "imín,"
 },
 {
  "word": "αὐτοὺς",
  "translation": "a ellos",
  "phonetic": "aftoús"
 },
 {
  "word": "πείθεσθαι",
  "translation": "convencido",
  "phonetic": "peíthesthai"
 },
 {
  "word": "βάλλομεν",
  "translation": "nosotros ponemos",
  "phonetic": "vállomen"
 },
 {
  "word": "στόματα",
  "translation": "bocas",
  "phonetic": "stómata"
 },
 {
  "word": "χαλινοὺς",
  "translation": "bridas",
  "phonetic": "chalinoús"
 },
 {
  "word": "ἵππων",
  "translation": "de caballos",
  "phonetic": "íppon"
 },
 {
  "word": "⸂εἰ",
  "translation": "⸂εἰ",
  "phonetic": "⸂ei"
 },
 {
  "word": "σῶμα.",
  "translation": "cuerpo.",
  "phonetic": "sóma."
 },
 {
  "word": "χαλιναγωγῆσαι",
  "translation": "refrenar",
  "phonetic": "chalinagogísai"
 },
 {
  "word": "δυνατὸς",
  "translation": "posible",
  "phonetic": "dynatós"
 },
 {
  "word": "ἀνήρ,",
  "translation": "hombre,",
  "phonetic": "anír,"
 },
 {
  "word": "τέλειος",
  "translation": "perfecto",
  "phonetic": "téleios"
 },
 {
  "word": "πταίει,",
  "translation": "Está incorrecto",
  "phonetic": "ptaíei,"
 },
 {
  "word": "ἅπαντες.",
  "translation": "todo.",
  "phonetic": "ápantes."
 },
 {
  "word": "πταίομεν",
  "translation": "estamos equivocados",
  "phonetic": "ptaíomen"
 },
 {
  "word": "πολλὰ",
  "translation": "muchos",
  "phonetic": "pollá"
 },
 {
  "word": "λημψόμεθα·",
  "translation": "nosotros extrañamos",
  "phonetic": "limpsómetha:"
 },
 {
  "word": "κρίμα",
  "translation": "lástima",
  "phonetic": "kríma"
 },
 {
  "word": "μεῖζον",
  "translation": "importante",
  "phonetic": "meízon"
 },
 {
  "word": "εἰδότες",
  "translation": "informantes",
  "phonetic": "eidótes"
 },
 {
  "word": "γίνεσθε,",
  "translation": "convertirse",
  "phonetic": "gínesthe,"
 },
 {
  "word": "διδάσκαλοι",
  "translation": "maestros",
  "phonetic": "didáskaloi"
 },
 {
  "word": "πολλοὶ",
  "translation": "muchos",
  "phonetic": "polloí"
 },
 {
  "word": "ἐστιν.",
  "translation": "es.",
  "phonetic": "estin."
 },
 {
  "word": "νεκρόν",
  "translation": "muerto",
  "phonetic": "nekrón"
 },
 {
  "word": "πνεύματος",
  "translation": "espíritu",
  "phonetic": "pnévmatos"
 },
 {
  "word": "σῶμα",
  "translation": "cuerpo",
  "phonetic": "sóma"
 },
 {
  "word": "⸀γὰρ",
  "translation": "⸀gẰr",
  "phonetic": "⸀gár"
 },
 {
  "word": "ὥσπερ",
  "translation": "como aprox.",
  "phonetic": "ósper"
 },
 {
  "word": "ἐκβαλοῦσα;",
  "translation": "¿eyaculé?",
  "phonetic": "ekvaloúsa?"
 },
 {
  "word": "ὁδῷ",
  "translation": "camino",
  "phonetic": "odó"
 },
 {
  "word": "ἑτέρᾳ",
  "translation": "otro",
  "phonetic": "etéra"
 },
 {
  "word": "ἀγγέλους",
  "translation": "ángeles",
  "phonetic": "angélous"
 },
 {
  "word": "ὑποδεξαμένη",
  "translation": "sin poder",
  "phonetic": "ypodexaméni"
 },
 {
  "word": "πόρνη",
  "translation": "prostituta",
  "phonetic": "pórni"
 },
 {
  "word": "Ῥαὰβ",
  "translation": "Ῥaẽb",
  "phonetic": "Raáv"
 },
 {
  "word": "ὁμοίως",
  "translation": "similarmente",
  "phonetic": "omoíos"
 },
 {
  "word": "μόνον.",
  "translation": "solo.",
  "phonetic": "mónon."
 },
 {
  "word": "δικαιοῦται",
  "translation": "tiene derecho",
  "phonetic": "dikaioútai"
 },
 {
  "word": "⸀ὁρᾶτε",
  "translation": "⸀Verás",
  "phonetic": "⸀oráte"
 },
 {
  "word": "ἐκλήθη.",
  "translation": "fue convocado",
  "phonetic": "eklíthi."
 },
 {
  "word": "φίλος",
  "translation": "amigo",
  "phonetic": "fílos"
 },
 {
  "word": "δικαιοσύνην,",
  "translation": "justicia,",
  "phonetic": "dikaiosýnin,"
 },
 {
  "word": "αὐτῷ",
  "translation": "a él mismo",
  "phonetic": "aftó"
 },
 {
  "word": "ἐλογίσθη",
  "translation": "calculado",
  "phonetic": "elogísthi"
 },
 {
  "word": "θεῷ,",
  "translation": "Dios,",
  "phonetic": "theó,"
 },
 {
  "word": "Ἐπίστευσεν",
  "translation": "Él creyó",
  "phonetic": "Epístefsen"
 },
 {
  "word": "λέγουσα·",
  "translation": "Estaba diciendo",
  "phonetic": "légousa:"
 },
 {
  "word": "γραφὴ",
  "translation": "escribiendo",
  "phonetic": "grafí"
 },
 {
  "word": "ἐπληρώθη",
  "translation": "fue pagado",
  "phonetic": "epliróthi"
 },
 {
  "word": "ἐτελειώθη,",
  "translation": "se acabó",
  "phonetic": "eteleióthi,"
 },
 {
  "word": "ἔργοις",
  "translation": "obras",
  "phonetic": "érgois"
 },
 {
  "word": "συνήργει",
  "translation": "ocurre",
  "phonetic": "synírgei"
 },
 {
  "word": "βλέπεις",
  "translation": "verás",
  "phonetic": "vlépeis"
 },
 {
  "word": "θυσιαστήριον;",
  "translation": "¿altar?",
  "phonetic": "thysiastírion?"
 },
 {
  "word": "υἱὸν",
  "translation": "hijo",
  "phonetic": "yión"
 },
 {
  "word": "Ἰσαὰκ",
  "translation": "isaac",
  "phonetic": "Isaák"
 },
 {
  "word": "ἀνενέγκας",
  "translation": "no tienes que hacerlo",
  "phonetic": "anenénkas"
 },
 {
  "word": "ἐδικαιώθη,",
  "translation": "justificado",
  "phonetic": "edikaióthi,"
 },
 {
  "word": "πατὴρ",
  "translation": "padre",
  "phonetic": "patír"
 },
 {
  "word": "Ἀβραὰμ",
  "translation": "Abrahán",
  "phonetic": "Avraám"
 },
 {
  "word": "ἐστιν;",
  "translation": "¿Lo es?",
  "phonetic": "estin?"
 },
 {
  "word": "⸀ἀργή",
  "translation": "⸀ἀγι",
  "phonetic": "⸀argí"
 },
 {
  "word": "χωρὶς",
  "translation": "sin",
  "phonetic": "chorís"
 },
 {
  "word": "κενέ,",
  "translation": "Kene,",
  "phonetic": "kené,"
 },
 {
  "word": "ἄνθρωπε",
  "translation": "hombre",
  "phonetic": "ánthrope"
 },
 {
  "word": "ὦ",
  "translation": "ὦ",
  "phonetic": "ó"
 },
 {
  "word": "γνῶναι,",
  "translation": "saber",
  "phonetic": "gnónai,"
 },
 {
  "word": "θέλεις",
  "translation": "Quieres",
  "phonetic": "théleis"
 },
 {
  "word": "φρίσσουσιν.",
  "translation": "frisusina",
  "phonetic": "fríssousin."
 },
 {
  "word": "πιστεύουσιν",
  "translation": "ellos creen",
  "phonetic": "pistévousin"
 },
 {
  "word": "δαιμόνια",
  "translation": "demonios",
  "phonetic": "daimónia"
 },
 {
  "word": "ποιεῖς·",
  "translation": "OMS;",
  "phonetic": "poieís:"
 },
 {
  "word": "θεός⸃;",
  "translation": "dios⸃;",
  "phonetic": "theós⸃?"
 },
 {
  "word": "⸂εἷς",
  "translation": "⸂en",
  "phonetic": "⸂eís"
 },
 {
  "word": "πιστεύεις",
  "translation": "tu crees",
  "phonetic": "pistéveis"
 },
 {
  "word": "σὺ",
  "translation": "tú",
  "phonetic": "sý"
 },
 {
  "word": "⸀πίστιν.",
  "translation": "⸀creer.",
  "phonetic": "⸀pístin."
 },
 {
  "word": "ἔργων",
  "translation": "obras",
  "phonetic": "érgon"
 },
 {
  "word": "ἐκ",
  "translation": "de",
  "phonetic": "ek"
 },
 {
  "word": "δείξω⸃",
  "phonetic": "deíxo⸃"
 },
 {
  "word": "⸂σοι",
  "phonetic": "⸂soi"
 },
 {
  "word": "κἀγώ",
  "phonetic": "kagó"
 },
 {
  "word": "⸀ἔργων,",
  "phonetic": "⸀érgon,"
 },
 {
  "word": "⸀χωρὶς",
  "phonetic": "⸀chorís"
 },
 {
  "word": "μοι",
  "phonetic": "moi"
 },
 {
  "word": "δεῖξόν",
  "phonetic": "deíxón"
 },
 {
  "word": "ἔχω.",
  "phonetic": "écho."
 },
 {
  "word": "κἀγὼ",
  "phonetic": "kagó"
 },
 {
  "word": "ἔχεις",
  "phonetic": "écheis"
 },
 {
  "word": "τις·",
  "phonetic": "tis:"
 },
 {
  "word": "ἐρεῖ",
  "phonetic": "ereí"
 },
 {
  "word": "Ἀλλ’",
  "phonetic": "All’"
 },
 {
  "word": "ἑαυτήν.",
  "phonetic": "eaftín."
 },
 {
  "word": "καθ’",
  "phonetic": "kath’"
 },
 {
  "word": "νεκρά",
  "phonetic": "nekrá"
 },
 {
  "word": "ἔργα⸃,",
  "phonetic": "érga⸃,"
 },
 {
  "word": "⸂ἔχῃ",
  "phonetic": "⸂échi"
 },
 {
  "word": "πίστις,",
  "phonetic": "pístis,"
 },
 {
  "word": "ὄφελος;",
  "phonetic": "ófelos?"
 },
 {
  "word": "⸀τί",
  "phonetic": "⸀tí"
 },
 {
  "word": "σώματος,",
  "phonetic": "sómatos,"
 },
 {
  "word": "ἐπιτήδεια",
  "phonetic": "epitídeia"
 },
 {
  "word": "τὰ",
  "phonetic": "tá"
 },
 {
  "word": "δῶτε",
  "phonetic": "dóte"
 },
 {
  "word": "χορτάζεσθε,",
  "phonetic": "chortázesthe,"
 },
 {
  "word": "θερμαίνεσθε",
  "phonetic": "thermaínesthe"
 },
 {
  "word": "εἰρήνῃ,",
  "translation": "paz,",
  "phonetic": "eiríni,"
 },
 {
  "word": "Ὑπάγετε",
  "translation": "De nada",
  "phonetic": "Ypágete"
 },
 {
  "word": "ὑμῶν·",
  "translation": "de ti;",
  "phonetic": "ymón:"
 },
 {
  "word": "ἐξ",
  "translation": "ex",
  "phonetic": "ex"
 },
 {
  "word": "αὐτοῖς",
  "translation": "a ellos",
  "phonetic": "aftoís"
 },
 {
  "word": "εἴπῃ",
  "translation": "él dijo",
  "phonetic": "eípi"
 },
 {
  "word": "τροφῆς,",
  "translation": "alimento,",
  "phonetic": "trofís,"
 },
 {
  "word": "ἐφημέρου",
  "translation": "vicario",
  "phonetic": "efimérou"
 },
 {
  "word": "⸀λειπόμενοι",
  "translation": "⸀desaparecido",
  "phonetic": "⸀leipómenoi"
 },
 {
  "word": "ὑπάρχωσιν",
  "translation": "existencia",
  "phonetic": "ypárchosin"
 },
 {
  "word": "γυμνοὶ",
  "translation": "desnudo",
  "phonetic": "gymnoí"
 },
 {
  "word": "ἀδελφὴ",
  "translation": "hermana",
  "phonetic": "adelfí"
 },
 {
  "word": "⸀ἐὰν",
  "translation": "⸀si",
  "phonetic": "⸀eán"
 },
 {
  "word": "πίστις",
  "translation": "tu crees",
  "phonetic": "pístis"
 },
 {
  "word": "δύναται",
  "translation": "poder",
  "phonetic": "dýnatai"
 },
 {
  "word": "ἔχῃ;",
  "translation": "ἔχῃ",
  "phonetic": "échi?"
 },
 {
  "word": "ἔργα",
  "translation": "obras",
  "phonetic": "érga"
 },
 {
  "word": "ἔχειν",
  "translation": "tener",
  "phonetic": "échein"
 },
 {
  "word": "λέγῃ",
  "translation": "él dice",
  "phonetic": "légi"
 },
 {
  "word": "ὄφελος,",
  "translation": "aprovechar,",
  "phonetic": "ófelos,"
 },
 {
  "word": "⸀Τί",
  "translation": "⸀Qué",
  "phonetic": "⸀Tí"
 },
 {
  "word": "κρίσεως.",
  "translation": "crisis.",
  "phonetic": "kríseos."
 },
 {
  "word": "⸀ἔλεος",
  "translation": "⸀merced",
  "phonetic": "⸀éleos"
 },
 {
  "word": "κατακαυχᾶται",
  "translation": "el se jacta",
  "phonetic": "katakafchátai"
 },
 {
  "word": "ἔλεος·",
  "translation": "merced·",
  "phonetic": "éleos:"
 },
 {
  "word": "ποιήσαντι",
  "translation": "¿Qué hiciste?",
  "phonetic": "poiísanti"
 },
 {
  "word": "ἀνέλεος",
  "translation": "descuidado",
  "phonetic": "anéleos"
 },
 {
  "word": "κρίσις",
  "translation": "crisis",
  "phonetic": "krísis"
 },
 {
  "word": "κρίνεσθαι.",
  "translation": "ser juzgado.",
  "phonetic": "krínesthai."
 },
 {
  "word": "μέλλοντες",
  "translation": "futuros",
  "phonetic": "méllontes"
 },
 {
  "word": "διὰ",
  "translation": "para",
  "phonetic": "diá"
 },
 {
  "word": "ποιεῖτε",
  "translation": "¿qué estás haciendo?",
  "phonetic": "poieíte"
 },
 {
  "word": "λαλεῖτε",
  "translation": "tu cantas",
  "phonetic": "laleíte"
 },
 {
  "word": "νόμου.",
  "translation": "ley.",
  "phonetic": "nómou."
 },
 {
  "word": "παραβάτης",
  "translation": "delincuente",
  "phonetic": "paravátis"
 },
 {
  "word": "γέγονας",
  "translation": "evento",
  "phonetic": "gégonas"
 },
 {
  "word": "δέ,",
  "translation": "no,",
  "phonetic": "dé,"
 },
 {
  "word": "φονεύεις⸃",
  "translation": "tu matas⸃",
  "phonetic": "fonéveis⸃"
 },
 {
  "word": "⸂μοιχεύεις",
  "translation": "⸂ estás cometiendo adulterio",
  "phonetic": "⸂moichéveis"
 },
 {
  "word": "⸀φονεύσῃς·",
  "translation": "⸀tu matas;",
  "phonetic": "⸀fonéfsis:"
 },
 {
  "word": "καί",
  "translation": "y",
  "phonetic": "kaí"
 },
 {
  "word": "εἶπεν",
  "translation": "dicho",
  "phonetic": "eípen"
 },
 {
  "word": "⸀μοιχεύσῃς",
  "translation": "⸀cometes adulterio",
  "phonetic": "⸀moichéfsis"
 },
 {
  "word": "Μὴ",
  "translation": "No",
  "phonetic": "Mí"
 },
 {
  "word": "εἰπών",
  "translation": "ellos dicen",
  "phonetic": "eipón"
 },
 {
  "word": "ἔνοχος.",
  "translation": "culpable.",
  "phonetic": "énochos."
 },
 {
  "word": "πάντων",
  "translation": "de todos modos",
  "phonetic": "pánton"
 },
 {
  "word": "γέγονεν",
  "translation": "evento",
  "phonetic": "gégonen"
 },
 {
  "word": "ἑνί,",
  "translation": "qué,",
  "phonetic": "ení,"
 },
 {
  "word": "πταίσῃ⸃",
  "translation": "falla⸃",
  "phonetic": "ptaísi⸃"
 },
 {
  "word": "⸂τηρήσῃ,",
  "translation": "⸂mantener",
  "phonetic": "⸂tirísi,"
 },
 {
  "word": "ὅλον",
  "translation": "entero",
  "phonetic": "ólon"
 },
 {
  "word": "ὅστις",
  "translation": "OMS",
  "phonetic": "óstis"
 },
 {
  "word": "παραβάται.",
  "translation": "es violado",
  "phonetic": "paravátai."
 },
 {
  "word": "νόμου",
  "translation": "ley",
  "phonetic": "nómou"
 },
 {
  "word": "ἐλεγχόμενοι",
  "translation": "siendo controlado",
  "phonetic": "elenchómenoi"
 },
 {
  "word": "ἐργάζεσθε,",
  "translation": "tu trabajas",
  "phonetic": "ergázesthe,"
 },
 {
  "word": "ἁμαρτίαν",
  "translation": "pecado",
  "phonetic": "amartían"
 },
 {
  "word": "προσωπολημπτεῖτε,",
  "translation": "compadécete de ti mismo",
  "phonetic": "prosopolimpteíte,"
 },
 {
  "word": "εἰ",
  "translation": "eh",
  "phonetic": "ei"
 },
 {
  "word": "ποιεῖτε·",
  "translation": "Tú",
  "phonetic": "poieíte:"
 },
 {
  "word": "καλῶς",
  "translation": "Bueno",
  "phonetic": "kalós"
 },
 {
  "word": "σεαυτόν,",
  "translation": "en él,",
  "phonetic": "seaftón,"
 },
 {
  "word": "σου",
  "translation": "tú",
  "phonetic": "sou"
 },
 {
  "word": "πλησίον",
  "translation": "cerca",
  "phonetic": "plisíon"
 },
 {
  "word": "Ἀγαπήσεις",
  "translation": "Ama",
  "phonetic": "Agapíseis"
 },
 {
  "word": "γραφήν",
  "translation": "escribiendo",
  "phonetic": "grafín"
 },
 {
  "word": "κατὰ",
  "translation": "contra",
  "phonetic": "katá"
 },
 {
  "word": "βασιλικὸν",
  "translation": "Albahaca",
  "phonetic": "vasilikón"
 },
 {
  "word": "τελεῖτε",
  "translation": "finalizar",
  "phonetic": "teleíte"
 },
 {
  "word": "μέντοι",
  "translation": "mentas",
  "phonetic": "méntoi"
 },
 {
  "word": "ὑμᾶς;",
  "translation": "¿tú?",
  "phonetic": "ymás?"
 },
 {
  "word": "ἐφ’",
  "translation": "si",
  "phonetic": "ef’"
 },
 {
  "word": "ἐπικληθὲν",
  "translation": "invocado",
  "phonetic": "epiklithén"
 },
 {
  "word": "ὄνομα",
  "translation": "nombre",
  "phonetic": "ónoma"
 },
 {
  "word": "καλὸν",
  "translation": "bien",
  "phonetic": "kalón"
 },
 {
  "word": "βλασφημοῦσιν",
  "translation": "ellos blasfeman",
  "phonetic": "vlasfimoúsin"
 },
 {
  "word": "κριτήρια;",
  "translation": "Criterios;",
  "phonetic": "kritíria?"
 },
 {
  "word": "ὑμᾶς",
  "translation": "tú",
  "phonetic": "ymás"
 },
 {
  "word": "ἕλκουσιν",
  "translation": "úlcera",
  "phonetic": "élkousin"
 },
 {
  "word": "αὐτοὶ",
  "translation": "estos",
  "phonetic": "aftoí"
 },
 {
  "word": "ὑμῶν,",
  "translation": "de ti",
  "phonetic": "ymón,"
 },
 {
  "word": "καταδυναστεύουσιν",
  "translation": "oprimido",
  "phonetic": "katadynastévousin"
 },
 {
  "word": "πλούσιοι",
  "translation": "rico",
  "phonetic": "ploúsioi"
 },
 {
  "word": "οἱ",
  "translation": "el",
  "phonetic": "oi"
 },
 {
  "word": "πτωχόν.",
  "translation": "pobre.",
  "phonetic": "ptochón."
 },
 {
  "word": "ἠτιμάσατε",
  "translation": "apreciaste",
  "phonetic": "itimásate"
 },
 {
  "word": "ὑμεῖς",
  "translation": "S.M",
  "phonetic": "ymeís"
 },
 {
  "word": "αὐτόν;",
  "translation": "a él;",
  "phonetic": "aftón?"
 },
 {
  "word": "ἐπηγγείλατο",
  "translation": "fue insultado",
  "phonetic": "epingeílato"
 },
 {
  "word": "ἧς",
  "translation": "ἧς",
  "phonetic": "ís"
 },
 {
  "word": "βασιλείας",
  "translation": "reinado",
  "phonetic": "vasileías"
 },
 {
  "word": "κληρονόμους",
  "translation": "herederos",
  "phonetic": "klironómous"
 },
 {
  "word": "πίστει",
  "translation": "cree",
  "phonetic": "pístei"
 },
 {
  "word": "πλουσίους",
  "translation": "gente rica",
  "phonetic": "plousíous"
 },
 {
  "word": "κόσμῳ⸃",
  "translation": "mundo",
  "phonetic": "kósmo⸃"
 },
 {
  "word": "⸂τῷ",
  "translation": "⸂τῷ",
  "phonetic": "⸂tó"
 },
 {
  "word": "πτωχοὺς",
  "translation": "Gente pobre",
  "phonetic": "ptochoús"
 },
 {
  "word": "τοὺς",
  "translation": "su",
  "phonetic": "toús"
 },
 {
  "word": "ἐξελέξατο",
  "translation": "elegido",
  "phonetic": "exeléxato"
 },
 {
  "word": "οὐχ",
  "translation": "no",
  "phonetic": "ouch"
 },
 {
  "word": "ἀκούσατε,",
  "translation": "escuchaste",
  "phonetic": "akoúsate,"
 },
 {
  "word": "πονηρῶν;",
  "translation": "¿malvado?",
  "phonetic": "ponirón?"
 },
 {
  "word": "διαλογισμῶν",
  "translation": "meditaciones",
  "phonetic": "dialogismón"
 },
 {
  "word": "κριταὶ",
  "translation": "jueces",
  "phonetic": "kritaí"
 },
 {
  "word": "ἐγένεσθε",
  "translation": "nacer",
  "phonetic": "egénesthe"
 },
 {
  "word": "ἑαυτοῖς",
  "translation": "para ellos mismos",
  "phonetic": "eaftoís"
 },
 {
  "word": "διεκρίθητε",
  "translation": "distinguirte",
  "phonetic": "diekríthite"
 },
 {
  "word": "⸀οὐ",
  "translation": "⸀οὐ",
  "phonetic": "⸀ou"
 },
 {
  "word": "ὑποπόδιόν",
  "translation": "escabel",
  "phonetic": "ypopódión"
 },
 {
  "word": "ἐκεῖ⸃",
  "translation": "allá⸃",
  "phonetic": "ekeí⸃"
 },
 {
  "word": "⸂ἢ",
  "translation": "⸂el",
  "phonetic": "⸂í"
 },
 {
  "word": "στῆθι",
  "translation": "pecho",
  "phonetic": "stíthi"
 },
 {
  "word": "εἴπητε·",
  "translation": "Usted dijo",
  "phonetic": "eípite:"
 },
 {
  "word": "πτωχῷ",
  "translation": "pobre",
  "phonetic": "ptochó"
 },
 {
  "word": "καλῶς,",
  "translation": "Bueno,",
  "phonetic": "kalós,"
 },
 {
  "word": "ὧδε",
  "translation": "aquí",
  "phonetic": "óde"
 },
 {
  "word": "κάθου",
  "translation": "siéntate",
  "phonetic": "káthou"
 },
 {
  "word": "Σὺ",
  "translation": "Tú",
  "phonetic": "Sý"
 },
 {
  "word": "⸀εἴπητε·",
  "translation": "⸀Usted dijo;",
  "phonetic": "⸀eípite:"
 },
 {
  "word": "λαμπρὰν",
  "translation": "brillante",
  "phonetic": "lamprán"
 },
 {
  "word": "ἐσθῆτα",
  "translation": "ser",
  "phonetic": "esthíta"
 },
 {
  "word": "φοροῦντα",
  "translation": "agotador",
  "phonetic": "foroúnta"
 },
 {
  "word": "ἐπὶ",
  "translation": "en",
  "phonetic": "epí"
 },
 {
  "word": "δὲ⸃",
  "translation": "no⸃",
  "phonetic": "dé⸃"
 },
 {
  "word": "⸂ἐπιβλέψητε",
  "translation": "⸂ἐsupervisar",
  "phonetic": "⸂epivlépsite"
 },
 {
  "word": "ἐσθῆτι,",
  "translation": "Sí,",
  "phonetic": "esthíti,"
 },
 {
  "word": "ῥυπαρᾷ",
  "translation": "ῥυπαρᾷ",
  "phonetic": "rypará"
 },
 {
  "word": "πτωχὸς",
  "translation": "pobre",
  "phonetic": "ptochós"
 },
 {
  "word": "λαμπρᾷ,",
  "translation": "brillante,",
  "phonetic": "lamprá,"
 },
 {
  "word": "ἐσθῆτι",
  "translation": "es",
  "phonetic": "esthíti"
 },
 {
  "word": "χρυσοδακτύλιος",
  "translation": "anillo de oro",
  "phonetic": "chrysodaktýlios"
 },
 {
  "word": "συναγωγὴν",
  "translation": "Sinagoga",
  "phonetic": "synagogín"
 },
 {
  "word": "⸀εἰς",
  "translation": "⸀en",
  "phonetic": "⸀eis"
 },
 {
  "word": "εἰσέλθῃ",
  "translation": "ingresar",
  "phonetic": "eisélthi"
 },
 {
  "word": "ἐὰν",
  "translation": "si",
  "phonetic": "eán"
 },
 {
  "word": "δόξης;",
  "translation": "¿gloria?",
  "phonetic": "dóxis?"
 },
 {
  "word": "ἡμῶν",
  "translation": "nuestro",
  "phonetic": "imón"
 },
 {
  "word": "πίστιν",
  "translation": "creer",
  "phonetic": "pístin"
 },
 {
  "word": "τὴν",
  "translation": "el",
  "phonetic": "tín"
 },
 {
  "word": "ἔχετε",
  "translation": "usted tiene",
  "phonetic": "échete"
 },
 {
  "word": "προσωπολημψίαις",
  "translation": "lesiones personales",
  "phonetic": "prosopolimpsíais"
 },
 {
  "word": "Ἀδελφοί",
  "translation": "Hermanos de religion",
  "phonetic": "Adelfoí"
 },
 {
  "word": "κόσμου.",
  "translation": "mundo.",
  "phonetic": "kósmou."
 },
 {
  "word": "τηρεῖν",
  "translation": "adherirse a",
  "phonetic": "tireín"
 },
 {
  "word": "ἄσπιλον",
  "translation": "aspilón",
  "phonetic": "áspilon"
 },
 {
  "word": "αὐτῶν,",
  "translation": "de ellos,",
  "phonetic": "aftón,"
 },
 {
  "word": "θλίψει",
  "translation": "afligirse",
  "phonetic": "thlípsei"
 },
 {
  "word": "χήρας",
  "translation": "viuda",
  "phonetic": "chíras"
 },
 {
  "word": "ὀρφανοὺς",
  "translation": "huérfanos",
  "phonetic": "orfanoús"
 },
 {
  "word": "ἐπισκέπτεσθαι",
  "translation": "estas visitando",
  "phonetic": "episképtesthai"
 },
 {
  "word": "ἐστίν,",
  "translation": "es,",
  "phonetic": "estín,"
 },
 {
  "word": "αὕτη",
  "translation": "ella",
  "phonetic": "áfti"
 },
 {
  "word": "πατρὶ",
  "translation": "padre",
  "phonetic": "patrí"
 },
 {
  "word": "θεῷ",
  "translation": "Dios",
  "phonetic": "theó"
 },
 {
  "word": "⸀τῷ",
  "translation": "⸀τῷ",
  "phonetic": "⸀tó"
 },
 {
  "word": "ἀμίαντος",
  "translation": "amianto",
  "phonetic": "amíantos"
 },
 {
  "word": "καθαρὰ",
  "translation": "neto",
  "phonetic": "kathará"
 },
 {
  "word": "θρησκεία",
  "translation": "religión",
  "phonetic": "thriskeía"
 },
 {
  "word": "θρησκεία.",
  "translation": "religión.",
  "phonetic": "thriskeía."
 },
 {
  "word": "μάταιος",
  "translation": "vano",
  "phonetic": "mátaios"
 },
 {
  "word": "τούτου",
  "translation": "del mismo",
  "phonetic": "toútou"
 },
 {
  "word": "⸀αὐτοῦ,",
  "translation": "⸀su,",
  "phonetic": "⸀aftoú,"
 },
 {
  "word": "καρδίαν",
  "translation": "corazón",
  "phonetic": "kardían"
 },
 {
  "word": "ἀπατῶν",
  "translation": "de engaño",
  "phonetic": "apatón"
 },
 {
  "word": "⸀αὐτοῦ",
  "translation": "⸀su",
  "phonetic": "⸀aftoú"
 },
 {
  "word": "γλῶσσαν",
  "translation": "ellos hablaron",
  "phonetic": "glóssan"
 },
 {
  "word": "χαλιναγωγῶν",
  "translation": "bridas",
  "phonetic": "chalinagogón"
 },
 {
  "word": "⸀εἶναι",
  "translation": "⸀es",
  "phonetic": "⸀eínai"
 },
 {
  "word": "θρησκὸς",
  "translation": "religioso",
  "phonetic": "thriskós"
 },
 {
  "word": "δοκεῖ",
  "translation": "intentar",
  "phonetic": "dokeí"
 },
 {
  "word": "Εἴ",
  "translation": "eh",
  "phonetic": "Eí"
 },
 {
  "word": "ἔσται.",
  "translation": "es.",
  "phonetic": "éstai."
 },
 {
  "word": "ποιήσει",
  "translation": "qué",
  "phonetic": "poiísei"
 },
 {
  "word": "μακάριος",
  "translation": "feliz",
  "phonetic": "makários"
 },
 {
  "word": "ἔργου,",
  "translation": "trabajar,",
  "phonetic": "érgou,"
 },
 {
  "word": "ποιητὴς",
  "translation": "poeta",
  "phonetic": "poiitís"
 },
 {
  "word": "ἀλλὰ",
  "translation": "pero",
  "phonetic": "allá"
 },
 {
  "word": "ἐπιλησμονῆς",
  "translation": "de religión",
  "phonetic": "epilismonís"
 },
 {
  "word": "⸀οὐκ",
  "translation": "⸀UNED",
  "phonetic": "⸀ouk"
 },
 {
  "word": "παραμείνας,",
  "translation": "te quedas",
  "phonetic": "parameínas,"
 },
 {
  "word": "ἐλευθερίας",
  "translation": "libertad",
  "phonetic": "eleftherías"
 },
 {
  "word": "νόμον",
  "translation": "ley",
  "phonetic": "nómon"
 },
 {
  "word": "παρακύψας",
  "translation": "te desviaste",
  "phonetic": "parakýpsas"
 },
 {
  "word": "ἦν.",
  "translation": "era.",
  "phonetic": "ín."
 },
 {
  "word": "ὁποῖος",
  "translation": "Cualquier",
  "phonetic": "opoíos"
 },
 {
  "word": "ἐπελάθετο",
  "translation": "superlativo",
  "phonetic": "epelátheto"
 },
 {
  "word": "εὐθέως",
  "translation": "directamente",
  "phonetic": "efthéos"
 },
 {
  "word": "ἀπελήλυθεν",
  "translation": "absolutamente",
  "phonetic": "apelílythen"
 },
 {
  "word": "ἑαυτὸν",
  "translation": "él mismo",
  "phonetic": "eaftón"
 },
 {
  "word": "κατενόησεν",
  "translation": "comprendido",
  "phonetic": "katenóisen"
 },
 {
  "word": "ἐσόπτρῳ,",
  "translation": "abierto,",
  "phonetic": "esóptro,"
 },
 {
  "word": "γενέσεως",
  "translation": "de nacimiento",
  "phonetic": "genéseos"
 },
 {
  "word": "πρόσωπον",
  "translation": "persona",
  "phonetic": "prósopon"
 },
 {
  "word": "κατανοοῦντι",
  "translation": "comprensible",
  "phonetic": "katanooúnti"
 },
 {
  "word": "ἀνδρὶ",
  "translation": "hombre",
  "phonetic": "andrí"
 },
 {
  "word": "οὗτος",
  "translation": "Éste",
  "phonetic": "oútos"
 },
 {
  "word": "ποιητής,",
  "translation": "poeta,",
  "phonetic": "poiitís,"
 },
 {
  "word": "οὐ",
  "translation": "No",
  "phonetic": "ou"
 },
 {
  "word": "ἐστὶν",
  "translation": "es",
  "phonetic": "estín"
 },
 {
  "word": "ἀκροατὴς",
  "translation": "oyente",
  "phonetic": "akroatís"
 },
 {
  "word": "εἴ",
  "translation": "ey",
  "phonetic": "eí"
 },
 {
  "word": "ἑαυτούς.",
  "translation": "ellos mismos.",
  "phonetic": "eaftoús."
 },
 {
  "word": "παραλογιζόμενοι",
  "translation": "Inventado excusas",
  "phonetic": "paralogizómenoi"
 },
 {
  "word": "μόνον⸃",
  "translation": "solo⸃",
  "phonetic": "mónon⸃"
 },
 {
  "word": "⸂ἀκροαταὶ",
  "translation": "⸂ἀκροατὶ",
  "phonetic": "⸂akroataí"
 },
 {
  "word": "λόγου",
  "translation": "razón",
  "phonetic": "lógou"
 },
 {
  "word": "ποιηταὶ",
  "translation": "poeta",
  "phonetic": "poiitaí"
 },
 {
  "word": "Γίνεσθε",
  "translation": "Ser uno mismo",
  "phonetic": "Gínesthe"
 },
 {
  "word": "ὑμῶν.",
  "translation": "de ti.",
  "phonetic": "ymón."
 },
 {
  "word": "ψυχὰς",
  "translation": "alma",
  "phonetic": "psychás"
 },
 {
  "word": "τὰς",
  "translation": "el",
  "phonetic": "tás"
 },
 {
  "word": "σῶσαι",
  "translation": "ahorras",
  "phonetic": "sósai"
 },
 {
  "word": "δυνάμενον",
  "translation": "posible",
  "phonetic": "dynámenon"
 },
 {
  "word": "λόγον",
  "translation": "razón",
  "phonetic": "lógon"
 },
 {
  "word": "ἔμφυτον",
  "translation": "innatamente",
  "phonetic": "émfyton"
 },
 {
  "word": "δέξασθε",
  "translation": "aceptado",
  "phonetic": "déxasthe"
 },
 {
  "word": "πραΰτητι",
  "translation": "dulzura",
  "phonetic": "praftiti"
 },
 {
  "word": "κακίας",
  "translation": "de malicia",
  "phonetic": "kakías"
 },
 {
  "word": "περισσείαν",
  "translation": "demasiado",
  "phonetic": "perisseían"
 },
 {
  "word": "ῥυπαρίαν",
  "translation": "ῥyparian",
  "phonetic": "ryparían"
 },
 {
  "word": "πᾶσαν",
  "translation": "todos",
  "phonetic": "pásan"
 },
 {
  "word": "ἀποθέμενοι",
  "translation": "almacenado",
  "phonetic": "apothémenoi"
 },
 {
  "word": "διὸ",
  "translation": "a través de",
  "phonetic": "dió"
 },
 {
  "word": "ἐργάζεται⸃.",
  "translation": "él trabaja⸃.",
  "phonetic": "ergázetai⸃."
 },
 {
  "word": "⸂οὐκ",
  "translation": "⸂ouὐ",
  "phonetic": "⸂ouk"
 },
 {
  "word": "δικαιοσύνην",
  "translation": "justicia",
  "phonetic": "dikaiosýnin"
 },
 {
  "word": "ἀνδρὸς",
  "translation": "del hombre",
  "phonetic": "andrós"
 },
 {
  "word": "ὀργὴ",
  "translation": "enojo",
  "phonetic": "orgí"
 },
 {
  "word": "ὀργήν,",
  "translation": "ira,",
  "phonetic": "orgín,"
 },
 {
  "word": "λαλῆσαι,",
  "translation": "cantar,",
  "phonetic": "lalísai,"
 },
 {
  "word": "βραδὺς",
  "translation": "lento",
  "phonetic": "vradýs"
 },
 {
  "word": "ἀκοῦσαι,",
  "translation": "oyes",
  "phonetic": "akoúsai,"
 },
 {
  "word": "ταχὺς",
  "translation": "rápido",
  "phonetic": "tachýs"
 },
 {
  "word": "πᾶς",
  "translation": "anda tu",
  "phonetic": "pás"
 },
 {
  "word": "⸀δὲ",
  "translation": "⸀no",
  "phonetic": "⸀dé"
 },
 {
  "word": "ἔστω",
  "translation": "al menos",
  "phonetic": "ésto"
 },
 {
  "word": "⸀Ἴστε,",
  "translation": "⸀Ἴste,",
  "phonetic": "⸀Íste,"
 },
 {
  "word": "κτισμάτων.",
  "translation": "de edificios",
  "phonetic": "ktismáton."
 },
 {
  "word": "τινα",
  "translation": "Tina",
  "phonetic": "tina"
 },
 {
  "word": "ἀπαρχήν",
  "translation": "en primer lugar",
  "phonetic": "aparchín"
 },
 {
  "word": "εἶναι",
  "translation": "es",
  "phonetic": "eínai"
 },
 {
  "word": "εἰς",
  "translation": "en",
  "phonetic": "eis"
 },
 {
  "word": "ἀληθείας,",
  "translation": "verdad,",
  "phonetic": "alitheías,"
 },
 {
  "word": "λόγῳ",
  "translation": "razón",
  "phonetic": "lógo"
 },
 {
  "word": "ἡμᾶς",
  "translation": "a nosotros",
  "phonetic": "imás"
 },
 {
  "word": "ἀπεκύησεν",
  "translation": "abandonó",
  "phonetic": "apekýisen"
 },
 {
  "word": "βουληθεὶς",
  "translation": "deseoso",
  "phonetic": "voulitheís"
 },
 {
  "word": "ἀποσκίασμα.",
  "translation": "sombra.",
  "phonetic": "aposkíasma."
 },
 {
  "word": "τροπῆς",
  "translation": "manera",
  "phonetic": "tropís"
 },
 {
  "word": "ἢ",
  "translation": "el",
  "phonetic": "í"
 },
 {
  "word": "παραλλαγὴ",
  "translation": "variación",
  "phonetic": "parallagí"
 },
 {
  "word": "ἔνι",
  "translation": "año",
  "phonetic": "éni"
 },
 {
  "word": "οὐκ",
  "translation": "No",
  "phonetic": "ouk"
 },
 {
  "word": "ᾧ",
  "translation": "ᾧ",
  "phonetic": "ó"
 },
 {
  "word": "παρ’",
  "translation": "a pesar de",
  "phonetic": "par’"
 },
 {
  "word": "φώτων,",
  "translation": "luces,",
  "phonetic": "fóton,"
 },
 {
  "word": "τῶν",
  "translation": "de",
  "phonetic": "tón"
 },
 {
  "word": "πατρὸς",
  "translation": "padre",
  "phonetic": "patrós"
 },
 {
  "word": "ἀπὸ",
  "translation": "de",
  "phonetic": "apó"
 },
 {
  "word": "καταβαῖνον",
  "translation": "bajando",
  "phonetic": "katavaínon"
 },
 {
  "word": "ἐστιν,",
  "translation": "es,",
  "phonetic": "estin,"
 },
 {
  "word": "ἄνωθέν",
  "translation": "arriba",
  "phonetic": "ánothén"
 },
 {
  "word": "δώρημα",
  "translation": "regalo",
  "phonetic": "dórima"
 },
 {
  "word": "πᾶν",
  "translation": "Cacerola",
  "phonetic": "pán"
 },
 {
  "word": "ἀγαθὴ",
  "translation": "bien",
  "phonetic": "agathí"
 },
 {
  "word": "δόσις",
  "translation": "dosis",
  "phonetic": "dósis"
 },
 {
  "word": "Πᾶσα",
  "translation": "Todo",
  "phonetic": "Pása"
 },
 {
  "word": "ἀγαπητοί.",
  "translation": "Estimado.",
  "phonetic": "agapitoí."
 },
 {
  "word": "μου",
  "translation": "mi",
  "phonetic": "mou"
 },
 {
  "word": "πλανᾶσθε,",
  "translation": "Está usted equivocado",
  "phonetic": "planásthe,"
 },
 {
  "word": "θάνατον.",
  "translation": "muerte.",
  "phonetic": "thánaton."
 },
 {
  "word": "ἀποκύει",
  "translation": "resultados",
  "phonetic": "apokýei"
 },
 {
  "word": "ἀποτελεσθεῖσα",
  "translation": "Ya lo había hecho",
  "phonetic": "apotelestheísa"
 },
 {
  "word": "ἁμαρτία",
  "translation": "pecado",
  "phonetic": "amartía"
 },
 {
  "word": "ἁμαρτίαν,",
  "translation": "pecado,",
  "phonetic": "amartían,"
 },
 {
  "word": "τίκτει",
  "translation": "hace tictac",
  "phonetic": "tíktei"
 },
 {
  "word": "συλλαβοῦσα",
  "phonetic": "syllavoúsa"
 },
 {
  "word": "ἐπιθυμία",
  "phonetic": "epithymía"
 },
 {
  "word": "εἶτα",
  "phonetic": "eíta"
 },
 {
  "word": "δελεαζόμενος·",
  "phonetic": "deleazómenos:"
 },
 {
  "word": "ἐξελκόμενος",
  "phonetic": "exelkómenos"
 },
 {
  "word": "ἐπιθυμίας",
  "phonetic": "epithymías"
 },
 {
  "word": "ἰδίας",
  "phonetic": "idías"
 },
 {
  "word": "ὑπὸ",
  "phonetic": "ypó"
 },
 {
  "word": "πειράζεται",
  "phonetic": "peirázetai"
 },
 {
  "word": "ἕκαστος",
  "phonetic": "ékastos"
 },
 {
  "word": "οὐδένα.",
  "phonetic": "oudéna."
 },
 {
  "word": "αὐτὸς",
  "phonetic": "aftós"
 },
 {
  "word": "πειράζει",
  "phonetic": "peirázei"
 },
 {
  "word": "κακῶν,",
  "phonetic": "kakón,"
 },
 {
  "word": "ἐστιν",
  "phonetic": "estin"
 },
 {
  "word": "ἀπείραστός",
  "phonetic": "apeírastós"
 },
 {
  "word": "θεὸς",
  "translation": "dios",
  "phonetic": "theós"
 },
 {
  "word": "πειράζομαι·",
  "translation": "mal humor·",
  "phonetic": "peirázomai:"
 },
 {
  "word": "Ἀπὸ",
  "translation": "De",
  "phonetic": "Apó"
 },
 {
  "word": "λεγέτω",
  "translation": "decir",
  "phonetic": "legéto"
 },
 {
  "word": "πειραζόμενος",
  "translation": "broma",
  "phonetic": "peirazómenos"
 },
 {
  "word": "μηδεὶς",
  "translation": "cero",
  "phonetic": "mideís"
 },
 {
  "word": "αὐτόν.",
  "translation": "a él.",
  "phonetic": "aftón."
 },
 {
  "word": "ἀγαπῶσιν",
  "translation": "amar",
  "phonetic": "agapósin"
 },
 {
  "word": "τοῖς",
  "translation": "a ellos",
  "phonetic": "toís"
 },
 {
  "word": "⸀ἐπηγγείλατο",
  "translation": "⸀ordenado",
  "phonetic": "⸀epingeílato"
 },
 {
  "word": "ὃν",
  "translation": "OMS",
  "phonetic": "ón"
 },
 {
  "word": "ζωῆς,",
  "translation": "vida,",
  "phonetic": "zoís,"
 },
 {
  "word": "στέφανον",
  "translation": "Stefanon",
  "phonetic": "stéfanon"
 },
 {
  "word": "λήμψεται",
  "translation": "se toma",
  "phonetic": "límpsetai"
 },
 {
  "word": "γενόμενος",
  "translation": "nacer",
  "phonetic": "genómenos"
 },
 {
  "word": "δόκιμος",
  "translation": "cadete",
  "phonetic": "dókimos"
 },
 {
  "word": "πειρασμόν,",
  "translation": "tentación,",
  "phonetic": "peirasmón,"
 },
 {
  "word": "ὑπομένει",
  "translation": "perdura",
  "phonetic": "ypoménei"
 },
 {
  "word": "ὃς",
  "translation": "OMS",
  "phonetic": "ós"
 },
 {
  "word": "Μακάριος",
  "translation": "Feliz",
  "phonetic": "Makários"
 },
 {
  "word": "μαρανθήσεται.",
  "translation": "se marchita",
  "phonetic": "maranthísetai."
 },
 {
  "word": "πορείαις",
  "translation": "marzo",
  "phonetic": "poreíais"
 },
 {
  "word": "οὕτως",
  "translation": "como eso",
  "phonetic": "oútos"
 },
 {
  "word": "ἀπώλετο·",
  "translation": "desperdiciado;",
  "phonetic": "apóleto:"
 },
 {
  "word": "προσώπου",
  "translation": "persona",
  "phonetic": "prosópou"
 },
 {
  "word": "εὐπρέπεια",
  "translation": "decencia",
  "phonetic": "efprépeia"
 },
 {
  "word": "ἐξέπεσεν",
  "translation": "Se cayó",
  "phonetic": "exépesen"
 },
 {
  "word": "αὐτοῦ",
  "translation": "su",
  "phonetic": "aftoú"
 },
 {
  "word": "χόρτον,",
  "translation": "césped,",
  "phonetic": "chórton,"
 },
 {
  "word": "τὸν",
  "translation": "el",
  "phonetic": "tón"
 },
 {
  "word": "ἐξήρανεν",
  "translation": "secado",
  "phonetic": "exíranen"
 },
 {
  "word": "καύσωνι",
  "translation": "ola de calor",
  "phonetic": "káfsoni"
 },
 {
  "word": "σὺν",
  "translation": "más",
  "phonetic": "sýn"
 },
 {
  "word": "ἥλιος",
  "translation": "sol",
  "phonetic": "ílios"
 },
 {
  "word": "ἀνέτειλεν",
  "translation": "se levantaron",
  "phonetic": "anéteilen"
 },
 {
  "word": "παρελεύσεται.",
  "translation": "se desfila.",
  "phonetic": "pareléfsetai."
 },
 {
  "word": "χόρτου",
  "translation": "de hierba",
  "phonetic": "chórtou"
 },
 {
  "word": "ἄνθος",
  "translation": "flor",
  "phonetic": "ánthos"
 },
 {
  "word": "ὡς",
  "translation": "como",
  "phonetic": "os"
 },
 {
  "word": "ταπεινώσει",
  "translation": "humillar",
  "phonetic": "tapeinósei"
 },
 {
  "word": "πλούσιος",
  "translation": "rico",
  "phonetic": "ploúsios"
 },
 {
  "word": "αὐτοῦ,",
  "translation": "su,",
  "phonetic": "aftoú,"
 },
 {
  "word": "ὕψει",
  "translation": "rostro",
  "phonetic": "ýpsei"
 },
 {
  "word": "τῷ",
  "translation": "a",
  "phonetic": "tó"
 },
 {
  "word": "ταπεινὸς",
  "translation": "humilde",
  "phonetic": "tapeinós"
 },
 {
  "word": "ἀδελφὸς",
  "translation": "hermano",
  "phonetic": "adelfós"
 },
 {
  "word": "Καυχάσθω",
  "translation": "estoy presumiendo",
  "phonetic": "Kafchástho"
 },
 {
  "word": "αὐτοῦ.",
  "translation": "su.",
  "phonetic": "aftoú."
 },
 {
  "word": "ὁδοῖς",
  "translation": "carreteras",
  "phonetic": "odoís"
 },
 {
  "word": "πάσαις",
  "translation": "Todos ustedes",
  "phonetic": "pásais"
 },
 {
  "word": "ἀκατάστατος",
  "translation": "desordenado",
  "phonetic": "akatástatos"
 },
 {
  "word": "δίψυχος,",
  "translation": "de dos corazones,",
  "phonetic": "dípsychos,"
 },
 {
  "word": "ἀνὴρ",
  "translation": "hombre",
  "phonetic": "anír"
 },
 {
  "word": "τι",
  "translation": "qué",
  "phonetic": "ti"
 },
 {
  "word": "λήμψεταί",
  "translation": "se toma",
  "phonetic": "límpsetaí"
 },
 {
  "word": "ἐκεῖνος",
  "translation": "eso",
  "phonetic": "ekeínos"
 },
 {
  "word": "ἄνθρωπος",
  "translation": "persona",
  "phonetic": "ánthropos"
 },
 {
  "word": "οἰέσθω",
  "translation": "estoy aquí",
  "phonetic": "oiéstho"
 },
 {
  "word": "μὴ",
  "translation": "no",
  "phonetic": "mí"
 },
 {
  "word": "ῥιπιζομένῳ·",
  "translation": "ῥιπιZOμένῳ·",
  "phonetic": "ripizoméno:"
 },
 {
  "word": "ἀνεμιζομένῳ",
  "translation": "admirador",
  "phonetic": "anemizoméno"
 },
 {
  "word": "θαλάσσης",
  "translation": "mar",
  "phonetic": "thalássis"
 },
 {
  "word": "κλύδωνι",
  "translation": "klydoni",
  "phonetic": "klýdoni"
 },
 {
  "word": "ἔοικεν",
  "translation": "Oicen",
  "phonetic": "éoiken"
 },
 {
  "word": "διακρινόμενος",
  "translation": "distinguido",
  "phonetic": "diakrinómenos"
 },
 {
  "word": "γὰρ",
  "translation": "porque",
  "phonetic": "gár"
 },
 {
  "word": "ὁ",
  "translation": "El",
  "phonetic": "o"
 },
 {
  "word": "διακρινόμενος,",
  "translation": "distinguido,",
  "phonetic": "diakrinómenos,"
 },
 {
  "word": "μηδὲν",
  "translation": "cero",
  "phonetic": "midén"
 },
 {
  "word": "πίστει,",
  "translation": "cree",
  "phonetic": "pístei,"
 },
 {
  "word": "αὐτῷ·",
  "translation": "αὐτῷ",
  "phonetic": "aftó:"
 },
 {
  "word": "δοθήσεται",
  "translation": "es dado",
  "phonetic": "dothísetai"
 },
 {
  "word": "ὀνειδίζοντος,",
  "translation": "ignorante,",
  "phonetic": "oneidízontos,"
 },
 {
  "word": "⸀μὴ",
  "translation": "⸀no",
  "phonetic": "⸀mí"
 },
 {
  "word": "ἁπλῶς",
  "translation": "simplemente",
  "phonetic": "aplós"
 },
 {
  "word": "πᾶσιν",
  "translation": "todos",
  "phonetic": "pásin"
 },
 {
  "word": "διδόντος",
  "translation": "didonto",
  "phonetic": "didóntos"
 },
 {
  "word": "τοῦ",
  "translation": "de",
  "phonetic": "toú"
 },
 {
  "word": "παρὰ",
  "translation": "a pesar de",
  "phonetic": "pará"
 },
 {
  "word": "αἰτείτω",
  "translation": "por favor",
  "phonetic": "aiteíto"
 },
 {
  "word": "σοφίας,",
  "translation": "sabiduría,",
  "phonetic": "sofías,"
 },
 {
  "word": "λείπεται",
  "translation": "Está perdido",
  "phonetic": "leípetai"
 },
 {
  "word": "τις",
  "translation": "a ellos",
  "phonetic": "tis"
 },
 {
  "word": "δέ",
  "translation": "no",
  "phonetic": "dé"
 },
 {
  "word": "Εἰ",
  "translation": "mi",
  "phonetic": "Ei"
 },
 {
  "word": "λειπόμενοι.",
  "translation": "desaparecido.",
  "phonetic": "leipómenoi."
 },
 {
  "word": "μηδενὶ",
  "translation": "cero",
  "phonetic": "midení"
 },
 {
  "word": "ὁλόκληροι,",
  "translation": "entero,",
  "phonetic": "olókliroi,"
 },
 {
  "word": "τέλειοι",
  "translation": "perfecto",
  "phonetic": "téleioi"
 },
 {
  "word": "ἦτε",
  "translation": "estabas",
  "phonetic": "íte"
 },
 {
  "word": "ἵνα",
  "translation": "fibra",
  "phonetic": "ína"
 },
 {
  "word": "ἐχέτω,",
  "translation": "aquí,",
  "phonetic": "echéto,"
 },
 {
  "word": "τέλειον",
  "translation": "perfecto",
  "phonetic": "téleion"
 },
 {
  "word": "ἔργον",
  "translation": "trabajar",
  "phonetic": "érgon"
 },
 {
  "word": "ὑπομονὴ",
  "translation": "paciencia",
  "phonetic": "ypomoní"
 },
 {
  "word": "δὲ",
  "translation": "no",
  "phonetic": "dé"
 },
 {
  "word": "ἡ",
  "translation": "el",
  "phonetic": "i"
 },
 {
  "word": "ὑπομονήν·",
  "translation": "paciencia;",
  "phonetic": "ypomonín:"
 },
 {
  "word": "κατεργάζεται",
  "translation": "es procesado",
  "phonetic": "katergázetai"
 },
 {
  "word": "πίστεως",
  "translation": "de la fe",
  "phonetic": "písteos"
 },
 {
  "word": "τῆς",
  "translation": "de ella",
  "phonetic": "tís"
 },
 {
  "word": "ὑμῶν",
  "translation": "de ti",
  "phonetic": "ymón"
 },
 {
  "word": "δοκίμιον",
  "translation": "ensayo",
  "phonetic": "dokímion"
 },
 {
  "word": "τὸ",
  "translation": "el",
  "phonetic": "tó"
 },
 {
  "word": "ὅτι",
  "translation": "eso",
  "phonetic": "óti"
 },
 {
  "word": "γινώσκοντες",
  "translation": "conocimiento",
  "phonetic": "ginóskontes"
 },
 {
  "word": "ποικίλοις,",
  "translation": "varios,",
  "phonetic": "poikílois,"
 },
 {
  "word": "περιπέσητε",
  "translation": "caer",
  "phonetic": "peripésite"
 },
 {
  "word": "πειρασμοῖς",
  "translation": "tentaciones",
  "phonetic": "peirasmoís"
 },
 {
  "word": "ὅταν",
  "translation": "cuando",
  "phonetic": "ótan"
 },
 {
  "word": "μου,",
  "translation": "mi,",
  "phonetic": "mou,"
 },
 {
  "word": "ἀδελφοί",
  "translation": "hermanos de religion",
  "phonetic": "adelfoí"
 },
 {
  "word": "ἡγήσασθε,",
  "translation": "eres",
  "phonetic": "igísasthe,"
 },
 {
  "word": "χαρὰν",
  "translation": "feliz",
  "phonetic": "charán"
 },
 {
  "word": "Πᾶσαν",
  "translation": "Todos",
  "phonetic": "Pásan"
 },
 {
  "word": "χαίρειν.",
  "translation": "feliz",
  "phonetic": "chaírein."
 },
 {
  "word": "διασπορᾷ",
  "translation": "dispersión",
  "phonetic": "diasporá"
 },
 {
  "word": "τῇ",
  "translation": "ῇ",
  "phonetic": "tí"
 },
 {
  "word": "ἐν",
  "translation": "en",
  "phonetic": "en"
 },
 {
  "word": "φυλαῖς",
  "translation": "clanes",
  "phonetic": "fylaís"
 },
 {
  "word": "δώδεκα",
  "translation": "doce",
  "phonetic": "dódeka"
 },
 {
  "word": "ταῖς",
  "translation": "a ellos",
  "phonetic": "taís"
 },
 {
  "word": "δοῦλος",
  "translation": "esclavo",
  "phonetic": "doúlos"
 },
 {
  "word": "Χριστοῦ",
  "translation": "de Cristo",
  "phonetic": "Christoú"
 },
 {
  "word": "Ἰησοῦ",
  "translation": "Jesús",
  "phonetic": "Iisoú"
 },
 {
  "word": "κυρίου",
  "translation": "Señor",
  "phonetic": "kyríou"
 },
 {
  "word": "καὶ",
  "translation": "y",
  "phonetic": "kaí"
 },
 {
  "word": "θεοῦ",
  "translation": "de Dios",
  "phonetic": "theoú"
 },
 {
  "word": "Ἰάκωβος",
  "translation": "jacob",
  "phonetic": "Iákovos"
 }
]