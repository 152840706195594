import Box from '@mui/material/Box'
import React, { useState, useEffect, useRef } from 'react'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import { useEditContext } from '../../../contexts/EditContext';
import { useParams } from 'react-router-dom';
import { DataSnapshot, onValue, ref, set, update } from 'firebase/database';
import { rtdb } from '../../../firebase/setup';
import { iBlockListItem, iBlockType, iTextBlock } from '../../iEditor';
import EditMenu from './EditMenu';

interface EditableBlockProps {
    block_metadata: iBlockListItem;
}

export default function EditableBlock({ block_metadata }: EditableBlockProps) {

    const { type } = block_metadata

    const { document_id, branch_id, version_id,  } = useParams()
    const { updateSettings, setUpdateSettings, requestDatabaseSave } = useEditContext()
    const [ unsavedChanges, setUnsavedChanges ] = useState<boolean>(false)
    const [ editorContent, setEditorContent ] = useState<undefined | string>();
    const updateTimeout = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
        console.log('EditableBlock: block_metadata changed:', block_metadata);
    }, [])

    useEffect(() => {
        const blockDataPath = `documents/content/${document_id}/branches/${branch_id}/${version_id}/blocks/data/${block_metadata.id}`;
        const blockDataRef = ref(rtdb, blockDataPath);

        const handleData = (snapshot: DataSnapshot) => {
            if (snapshot.exists()) {
                const blockData = snapshot.val() as iTextBlock;
                console.log('Got block data:', blockData);
                setEditorContent(blockData.data.text);
            } else {
            console.log('No block data available');
            }
        };

        const unsubscribe = onValue(blockDataRef, handleData, (error) => {
            console.error('Failed to get block data:', error);
        });

        return () => {
            unsubscribe();
        };
        }, [block_metadata]);

    useEffect(() => {
        if (unsavedChanges) {
            const updateRef = ref(rtdb, `documents/content/${document_id}/branches/${branch_id}/${version_id}/blocks/data/${block_metadata.id}/data/text`); // Replace with the appropriate path
            set(updateRef, editorContent).then(() => {
                setUnsavedChanges(false);
            }).catch((error) => {
                console.error('Error updating data:', error);
            });
        }
    }, [requestDatabaseSave]);

    // useEffect(() => {
    //     if (updateSettings.updateMethod === 'live' && updateSettings.updateOptions.liveType === 'continuous') {
    //         handleDatabaseUpdate(editorContent);
    //     } else if (updateSettings.updateMethod === 'live' && updateSettings.updateOptions.liveType === 'throttled') {
    //         if (updateTimeout.current) {
    //             clearTimeout(updateTimeout.current);
    //         }
    //         updateTimeout.current = setTimeout(() => {
    //             handleDatabaseUpdate(editorContent);
    //         }, updateSettings.updateOptions.throttledDelay);
    //     } else if (updateSettings.updateMethod === 'onInterval' && updateSettings.updateOptions.interval > 0) {
    //         if (updateTimeout.current) {
    //             clearTimeout(updateTimeout.current);
    //         }
    //         updateTimeout.current = setTimeout(() => {
    //             handleDatabaseUpdate(editorContent);
    //         }, updateSettings.updateOptions.interval * 60 * 1000);
    //     }
    //     if (updateTimeout.current) {
    //         clearTimeout(updateTimeout.current);
    //     }
    // }, [editorContent, /* other dependencies from EditContext */]);

    const handleDatabaseUpdate = (newContent: string) => {
        // Perform the database update logic here, e.g., calling an API, updating Firebase, etc.
    };

    const handleEditorChange = (newContent: string) => {
        setEditorContent(newContent);
        console.log(newContent)
        if (!unsavedChanges) {
            setUnsavedChanges(true);
        }
    };

    const deleteBlock = async () => {
        // Define paths to the data
        const blockListPath = `documents/content/${document_id}/branches/${branch_id}/${version_id}/blocks/list/${block_metadata.id}`;
        const blockDataPath = `documents/content/${document_id}/branches/${branch_id}/${version_id}/blocks/data/${block_metadata.id}`;

        // Begin a multi-path update
        const updates: { [key: string]: null } = {};
        updates[blockListPath] = null; // Remove block from the list
        updates[blockDataPath] = null; // Remove block data

        // Perform the update
        try {
            await update(ref(rtdb), updates);
            console.log('Block deleted successfully');
        } catch (error) {
            console.error('Failed to delete block:', error);
        }
    };

    type InnerStyle = {
        [K in iBlockType]?: React.CSSProperties;
    };

    type StyleSet = {
    [key: string]: InnerStyle;
    };

    const styles: StyleSet = {
        standard: {
            text: {
                fontFamily: 'Satoshi Variable',
                fontFeatureSettings: "ss03",
                fontSize: '2.1rem',
                // lineHeight: '2.8rem',
                // letterSpacing: '0.01em',
                // color: '#000000',
                // margin: '0px',
                // padding: '0px',
            },
            h_a: {
                fontFamily: 'Satoshi Variable',
                fontFeatureSettings: "ss03",
                fontSize: '3rem',
                fontVariationSettings: "'wght' 700",
            },
            h_b: {
                fontFamily: 'Satoshi Variable',
                fontFeatureSettings: "ss03",
                fontSize: '2.7rem',
                fontVariationSettings: "'wght' 700",
            },
            h_c: {
                fontFamily: 'Satoshi Variable',
                fontFeatureSettings: "ss03",
                fontSize: '2.4rem',
                fontVariationSettings: "'wght' 700",
            },
            h_d: {
                fontFamily: 'Satoshi Variable',
                fontFeatureSettings: "ss03",
                fontSize: '2.1rem',
                fontVariationSettings: "'wght' 700",
            },
        }
    }

    const style_set: string = 'standard'

    function transformTo(v: iBlockType) {
        const blockDataPath = `documents/content/${document_id}/branches/${branch_id}/${version_id}/blocks/data/${block_metadata.id}/data/type`;
        // block list path
        const blockListPath = `documents/content/${document_id}/branches/${branch_id}/${version_id}/blocks/list/${block_metadata.id}/type`;
        // update block type

        update(ref(rtdb), {
            [blockListPath]: v,
            [blockDataPath]: v,
        }).then(() => {
            console.log('Block type updated successfully');
        }).catch((error) => {
            console.error('Failed to update block type:', error);
        });
        // set(ref(rtdb, blockDataPath), v).then(() => {
        //     console.log('Block type updated successfully');
        // }).catch((error) => {
        //     console.error('Failed to update block type:', error);
        // });
    }

    return (
        <Box
            sx={{
                pb: 1,
                '& .ql-toolbar.ql-snow': {
                    border: 'none !important',
                },
                '& .ql-container.ql-snow': {
                    border: 'none !important',
                },
                '& .ql-editor p, .ql-editor ol, .ql-editor ul, .ql-editor pre, .ql-editor blockquote': {
                    ...styles?.[style_set]?.[type] ?? {},
                },
                '& .ql-editor': {
                    pt: '0px',
                    pb: '0px',
                    // pr: 1,
                },
                display: 'flex',
                flexDirection: 'row',
                // show TBE_ButtonWrapper when hovering over the block
                '& .TBE_ButtonWrapper': {
                    opacity: 0,
                    transition: 'opacity 0.2s ease-in-out',
                },
                '&:hover .TBE_ButtonWrapper': {
                    opacity: 0.7,
                },
            }}
        >
            <Box className='TBE_ButtonWrapper' >
                <EditMenu deleteBlock={deleteBlock} transformTo={transformTo} />
            </Box>
            <Box sx={{ flexGrow: 1, pt: '2.4px' }}>
                <ReactQuill theme='bubble' value={editorContent} onChange={(e) => handleEditorChange(e)}
                    modules={{
                        toolbar: [
                            // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                            ['bold', 'italic', 'underline'],
                            // [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                            // ['link', 'image', 'video'],
                            ['clean']
                        ]
                    }}
                />
            </Box>
        </Box>
    )
}
