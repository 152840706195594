import Box from '@mui/material/Box'
import React from 'react'
import { Oval } from  'react-loader-spinner'

export default function Loading() {
  return (
    <Box
        sx={{
            width: '100vw',
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '& circle': {
              stroke: 'gray !important'
            }
        }}
    >
        <Oval
            color='black'
            height={33}
            width={33}
        />
        {/* Cargando... */}
    </Box>
  )
}
