import React, { useEffect, useState } from 'react'
import BodyLayout from '../../misc/BodyLayout'
import Box from '@mui/material/Box'
import { useParams } from 'react-router-dom'
import { iBlockID, iBlockListItem, iBlockType } from '../iEditor'
import { off, onValue, ref, DataSnapshot, update, child, push } from 'firebase/database'
import { rtdb } from '../../firebase/setup'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'
import TextBlockEdit from '../block components/edit/TextBlockEdit'
import { useEditContext } from '../../contexts/EditContext'

export default function DocumentEditor() {

    const { document_id, branch_id, version_id } = useParams()
    const { updateSettings, setUpdateSettings, setRequestDatabaseSave } = useEditContext()
    const [blocksList, setBlocksList] = useState<iBlockListItem[]>([]);
    const [ addType, setAddType ] = useState<iBlockType>('text');

    useEffect(() => {
        const blocksListRef = ref(rtdb, `documents/content/${document_id}/branches/${branch_id}/${version_id}/blocks/list`);

        const handleData = (snapshot: DataSnapshot) => {
            if (snapshot.exists()) {
                setBlocksList(Object.values(snapshot.val() as { [key: iBlockID]: iBlockListItem }).map((block: iBlockListItem) => {
                    return {
                        id: block.id,
                        type: block.type,
                        index: block.index
                    }
                }));
            } else {
                console.log('No data available');
            }
        };

        onValue(blocksListRef, handleData, (error: Error) => {
            console.error('Failed to get blocks list:', error);
        });

        return () => {
            off(blocksListRef, 'value', handleData);
        };
    }, [document_id, branch_id, version_id]);

    const block_types = [
        {
            name: 'Texto',
            value: 'text',
        }
    ]

    function getDefaultDataForBlockType(blockType: iBlockType) {
        switch (blockType) {
            case 'text':
                return {
                    text: ''
                };
            case 'image':
                return {
                    url: ''
                };
            case 'code':
                return {
                    code: '',
                    language: ''
                };
            default:
                return {};
        }
    }

    const addBlock = async () => {
        const blocksListPath = `documents/content/${document_id}/branches/${branch_id}/${version_id}/blocks/list`;
        const blocksDataPath = `documents/content/${document_id}/branches/${branch_id}/${version_id}/blocks/data`;
        const newBlockRef = push(ref(rtdb, blocksDataPath));
        const newBlockID = newBlockRef.key;
        const newIndex = Object.keys(blocksList).length;

        if (newBlockID) {
            const blockListItem: iBlockListItem = {
                id: newBlockID,
                type: addType,
                index: newIndex
            };

            const updates: any = {};
            updates[`${blocksListPath}/${newBlockID}`] = blockListItem;
            updates[`${blocksDataPath}/${newBlockID}`] = {
                id: newBlockID,
                type: addType,
                data: getDefaultDataForBlockType(addType)
            };

            console.log({
                blocksListPath,
                blocksDataPath,
                newBlockRef,
                newBlockID,
                newIndex,
                blockListItem,
            })
            try {
                await update(ref(rtdb), updates);
                console.log('Block added successfully');
            } catch (error) {
                console.error('Failed to add block:', error);
            }
        } else {
            console.error('Failed to generate new block ID');
        }
    };

    const renderBlock = (block: iBlockListItem) => {
        console.log(block)
        switch (block.type) {
            case 'text':
            case 'h_a':
            case 'h_b':
            case 'h_c':
            case 'h_d':
            case 'h_e':
            case 'h_f':
            case 'h_g':
                return <TextBlockEdit key={block.id} block_metadata={block} />;
            case 'image':
                // return <ImageBlock key={block.id} blockID={block.id} />;
            case 'code':
                // return <CodeBlock key={block.id} blockID={block.id} />;
            default:
                return null;
        }
    };

    return (
        <BodyLayout>
            <Box sx={{ pt: 10 }}>
                {blocksList && blocksList.map((block: iBlockListItem) => renderBlock(block))}
                <Box sx={{ pt: 3 }}>
                    <Divider />
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            p: 2,
                        }}
                    >
                        <FormControl>
                            <InputLabel id="demo-simple-select-label">Tipo</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={addType}
                                label="Tipo"
                                onChange={(event) => {
                                    setAddType(event.target.value as iBlockType);
                                }}
                            >
                                {
                                    block_types.map((block_type, index) => {
                                        return (
                                            <MenuItem key={index} value={block_type.value}>{block_type.name}</MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </FormControl>
                        <Button
                            onClick={addBlock}
                        >
                            Añadir
                        </Button>
                    </Box>
                </Box>
                <Button
                    onClick={() => setRequestDatabaseSave(p => p+1)}
                    fullWidth
                >
                    Guardar
                </Button>
            </Box>
        </BodyLayout>
    )
}
