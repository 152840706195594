export const tischendorf_JAS = `JAS 1:1.1 C Ἰάκωβος Ἰάκωβος N-NSM 2385 Ἰάκωβος ! Ἰάκωβος
JAS 1:1.2 . θεοῦ θεοῦ N-GSM 2316 θεός ! θεός
JAS 1:1.3 . καὶ καὶ CONJ 2532 καί ! καί
JAS 1:1.4 . κυρίου κυρίου N-GSM 2962 κύριος ! κύριος
JAS 1:1.5 . Ἰησοῦ Ἰησοῦ N-GSM 2424 Ἰησοῦς ! Ἰησοῦς
JAS 1:1.6 . Χριστοῦ Χριστοῦ N-GSM 5547 Χριστός ! Χριστός
JAS 1:1.7 . δοῦλος δοῦλος N-NSM 1401 δοῦλος ! δοῦλος (II)
JAS 1:1.8 . ταῖς ταῖς T-DPF 3588 ὁ ! ὁ
JAS 1:1.9 . δώδεκα δώδεκα A-NUI 1427 δώδεκα ! δώδεκα
JAS 1:1.10 . φυλαῖς φυλαῖς N-DPF 5443 φυλή ! φυλή
JAS 1:1.11 . ταῖς ταῖς T-DPF 3588 ὁ ! ὁ
JAS 1:1.12 . ἐν ἐν PREP 1722 ἐν ! ἐν
JAS 1:1.13 . τῇ τῇ T-DSF 3588 ὁ ! ὁ
JAS 1:1.14 . διασπορᾷ διασπορᾷ N-DSF 1290 διασπορά ! διασπορά
JAS 1:1.15 . χαίρειν. χαίρειν. V-PAN 5463 χαίρω ! χαίρω
JAS 1:2.1 P Πᾶσαν Πᾶσαν A-ASF 3956 πᾶς ! πᾶς
JAS 1:2.2 . χαρὰν χαρὰν N-ASF 5479 χαρά ! χαρά
JAS 1:2.3 . ἡγήσασθε, ἡγήσασθε, V-ADM-2P 2233 ἡγέομαι ! ἡγέομαι
JAS 1:2.4 . ἀδελφοί ἀδελφοί N-VPM 80 ἀδελφός ! ἀδελφός
JAS 1:2.5 . μου, μου, P-1GS 1473 ἐγώ ! ἐγώ
JAS 1:2.6 . ὅταν ὅταν CONJ 3752 ὅταν ! ὅταν
JAS 1:2.7 . πειρασμοῖς πειρασμοῖς N-DPM 3986 πειρασμός ! πειρασμός
JAS 1:2.8 . περιπέσητε περιπέσητε V-2AAS-2P 4045 περιπίπτω ! περιπίπτω
JAS 1:2.9 . ποικίλοις, ποικίλοις, A-DPM 4164 ποικίλος ! ποικίλος
JAS 1:3.1 . γινώσκοντες γινώσκοντες V-PAP-NPM 1097 γινώσκω ! γινώσκω
JAS 1:3.2 . ὅτι ὅτι CONJ 3754 ὅτι ! ὅτι
JAS 1:3.3 . τὸ τὸ T-NSN 3588 ὁ ! ὁ
JAS 1:3.4 . δοκίμιον δοκίμιον N-NSN 1383 δοκίμιον ! δοκίμιον
JAS 1:3.5 . ὑμῶν ὑμῶν P-2GP 5210 ὑμεῖς ! σύ
JAS 1:3.6 . τῆς τῆς T-GSF 3588 ὁ ! ὁ
JAS 1:3.7 . πίστεως πίστεως N-GSF 4102 πίστις ! πίστις
JAS 1:3.8 . κατεργάζεται κατεργάζεται V-PNI-3S 2716 κατεργάζομαι ! κατεργάζομαι
JAS 1:3.9 . ὑπομονήν. ὑπομονήν. N-ASF 5281 ὑπομονή ! ὑπομονή
JAS 1:4.1 . ἡ ἡ T-NSF 3588 ὁ ! ὁ
JAS 1:4.2 . δὲ δὲ CONJ 1161 δέ ! δέ
JAS 1:4.3 . ὑπομονὴ ὑπομονὴ N-NSF 5281 ὑπομονή ! ὑπομονή
JAS 1:4.4 . ἔργον ἔργον N-ASN 2041 ἔργον ! ἔργον
JAS 1:4.5 . τέλειον τέλειον A-ASN 5046 τέλειος ! τέλειος
JAS 1:4.6 . ἐχέτω, ἐχέτω, V-PAM-3S 2192 ἔχω ! ἔχω
JAS 1:4.7 . ἵνα ἵνα CONJ 2443 ἵνα ! ἵνα
JAS 1:4.8 . ἦτε ἦτε V-PAS-2P 1510 εἰμί ! εἰμί
JAS 1:4.9 . τέλειοι τέλειοι A-NPM 5046 τέλειος ! τέλειος
JAS 1:4.10 . καὶ καὶ CONJ 2532 καί ! καί
JAS 1:4.11 . ὁλόκληροι, ὁλόκληροι, A-NPM 3648 ὁλόκληρος ! ὁλόκληρος
JAS 1:4.12 . ἐν ἐν PREP 1722 ἐν ! ἐν
JAS 1:4.13 . μηδενὶ μηδενὶ A-DSN-N 3367 μηδείς ! μηδείς
JAS 1:4.14 . λειπόμενοι. λειπόμενοι. V-PPP-NPM 3007 λείπω ! λείπω
JAS 1:5.1 . εἰ εἰ COND 1487 εἰ ! εἰ
JAS 1:5.2 . δέ δέ CONJ 1161 δέ ! δέ
JAS 1:5.3 . τις τις X-NSM 5100 τὶς ! τὶς
JAS 1:5.4 . ὑμῶν ὑμῶν P-2GP 5210 ὑμεῖς ! σύ
JAS 1:5.5 . λείπεται λείπεται V-PPI-3S 3007 λείπω ! λείπω
JAS 1:5.6 . σοφίας, σοφίας, N-GSF 4678 σοφία ! σοφία
JAS 1:5.7 . αἰτείτω αἰτείτω V-PAM-3S 154 αἰτέω ! αἰτέω
JAS 1:5.8 . παρὰ παρὰ PREP 3844 παρά ! παρά
JAS 1:5.9 . τοῦ τοῦ T-GSM 3588 ὁ ! ὁ
JAS 1:5.10 . διδόντος διδόντος V-PAP-GSM 1325 δίδωμι ! δίδωμι
JAS 1:5.11 . θεοῦ θεοῦ N-GSM 2316 θεός ! θεός
JAS 1:5.12 . πᾶσιν πᾶσιν A-DPM 3956 πᾶς ! πᾶς
JAS 1:5.13 . ἁπλῶς ἁπλῶς ADV 574 ἁπλῶς ! ἁπλῶς
JAS 1:5.14 . καὶ καὶ CONJ 2532 καί ! καί
JAS 1:5.15 . μὴ μὴ PRT-N 3361 μή ! μή
JAS 1:5.16 . ὀνειδίζοντος, ὀνειδίζοντος, V-PAP-GSM 3679 ὀνειδίζω ! ὀνειδίζω
JAS 1:5.17 . καὶ καὶ CONJ 2532 καί ! καί
JAS 1:5.18 . δοθήσεται δοθήσεται V-FPI-3S 1325 δίδωμι ! δίδωμι
JAS 1:5.19 . αὐτῷ. αὐτῷ. P-DSM 846 αὐτός ! αὐτός
JAS 1:6.1 . αἰτείτω αἰτείτω V-PAM-3S 154 αἰτέω ! αἰτέω
JAS 1:6.2 . δὲ δὲ CONJ 1161 δέ ! δέ
JAS 1:6.3 . ἐν ἐν PREP 1722 ἐν ! ἐν
JAS 1:6.4 . πίστει, πίστει, N-DSF 4102 πίστις ! πίστις
JAS 1:6.5 . μηδὲν μηδὲν A-ASN-N 3367 μηδείς ! μηδείς
JAS 1:6.6 . διακρινόμενος· διακρινόμενος· V-PMP-NSM 1252 διακρίνω ! διακρίνω
JAS 1:6.7 . ὁ ὁ T-NSM 3588 ὁ ! ὁ
JAS 1:6.8 . γὰρ γὰρ CONJ 1063 γάρ ! γάρ
JAS 1:6.9 . διακρινόμενος διακρινόμενος V-PMP-NSM 1252 διακρίνω ! διακρίνω
JAS 1:6.10 . ἔοικεν ἔοικεν V-RAI-3S 1503 εἴκω ! ἔοικα
JAS 1:6.11 . κλύδωνι κλύδωνι N-DSM 2830 κλύδων ! κλύδων
JAS 1:6.12 . θαλάσσης θαλάσσης N-GSF 2281 θάλασσα ! θάλασσα
JAS 1:6.13 . ἀνεμιζομένῳ ἀνεμιζομένῳ V-PPP-DSM 416 ἀνεμίζω ! ἀνεμίζω
JAS 1:6.14 . καὶ καὶ CONJ 2532 καί ! καί
JAS 1:6.15 . ῥιπιζομένῳ. ῥιπιζομένῳ. V-PPP-DSM 4494 ῥιπίζω ! ῥιπίζω
JAS 1:7.1 . μὴ μὴ PRT-N 3361 μή ! μή
JAS 1:7.2 . γὰρ γὰρ CONJ 1063 γάρ ! γάρ
JAS 1:7.3 . οἰέσθω οἰέσθω V-PNM-3S 3633 οἴομαι ! οἴομαι
JAS 1:7.4 . ὁ ὁ T-NSM 3588 ὁ ! ὁ
JAS 1:7.5 . ἄνθρωπος ἄνθρωπος N-NSM 444 ἄνθρωπος ! ἄνθρωπος
JAS 1:7.6 . ἐκεῖνος ἐκεῖνος D-NSM 1565 ἐκεῖνος ! ἐκεῖνος
JAS 1:7.7 . ὅτι ὅτι CONJ 3754 ὅτι ! ὅτι
JAS 1:7.8 . λήμψεταί λήμψεταί V-FDI-3S 2983 λαμβάνω ! λαμβάνω
JAS 1:7.9 . τι τι X-ASN 5100 τὶς ! τὶς
JAS 1:7.10 . παρὰ παρὰ PREP 3844 παρά ! παρά
JAS 1:7.11 . τοῦ τοῦ T-GSM 3588 ὁ ! ὁ
JAS 1:7.12 . κυρίου, κυρίου, N-GSM 2962 κύριος ! κύριος
JAS 1:8.1 . ἀνὴρ ἀνὴρ N-NSM 435 ἀνήρ ! ἀνήρ
JAS 1:8.2 . δίψυχος, δίψυχος, A-NSM 1374 δίψυχος ! δίψυχος
JAS 1:8.3 . ἀκατάστατος ἀκατάστατος A-NSM 182 ἀκατάστατος ! ἀκατάστατος
JAS 1:8.4 . ἐν ἐν PREP 1722 ἐν ! ἐν
JAS 1:8.5 . πάσαις πάσαις A-DPF 3956 πᾶς ! πᾶς
JAS 1:8.6 . ταῖς ταῖς T-DPF 3588 ὁ ! ὁ
JAS 1:8.7 . ὁδοῖς ὁδοῖς N-DPF 3598 ὁδός ! ὁδός
JAS 1:8.8 . αὐτοῦ. αὐτοῦ. P-GSM 846 αὐτός ! αὐτός
JAS 1:9.1 . Καυχάσθω Καυχάσθω V-PNM-3S 2744 καυχάομαι ! καυχάομαι
JAS 1:9.2 . δὲ δὲ CONJ 1161 δέ ! δέ
JAS 1:9.3 . ὁ ὁ T-NSM 3588 ὁ ! ὁ
JAS 1:9.4 . ἀδελφὸς ἀδελφὸς N-NSM 80 ἀδελφός ! ἀδελφός
JAS 1:9.5 . ὁ ὁ T-NSM 3588 ὁ ! ὁ
JAS 1:9.6 . ταπεινὸς ταπεινὸς A-NSM 5011 ταπεινός ! ταπεινός
JAS 1:9.7 . ἐν ἐν PREP 1722 ἐν ! ἐν
JAS 1:9.8 . τῷ τῷ T-DSN 3588 ὁ ! ὁ
JAS 1:9.9 . ὕψει ὕψει N-DSN 5311 ὕψος ! ὕψος
JAS 1:9.10 . αὐτοῦ, αὐτοῦ, P-GSM 846 αὐτός ! αὐτός
JAS 1:10.1 . ὁ ὁ T-NSM 3588 ὁ ! ὁ
JAS 1:10.2 . δὲ δὲ CONJ 1161 δέ ! δέ
JAS 1:10.3 . πλούσιος πλούσιος A-NSM 4145 πλούσιος ! πλούσιος
JAS 1:10.4 . ἐν ἐν PREP 1722 ἐν ! ἐν
JAS 1:10.5 . τῇ τῇ T-DSF 3588 ὁ ! ὁ
JAS 1:10.6 . ταπεινώσει ταπεινώσει N-DSF 5014 ταπείνωσις ! ταπείνωσις
JAS 1:10.7 . αὐτοῦ, αὐτοῦ, P-GSM 846 αὐτός ! αὐτός
JAS 1:10.8 . ὅτι ὅτι CONJ 3754 ὅτι ! ὅτι
JAS 1:10.9 . ὡς ὡς ADV 5613 ὡς ! ὡς
JAS 1:10.10 . ἄνθος ἄνθος N-NSN 438 ἄνθος ! ἄνθος
JAS 1:10.11 . χόρτου χόρτου N-GSM 5528 χόρτος ! χόρτος
JAS 1:10.12 . παρελεύσεται. παρελεύσεται. V-FDI-3S 3928 παρέρχομαι ! παρέρχομαι
JAS 1:11.1 . ἀνέτειλεν ἀνέτειλεν V-AAI-3S 393 ἀνατέλλω ! ἀνατέλλω
JAS 1:11.2 . γὰρ γὰρ CONJ 1063 γάρ ! γάρ
JAS 1:11.3 . ὁ ὁ T-NSM 3588 ὁ ! ὁ
JAS 1:11.4 . ἥλιος ἥλιος N-NSM 2246 ἥλιος ! ἥλιος
JAS 1:11.5 . σὺν σὺν PREP 4862 σύν ! σύν
JAS 1:11.6 . τῷ τῷ T-DSM 3588 ὁ ! ὁ
JAS 1:11.7 . καύσωνι καύσωνι N-DSM 2742 καύσων ! καύσων
JAS 1:11.8 . καὶ καὶ CONJ 2532 καί ! καί
JAS 1:11.9 . ἐξήρανεν ἐξήρανεν V-AAI-3S 3583 ξηραίνω ! ξηραίνω
JAS 1:11.10 . τὸν τὸν T-ASM 3588 ὁ ! ὁ
JAS 1:11.11 . χόρτον, χόρτον, N-ASM 5528 χόρτος ! χόρτος
JAS 1:11.12 . καὶ καὶ CONJ 2532 καί ! καί
JAS 1:11.13 . τὸ τὸ T-NSN 3588 ὁ ! ὁ
JAS 1:11.14 . ἄνθος ἄνθος N-NSN 438 ἄνθος ! ἄνθος
JAS 1:11.15 . αὐτοῦ αὐτοῦ P-GSM 846 αὐτός ! αὐτός
JAS 1:11.16 . ἐξέπεσεν ἐξέπεσεν V-2AAI-3S 1601 ἐκπίπτω ! ἐκπίπτω
JAS 1:11.17 . καὶ καὶ CONJ 2532 καί ! καί
JAS 1:11.18 . ἡ ἡ T-NSF 3588 ὁ ! ὁ
JAS 1:11.19 . εὐπρέπεια εὐπρέπεια N-NSF 2143 εὐπρέπεια ! εὐπρέπεια
JAS 1:11.20 . τοῦ τοῦ T-GSN 3588 ὁ ! ὁ
JAS 1:11.21 . προσώπου προσώπου N-GSN 4383 πρόσωπον ! πρόσωπον
JAS 1:11.22 . αὐτοῦ αὐτοῦ P-GSM 846 αὐτός ! αὐτός
JAS 1:11.23 . ἀπώλετο· ἀπώλετο· V-2AMI-3S 622 ἀπόλλυμι ! ἀπόλλυμι
JAS 1:11.24 . οὕτως οὕτως ADV 3779 οὕτω ! οὕτω
JAS 1:11.25 . καὶ καὶ CONJ 2532 καί ! καί
JAS 1:11.26 . ὁ ὁ T-NSM 3588 ὁ ! ὁ
JAS 1:11.27 . πλούσιος πλούσιος A-NSM 4145 πλούσιος ! πλούσιος
JAS 1:11.28 . ἐν ἐν PREP 1722 ἐν ! ἐν
JAS 1:11.29 . ταῖς ταῖς T-DPF 3588 ὁ ! ὁ
JAS 1:11.30 . πορείαις πορείαις N-DPF 4197 πορεία ! πορεία
JAS 1:11.31 . αὐτοῦ αὐτοῦ P-GSM 846 αὐτός ! αὐτός
JAS 1:11.32 . μαρανθήσεται. μαρανθήσεται. V-FPI-3S 3133 μαραίνω ! μαραίνω
JAS 1:12.1 P Μακάριος Μακάριος A-NSM 3107 μακάριος ! μακάριος
JAS 1:12.2 . ἀνὴρ ἀνὴρ N-NSM 435 ἀνήρ ! ἀνήρ
JAS 1:12.3 . ὃς ὃς R-NSM 3739 ὅς ! ὅς
JAS 1:12.4 . ὑπομένει ὑπομένει V-PAI-3S 5278 ὑπομένω ! ὑπομένω
JAS 1:12.5 . πειρασμόν, πειρασμόν, N-ASM 3986 πειρασμός ! πειρασμός
JAS 1:12.6 . ὅτι ὅτι CONJ 3754 ὅτι ! ὅτι
JAS 1:12.7 . δόκιμος δόκιμος A-NSM 1384 δόκιμος ! δόκιμος
JAS 1:12.8 . γενόμενος γενόμενος V-2ADP-NSM 1096 γίνομαι ! γίνομαι
JAS 1:12.9 . λήμψεται λήμψεται V-FDI-3S 2983 λαμβάνω ! λαμβάνω
JAS 1:12.10 . τὸν τὸν T-ASM 3588 ὁ ! ὁ
JAS 1:12.11 . στέφανον στέφανον N-ASM 4735 στέφανος ! στέφανος
JAS 1:12.12 . τῆς τῆς T-GSF 3588 ὁ ! ὁ
JAS 1:12.13 . ζωῆς, ζωῆς, N-GSF 2222 ζωή ! ζωή
JAS 1:12.14 . ὃν ὃν R-ASM 3739 ὅς ! ὅς
JAS 1:12.15 . ἐπηγγείλατο ἐπηγγείλατο V-ADI-3S 1861 ἐπαγγέλλω ! ἐπαγγέλλομαι
JAS 1:12.16 . τοῖς τοῖς T-DPM 3588 ὁ ! ὁ
JAS 1:12.17 . ἀγαπῶσιν ἀγαπῶσιν V-PAP-DPM 25 ἀγαπάω ! ἀγαπάω
JAS 1:12.18 . αὐτόν. αὐτόν. P-ASM 846 αὐτός ! αὐτός
JAS 1:13.1 . μηδεὶς μηδεὶς A-NSM-N 3367 μηδείς ! μηδείς
JAS 1:13.2 . πειραζόμενος πειραζόμενος V-PPP-NSM 3985 πειράζω ! πειράζω
JAS 1:13.3 . λεγέτω λεγέτω V-PAM-3S 3004 λέγω ! λέγω
JAS 1:13.4 . ὅτι ὅτι CONJ 3754 ὅτι ! ὅτι
JAS 1:13.5 . ἀπὸ ἀπὸ PREP 575 ἀπό ! ἀπό
JAS 1:13.6 . θεοῦ θεοῦ N-GSM 2316 θεός ! θεός
JAS 1:13.7 . πειράζομαι· πειράζομαι· V-PPI-1S 3985 πειράζω ! πειράζω
JAS 1:13.8 . ὁ ὁ T-NSM 3588 ὁ ! ὁ
JAS 1:13.9 . γὰρ γὰρ CONJ 1063 γάρ ! γάρ
JAS 1:13.10 . θεὸς θεὸς N-NSM 2316 θεός ! θεός
JAS 1:13.11 . ἀπείραστός ἀπείραστός A-NSM 551 ἀπείραστος ! ἀπείραστος
JAS 1:13.12 . ἐστιν ἐστιν V-PAI-3S 1510 εἰμί ! εἰμί
JAS 1:13.13 . κακῶν, κακῶν, A-GPN 2556 κακός ! κακός
JAS 1:13.14 . πειράζει πειράζει V-PAI-3S 3985 πειράζω ! πειράζω
JAS 1:13.15 . δὲ δὲ CONJ 1161 δέ ! δέ
JAS 1:13.16 . αὐτὸς αὐτὸς P-NSM 846 αὐτός ! αὐτός
JAS 1:13.17 . οὐδένα. οὐδένα. A-ASM-N 3762 οὐδείς ! οὐδείς
JAS 1:14.1 . ἕκαστος ἕκαστος A-NSM 1538 ἕκαστος ! ἕκαστος
JAS 1:14.2 . δὲ δὲ CONJ 1161 δέ ! δέ
JAS 1:14.3 . πειράζεται πειράζεται V-PPI-3S 3985 πειράζω ! πειράζω
JAS 1:14.4 . ὑπὸ ὑπὸ PREP 5259 ὑπό ! ὑπό
JAS 1:14.5 . τῆς τῆς T-GSF 3588 ὁ ! ὁ
JAS 1:14.6 . ἰδίας ἰδίας A-GSF 2398 ἴδιος ! ἴδιος
JAS 1:14.7 . ἐπιθυμίας ἐπιθυμίας N-GSF 1939 ἐπιθυμία ! ἐπιθυμία
JAS 1:14.8 . ἐξελκόμενος ἐξελκόμενος V-PPP-NSM 1828 ἐξέλκω ! ἐξέλκω
JAS 1:14.9 . καὶ καὶ CONJ 2532 καί ! καί
JAS 1:14.10 . δελεαζόμενος· δελεαζόμενος· V-PPP-NSM 1185 δελεάζω ! δελεάζω
JAS 1:15.1 . εἶτα εἶτα ADV 1534 εἶτα ! εἶτα
JAS 1:15.2 . ἡ ἡ T-NSF 3588 ὁ ! ὁ
JAS 1:15.3 . ἐπιθυμία ἐπιθυμία N-NSF 1939 ἐπιθυμία ! ἐπιθυμία
JAS 1:15.4 . συλλαβοῦσα συλλαβοῦσα V-2AAP-NSF 4815 συλλαμβάνω ! συλλαμβάνω
JAS 1:15.5 . τίκτει τίκτει V-PAI-3S 5088 τίκτω ! τίκτω
JAS 1:15.6 . ἁμαρτίαν, ἁμαρτίαν, N-ASF 266 ἁμαρτία ! ἁμαρτία
JAS 1:15.7 . ἡ ἡ T-NSF 3588 ὁ ! ὁ
JAS 1:15.8 . δὲ δὲ CONJ 1161 δέ ! δέ
JAS 1:15.9 . ἁμαρτία ἁμαρτία N-NSF 266 ἁμαρτία ! ἁμαρτία
JAS 1:15.10 . ἀποτελεσθεῖσα ἀποτελεσθεῖσα V-APP-NSF 658 ἀποτελέω ! ἀποτελέω
JAS 1:15.11 . ἀποκύει ἀποκύει V-PAI-3S 616 ἀποκυέω ! ἀποκυέω
JAS 1:15.12 . θάνατον. θάνατον. N-ASM 2288 θάνατος ! θάνατος
JAS 1:16.1 P Μὴ Μὴ PRT-N 3361 μή ! μή
JAS 1:16.2 . πλανᾶσθε, πλανᾶσθε, V-PPM-2P 4105 πλανάω ! πλανάω
JAS 1:16.3 . ἀδελφοί ἀδελφοί N-VPM 80 ἀδελφός ! ἀδελφός
JAS 1:16.4 . μου μου P-1GS 1473 ἐγώ ! ἐγώ
JAS 1:16.5 . ἀγαπητοί. ἀγαπητοί. A-VPM 27 ἀγαπητός ! ἀγαπητός
JAS 1:17.1 . πᾶσα πᾶσα A-NSF 3956 πᾶς ! πᾶς
JAS 1:17.2 . δόσις δόσις N-NSF 1394 δόσις ! δόσις
JAS 1:17.3 . ἀγαθὴ ἀγαθὴ A-NSF 18 ἀγαθός ! ἀγαθός
JAS 1:17.4 . καὶ καὶ CONJ 2532 καί ! καί
JAS 1:17.5 . πᾶν πᾶν A-NSN 3956 πᾶς ! πᾶς
JAS 1:17.6 . δώρημα δώρημα N-NSN 1434 δώρημα ! δώρημα
JAS 1:17.7 . τέλειον τέλειον A-NSN 5046 τέλειος ! τέλειος
JAS 1:17.8 . ἄνωθέν ἄνωθέν ADV 509 ἄνωθεν ! ἄνωθεν
JAS 1:17.9 . ἐστιν, ἐστιν, V-PAI-3S 1510 εἰμί ! εἰμί
JAS 1:17.10 . καταβαῖνον καταβαῖνον V-PAP-NSN 2597 καταβαίνω ! καταβαίνω
JAS 1:17.11 . ἀπὸ ἀπὸ PREP 575 ἀπό ! ἀπό
JAS 1:17.12 . τοῦ τοῦ T-GSM 3588 ὁ ! ὁ
JAS 1:17.13 . πατρὸς πατρὸς N-GSM 3962 πατήρ ! πατήρ
JAS 1:17.14 . τῶν τῶν T-GPN 3588 ὁ ! ὁ
JAS 1:17.15 . φώτων, φώτων, N-GPN 5457 φῶς ! φῶς
JAS 1:17.16 . παρ’ παρ’ PREP 3844 παρά ! παρά
JAS 1:17.17 . ᾧ ᾧ R-DSM 3739 ὅς ! ὅς
JAS 1:17.18 . οὐκ οὐκ PRT-N 3756 οὐ ! οὐ
JAS 1:17.19 . ἔνι ἔνι V-PAI-3S 1762 ἔνι ! ἔνι
JAS 1:17.20 . παραλλαγὴ παραλλαγὴ N-NSF 3883 παραλλαγή ! παραλλαγή
JAS 1:17.21 . ἢ ἢ PRT 2228 ἤ ! ἤ
JAS 1:17.22 . τροπῆς τροπῆς N-GSF 5157 τροπή ! τροπή
JAS 1:17.23 . ἀποσκίασμα. ἀποσκίασμα. N-NSN 644 ἀποσκίασμα ! ἀποσκίασμα
JAS 1:18.1 . βουληθεὶς βουληθεὶς V-AOP-NSM 1014 βούλομαι ! βούλομαι
JAS 1:18.2 . ἀπεκύησεν ἀπεκύησεν V-AAI-3S 616 ἀποκυέω ! ἀποκυέω
JAS 1:18.3 . ἡμᾶς ἡμᾶς P-1AP 2249 ἡμεῖς ! ἐγώ
JAS 1:18.4 . λόγῳ λόγῳ N-DSM 3056 λόγος ! λόγος
JAS 1:18.5 . ἀληθείας, ἀληθείας, N-GSF 225 ἀλήθεια ! ἀλήθεια
JAS 1:18.6 . εἰς εἰς PREP 1519 εἰς ! εἰς
JAS 1:18.7 . τὸ τὸ T-ASN 3588 ὁ ! ὁ
JAS 1:18.8 . εἶναι εἶναι V-PAN 1510 εἰμί ! εἰμί
JAS 1:18.9 . ἡμᾶς ἡμᾶς P-1AP 2249 ἡμεῖς ! ἐγώ
JAS 1:18.10 . ἀπαρχήν ἀπαρχήν N-ASF 536 ἀπαρχή ! ἀπαρχή
JAS 1:18.11 . τινα τινα X-ASF 5100 τὶς ! τὶς
JAS 1:18.12 . τῶν τῶν T-GPN 3588 ὁ ! ὁ
JAS 1:18.13 . αὐτοῦ αὐτοῦ P-GSM 846 αὐτός ! αὐτός
JAS 1:18.14 . κτισμάτων. κτισμάτων. N-GPN 2938 κτίσμα ! κτίσμα
JAS 1:19.1 P Ἴστε, Ἴστε, V-RAM-2P 1492 εἴδω ! οἶδα
JAS 1:19.2 . ἀδελφοί ἀδελφοί N-VPM 80 ἀδελφός ! ἀδελφός
JAS 1:19.3 . μου μου P-1GS 1473 ἐγώ ! ἐγώ
JAS 1:19.4 . ἀγαπητοί. ἀγαπητοί. A-VPM 27 ἀγαπητός ! ἀγαπητός
JAS 1:19.5 . ἔστω ἔστω V-PAM-3S 1510 εἰμί ! εἰμί
JAS 1:19.6 . δὲ δὲ CONJ 1161 δέ ! δέ
JAS 1:19.7 . πᾶς πᾶς A-NSM 3956 πᾶς ! πᾶς
JAS 1:19.8 . ἄνθρωπος ἄνθρωπος N-NSM 444 ἄνθρωπος ! ἄνθρωπος
JAS 1:19.9 . ταχὺς ταχὺς A-NSM 5036 ταχύς ! ταχύς
JAS 1:19.10 . εἰς εἰς PREP 1519 εἰς ! εἰς
JAS 1:19.11 . τὸ τὸ T-ASN 3588 ὁ ! ὁ
JAS 1:19.12 . ἀκοῦσαι, ἀκοῦσαι, V-AAN 191 ἀκούω ! ἀκούω
JAS 1:19.13 . βραδὺς βραδὺς A-NSM 1021 βραδύς ! βραδύς
JAS 1:19.14 . εἰς εἰς PREP 1519 εἰς ! εἰς
JAS 1:19.15 . τὸ τὸ T-ASN 3588 ὁ ! ὁ
JAS 1:19.16 . λαλῆσαι, λαλῆσαι, V-AAN 2980 λαλέω ! λαλέω
JAS 1:19.17 . βραδὺς βραδὺς A-NSM 1021 βραδύς ! βραδύς
JAS 1:19.18 . εἰς εἰς PREP 1519 εἰς ! εἰς
JAS 1:19.19 . ὀργήν· ὀργήν· N-ASF 3709 ὀργή ! ὀργή
JAS 1:20.1 . ὀργὴ ὀργὴ N-NSF 3709 ὀργή ! ὀργή
JAS 1:20.2 . γὰρ γὰρ CONJ 1063 γάρ ! γάρ
JAS 1:20.3 . ἀνδρὸς ἀνδρὸς N-GSM 435 ἀνήρ ! ἀνήρ
JAS 1:20.4 . δικαιοσύνην δικαιοσύνην N-ASF 1343 δικαιοσύνη ! δικαιοσύνη
JAS 1:20.5 . θεοῦ θεοῦ N-GSM 2316 θεός ! θεός
JAS 1:20.6 . οὐκ οὐκ PRT-N 3756 οὐ ! οὐ
JAS 1:20.7 . ἐργάζεται. ἐργάζεται. V-PNI-3S 2038 ἐργάζομαι ! ἐργάζομαι
JAS 1:21.1 . διὸ διὸ CONJ 1352 διό ! διό
JAS 1:21.2 . ἀποθέμενοι ἀποθέμενοι V-2AMP-NPM 659 ἀποτίθημι ! ἀποτίθημι
JAS 1:21.3 . πᾶσαν πᾶσαν A-ASF 3956 πᾶς ! πᾶς
JAS 1:21.4 . ῥυπαρίαν ῥυπαρίαν N-ASF 4507 ῥυπαρία ! ῥυπαρία
JAS 1:21.5 . καὶ καὶ CONJ 2532 καί ! καί
JAS 1:21.6 . περισσείαν περισσείαν N-ASF 4050 περισσεία ! περισσεία
JAS 1:21.7 . κακίας κακίας N-GSF 2549 κακία ! κακία
JAS 1:21.8 . ἐν ἐν PREP 1722 ἐν ! ἐν
JAS 1:21.9 . πραΰτητι πραΰτητι N-DSF 4240 πραΰτης ! πραΰτης
JAS 1:21.10 . δέξασθε δέξασθε V-ADM-2P 1209 δέχομαι ! δέχομαι
JAS 1:21.11 . τὸν τὸν T-ASM 3588 ὁ ! ὁ
JAS 1:21.12 . ἔμφυτον ἔμφυτον A-ASM 1721 ἔμφυτος ! ἔμφυτος
JAS 1:21.13 . λόγον λόγον N-ASM 3056 λόγος ! λόγος
JAS 1:21.14 . τὸν τὸν T-ASM 3588 ὁ ! ὁ
JAS 1:21.15 . δυνάμενον δυνάμενον V-PNP-ASM 1410 δύναμαι ! δύναμαι
JAS 1:21.16 . σῶσαι σῶσαι V-AAN 4982 σώζω ! σῴζω
JAS 1:21.17 . τὰς τὰς T-APF 3588 ὁ ! ὁ
JAS 1:21.18 . ψυχὰς ψυχὰς N-APF 5590 ψυχή ! ψυχή
JAS 1:21.19 . ὑμῶν. ὑμῶν. P-2GP 5210 ὑμεῖς ! σύ
JAS 1:22.1 . γίνεσθε γίνεσθε V-PNM-2P 1096 γίνομαι ! γίνομαι
JAS 1:22.2 . δὲ δὲ CONJ 1161 δέ ! δέ
JAS 1:22.3 . ποιηταὶ ποιηταὶ N-NPM 4163 ποιητής ! ποιητής
JAS 1:22.4 . λόγου λόγου N-GSM 3056 λόγος ! λόγος
JAS 1:22.5 . καὶ καὶ CONJ 2532 καί ! καί
JAS 1:22.6 . μὴ μὴ PRT-N 3361 μή ! μή
JAS 1:22.7 . μόνον μόνον ADV 3440 μόνον ! μόνος
JAS 1:22.8 . ἀκροαταὶ ἀκροαταὶ N-NPM 202 ἀκροατής ! ἀκροατής
JAS 1:22.9 . παραλογιζόμενοι παραλογιζόμενοι V-PNP-NPM 3884 παραλογίζομαι ! παραλογίζομαι
JAS 1:22.10 . ἑαυτούς. ἑαυτούς. F-2APM 1438 ἑαυτοῦ ! ἑαυτοῦ
JAS 1:23.1 . ὅτι ὅτι CONJ 3754 ὅτι ! ὅτι
JAS 1:23.2 . εἴ εἴ COND 1487 εἰ ! εἰ
JAS 1:23.3 . τις τις X-NSM 5100 τὶς ! τὶς
JAS 1:23.4 . ἀκροατὴς ἀκροατὴς N-NSM 202 ἀκροατής ! ἀκροατής
JAS 1:23.5 . λόγου λόγου N-GSM 3056 λόγος ! λόγος
JAS 1:23.6 . ἐστὶν ἐστὶν V-PAI-3S 1510 εἰμί ! εἰμί
JAS 1:23.7 . καὶ καὶ CONJ 2532 καί ! καί
JAS 1:23.8 . οὐ οὐ PRT-N 3756 οὐ ! οὐ
JAS 1:23.9 . ποιητής, ποιητής, N-NSM 4163 ποιητής ! ποιητής
JAS 1:23.10 . οὗτος οὗτος D-NSM 3778 οὗτος ! οὗτος
JAS 1:23.11 . ἔοικεν ἔοικεν V-RAI-3S 1503 εἴκω ! ἔοικα
JAS 1:23.12 . ἀνδρὶ ἀνδρὶ N-DSM 435 ἀνήρ ! ἀνήρ
JAS 1:23.13 . κατανοοῦντι κατανοοῦντι V-PAP-DSM 2657 κατανοέω ! κατανοέω
JAS 1:23.14 . τὸ τὸ T-ASN 3588 ὁ ! ὁ
JAS 1:23.15 . πρόσωπον πρόσωπον N-ASN 4383 πρόσωπον ! πρόσωπον
JAS 1:23.16 . τῆς τῆς T-GSF 3588 ὁ ! ὁ
JAS 1:23.17 . γενέσεως γενέσεως N-GSF 1078 γένεσις ! γένεσις
JAS 1:23.18 . αὐτοῦ αὐτοῦ P-GSM 846 αὐτός ! αὐτός
JAS 1:23.19 . ἐν ἐν PREP 1722 ἐν ! ἐν
JAS 1:23.20 . ἐσόπτρῳ· ἐσόπτρῳ· N-DSN 2072 ἔσοπτρον ! ἔσοπτρον
JAS 1:24.1 . κατενόησεν κατενόησεν V-AAI-3S 2657 κατανοέω ! κατανοέω
JAS 1:24.2 . γὰρ γὰρ CONJ 1063 γάρ ! γάρ
JAS 1:24.3 . ἑαυτὸν ἑαυτὸν F-3ASM 1438 ἑαυτοῦ ! ἑαυτοῦ
JAS 1:24.4 . καὶ καὶ CONJ 2532 καί ! καί
JAS 1:24.5 . ἀπελήλυθεν ἀπελήλυθεν V-2RAI-3S 565 ἀπέρχομαι ! ἀπέρχομαι
JAS 1:24.6 . καὶ καὶ CONJ 2532 καί ! καί
JAS 1:24.7 . εὐθέως εὐθέως ADV 2112 εὐθέως ! εὐθέως
JAS 1:24.8 . ἐπελάθετο ἐπελάθετο V-2ADI-3S 1950 ἐπιλανθάνομαι ! ἐπιλανθάνομαι
JAS 1:24.9 . ὁποῖος ὁποῖος A-NSM 3697 ὁποῖος ! ὁποῖος
JAS 1:24.10 . ἦν. ἦν. V-IAI-3S 1510 εἰμί ! εἰμί
JAS 1:25.1 . ὁ ὁ T-NSM 3588 ὁ ! ὁ
JAS 1:25.2 . δὲ δὲ CONJ 1161 δέ ! δέ
JAS 1:25.3 . παρακύψας παρακύψας V-AAP-NSM 3879 παρακύπτω ! παρακύπτω
JAS 1:25.4 . εἰς εἰς PREP 1519 εἰς ! εἰς
JAS 1:25.5 . νόμον νόμον N-ASM 3551 νόμος ! νόμος
JAS 1:25.6 . τέλειον τέλειον A-ASM 5046 τέλειος ! τέλειος
JAS 1:25.7 . τὸν τὸν T-ASM 3588 ὁ ! ὁ
JAS 1:25.8 . τῆς τῆς T-GSF 3588 ὁ ! ὁ
JAS 1:25.9 . ἐλευθερίας ἐλευθερίας N-GSF 1657 ἐλευθερία ! ἐλευθερία
JAS 1:25.10 . καὶ καὶ CONJ 2532 καί ! καί
JAS 1:25.11 . παραμείνας, παραμείνας, V-AAP-NSM 3887 παραμένω ! παραμένω
JAS 1:25.12 . οὐκ οὐκ PRT-N 3756 οὐ ! οὐ
JAS 1:25.13 . ἀκροατὴς ἀκροατὴς N-NSM 202 ἀκροατής ! ἀκροατής
JAS 1:25.14 . ἐπιλησμονῆς ἐπιλησμονῆς N-GSF 1953 ἐπιλησμονή ! ἐπιλησμονή
JAS 1:25.15 . γενόμενος γενόμενος V-2ADP-NSM 1096 γίνομαι ! γίνομαι
JAS 1:25.16 . ἀλλὰ ἀλλὰ CONJ 235 ἀλλά ! ἀλλά
JAS 1:25.17 . ποιητὴς ποιητὴς N-NSM 4163 ποιητής ! ποιητής
JAS 1:25.18 . ἔργου, ἔργου, N-GSN 2041 ἔργον ! ἔργον
JAS 1:25.19 . οὗτος οὗτος D-NSM 3778 οὗτος ! οὗτος
JAS 1:25.20 . μακάριος μακάριος A-NSM 3107 μακάριος ! μακάριος
JAS 1:25.21 . ἐν ἐν PREP 1722 ἐν ! ἐν
JAS 1:25.22 . τῇ τῇ T-DSF 3588 ὁ ! ὁ
JAS 1:25.23 . ποιήσει ποιήσει N-DSF 4162 ποίησις ! ποίησις
JAS 1:25.24 . αὐτοῦ αὐτοῦ P-GSM 846 αὐτός ! αὐτός
JAS 1:25.25 . ἔσται. ἔσται. V-FDI-3S 1510 εἰμί ! εἰμί
JAS 1:26.1 . εἴ εἴ COND 1487 εἰ ! εἰ
JAS 1:26.2 . τις τις X-NSM 5100 τὶς ! τὶς
JAS 1:26.3 . δοκεῖ δοκεῖ V-PAI-3S 1380 δοκέω ! δοκέω
JAS 1:26.4 . θρησκὸς θρησκὸς A-NSM 2357 θρησκός ! θρησκός
JAS 1:26.5 . εἶναι, εἶναι, V-PAN 1510 εἰμί ! εἰμί
JAS 1:26.6 . μὴ μὴ PRT-N 3361 μή ! μή
JAS 1:26.7 . χαλιναγωγῶν χαλιναγωγῶν V-PAP-NSM 5468 χαλιναγωγέω ! χαλιναγωγέω
JAS 1:26.8 . γλῶσσαν γλῶσσαν N-ASF 1100 γλῶσσα ! γλῶσσα
JAS 1:26.9 . αὐτοῦ αὐτοῦ P-GSM 846 αὐτός ! αὐτός
JAS 1:26.10 . ἀλλὰ ἀλλὰ CONJ 235 ἀλλά ! ἀλλά
JAS 1:26.11 . ἀπατῶν ἀπατῶν V-PAP-NSM 538 ἀπατάω ! ἀπατάω
JAS 1:26.12 . καρδίαν καρδίαν N-ASF 2588 καρδία ! καρδία
JAS 1:26.13 . αὐτοῦ, αὐτοῦ, P-GSM 846 αὐτός ! αὐτός
JAS 1:26.14 . τούτου τούτου D-GSM 3778 οὗτος ! οὗτος
JAS 1:26.15 . μάταιος μάταιος A-NSF 3152 μάταιος ! μάταιος
JAS 1:26.16 . ἡ ἡ T-NSF 3588 ὁ ! ὁ
JAS 1:26.17 . θρησκία. θρησκία. N-NSF 2356 θρησκεία ! θρησκεία
JAS 1:27.1 . θρησκία θρησκία N-NSF 2356 θρησκεία ! θρησκεία
JAS 1:27.2 . καθαρὰ καθαρὰ A-NSF 2513 καθαρός ! καθαρός
JAS 1:27.3 . καὶ καὶ CONJ 2532 καί ! καί
JAS 1:27.4 . ἀμίαντος ἀμίαντος A-NSF 283 ἀμίαντος ! ἀμίαντος
JAS 1:27.5 . παρὰ παρὰ PREP 3844 παρά ! παρά
JAS 1:27.6 . θεῷ θεῷ N-DSM 2316 θεός ! θεός
JAS 1:27.7 . καὶ καὶ CONJ 2532 καί ! καί
JAS 1:27.8 . πατρὶ πατρὶ N-DSM 3962 πατήρ ! πατήρ
JAS 1:27.9 . αὕτη αὕτη D-NSF 3778 οὗτος ! οὗτος
JAS 1:27.10 . ἐστίν, ἐστίν, V-PAI-3S 1510 εἰμί ! εἰμί
JAS 1:27.11 . ἐπισκέπτεσθαι ἐπισκέπτεσθαι V-PNN 1980 ἐπισκέπτομαι ! ἐπισκέπτομαι
JAS 1:27.12 . ὀρφανοὺς ὀρφανοὺς A-APM 3737 ὀρφανός ! ὀρφανός
JAS 1:27.13 . καὶ καὶ CONJ 2532 καί ! καί
JAS 1:27.14 . χήρας χήρας N-APF 5503 χήρα ! χῆρος
JAS 1:27.15 . ἐν ἐν PREP 1722 ἐν ! ἐν
JAS 1:27.16 . τῇ τῇ T-DSF 3588 ὁ ! ὁ
JAS 1:27.17 . θλίψει θλίψει N-DSF 2347 θλῖψις ! θλῖψις
JAS 1:27.18 . αὐτῶν, αὐτῶν, P-GPM 846 αὐτός ! αὐτός
JAS 1:27.19 . ἄσπιλον ἄσπιλον A-ASM 784 ἄσπιλος ! ἄσπιλος
JAS 1:27.20 . ἑαυτὸν ἑαυτὸν F-3ASM 1438 ἑαυτοῦ ! ἑαυτοῦ
JAS 1:27.21 . τηρεῖν τηρεῖν V-PAN 5083 τηρέω ! τηρέω
JAS 1:27.22 . ἀπὸ ἀπὸ PREP 575 ἀπό ! ἀπό
JAS 1:27.23 . τοῦ τοῦ T-GSM 3588 ὁ ! ὁ
JAS 1:27.24 . κόσμου. κόσμου. N-GSM 2889 κόσμος ! κόσμος
JAS 2:1.1 C Ἀδελφοί Ἀδελφοί N-VPM 80 ἀδελφός ! ἀδελφός
JAS 2:1.2 . μου, μου, P-1GS 1473 ἐγώ ! ἐγώ
JAS 2:1.3 . μὴ μὴ PRT-N 3361 μή ! μή
JAS 2:1.4 . ἐν ἐν PREP 1722 ἐν ! ἐν
JAS 2:1.5 . προσωπολημψίαις προσωπολημψίαις N-DPF 4382 προσωποληψία ! προσωπολημψία
JAS 2:1.6 . ἔχετε ἔχετε V-PAM-2P 2192 ἔχω ! ἔχω
JAS 2:1.7 . τὴν τὴν T-ASF 3588 ὁ ! ὁ
JAS 2:1.8 . πίστιν πίστιν N-ASF 4102 πίστις ! πίστις
JAS 2:1.9 . τοῦ τοῦ T-GSM 3588 ὁ ! ὁ
JAS 2:1.10 . κυρίου κυρίου N-GSM 2962 κύριος ! κύριος
JAS 2:1.11 . ἡμῶν ἡμῶν P-1GP 2249 ἡμεῖς ! ἐγώ
JAS 2:1.12 . Ἰησοῦ Ἰησοῦ N-GSM 2424 Ἰησοῦς ! Ἰησοῦς
JAS 2:1.13 . Χριστοῦ Χριστοῦ N-GSM 5547 Χριστός ! Χριστός
JAS 2:1.14 . τῆς τῆς T-GSF 3588 ὁ ! ὁ
JAS 2:1.15 . δόξης. δόξης. N-GSF 1391 δόξα ! δόξα
JAS 2:2.1 . ἐὰν ἐὰν COND 1437 ἐάν ! ἐάν
JAS 2:2.2 . γὰρ γὰρ CONJ 1063 γάρ ! γάρ
JAS 2:2.3 . εἰσέλθῃ εἰσέλθῃ V-2AAS-3S 1525 εἰσέρχομαι ! εἰσέρχομαι
JAS 2:2.4 . εἰς εἰς PREP 1519 εἰς ! εἰς
JAS 2:2.5 . συναγωγὴν συναγωγὴν N-ASF 4864 συναγωγή ! συναγωγή
JAS 2:2.6 . ὑμῶν ὑμῶν P-2GP 5210 ὑμεῖς ! σύ
JAS 2:2.7 . ἀνὴρ ἀνὴρ N-NSM 435 ἀνήρ ! ἀνήρ
JAS 2:2.8 . χρυσοδακτύλιος χρυσοδακτύλιος A-NSM 5554 χρυσοδακτύλιος ! χρυσοδακτύλιος
JAS 2:2.9 . ἐν ἐν PREP 1722 ἐν ! ἐν
JAS 2:2.10 . ἐσθῆτι ἐσθῆτι N-DSF 2066 ἐσθής ! ἐσθής
JAS 2:2.11 . λαμπρᾷ, λαμπρᾷ, A-DSF 2986 λαμπρός ! λαμπρός
JAS 2:2.12 . εἰσέλθῃ εἰσέλθῃ V-2AAS-3S 1525 εἰσέρχομαι ! εἰσέρχομαι
JAS 2:2.13 . δὲ δὲ CONJ 1161 δέ ! δέ
JAS 2:2.14 . καὶ καὶ CONJ 2532 καί ! καί
JAS 2:2.15 . πτωχὸς πτωχὸς A-NSM 4434 πτωχός ! πτωχός
JAS 2:2.16 . ἐν ἐν PREP 1722 ἐν ! ἐν
JAS 2:2.17 . ῥυπαρᾷ ῥυπαρᾷ A-DSF 4508 ῥυπαρός ! ῥυπαρός
JAS 2:2.18 . ἐσθῆτι, ἐσθῆτι, N-DSF 2066 ἐσθής ! ἐσθής
JAS 2:3.1 . καὶ καὶ CONJ 2532 καί ! καί
JAS 2:3.2 . ἐπιβλέψητε ἐπιβλέψητε V-AAS-2P 1914 ἐπιβλέπω ! ἐπιβλέπω
JAS 2:3.3 . ἐπὶ ἐπὶ PREP 1909 ἐπί ! ἐπί
JAS 2:3.4 . τὸν τὸν T-ASM 3588 ὁ ! ὁ
JAS 2:3.5 . φοροῦντα φοροῦντα V-PAP-ASM 5409 φορέω ! φορέω
JAS 2:3.6 . τὴν τὴν T-ASF 3588 ὁ ! ὁ
JAS 2:3.7 . ἐσθῆτα ἐσθῆτα N-ASF 2066 ἐσθής ! ἐσθής
JAS 2:3.8 . τὴν τὴν T-ASF 3588 ὁ ! ὁ
JAS 2:3.9 . λαμπρὰν λαμπρὰν A-ASF 2986 λαμπρός ! λαμπρός
JAS 2:3.10 . καὶ καὶ CONJ 2532 καί ! καί
JAS 2:3.11 . εἴπητε, εἴπητε, V-2AAS-2P 3004 λέγω ! λέγω
JAS 2:3.12 . σὺ σὺ P-2NS 4771 σύ ! σύ
JAS 2:3.13 . κάθου κάθου V-PNM-2S 2521 κάθημαι ! κάθημαι
JAS 2:3.14 . ὧδε ὧδε ADV 5602 ὧδε ! ὧδε
JAS 2:3.15 . καλῶς, καλῶς, ADV 2573 καλῶς ! καλῶς
JAS 2:3.16 . καὶ καὶ CONJ 2532 καί ! καί
JAS 2:3.17 . τῷ τῷ T-DSM 3588 ὁ ! ὁ
JAS 2:3.18 . πτωχῷ πτωχῷ A-DSM 4434 πτωχός ! πτωχός
JAS 2:3.19 . εἴπητε, εἴπητε, V-2AAS-2P 3004 λέγω ! λέγω
JAS 2:3.20 . σὺ σὺ P-2NS 4771 σύ ! σύ
JAS 2:3.21 . στῆθι στῆθι V-2AAM-2S 2476 ἵστημι ! ἵστημι
JAS 2:3.22 . ἐκεῖ ἐκεῖ ADV 1563 ἐκεῖ ! ἐκεῖ
JAS 2:3.23 . ἢ ἢ PRT 2228 ἤ ! ἤ
JAS 2:3.24 . κάθου κάθου V-PNM-2S 2521 κάθημαι ! κάθημαι
JAS 2:3.25 . ὑπὸ ὑπὸ PREP 5259 ὑπό ! ὑπό
JAS 2:3.26 . τὸ τὸ T-ASN 3588 ὁ ! ὁ
JAS 2:3.27 . ὑποπόδιόν ὑποπόδιόν N-ASN 5286 ὑποπόδιον ! ὑποπόδιον
JAS 2:3.28 . μου, μου, P-1GS 1473 ἐγώ ! ἐγώ
JAS 2:4.1 . οὐ οὐ PRT-N 3756 οὐ ! οὐ
JAS 2:4.2 . διεκρίθητε διεκρίθητε V-API-2P 1252 διακρίνω ! διακρίνω
JAS 2:4.3 . ἐν ἐν PREP 1722 ἐν ! ἐν
JAS 2:4.4 . ἑαυτοῖς ἑαυτοῖς F-2DPM 1438 ἑαυτοῦ ! ἑαυτοῦ
JAS 2:4.5 . καὶ καὶ CONJ 2532 καί ! καί
JAS 2:4.6 . ἐγένεσθε ἐγένεσθε V-2ADI-2P 1096 γίνομαι ! γίνομαι
JAS 2:4.7 . κριταὶ κριταὶ N-NPM 2923 κριτής ! κριτής
JAS 2:4.8 . διαλογισμῶν διαλογισμῶν N-GPM 1261 διαλογισμός ! διαλογισμός
JAS 2:4.9 . πονηρῶν; πονηρῶν; A-GPM 4190 πονηρός ! πονηρός
JAS 2:5.1 P Ἀκούσατε, Ἀκούσατε, V-AAM-2P 191 ἀκούω ! ἀκούω
JAS 2:5.2 . ἀδελφοί ἀδελφοί N-VPM 80 ἀδελφός ! ἀδελφός
JAS 2:5.3 . μου μου P-1GS 1473 ἐγώ ! ἐγώ
JAS 2:5.4 . ἀγαπητοί. ἀγαπητοί. A-VPM 27 ἀγαπητός ! ἀγαπητός
JAS 2:5.5 . οὐχ οὐχ PRT-N 3756 οὐ ! οὐ
JAS 2:5.6 . ὁ ὁ T-NSM 3588 ὁ ! ὁ
JAS 2:5.7 . θεὸς θεὸς N-NSM 2316 θεός ! θεός
JAS 2:5.8 . ἐξελέξατο ἐξελέξατο V-AMI-3S 1586 ἐκλέγομαι ! ἐκλέγω
JAS 2:5.9 . τοὺς τοὺς T-APM 3588 ὁ ! ὁ
JAS 2:5.10 . πτωχοὺς πτωχοὺς A-APM 4434 πτωχός ! πτωχός
JAS 2:5.11 . τῷ τῷ T-DSM 3588 ὁ ! ὁ
JAS 2:5.12 . κόσμῳ κόσμῳ N-DSM 2889 κόσμος ! κόσμος
JAS 2:5.13 . πλουσίους πλουσίους A-APM 4145 πλούσιος ! πλούσιος
JAS 2:5.14 . ἐν ἐν PREP 1722 ἐν ! ἐν
JAS 2:5.15 . πίστει πίστει N-DSF 4102 πίστις ! πίστις
JAS 2:5.16 . καὶ καὶ CONJ 2532 καί ! καί
JAS 2:5.17 . κληρονόμους κληρονόμους N-APM 2818 κληρονόμος ! κληρονόμος
JAS 2:5.18 . τῆς τῆς T-GSF 3588 ὁ ! ὁ
JAS 2:5.19 . βασιλείας βασιλείας N-GSF 932 βασιλεία ! βασιλεία
JAS 2:5.20 . ἧς ἧς R-GSF 3739 ὅς ! ὅς
JAS 2:5.21 . ἐπηγγείλατο ἐπηγγείλατο V-ADI-3S 1861 ἐπαγγέλλω ! ἐπαγγέλλομαι
JAS 2:5.22 . τοῖς τοῖς T-DPM 3588 ὁ ! ὁ
JAS 2:5.23 . ἀγαπῶσιν ἀγαπῶσιν V-PAP-DPM 25 ἀγαπάω ! ἀγαπάω
JAS 2:5.24 . αὐτόν; αὐτόν; P-ASM 846 αὐτός ! αὐτός
JAS 2:6.1 . ὑμεῖς ὑμεῖς P-2NP 5210 ὑμεῖς ! σύ
JAS 2:6.2 . δὲ δὲ CONJ 1161 δέ ! δέ
JAS 2:6.3 . ἠτιμάσατε ἠτιμάσατε V-AAI-2P 818 ἀτιμάζω ! ἀτιμάζω
JAS 2:6.4 . τὸν τὸν T-ASM 3588 ὁ ! ὁ
JAS 2:6.5 . πτωχόν. πτωχόν. A-ASM 4434 πτωχός ! πτωχός
JAS 2:6.6 . οὐχ οὐχ PRT-N 3756 οὐ ! οὐ
JAS 2:6.7 . οἱ οἱ T-NPM 3588 ὁ ! ὁ
JAS 2:6.8 . πλούσιοι πλούσιοι A-NPM 4145 πλούσιος ! πλούσιος
JAS 2:6.9 . καταδυναστεύουσιν καταδυναστεύουσιν V-PAI-3P 2616 καταδυναστεύω ! καταδυναστεύω
JAS 2:6.10 . ὑμᾶς, ὑμᾶς, P-2AP 5210 ὑμεῖς ! σύ
JAS 2:6.11 . καὶ καὶ CONJ 2532 καί ! καί
JAS 2:6.12 . αὐτοὶ αὐτοὶ P-NPM 846 αὐτός ! αὐτός
JAS 2:6.13 . ἕλκουσιν ἕλκουσιν V-PAI-3P 1670 ἑλκύω ! ἕλκω
JAS 2:6.14 . ὑμᾶς ὑμᾶς P-2AP 5210 ὑμεῖς ! σύ
JAS 2:6.15 . εἰς εἰς PREP 1519 εἰς ! εἰς
JAS 2:6.16 . κριτήρια; κριτήρια; N-APN 2922 κριτήριον ! κριτήριον
JAS 2:7.1 . οὐκ οὐκ PRT-N 3756 οὐ ! οὐ
JAS 2:7.2 . αὐτοὶ αὐτοὶ P-NPM 846 αὐτός ! αὐτός
JAS 2:7.3 . βλασφημοῦσιν βλασφημοῦσιν V-PAI-3P 987 βλασφημέω ! βλασφημέω
JAS 2:7.4 . τὸ τὸ T-ASN 3588 ὁ ! ὁ
JAS 2:7.5 . καλὸν καλὸν A-ASN 2570 καλός ! καλός
JAS 2:7.6 . ὄνομα ὄνομα N-ASN 3686 ὄνομα ! ὄνομα
JAS 2:7.7 . τὸ τὸ T-ASN 3588 ὁ ! ὁ
JAS 2:7.8 . ἐπικληθὲν ἐπικληθὲν V-APP-ASN 1941 ἐπικαλέομαι ! ἐπικαλέω
JAS 2:7.9 . ἐφ’ ἐφ’ PREP 1909 ἐπί ! ἐπί
JAS 2:7.10 . ὑμᾶς; ὑμᾶς; P-2AP 5210 ὑμεῖς ! σύ
JAS 2:8.1 . εἰ εἰ COND 1487 εἰ ! εἰ
JAS 2:8.2 . μέντοι μέντοι CONJ 3305 μέντοι ! μέντοι
JAS 2:8.3 . νόμον νόμον N-ASM 3551 νόμος ! νόμος
JAS 2:8.4 . τελεῖτε τελεῖτε V-PAI-2P 5055 τελέω ! τελέω
JAS 2:8.5 . βασιλικὸν βασιλικὸν A-ASM 937 βασιλικός ! βασιλικός
JAS 2:8.6 . κατὰ κατὰ PREP 2596 κατά ! κατά
JAS 2:8.7 . τὴν τὴν T-ASF 3588 ὁ ! ὁ
JAS 2:8.8 . γραφήν, γραφήν, N-ASF 1124 γραφή ! γραφή
JAS 2:8.9 . ἀγαπήσεις ἀγαπήσεις V-FAI-2S 25 ἀγαπάω ! ἀγαπάω
JAS 2:8.10 . τὸν τὸν T-ASM 3588 ὁ ! ὁ
JAS 2:8.11 . πλησίον πλησίον ADV 4139 πλησίον ! πλησίον
JAS 2:8.12 . σου σου P-2GS 4771 σύ ! σύ
JAS 2:8.13 . ὡς ὡς ADV 5613 ὡς ! ὡς
JAS 2:8.14 . σεαυτόν, σεαυτόν, F-2ASM 4572 σεαυτοῦ ! σεαυτοῦ
JAS 2:8.15 . καλῶς καλῶς ADV 2573 καλῶς ! καλῶς
JAS 2:8.16 . ποιεῖτε· ποιεῖτε· V-PAI-2P 4160 ποιέω ! ποιέω
JAS 2:9.1 . εἰ εἰ COND 1487 εἰ ! εἰ
JAS 2:9.2 . δὲ δὲ CONJ 1161 δέ ! δέ
JAS 2:9.3 . προσωπολημπτεῖτε, προσωπολημπτεῖτε, V-PAI-2P 4380 προσωποληπτέω ! προσωπολημπτέω
JAS 2:9.4 . ἁμαρτίαν ἁμαρτίαν N-ASF 266 ἁμαρτία ! ἁμαρτία
JAS 2:9.5 . ἐργάζεσθε, ἐργάζεσθε, V-PNI-2P 2038 ἐργάζομαι ! ἐργάζομαι
JAS 2:9.6 . ἐλεγχόμενοι ἐλεγχόμενοι V-PPP-NPM 1651 ἐλέγχω ! ἐλέγχω
JAS 2:9.7 . ὑπὸ ὑπὸ PREP 5259 ὑπό ! ὑπό
JAS 2:9.8 . τοῦ τοῦ T-GSM 3588 ὁ ! ὁ
JAS 2:9.9 . νόμου νόμου N-GSM 3551 νόμος ! νόμος
JAS 2:9.10 . ὡς ὡς ADV 5613 ὡς ! ὡς
JAS 2:9.11 . παραβάται. παραβάται. N-NPM 3848 παραβάτης ! παραβάτης
JAS 2:10.1 . ὅστις ὅστις R-NSM 3748 ὅστις ! ὅστις
JAS 2:10.2 . γὰρ γὰρ CONJ 1063 γάρ ! γάρ
JAS 2:10.3 . ὅλον ὅλον A-ASM 3650 ὅλος ! ὅλος
JAS 2:10.4 . τὸν τὸν T-ASM 3588 ὁ ! ὁ
JAS 2:10.5 . νόμον νόμον N-ASM 3551 νόμος ! νόμος
JAS 2:10.6 . τηρήσῃ, τηρήσῃ, V-AAS-3S 5083 τηρέω ! τηρέω
JAS 2:10.7 . πταίσῃ πταίσῃ V-AAS-3S 4417 πταίω ! πταίω
JAS 2:10.8 . δὲ δὲ CONJ 1161 δέ ! δέ
JAS 2:10.9 . ἐν ἐν PREP 1722 ἐν ! ἐν
JAS 2:10.10 . ἑνί, ἑνί, A-DSN 1520 εἷς ! εἷς
JAS 2:10.11 . γέγονεν γέγονεν V-2RAI-3S 1096 γίνομαι ! γίνομαι
JAS 2:10.12 . πάντων πάντων A-GPN 3956 πᾶς ! πᾶς
JAS 2:10.13 . ἔνοχος. ἔνοχος. A-NSM 1777 ἔνοχος ! ἔνοχος
JAS 2:11.1 . ὁ ὁ T-NSM 3588 ὁ ! ὁ
JAS 2:11.2 . γὰρ γὰρ CONJ 1063 γάρ ! γάρ
JAS 2:11.3 . εἰπών, εἰπών, V-2AAP-NSM 3004 λέγω ! λέγω
JAS 2:11.4 . μὴ μὴ PRT-N 3361 μή ! μή
JAS 2:11.5 . μοιχεύσῃς, μοιχεύσῃς, V-AAS-2S 3431 μοιχεύω ! μοιχεύω
JAS 2:11.6 . εἶπεν εἶπεν V-2AAI-3S 3004 λέγω ! λέγω
JAS 2:11.7 . καί, καί, CONJ 2532 καί ! καί
JAS 2:11.8 . μὴ μὴ PRT-N 3361 μή ! μή
JAS 2:11.9 . φονεύσῃς· φονεύσῃς· V-AAS-2S 5407 φονεύω ! φονεύω
JAS 2:11.10 . εἰ εἰ COND 1487 εἰ ! εἰ
JAS 2:11.11 . δὲ δὲ CONJ 1161 δέ ! δέ
JAS 2:11.12 . οὐ οὐ PRT-N 3756 οὐ ! οὐ
JAS 2:11.13 . μοιχεύεις, μοιχεύεις, V-PAI-2S 3431 μοιχεύω ! μοιχεύω
JAS 2:11.14 . φονεύεις φονεύεις V-PAI-2S 5407 φονεύω ! φονεύω
JAS 2:11.15 . δέ, δέ, CONJ 1161 δέ ! δέ
JAS 2:11.16 . γέγονας γέγονας V-2RAI-2S 1096 γίνομαι ! γίνομαι
JAS 2:11.17 . παραβάτης παραβάτης N-NSM 3848 παραβάτης ! παραβάτης
JAS 2:11.18 . νόμου. νόμου. N-GSM 3551 νόμος ! νόμος
JAS 2:12.1 . οὗτως οὗτως ADV 3779 οὕτω ! οὕτω
JAS 2:12.2 . λαλεῖτε λαλεῖτε V-PAM-2P 2980 λαλέω ! λαλέω
JAS 2:12.3 . καὶ καὶ CONJ 2532 καί ! καί
JAS 2:12.4 . οὕτως οὕτως ADV 3779 οὕτω ! οὕτω
JAS 2:12.5 . ποιεῖτε ποιεῖτε V-PAM-2P 4160 ποιέω ! ποιέω
JAS 2:12.6 . ὡς ὡς ADV 5613 ὡς ! ὡς
JAS 2:12.7 . διὰ διὰ PREP 1223 διά ! διά
JAS 2:12.8 . νόμου νόμου N-GSM 3551 νόμος ! νόμος
JAS 2:12.9 . ἐλευθερίας ἐλευθερίας N-GSF 1657 ἐλευθερία ! ἐλευθερία
JAS 2:12.10 . μέλλοντες μέλλοντες V-PAP-NPM 3195 μέλλω ! μέλλω
JAS 2:12.11 . κρίνεσθαι. κρίνεσθαι. V-PPN 2919 κρίνω ! κρίνω
JAS 2:13.1 . ἡ ἡ T-NSF 3588 ὁ ! ὁ
JAS 2:13.2 . γὰρ γὰρ CONJ 1063 γάρ ! γάρ
JAS 2:13.3 . κρίσις κρίσις N-NSF 2920 κρίσις ! κρίσις
JAS 2:13.4 . ἀνέλεος ἀνέλεος A-NSF 448 ἀνίλεως ! ἀνέλεος
JAS 2:13.5 . τῷ τῷ T-DSM 3588 ὁ ! ὁ
JAS 2:13.6 . μὴ μὴ PRT-N 3361 μή ! μή
JAS 2:13.7 . ποιήσαντι ποιήσαντι V-AAP-DSM 4160 ποιέω ! ποιέω
JAS 2:13.8 . ἔλεος· ἔλεος· N-ASN 1656 ἔλεος ! ἔλεος
JAS 2:13.9 . κατακαυχᾶται κατακαυχᾶται V-PNI-3S 2620 κατακαυχάομαι ! κατακαυχάομαι
JAS 2:13.10 . ἔλεος ἔλεος N-NSN 1656 ἔλεος ! ἔλεος
JAS 2:13.11 . κρίσεως. κρίσεως. N-GSF 2920 κρίσις ! κρίσις
JAS 2:14.1 P Τί Τί I-NSN 5101 τίς ! τίς
JAS 2:14.2 . τὸ τὸ T-NSN 3588 ὁ ! ὁ
JAS 2:14.3 . ὄφελος, ὄφελος, N-NSN 3786 ὄφελος ! ὄφελος
JAS 2:14.4 . ἀδελφοί ἀδελφοί N-VPM 80 ἀδελφός ! ἀδελφός
JAS 2:14.5 . μου, μου, P-1GS 1473 ἐγώ ! ἐγώ
JAS 2:14.6 . ἐὰν ἐὰν COND 1437 ἐάν ! ἐάν
JAS 2:14.7 . πίστιν πίστιν N-ASF 4102 πίστις ! πίστις
JAS 2:14.8 . λέγῃ λέγῃ V-PAS-3S 3004 λέγω ! λέγω
JAS 2:14.9 . τις τις X-NSM 5100 τὶς ! τὶς
JAS 2:14.10 . ἔχειν, ἔχειν, V-PAN 2192 ἔχω ! ἔχω
JAS 2:14.11 . ἔργα ἔργα N-APN 2041 ἔργον ! ἔργον
JAS 2:14.12 . δὲ δὲ CONJ 1161 δέ ! δέ
JAS 2:14.13 . μὴ μὴ PRT-N 3361 μή ! μή
JAS 2:14.14 . ἔχῃ; ἔχῃ; V-PAS-3S 2192 ἔχω ! ἔχω
JAS 2:14.15 . μὴ μὴ PRT-N 3361 μή ! μή
JAS 2:14.16 . δύναται δύναται V-PNI-3S 1410 δύναμαι ! δύναμαι
JAS 2:14.17 . ἡ ἡ T-NSF 3588 ὁ ! ὁ
JAS 2:14.18 . πίστις πίστις N-NSF 4102 πίστις ! πίστις
JAS 2:14.19 . σῶσαι σῶσαι V-AAN 4982 σώζω ! σῴζω
JAS 2:14.20 . αὐτόν; αὐτόν; P-ASM 846 αὐτός ! αὐτός
JAS 2:15.1 . ἐὰν ἐὰν COND 1437 ἐάν ! ἐάν
JAS 2:15.2 . ἀδελφὸς ἀδελφὸς N-NSM 80 ἀδελφός ! ἀδελφός
JAS 2:15.3 . ἢ ἢ PRT 2228 ἤ ! ἤ
JAS 2:15.4 . ἀδελφὴ ἀδελφὴ N-NSF 79 ἀδελφή ! ἀδελφή
JAS 2:15.5 . γυμνοὶ γυμνοὶ A-NPM 1131 γυμνός ! γυμνός
JAS 2:15.6 . ὑπάρχωσιν ὑπάρχωσιν V-PAS-3P 5225 ὑπάρχω ! ὑπάρχω
JAS 2:15.7 . καὶ καὶ CONJ 2532 καί ! καί
JAS 2:15.8 . λειπόμενοι λειπόμενοι V-PPP-NPM 3007 λείπω ! λείπω
JAS 2:15.9 . τῆς τῆς T-GSF 3588 ὁ ! ὁ
JAS 2:15.10 . ἐφημέρου ἐφημέρου A-GSF 2184 ἐφήμερος ! ἐφήμερος
JAS 2:15.11 . τροφῆς, τροφῆς, N-GSF 5160 τροφή ! τροφή
JAS 2:16.1 . εἴπῃ εἴπῃ V-2AAS-3S 3004 λέγω ! λέγω
JAS 2:16.2 . δέ δέ CONJ 1161 δέ ! δέ
JAS 2:16.3 . τις τις X-NSM 5100 τὶς ! τὶς
JAS 2:16.4 . αὐτοῖς αὐτοῖς P-DPM 846 αὐτός ! αὐτός
JAS 2:16.5 . ἐξ ἐξ PREP 1537 ἐκ ! ἐκ
JAS 2:16.6 . ὑμῶν, ὑμῶν, P-2GP 5210 ὑμεῖς ! σύ
JAS 2:16.7 . ὑπάγετε ὑπάγετε V-PAM-2P 5217 ὑπάγω ! ὑπάγω
JAS 2:16.8 . ἐν ἐν PREP 1722 ἐν ! ἐν
JAS 2:16.9 . εἰρήνῃ, εἰρήνῃ, N-DSF 1515 εἰρήνη ! εἰρήνη
JAS 2:16.10 . θερμαίνεσθε θερμαίνεσθε V-PEM-2P 2328 θερμαίνω ! θερμαίνω
JAS 2:16.11 . καὶ καὶ CONJ 2532 καί ! καί
JAS 2:16.12 . χορτάζεσθε, χορτάζεσθε, V-PPM-2P 5526 χορτάζω ! χορτάζω
JAS 2:16.13 . μὴ μὴ PRT-N 3361 μή ! μή
JAS 2:16.14 . δῶτε δῶτε V-2AAS-2P 1325 δίδωμι ! δίδωμι
JAS 2:16.15 . δὲ δὲ CONJ 1161 δέ ! δέ
JAS 2:16.16 . αὐτοῖς αὐτοῖς P-DPM 846 αὐτός ! αὐτός
JAS 2:16.17 . τὰ τὰ T-APN 3588 ὁ ! ὁ
JAS 2:16.18 . ἐπιτήδεια ἐπιτήδεια A-APN 2006 ἐπιτήδειος ! ἐπιτήδειος
JAS 2:16.19 . τοῦ τοῦ T-GSN 3588 ὁ ! ὁ
JAS 2:16.20 . σώματος, σώματος, N-GSN 4983 σῶμα ! σῶμα
JAS 2:16.21 . τί τί I-NSN 5101 τίς ! τίς
JAS 2:16.22 . τὸ τὸ T-NSN 3588 ὁ ! ὁ
JAS 2:16.23 . ὄφελος; ὄφελος; N-NSN 3786 ὄφελος ! ὄφελος
JAS 2:17.1 . οὕτως οὕτως ADV 3779 οὕτω ! οὕτω
JAS 2:17.2 . καὶ καὶ CONJ 2532 καί ! καί
JAS 2:17.3 . ἡ ἡ T-NSF 3588 ὁ ! ὁ
JAS 2:17.4 . πίστις, πίστις, N-NSF 4102 πίστις ! πίστις
JAS 2:17.5 . ἐὰν ἐὰν COND 1437 ἐάν ! ἐάν
JAS 2:17.6 . μὴ μὴ PRT-N 3361 μή ! μή
JAS 2:17.7 . ἔχῃ ἔχῃ V-PAS-3S 2192 ἔχω ! ἔχω
JAS 2:17.8 . ἔργα, ἔργα, N-APN 2041 ἔργον ! ἔργον
JAS 2:17.9 . νεκρά νεκρά A-NSF 3498 νεκρός ! νεκρός
JAS 2:17.10 . ἐστιν ἐστιν V-PAI-3S 1510 εἰμί ! εἰμί
JAS 2:17.11 . καθ’ καθ’ PREP 2596 κατά ! κατά
JAS 2:17.12 . ἑαυτήν. ἑαυτήν. F-3ASF 1438 ἑαυτοῦ ! ἑαυτοῦ
JAS 2:18.1 . ἀλλ’ ἀλλ’ CONJ 235 ἀλλά ! ἀλλά
JAS 2:18.2 . ἐρεῖ ἐρεῖ V-FAI-3S 2046 ἐρέω ! εἶπον
JAS 2:18.3 . τις, τις, X-NSM 5100 τὶς ! τὶς
JAS 2:18.4 . σὺ σὺ P-2NS 4771 σύ ! σύ
JAS 2:18.5 . πίστιν πίστιν N-ASF 4102 πίστις ! πίστις
JAS 2:18.6 . ἔχεις ἔχεις V-PAI-2S 2192 ἔχω ! ἔχω
JAS 2:18.7 . κἀγὼ κἀγὼ P-1NS-K 2504 κἀγώ ! κἀγώ
JAS 2:18.8 . ἔργα ἔργα N-APN 2041 ἔργον ! ἔργον
JAS 2:18.9 . ἔχω. ἔχω. V-PAI-1S 2192 ἔχω ! ἔχω
JAS 2:18.10 . δεῖξόν δεῖξόν V-AAM-2S 1166 δεικνύω ! δείκνυμι
JAS 2:18.11 . μοι μοι P-1DS 1473 ἐγώ ! ἐγώ
JAS 2:18.12 . τὴν τὴν T-ASF 3588 ὁ ! ὁ
JAS 2:18.13 . πίστιν πίστιν N-ASF 4102 πίστις ! πίστις
JAS 2:18.14 . σου σου P-2GS 4771 σύ ! σύ
JAS 2:18.15 . χωρὶς χωρὶς ADV 5565 χωρίς ! χωρίς
JAS 2:18.16 . τῶν τῶν T-GPN 3588 ὁ ! ὁ
JAS 2:18.17 . ἔργων, ἔργων, N-GPN 2041 ἔργον ! ἔργον
JAS 2:18.18 . κἀγώ κἀγώ P-1NS-K 2504 κἀγώ ! κἀγώ
JAS 2:18.19 . σοι σοι P-2DS 4771 σύ ! σύ
JAS 2:18.20 . δείξω δείξω V-FAI-1S 1166 δεικνύω ! δείκνυμι
JAS 2:18.21 . ἐκ ἐκ PREP 1537 ἐκ ! ἐκ
JAS 2:18.22 . τῶν τῶν T-GPN 3588 ὁ ! ὁ
JAS 2:18.23 . ἔργων ἔργων N-GPN 2041 ἔργον ! ἔργον
JAS 2:18.24 . μου μου P-1GS 1473 ἐγώ ! ἐγώ
JAS 2:18.25 . τὴν τὴν T-ASF 3588 ὁ ! ὁ
JAS 2:18.26 . πίστιν. πίστιν. N-ASF 4102 πίστις ! πίστις
JAS 2:19.1 . σὺ σὺ P-2NS 4771 σύ ! σύ
JAS 2:19.2 . πιστεύεις πιστεύεις V-PAI-2S 4100 πιστεύω ! πιστεύω
JAS 2:19.3 . ὅτι ὅτι CONJ 3754 ὅτι ! ὅτι
JAS 2:19.4 . εἷς εἷς A-NSM 1520 εἷς ! εἷς
JAS 2:19.5 . ἐστιν ἐστιν V-PAI-3S 1510 εἰμί ! εἰμί
JAS 2:19.6 . ὁ ὁ T-NSM 3588 ὁ ! ὁ
JAS 2:19.7 . θεός; θεός; N-NSM 2316 θεός ! θεός
JAS 2:19.8 . καλῶς καλῶς ADV 2573 καλῶς ! καλῶς
JAS 2:19.9 . ποιεῖς· ποιεῖς· V-PAI-2S 4160 ποιέω ! ποιέω
JAS 2:19.10 . καὶ καὶ CONJ 2532 καί ! καί
JAS 2:19.11 . τὰ τὰ T-NPN 3588 ὁ ! ὁ
JAS 2:19.12 . δαιμόνια δαιμόνια N-NPN 1140 δαιμόνιον ! δαιμόνιον
JAS 2:19.13 . πιστεύουσιν πιστεύουσιν V-PAI-3P 4100 πιστεύω ! πιστεύω
JAS 2:19.14 . καὶ καὶ CONJ 2532 καί ! καί
JAS 2:19.15 . φρίσσουσιν. φρίσσουσιν. V-PAI-3P 5425 φρίσσω ! φρίσσω
JAS 2:20.1 . θέλεις θέλεις V-PAI-2S 2309 θέλω ! θέλω
JAS 2:20.2 . δὲ δὲ CONJ 1161 δέ ! δέ
JAS 2:20.3 . γνῶναι, γνῶναι, V-2AAN 1097 γινώσκω ! γινώσκω
JAS 2:20.4 . ὦ ὦ INJ 5599 ὦ ! ὦ
JAS 2:20.5 . ἄνθρωπε ἄνθρωπε N-VSM 444 ἄνθρωπος ! ἄνθρωπος
JAS 2:20.6 . κενέ, κενέ, A-VSM 2756 κενός ! κενός
JAS 2:20.7 . ὅτι ὅτι CONJ 3754 ὅτι ! ὅτι
JAS 2:20.8 . ἡ ἡ T-NSF 3588 ὁ ! ὁ
JAS 2:20.9 . πίστις πίστις N-NSF 4102 πίστις ! πίστις
JAS 2:20.10 . χωρὶς χωρὶς ADV 5565 χωρίς ! χωρίς
JAS 2:20.11 . τῶν τῶν T-GPN 3588 ὁ ! ὁ
JAS 2:20.12 . ἔργων ἔργων N-GPN 2041 ἔργον ! ἔργον
JAS 2:20.13 . ἀργή ἀργή A-NSF 692 ἀργός ! ἀργός
JAS 2:20.14 . ἐστιν; ἐστιν; V-PAI-3S 1510 εἰμί ! εἰμί
JAS 2:21.1 . Ἀβραὰμ Ἀβραὰμ N-PRI 11 Ἀβραάμ ! Ἀβραάμ
JAS 2:21.2 . ὁ ὁ T-NSM 3588 ὁ ! ὁ
JAS 2:21.3 . πατὴρ πατὴρ N-NSM 3962 πατήρ ! πατήρ
JAS 2:21.4 . ἡμῶν ἡμῶν P-1GP 2249 ἡμεῖς ! ἐγώ
JAS 2:21.5 . οὐκ οὐκ PRT-N 3756 οὐ ! οὐ
JAS 2:21.6 . ἐξ ἐξ PREP 1537 ἐκ ! ἐκ
JAS 2:21.7 . ἔργων ἔργων N-GPN 2041 ἔργον ! ἔργον
JAS 2:21.8 . ἐδικαιώθη, ἐδικαιώθη, V-API-3S 1344 δικαιόω ! δικαιόω
JAS 2:21.9 . ἀνενέγκας ἀνενέγκας V-AAP-NSM 399 ἀναφέρω ! ἀναφέρω
JAS 2:21.10 . Ἰσαὰκ Ἰσαὰκ N-PRI 2464 Ἰσαάκ ! Ἰσαάκ
JAS 2:21.11 . τὸν τὸν T-ASM 3588 ὁ ! ὁ
JAS 2:21.12 . υἱὸν υἱὸν N-ASM 5207 υἱός ! υἱός
JAS 2:21.13 . αὐτοῦ αὐτοῦ P-GSM 846 αὐτός ! αὐτός
JAS 2:21.14 . ἐπὶ ἐπὶ PREP 1909 ἐπί ! ἐπί
JAS 2:21.15 . τὸ τὸ T-ASN 3588 ὁ ! ὁ
JAS 2:21.16 . θυσιαστήριον; θυσιαστήριον; N-ASN 2379 θυσιαστήριον ! θυσιαστήριον
JAS 2:22.1 . βλέπεις βλέπεις V-PAI-2S 991 βλέπω ! βλέπω
JAS 2:22.2 . ὅτι ὅτι CONJ 3754 ὅτι ! ὅτι
JAS 2:22.3 . ἡ ἡ T-NSF 3588 ὁ ! ὁ
JAS 2:22.4 . πίστις πίστις N-NSF 4102 πίστις ! πίστις
JAS 2:22.5 . συνήργει συνήργει V-IAI-3S 4903 συνεργέω ! συνεργέω
JAS 2:22.6 . τοῖς τοῖς T-DPN 3588 ὁ ! ὁ
JAS 2:22.7 . ἔργοις ἔργοις N-DPN 2041 ἔργον ! ἔργον
JAS 2:22.8 . αὐτοῦ αὐτοῦ P-GSM 846 αὐτός ! αὐτός
JAS 2:22.9 . καὶ καὶ CONJ 2532 καί ! καί
JAS 2:22.10 . ἐκ ἐκ PREP 1537 ἐκ ! ἐκ
JAS 2:22.11 . τῶν τῶν T-GPN 3588 ὁ ! ὁ
JAS 2:22.12 . ἔργων ἔργων N-GPN 2041 ἔργον ! ἔργον
JAS 2:22.13 . ἡ ἡ T-NSF 3588 ὁ ! ὁ
JAS 2:22.14 . πίστις πίστις N-NSF 4102 πίστις ! πίστις
JAS 2:22.15 . ἐτελειώθη, ἐτελειώθη, V-API-3S 5048 τελειόω ! τελειόω
JAS 2:23.1 . καὶ καὶ CONJ 2532 καί ! καί
JAS 2:23.2 . ἐπληρώθη ἐπληρώθη V-API-3S 4137 πληρόω ! πληρόω
JAS 2:23.3 . ἡ ἡ T-NSF 3588 ὁ ! ὁ
JAS 2:23.4 . γραφὴ γραφὴ N-NSF 1124 γραφή ! γραφή
JAS 2:23.5 . ἡ ἡ T-NSF 3588 ὁ ! ὁ
JAS 2:23.6 . λέγουσα· λέγουσα· V-PAP-NSF 3004 λέγω ! λέγω
JAS 2:23.7 . ἐπίστευσεν ἐπίστευσεν V-AAI-3S 4100 πιστεύω ! πιστεύω
JAS 2:23.8 . δὲ δὲ CONJ 1161 δέ ! δέ
JAS 2:23.9 . Ἀβραὰμ Ἀβραὰμ N-PRI 11 Ἀβραάμ ! Ἀβραάμ
JAS 2:23.10 . τῷ τῷ T-DSM 3588 ὁ ! ὁ
JAS 2:23.11 . θεῷ, θεῷ, N-DSM 2316 θεός ! θεός
JAS 2:23.12 . καὶ καὶ CONJ 2532 καί ! καί
JAS 2:23.13 . ἐλογίσθη ἐλογίσθη V-API-3S 3049 λογίζομαι ! λογίζομαι
JAS 2:23.14 . αὐτῷ αὐτῷ P-DSM 846 αὐτός ! αὐτός
JAS 2:23.15 . εἰς εἰς PREP 1519 εἰς ! εἰς
JAS 2:23.16 . δικαιοσύνην, δικαιοσύνην, N-ASF 1343 δικαιοσύνη ! δικαιοσύνη
JAS 2:23.17 . καὶ καὶ CONJ 2532 καί ! καί
JAS 2:23.18 . φίλος φίλος A-NSM 5384 φίλος ! φίλος
JAS 2:23.19 . θεοῦ θεοῦ N-GSM 2316 θεός ! θεός
JAS 2:23.20 . ἐκλήθη. ἐκλήθη. V-API-3S 2564 καλέω ! καλέω
JAS 2:24.1 . ὁρᾶτε ὁρᾶτε V-PAI-2P 3708 ὁράω ! ὁράω
JAS 2:24.2 . ὅτι ὅτι CONJ 3754 ὅτι ! ὅτι
JAS 2:24.3 . ἐξ ἐξ PREP 1537 ἐκ ! ἐκ
JAS 2:24.4 . ἔργων ἔργων N-GPN 2041 ἔργον ! ἔργον
JAS 2:24.5 . δικαιοῦται δικαιοῦται V-PPI-3S 1344 δικαιόω ! δικαιόω
JAS 2:24.6 . ἄνθρωπος ἄνθρωπος N-NSM 444 ἄνθρωπος ! ἄνθρωπος
JAS 2:24.7 . καὶ καὶ CONJ 2532 καί ! καί
JAS 2:24.8 . οὐκ οὐκ PRT-N 3756 οὐ ! οὐ
JAS 2:24.9 . ἐκ ἐκ PREP 1537 ἐκ ! ἐκ
JAS 2:24.10 . πίστεως πίστεως N-GSF 4102 πίστις ! πίστις
JAS 2:24.11 . μόνον. μόνον. ADV 3440 μόνον ! μόνος
JAS 2:25.1 . ὁμοίως ὁμοίως ADV 3668 ὁμοίως ! ὁμοίως
JAS 2:25.2 . δὲ δὲ CONJ 1161 δέ ! δέ
JAS 2:25.3 . καὶ καὶ CONJ 2532 καί ! καί
JAS 2:25.4 . Ῥαὰβ Ῥαὰβ N-PRI 4460 Ῥαάβ ! Ῥαάβ
JAS 2:25.5 . ἡ ἡ T-NSF 3588 ὁ ! ὁ
JAS 2:25.6 . πόρνη πόρνη N-NSF 4204 πόρνη ! πόρνη
JAS 2:25.7 . οὐκ οὐκ PRT-N 3756 οὐ ! οὐ
JAS 2:25.8 . ἐξ ἐξ PREP 1537 ἐκ ! ἐκ
JAS 2:25.9 . ἔργων ἔργων N-GPN 2041 ἔργον ! ἔργον
JAS 2:25.10 . ἐδικαιώθη, ἐδικαιώθη, V-API-3S 1344 δικαιόω ! δικαιόω
JAS 2:25.11 . ὑποδεξαμένη ὑποδεξαμένη V-ADP-NSF 5264 ὑποδέχομαι ! ὑποδέχομαι
JAS 2:25.12 . τοὺς τοὺς T-APM 3588 ὁ ! ὁ
JAS 2:25.13 . ἀγγέλους ἀγγέλους N-APM 32 ἄγγελος ! ἄγγελος
JAS 2:25.14 . καὶ καὶ CONJ 2532 καί ! καί
JAS 2:25.15 . ἑτέρᾳ ἑτέρᾳ A-DSF 2087 ἕτερος ! ἕτερος
JAS 2:25.16 . ὁδῷ ὁδῷ N-DSF 3598 ὁδός ! ὁδός
JAS 2:25.17 . ἐκβαλοῦσα; ἐκβαλοῦσα; V-2AAP-NSF 1544 ἐκβάλλω ! ἐκβάλλω
JAS 2:26.1 . ὥσπερ ὥσπερ ADV 5618 ὥσπερ ! ὥσπερ
JAS 2:26.2 . γὰρ γὰρ CONJ 1063 γάρ ! γάρ
JAS 2:26.3 . τὸ τὸ T-NSN 3588 ὁ ! ὁ
JAS 2:26.4 . σῶμα σῶμα N-NSN 4983 σῶμα ! σῶμα
JAS 2:26.5 . χωρὶς χωρὶς ADV 5565 χωρίς ! χωρίς
JAS 2:26.6 . πνεύματος πνεύματος N-GSN 4151 πνεῦμα ! πνεῦμα
JAS 2:26.7 . νεκρόν νεκρόν A-NSN 3498 νεκρός ! νεκρός
JAS 2:26.8 . ἐστιν, ἐστιν, V-PAI-3S 1510 εἰμί ! εἰμί
JAS 2:26.9 . οὕτως οὕτως ADV 3779 οὕτω ! οὕτω
JAS 2:26.10 . καὶ καὶ CONJ 2532 καί ! καί
JAS 2:26.11 . ἡ ἡ T-NSF 3588 ὁ ! ὁ
JAS 2:26.12 . πίστις πίστις N-NSF 4102 πίστις ! πίστις
JAS 2:26.13 . χωρὶς χωρὶς ADV 5565 χωρίς ! χωρίς
JAS 2:26.14 . ἔργων ἔργων N-GPN 2041 ἔργον ! ἔργον
JAS 2:26.15 . νεκρά νεκρά A-NSF 3498 νεκρός ! νεκρός
JAS 2:26.16 . ἐστιν. ἐστιν. V-PAI-3S 1510 εἰμί ! εἰμί
JAS 3:1.1 C Μὴ Μὴ PRT-N 3361 μή ! μή
JAS 3:1.2 . πολλοὶ πολλοὶ A-NPM 4183 πολύς ! πολύς
JAS 3:1.3 . διδάσκαλοι διδάσκαλοι N-NPM 1320 διδάσκαλος ! διδάσκαλος
JAS 3:1.4 . γίνεσθε, γίνεσθε, V-PNM-2P 1096 γίνομαι ! γίνομαι
JAS 3:1.5 . ἀδελφοί ἀδελφοί N-VPM 80 ἀδελφός ! ἀδελφός
JAS 3:1.6 . μου, μου, P-1GS 1473 ἐγώ ! ἐγώ
JAS 3:1.7 . εἰδότες εἰδότες V-RAP-NPM 1492 εἴδω ! οἶδα
JAS 3:1.8 . ὅτι ὅτι CONJ 3754 ὅτι ! ὅτι
JAS 3:1.9 . μεῖζον μεῖζον A-ASN-C 3173 μέγας ! μέγας
JAS 3:1.10 . κρίμα κρίμα N-ASN 2917 κρίμα ! κρίμα
JAS 3:1.11 . λημψόμεθα. λημψόμεθα. V-FDI-1P 2983 λαμβάνω ! λαμβάνω
JAS 3:2.1 . πολλὰ πολλὰ A-APN 4183 πολύς ! πολύς
JAS 3:2.2 . γὰρ γὰρ CONJ 1063 γάρ ! γάρ
JAS 3:2.3 . πταίομεν πταίομεν V-PAI-1P 4417 πταίω ! πταίω
JAS 3:2.4 . ἅπαντες. ἅπαντες. A-NPM 537 ἅπας ! ἅπας
JAS 3:2.5 . εἴ εἴ COND 1487 εἰ ! εἰ
JAS 3:2.6 . τις τις X-NSM 5100 τὶς ! τὶς
JAS 3:2.7 . ἐν ἐν PREP 1722 ἐν ! ἐν
JAS 3:2.8 . λόγῳ λόγῳ N-DSM 3056 λόγος ! λόγος
JAS 3:2.9 . οὐ οὐ PRT-N 3756 οὐ ! οὐ
JAS 3:2.10 . πταίει, πταίει, V-PAI-3S 4417 πταίω ! πταίω
JAS 3:2.11 . οὗτος οὗτος D-NSM 3778 οὗτος ! οὗτος
JAS 3:2.12 . τέλειος τέλειος A-NSM 5046 τέλειος ! τέλειος
JAS 3:2.13 . ἀνήρ, ἀνήρ, N-NSM 435 ἀνήρ ! ἀνήρ
JAS 3:2.14 . δυνατὸς δυνατὸς A-NSM 1415 δυνατός ! δυνατός
JAS 3:2.15 . χαλιναγωγῆσαι χαλιναγωγῆσαι V-AAN 5468 χαλιναγωγέω ! χαλιναγωγέω
JAS 3:2.16 . καὶ καὶ CONJ 2532 καί ! καί
JAS 3:2.17 . ὅλον ὅλον A-ASN 3650 ὅλος ! ὅλος
JAS 3:2.18 . τὸ τὸ T-ASN 3588 ὁ ! ὁ
JAS 3:2.19 . σῶμα. σῶμα. N-ASN 4983 σῶμα ! σῶμα
JAS 3:3.1 . εἰ εἰ COND 1487 εἰ ! εἰ
JAS 3:3.2 . δὲ δὲ CONJ 1161 δέ ! δέ
JAS 3:3.3 . τῶν τῶν T-GPM 3588 ὁ ! ὁ
JAS 3:3.4 . ἵππων ἵππων N-GPM 2462 ἵππος ! ἵππος
JAS 3:3.5 . τοὺς τοὺς T-APM 3588 ὁ ! ὁ
JAS 3:3.6 . χαλινοὺς χαλινοὺς N-APM 5469 χαλινός ! χαλινός
JAS 3:3.7 . εἰς εἰς PREP 1519 εἰς ! εἰς
JAS 3:3.8 . τὰ τὰ T-APN 3588 ὁ ! ὁ
JAS 3:3.9 . στόματα στόματα N-APN 4750 στόμα ! στόμα
JAS 3:3.10 . βάλλομεν βάλλομεν V-PAI-1P 906 βάλλω ! βάλλω
JAS 3:3.11 . εἰς εἰς PREP 1519 εἰς ! εἰς
JAS 3:3.12 . τὸ τὸ T-ASN 3588 ὁ ! ὁ
JAS 3:3.13 . πείθεσθαι πείθεσθαι V-PPN 3982 πείθω ! πείθω
JAS 3:3.14 . αὐτοὺς αὐτοὺς P-APM 846 αὐτός ! αὐτός
JAS 3:3.15 . ἡμῖν, ἡμῖν, P-1DP 2249 ἡμεῖς ! ἐγώ
JAS 3:3.16 . καὶ καὶ CONJ 2532 καί ! καί
JAS 3:3.17 . ὅλον ὅλον A-ASN 3650 ὅλος ! ὅλος
JAS 3:3.18 . τὸ τὸ T-ASN 3588 ὁ ! ὁ
JAS 3:3.19 . σῶμα σῶμα N-ASN 4983 σῶμα ! σῶμα
JAS 3:3.20 . αὐτῶν αὐτῶν P-GPM 846 αὐτός ! αὐτός
JAS 3:3.21 . μετάγομεν. μετάγομεν. V-PAI-1P 3329 μετάγω ! μετάγω
JAS 3:4.1 . ἰδοὺ ἰδοὺ V-2AMM-2S 3708 ὁράω ! ὁράω
JAS 3:4.2 . καὶ καὶ CONJ 2532 καί ! καί
JAS 3:4.3 . τὰ τὰ T-NPN 3588 ὁ ! ὁ
JAS 3:4.4 . πλοῖα, πλοῖα, N-NPN 4143 πλοῖον ! πλοῖον
JAS 3:4.5 . τηλικαῦτα τηλικαῦτα D-NPN 5082 τηλικοῦτος ! τηλικοῦτος
JAS 3:4.6 . ὄντα ὄντα V-PAP-NPN 1510 εἰμί ! εἰμί
JAS 3:4.7 . καὶ καὶ CONJ 2532 καί ! καί
JAS 3:4.8 . ὑπὸ ὑπὸ PREP 5259 ὑπό ! ὑπό
JAS 3:4.9 . ἀνέμων ἀνέμων N-GPM 417 ἄνεμος ! ἄνεμος
JAS 3:4.10 . σκληρῶν σκληρῶν A-GPM 4642 σκληρός ! σκληρός
JAS 3:4.11 . ἐλαυνόμενα, ἐλαυνόμενα, V-PPP-NPN 1643 ἐλαύνω ! ἐλαύνω
JAS 3:4.12 . μετάγεται μετάγεται V-PPI-3S 3329 μετάγω ! μετάγω
JAS 3:4.13 . ὑπὸ ὑπὸ PREP 5259 ὑπό ! ὑπό
JAS 3:4.14 . ἐλαχίστου ἐλαχίστου A-GSN-S 1646 ἐλάχιστος ! ἐλάχιστος
JAS 3:4.15 . πηδαλίου πηδαλίου N-GSN 4079 πηδάλιον ! πηδάλιον
JAS 3:4.16 . ὅπου ὅπου ADV 3699 ὅπου ! ὅπου
JAS 3:4.17 . ἡ ἡ T-NSF 3588 ὁ ! ὁ
JAS 3:4.18 . ὁρμὴ ὁρμὴ N-NSF 3730 ὁρμή ! ὁρμή
JAS 3:4.19 . τοῦ τοῦ T-GSM 3588 ὁ ! ὁ
JAS 3:4.20 . εὐθύνοντος εὐθύνοντος V-PAP-GSM 2116 εὐθύνω ! εὐθύνω
JAS 3:4.21 . βούλεται· βούλεται· V-PNI-3S 1014 βούλομαι ! βούλομαι
JAS 3:5.1 . οὕτως οὕτως ADV 3779 οὕτω ! οὕτω
JAS 3:5.2 . καὶ καὶ CONJ 2532 καί ! καί
JAS 3:5.3 . ἡ ἡ T-NSF 3588 ὁ ! ὁ
JAS 3:5.4 . γλῶσσα γλῶσσα N-NSF 1100 γλῶσσα ! γλῶσσα
JAS 3:5.5 . μικρὸν μικρὸν A-NSN 3398 μικρός ! μικρός
JAS 3:5.6 . μέλος μέλος N-NSN 3196 μέλος ! μέλος
JAS 3:5.7 . ἐστὶν ἐστὶν V-PAI-3S 1510 εἰμί ! εἰμί
JAS 3:5.8 . καὶ καὶ CONJ 2532 καί ! καί
JAS 3:5.9 . μεγάλα μεγάλα A-APN 3173 μέγας ! μέγας
JAS 3:5.10 . αὐχεῖ. αὐχεῖ. V-PAI-3S 3166 μεγαλαυχέω ! αὐχέω
JAS 3:5.11 . ἰδοὺ ἰδοὺ V-2AMM-2S 3708 ὁράω ! ὁράω
JAS 3:5.12 . ἡλίκον ἡλίκον A-NSN 2245 ἡλίκος ! ἡλίκος
JAS 3:5.13 . πῦρ πῦρ N-NSN 4442 πῦρ ! πῦρ
JAS 3:5.14 . ἡλίκην ἡλίκην A-ASF 2245 ἡλίκος ! ἡλίκος
JAS 3:5.15 . ὕλην ὕλην N-ASF 5208 ὕλη ! ὕλη
JAS 3:5.16 . ἀνάπτει· ἀνάπτει· V-PAI-3S 381 ἀνάπτω ! ἀνάπτω
JAS 3:6.1 . ἡ ἡ T-NSF 3588 ὁ ! ὁ
JAS 3:6.2 . γλῶσσα γλῶσσα N-NSF 1100 γλῶσσα ! γλῶσσα
JAS 3:6.3 . πῦρ, πῦρ, N-NSN 4442 πῦρ ! πῦρ
JAS 3:6.4 . ὁ ὁ T-NSM 3588 ὁ ! ὁ
JAS 3:6.5 . κόσμος κόσμος N-NSM 2889 κόσμος ! κόσμος
JAS 3:6.6 . τῆς τῆς T-GSF 3588 ὁ ! ὁ
JAS 3:6.7 . ἀδικίας, ἀδικίας, N-GSF 93 ἀδικία ! ἀδικία
JAS 3:6.8 . ἡ ἡ T-NSF 3588 ὁ ! ὁ
JAS 3:6.9 . γλῶσσα γλῶσσα N-NSF 1100 γλῶσσα ! γλῶσσα
JAS 3:6.10 . καθίσταται καθίσταται V-PPI-3S 2525 καθίστημι ! καθίστημι
JAS 3:6.11 . ἐν ἐν PREP 1722 ἐν ! ἐν
JAS 3:6.12 . τοῖς τοῖς T-DPN 3588 ὁ ! ὁ
JAS 3:6.13 . μέλεσιν μέλεσιν N-DPN 3196 μέλος ! μέλος
JAS 3:6.14 . ἡμῶν, ἡμῶν, P-1GP 2249 ἡμεῖς ! ἐγώ
JAS 3:6.15 . ἡ ἡ T-NSF 3588 ὁ ! ὁ
JAS 3:6.16 . σπιλοῦσα σπιλοῦσα V-PAP-NSF 4695 σπιλόω ! σπιλόω
JAS 3:6.17 . ὅλον ὅλον A-ASN 3650 ὅλος ! ὅλος
JAS 3:6.18 . τὸ τὸ T-ASN 3588 ὁ ! ὁ
JAS 3:6.19 . σῶμα σῶμα N-ASN 4983 σῶμα ! σῶμα
JAS 3:6.20 . καὶ καὶ CONJ 2532 καί ! καί
JAS 3:6.21 . φλογίζουσα φλογίζουσα V-PAP-NSF 5394 φλογίζω ! φλογίζω
JAS 3:6.22 . τὸν τὸν T-ASM 3588 ὁ ! ὁ
JAS 3:6.23 . τροχὸν τροχὸν N-ASM 5164 τροχός ! τροχός
JAS 3:6.24 . τῆς τῆς T-GSF 3588 ὁ ! ὁ
JAS 3:6.25 . γενέσεως γενέσεως N-GSF 1078 γένεσις ! γένεσις
JAS 3:6.26 . καὶ καὶ CONJ 2532 καί ! καί
JAS 3:6.27 . φλογιζομένη φλογιζομένη V-PPP-NSF 5394 φλογίζω ! φλογίζω
JAS 3:6.28 . ὑπὸ ὑπὸ PREP 5259 ὑπό ! ὑπό
JAS 3:6.29 . τῆς τῆς T-GSF 3588 ὁ ! ὁ
JAS 3:6.30 . γεέννης. γεέννης. N-GSF 1067 γέεννα ! γέεννα
JAS 3:7.1 . πᾶσα πᾶσα A-NSF 3956 πᾶς ! πᾶς
JAS 3:7.2 . γὰρ γὰρ CONJ 1063 γάρ ! γάρ
JAS 3:7.3 . φύσις φύσις N-NSF 5449 φύσις ! φύσις
JAS 3:7.4 . θηρίων θηρίων N-GPN 2342 θηρίον ! θηρίον
JAS 3:7.5 . τε τε PRT 5037 τέ ! τέ
JAS 3:7.6 . καὶ καὶ CONJ 2532 καί ! καί
JAS 3:7.7 . πετεινῶν πετεινῶν N-GPN 4071 πετεινόν ! πετεινός
JAS 3:7.8 . ἑρπετῶν ἑρπετῶν N-GPN 2062 ἑρπετόν ! ἑρπετόν
JAS 3:7.9 . τε τε PRT 5037 τέ ! τέ
JAS 3:7.10 . καὶ καὶ CONJ 2532 καί ! καί
JAS 3:7.11 . ἐναλίων ἐναλίων A-GPN 1724 ἐνάλιος ! ἐνάλιος
JAS 3:7.12 . δαμάζεται δαμάζεται V-PPI-3S 1150 δαμάζω ! δαμάζω
JAS 3:7.13 . καὶ καὶ CONJ 2532 καί ! καί
JAS 3:7.14 . δεδάμασται δεδάμασται V-RPI-3S 1150 δαμάζω ! δαμάζω
JAS 3:7.15 . τῇ τῇ T-DSF 3588 ὁ ! ὁ
JAS 3:7.16 . φύσει φύσει N-DSF 5449 φύσις ! φύσις
JAS 3:7.17 . τῇ τῇ T-DSF 3588 ὁ ! ὁ
JAS 3:7.18 . ἀνθρωπίνῃ· ἀνθρωπίνῃ· A-DSF 442 ἀνθρώπινος ! ἀνθρώπινος
JAS 3:8.1 . τὴν τὴν T-ASF 3588 ὁ ! ὁ
JAS 3:8.2 . δὲ δὲ CONJ 1161 δέ ! δέ
JAS 3:8.3 . γλῶσσαν γλῶσσαν N-ASF 1100 γλῶσσα ! γλῶσσα
JAS 3:8.4 . οὐδεὶς οὐδεὶς A-NSM-N 3762 οὐδείς ! οὐδείς
JAS 3:8.5 . δύναται δύναται V-PNI-3S 1410 δύναμαι ! δύναμαι
JAS 3:8.6 . ἀνθρώπων ἀνθρώπων N-GPM 444 ἄνθρωπος ! ἄνθρωπος
JAS 3:8.7 . δαμάσαι· δαμάσαι· V-AAN 1150 δαμάζω ! δαμάζω
JAS 3:8.8 . ἀκατάστατον ἀκατάστατον A-NSN 182 ἀκατάστατος ! ἀκατάστατος
JAS 3:8.9 . κακόν, κακόν, A-NSN 2556 κακός ! κακός
JAS 3:8.10 . μεστὴ μεστὴ A-NSF 3324 μεστός ! μεστός
JAS 3:8.11 . ἰοῦ ἰοῦ N-GSM 2447 ἰός ! ἰός
JAS 3:8.12 . θανατηφόρου. θανατηφόρου. A-GSM 2287 θανατήφορος ! θανατηφόρος
JAS 3:9.1 . ἐν ἐν PREP 1722 ἐν ! ἐν
JAS 3:9.2 . αὐτῇ αὐτῇ P-DSF 846 αὐτός ! αὐτός
JAS 3:9.3 . εὐλογοῦμεν εὐλογοῦμεν V-PAI-1P 2127 εὐλογέω ! εὐλογέω
JAS 3:9.4 . τὸν τὸν T-ASM 3588 ὁ ! ὁ
JAS 3:9.5 . κύριον κύριον N-ASM 2962 κύριος ! κύριος
JAS 3:9.6 . καὶ καὶ CONJ 2532 καί ! καί
JAS 3:9.7 . πατέρα, πατέρα, N-ASM 3962 πατήρ ! πατήρ
JAS 3:9.8 . καὶ καὶ CONJ 2532 καί ! καί
JAS 3:9.9 . ἐν ἐν PREP 1722 ἐν ! ἐν
JAS 3:9.10 . αὐτῇ αὐτῇ P-DSF 846 αὐτός ! αὐτός
JAS 3:9.11 . καταρώμεθα καταρώμεθα V-PNI-1P 2672 καταράομαι ! καταράομαι
JAS 3:9.12 . τοὺς τοὺς T-APM 3588 ὁ ! ὁ
JAS 3:9.13 . ἀνθρώπους ἀνθρώπους N-APM 444 ἄνθρωπος ! ἄνθρωπος
JAS 3:9.14 . τοὺς τοὺς T-APM 3588 ὁ ! ὁ
JAS 3:9.15 . καθ’ καθ’ PREP 2596 κατά ! κατά
JAS 3:9.16 . ὁμοίωσιν ὁμοίωσιν N-ASF 3669 ὁμοίωσις ! ὁμοίωσις
JAS 3:9.17 . θεοῦ θεοῦ N-GSM 2316 θεός ! θεός
JAS 3:9.18 . γεγονότας· γεγονότας· V-2RAP-APM 1096 γίνομαι ! γίνομαι
JAS 3:10.1 . ἐκ ἐκ PREP 1537 ἐκ ! ἐκ
JAS 3:10.2 . τοῦ τοῦ T-GSN 3588 ὁ ! ὁ
JAS 3:10.3 . αὐτοῦ αὐτοῦ P-GSN 846 αὐτός ! αὐτός
JAS 3:10.4 . στόματος στόματος N-GSN 4750 στόμα ! στόμα
JAS 3:10.5 . ἐξέρχεται ἐξέρχεται V-PNI-3S 1831 ἐξέρχομαι ! ἐξέρχομαι
JAS 3:10.6 . εὐλογία εὐλογία N-NSF 2129 εὐλογία ! εὐλογία
JAS 3:10.7 . καὶ καὶ CONJ 2532 καί ! καί
JAS 3:10.8 . κατάρα. κατάρα. N-NSF 2671 κατάρα ! κατάρα
JAS 3:10.9 . οὐ οὐ PRT-N 3756 οὐ ! οὐ
JAS 3:10.10 . χρή, χρή, V-PAI-3S 5534 χρή ! χρή
JAS 3:10.11 . ἀδελφοί ἀδελφοί N-VPM 80 ἀδελφός ! ἀδελφός
JAS 3:10.12 . μου, μου, P-1GS 1473 ἐγώ ! ἐγώ
JAS 3:10.13 . ταῦτα ταῦτα D-NPN 3778 οὗτος ! οὗτος
JAS 3:10.14 . οὕτως οὕτως ADV 3779 οὕτω ! οὕτω
JAS 3:10.15 . γίνεσθαι. γίνεσθαι. V-PNN 1096 γίνομαι ! γίνομαι
JAS 3:11.1 . μήτι μήτι PRT-I 3385 μήτι ! μήτι
JAS 3:11.2 . ἡ ἡ T-NSF 3588 ὁ ! ὁ
JAS 3:11.3 . πηγὴ πηγὴ N-NSF 4077 πηγή ! πηγή
JAS 3:11.4 . ἐκ ἐκ PREP 1537 ἐκ ! ἐκ
JAS 3:11.5 . τῆς τῆς T-GSF 3588 ὁ ! ὁ
JAS 3:11.6 . αὐτῆς αὐτῆς P-GSF 846 αὐτός ! αὐτός
JAS 3:11.7 . ὀπῆς ὀπῆς N-GSF 3692 ὀπή ! ὀπή
JAS 3:11.8 . βρύει βρύει V-PAI-3S 1032 βρύω ! βρύω
JAS 3:11.9 . τὸ τὸ T-ASN 3588 ὁ ! ὁ
JAS 3:11.10 . γλυκὺ γλυκὺ A-ASN 1099 γλυκύς ! γλυκύς
JAS 3:11.11 . καὶ καὶ CONJ 2532 καί ! καί
JAS 3:11.12 . τὸ τὸ T-ASN 3588 ὁ ! ὁ
JAS 3:11.13 . πικρόν; πικρόν; A-ASN 4089 πικρός ! πικρός
JAS 3:12.1 . μὴ μὴ PRT-N 3361 μή ! μή
JAS 3:12.2 . δύναται, δύναται, V-PNI-3S 1410 δύναμαι ! δύναμαι
JAS 3:12.3 . ἀδελφοί ἀδελφοί N-VPM 80 ἀδελφός ! ἀδελφός
JAS 3:12.4 . μου, μου, P-1GS 1473 ἐγώ ! ἐγώ
JAS 3:12.5 . συκῆ συκῆ N-NSF 4808 συκῆ ! συκῆ
JAS 3:12.6 . ἐλαίας ἐλαίας N-APF 1636 ἐλαία ! ἐλαία
JAS 3:12.7 . ποιῆσαι ποιῆσαι V-AAN 4160 ποιέω ! ποιέω
JAS 3:12.8 . ἢ ἢ PRT 2228 ἤ ! ἤ
JAS 3:12.9 . ἄμπελος ἄμπελος N-NSF 288 ἄμπελος ! ἄμπελος
JAS 3:12.10 . σῦκα; σῦκα; N-APN 4810 σῦκον ! σῦκον
JAS 3:12.11 . οὔτε οὔτε CONJ-N 3777 οὔτε ! οὔτε
JAS 3:12.12 . ἁλυκὸν ἁλυκὸν A-NSN 252 ἁλυκός ! ἁλυκός
JAS 3:12.13 . γλυκὺ γλυκὺ A-ASN 1099 γλυκύς ! γλυκύς
JAS 3:12.14 . ποιῆσαι ποιῆσαι V-AAN 4160 ποιέω ! ποιέω
JAS 3:12.15 . ὕδωρ. ὕδωρ. N-ASN 5204 ὕδωρ ! ὕδωρ
JAS 3:13.1 P Τίς Τίς I-NSM 5101 τίς ! τίς
JAS 3:13.2 . σοφὸς σοφὸς A-NSM 4680 σοφός ! σοφός
JAS 3:13.3 . καὶ καὶ CONJ 2532 καί ! καί
JAS 3:13.4 . ἐπιστήμων ἐπιστήμων A-NSM 1990 ἐπιστήμων ! ἐπιστήμων
JAS 3:13.5 . ἐν ἐν PREP 1722 ἐν ! ἐν
JAS 3:13.6 . ὑμῖν; ὑμῖν; P-2DP 5210 ὑμεῖς ! σύ
JAS 3:13.7 . δειξάτω δειξάτω V-AAM-3S 1166 δεικνύω ! δείκνυμι
JAS 3:13.8 . ἐκ ἐκ PREP 1537 ἐκ ! ἐκ
JAS 3:13.9 . τῆς τῆς T-GSF 3588 ὁ ! ὁ
JAS 3:13.10 . καλῆς καλῆς A-GSF 2570 καλός ! καλός
JAS 3:13.11 . ἀναστροφῆς ἀναστροφῆς N-GSF 391 ἀναστροφή ! ἀναστροφή
JAS 3:13.12 . τὰ τὰ T-APN 3588 ὁ ! ὁ
JAS 3:13.13 . ἔργα ἔργα N-APN 2041 ἔργον ! ἔργον
JAS 3:13.14 . αὐτοῦ αὐτοῦ P-GSM 846 αὐτός ! αὐτός
JAS 3:13.15 . ἐν ἐν PREP 1722 ἐν ! ἐν
JAS 3:13.16 . πραΰτητι πραΰτητι N-DSF 4240 πραΰτης ! πραΰτης
JAS 3:13.17 . σοφίας. σοφίας. N-GSF 4678 σοφία ! σοφία
JAS 3:14.1 . εἰ εἰ COND 1487 εἰ ! εἰ
JAS 3:14.2 . δὲ δὲ CONJ 1161 δέ ! δέ
JAS 3:14.3 . ζῆλον ζῆλον N-ASM 2205 ζῆλος ! ζῆλος
JAS 3:14.4 . πικρὸν πικρὸν A-ASM 4089 πικρός ! πικρός
JAS 3:14.5 . ἔχετε ἔχετε V-PAI-2P 2192 ἔχω ! ἔχω
JAS 3:14.6 . καὶ καὶ CONJ 2532 καί ! καί
JAS 3:14.7 . ἐριθείαν ἐριθείαν N-ASF 2052 ἐριθεία ! ἐριθεία
JAS 3:14.8 . ἐν ἐν PREP 1722 ἐν ! ἐν
JAS 3:14.9 . τῇ τῇ T-DSF 3588 ὁ ! ὁ
JAS 3:14.10 . καρδίᾳ καρδίᾳ N-DSF 2588 καρδία ! καρδία
JAS 3:14.11 . ὑμῶν, ὑμῶν, P-2GP 5210 ὑμεῖς ! σύ
JAS 3:14.12 . μὴ μὴ PRT-N 3361 μή ! μή
JAS 3:14.13 . κατακαυχᾶσθε κατακαυχᾶσθε V-PNM-2P 2620 κατακαυχάομαι ! κατακαυχάομαι
JAS 3:14.14 . τῆς τῆς T-GSF 3588 ὁ ! ὁ
JAS 3:14.15 . ἀληθείας ἀληθείας N-GSF 225 ἀλήθεια ! ἀλήθεια
JAS 3:14.16 . καὶ καὶ CONJ 2532 καί ! καί
JAS 3:14.17 . ψεύδεσθε. ψεύδεσθε. V-PNM-2P 5574 ψεύδομαι ! ψεύδομαι
JAS 3:15.1 . οὐκ οὐκ PRT-N 3756 οὐ ! οὐ
JAS 3:15.2 . ἔστιν ἔστιν V-PAI-3S 1510 εἰμί ! εἰμί
JAS 3:15.3 . αὕτη αὕτη D-NSF 3778 οὗτος ! οὗτος
JAS 3:15.4 . ἡ ἡ T-NSF 3588 ὁ ! ὁ
JAS 3:15.5 . σοφία σοφία N-NSF 4678 σοφία ! σοφία
JAS 3:15.6 . ἄνωθεν ἄνωθεν ADV 509 ἄνωθεν ! ἄνωθεν
JAS 3:15.7 . κατερχομένη, κατερχομένη, V-PNP-NSF 2718 κατέρχομαι ! κατέρχομαι
JAS 3:15.8 . ἀλλὰ ἀλλὰ CONJ 235 ἀλλά ! ἀλλά
JAS 3:15.9 . ἐπίγειος, ἐπίγειος, A-NSF 1919 ἐπίγειος ! ἐπίγειος
JAS 3:15.10 . ψυχική, ψυχική, A-NSF 5591 ψυχικός ! ψυχικός
JAS 3:15.11 . δαιμονιώδης· δαιμονιώδης· A-NSF 1141 δαιμονιώδης ! δαιμονιώδης
JAS 3:16.1 . ὅπου ὅπου ADV 3699 ὅπου ! ὅπου
JAS 3:16.2 . γὰρ γὰρ CONJ 1063 γάρ ! γάρ
JAS 3:16.3 . ζῆλος ζῆλος N-NSM 2205 ζῆλος ! ζῆλος
JAS 3:16.4 . καὶ καὶ CONJ 2532 καί ! καί
JAS 3:16.5 . ἐριθεία, ἐριθεία, N-NSF 2052 ἐριθεία ! ἐριθεία
JAS 3:16.6 . ἐκεῖ ἐκεῖ ADV 1563 ἐκεῖ ! ἐκεῖ
JAS 3:16.7 . ἀκαταστασία ἀκαταστασία N-NSF 181 ἀκαταστασία ! ἀκαταστασία
JAS 3:16.8 . καὶ καὶ CONJ 2532 καί ! καί
JAS 3:16.9 . πᾶν πᾶν A-NSN 3956 πᾶς ! πᾶς
JAS 3:16.10 . φαῦλον φαῦλον A-NSN 5337 φαῦλος ! φαῦλος
JAS 3:16.11 . πρᾶγμα. πρᾶγμα. N-NSN 4229 πρᾶγμα ! πρᾶγμα
JAS 3:17.1 . ἡ ἡ T-NSF 3588 ὁ ! ὁ
JAS 3:17.2 . δὲ δὲ CONJ 1161 δέ ! δέ
JAS 3:17.3 . ἄνωθεν ἄνωθεν ADV 509 ἄνωθεν ! ἄνωθεν
JAS 3:17.4 . σοφία σοφία N-NSF 4678 σοφία ! σοφία
JAS 3:17.5 . πρῶτον πρῶτον ADV-S 4412 πρῶτον ! πρῶτος
JAS 3:17.6 . μὲν μὲν PRT 3303 μέν ! μέν
JAS 3:17.7 . ἁγνή ἁγνή A-NSF 53 ἁγνός ! ἁγνός
JAS 3:17.8 . ἐστιν, ἐστιν, V-PAI-3S 1510 εἰμί ! εἰμί
JAS 3:17.9 . ἔπειτα ἔπειτα ADV 1899 ἔπειτα ! ἔπειτα
JAS 3:17.10 . εἰρηνική, εἰρηνική, A-NSF 1516 εἰρηνικός ! εἰρηνικός
JAS 3:17.11 . ἐπιεικής, ἐπιεικής, A-NSF 1933 ἐπιεικής ! ἐπιεικής
JAS 3:17.12 . εὐπειθής, εὐπειθής, A-NSF 2138 εὐπειθής ! εὐπειθής
JAS 3:17.13 . μεστὴ μεστὴ A-NSF 3324 μεστός ! μεστός
JAS 3:17.14 . ἐλέους ἐλέους N-GSN 1656 ἔλεος ! ἔλεος
JAS 3:17.15 . καὶ καὶ CONJ 2532 καί ! καί
JAS 3:17.16 . καρπῶν καρπῶν N-GPM 2590 καρπός ! καρπός
JAS 3:17.17 . ἀγαθῶν, ἀγαθῶν, A-GPM 18 ἀγαθός ! ἀγαθός
JAS 3:17.18 . ἀδιάκριτος, ἀδιάκριτος, A-NSF 87 ἀδιάκριτος ! ἀδιάκριτος
JAS 3:17.19 . ἀνυπόκριτος· ἀνυπόκριτος· A-NSF 505 ἀνυπόκριτος ! ἀνυπόκριτος
JAS 3:18.1 . καρπὸς καρπὸς N-NSM 2590 καρπός ! καρπός
JAS 3:18.2 . δὲ δὲ CONJ 1161 δέ ! δέ
JAS 3:18.3 . δικαιοσύνης δικαιοσύνης N-GSF 1343 δικαιοσύνη ! δικαιοσύνη
JAS 3:18.4 . ἐν ἐν PREP 1722 ἐν ! ἐν
JAS 3:18.5 . εἰρήνῃ εἰρήνῃ N-DSF 1515 εἰρήνη ! εἰρήνη
JAS 3:18.6 . σπείρεται σπείρεται V-PPI-3S 4687 σπείρω ! σπείρω
JAS 3:18.7 . τοῖς τοῖς T-DPM 3588 ὁ ! ὁ
JAS 3:18.8 . ποιοῦσιν ποιοῦσιν V-PAP-DPM 4160 ποιέω ! ποιέω
JAS 3:18.9 . εἰρήνην. εἰρήνην. N-ASF 1515 εἰρήνη ! εἰρήνη
JAS 4:1.1 C Πόθεν Πόθεν ADV-I 4159 πόθεν ! πόθεν
JAS 4:1.2 . πόλεμοι πόλεμοι N-NPM 4171 πόλεμος ! πόλεμος
JAS 4:1.3 . καὶ καὶ CONJ 2532 καί ! καί
JAS 4:1.4 . πόθεν πόθεν ADV-I 4159 πόθεν ! πόθεν
JAS 4:1.5 . μάχαι μάχαι N-NPF 3163 μάχη ! μάχη
JAS 4:1.6 . ἐν ἐν PREP 1722 ἐν ! ἐν
JAS 4:1.7 . ὑμῖν; ὑμῖν; P-2DP 5210 ὑμεῖς ! σύ
JAS 4:1.8 . οὐκ οὐκ PRT-N 3756 οὐ ! οὐ
JAS 4:1.9 . ἐντεῦθεν, ἐντεῦθεν, ADV 1782 ἐντεῦθεν ! ἐντεῦθεν
JAS 4:1.10 . ἐκ ἐκ PREP 1537 ἐκ ! ἐκ
JAS 4:1.11 . τῶν τῶν T-GPF 3588 ὁ ! ὁ
JAS 4:1.12 . ἡδονῶν ἡδονῶν N-GPF 2237 ἡδονή ! ἡδονή
JAS 4:1.13 . ὑμῶν ὑμῶν P-2GP 5210 ὑμεῖς ! σύ
JAS 4:1.14 . τῶν τῶν T-GPF 3588 ὁ ! ὁ
JAS 4:1.15 . στρατευομένων στρατευομένων V-PMP-GPF 4754 στρατεύομαι ! στρατεύω
JAS 4:1.16 . ἐν ἐν PREP 1722 ἐν ! ἐν
JAS 4:1.17 . τοῖς τοῖς T-DPN 3588 ὁ ! ὁ
JAS 4:1.18 . μέλεσιν μέλεσιν N-DPN 3196 μέλος ! μέλος
JAS 4:1.19 . ὑμῶν; ὑμῶν; P-2GP 5210 ὑμεῖς ! σύ
JAS 4:2.1 . ἐπιθυμεῖτε, ἐπιθυμεῖτε, V-PAI-2P 1937 ἐπιθυμέω ! ἐπιθυμέω
JAS 4:2.2 . καὶ καὶ CONJ 2532 καί ! καί
JAS 4:2.3 . οὐκ οὐκ PRT-N 3756 οὐ ! οὐ
JAS 4:2.4 . ἔχετε· ἔχετε· V-PAI-2P 2192 ἔχω ! ἔχω
JAS 4:2.5 . φονεύετε φονεύετε V-PAI-2P 5407 φονεύω ! φονεύω
JAS 4:2.6 . καὶ καὶ CONJ 2532 καί ! καί
JAS 4:2.7 . ζηλοῦτε, ζηλοῦτε, V-PAI-2P 2206 ζηλόω ! ζηλόω
JAS 4:2.8 . καὶ καὶ CONJ 2532 καί ! καί
JAS 4:2.9 . οὐ οὐ PRT-N 3756 οὐ ! οὐ
JAS 4:2.10 . δύνασθε δύνασθε V-PNI-2P 1410 δύναμαι ! δύναμαι
JAS 4:2.11 . ἐπιτυχεῖν· ἐπιτυχεῖν· V-2AAN 2013 ἐπιτυγχάνω ! ἐπιτυγχάνω
JAS 4:2.12 . μάχεσθε μάχεσθε V-PNI-2P 3164 μάχομαι ! μάχομαι
JAS 4:2.13 . καὶ καὶ CONJ 2532 καί ! καί
JAS 4:2.14 . πολεμεῖτε. πολεμεῖτε. V-PAI-2P 4170 πολεμέω ! πολεμέω
JAS 4:2.15 . καὶ καὶ CONJ 2532 καί ! καί
JAS 4:2.16 . οὐκ οὐκ PRT-N 3756 οὐ ! οὐ
JAS 4:2.17 . ἔχετε ἔχετε V-PAI-2P 2192 ἔχω ! ἔχω
JAS 4:2.18 . διὰ διὰ PREP 1223 διά ! διά
JAS 4:2.19 . τὸ τὸ T-ASN 3588 ὁ ! ὁ
JAS 4:2.20 . μὴ μὴ PRT-N 3361 μή ! μή
JAS 4:2.21 . αἰτεῖσθαι αἰτεῖσθαι V-PMN 154 αἰτέω ! αἰτέω
JAS 4:2.22 . ὑμᾶς· ὑμᾶς· P-2AP 5210 ὑμεῖς ! σύ
JAS 4:3.1 . αἰτεῖτε αἰτεῖτε V-PAI-2P 154 αἰτέω ! αἰτέω
JAS 4:3.2 . καὶ καὶ CONJ 2532 καί ! καί
JAS 4:3.3 . οὐ οὐ PRT-N 3756 οὐ ! οὐ
JAS 4:3.4 . λαμβάνετε, λαμβάνετε, V-PAI-2P 2983 λαμβάνω ! λαμβάνω
JAS 4:3.5 . διότι διότι CONJ 1360 διότι ! διότι
JAS 4:3.6 . κακῶς κακῶς ADV 2560 κακῶς ! κακῶς
JAS 4:3.7 . αἰτεῖσθε, αἰτεῖσθε, V-PMI-2P 154 αἰτέω ! αἰτέω
JAS 4:3.8 . ἵνα ἵνα CONJ 2443 ἵνα ! ἵνα
JAS 4:3.9 . ἐν ἐν PREP 1722 ἐν ! ἐν
JAS 4:3.10 . ταῖς ταῖς T-DPF 3588 ὁ ! ὁ
JAS 4:3.11 . ἡδοναῖς ἡδοναῖς N-DPF 2237 ἡδονή ! ἡδονή
JAS 4:3.12 . ὑμῶν ὑμῶν P-2GP 5210 ὑμεῖς ! σύ
JAS 4:3.13 . δαπανήσητε. δαπανήσητε. V-AAS-2P 1159 δαπανάω ! δαπανάω
JAS 4:4.1 . μοιχαλίδες, μοιχαλίδες, N-VPF 3428 μοιχαλίς ! μοιχαλίς
JAS 4:4.2 . οὐκ οὐκ PRT-N 3756 οὐ ! οὐ
JAS 4:4.3 . οἴδατε οἴδατε V-RAI-2P 1492 εἴδω ! οἶδα
JAS 4:4.4 . ὅτι ὅτι CONJ 3754 ὅτι ! ὅτι
JAS 4:4.5 . ἡ ἡ T-NSF 3588 ὁ ! ὁ
JAS 4:4.6 . φιλία φιλία N-NSF 5373 φιλία ! φιλία
JAS 4:4.7 . τοῦ τοῦ T-GSM 3588 ὁ ! ὁ
JAS 4:4.8 . κόσμου κόσμου N-GSM 2889 κόσμος ! κόσμος
JAS 4:4.9 . ἔχθρα ἔχθρα N-NSF 2189 ἔχθρα ! ἔχθρα
JAS 4:4.10 . ἐστὶν ἐστὶν V-PAI-3S 1510 εἰμί ! εἰμί
JAS 4:4.11 . τῷ τῷ T-DSM 3588 ὁ ! ὁ
JAS 4:4.12 . θεῷ; θεῷ; N-DSM 2316 θεός ! θεός
JAS 4:4.13 . ὃς ὃς R-NSM 3739 ὅς ! ὅς
JAS 4:4.14 . ἐὰν ἐὰν COND 1437 ἐάν ! ἐάν
JAS 4:4.15 . οὖν οὖν CONJ 3767 οὖν ! οὖν
JAS 4:4.16 . βουληθῇ βουληθῇ V-AOS-3S 1014 βούλομαι ! βούλομαι
JAS 4:4.17 . φίλος φίλος A-NSM 5384 φίλος ! φίλος
JAS 4:4.18 . εἶναι εἶναι V-PAN 1510 εἰμί ! εἰμί
JAS 4:4.19 . τοῦ τοῦ T-GSM 3588 ὁ ! ὁ
JAS 4:4.20 . κόσμου, κόσμου, N-GSM 2889 κόσμος ! κόσμος
JAS 4:4.21 . ἐχθρὸς ἐχθρὸς A-NSM 2190 ἐχθρός ! ἐχθρός
JAS 4:4.22 . τοῦ τοῦ T-GSM 3588 ὁ ! ὁ
JAS 4:4.23 . θεοῦ θεοῦ N-GSM 2316 θεός ! θεός
JAS 4:4.24 . καθίσταται. καθίσταται. V-PPI-3S 2525 καθίστημι ! καθίστημι
JAS 4:5.1 . ἢ ἢ PRT 2228 ἤ ! ἤ
JAS 4:5.2 . δοκεῖτε δοκεῖτε V-PAI-2P 1380 δοκέω ! δοκέω
JAS 4:5.3 . ὅτι ὅτι CONJ 3754 ὅτι ! ὅτι
JAS 4:5.4 . κενῶς κενῶς ADV 2761 κενῶς ! κενῶς
JAS 4:5.5 . ἡ ἡ T-NSF 3588 ὁ ! ὁ
JAS 4:5.6 . γραφὴ γραφὴ N-NSF 1124 γραφή ! γραφή
JAS 4:5.7 . λέγει, λέγει, V-PAI-3S 3004 λέγω ! λέγω
JAS 4:5.8 . πρὸς πρὸς PREP 4314 πρός ! πρός
JAS 4:5.9 . φθόνον φθόνον N-ASM 5355 φθόνος ! φθόνος
JAS 4:5.10 . ἐπιποθεῖ ἐπιποθεῖ V-PAI-3S 1971 ἐπιποθέω ! ἐπιποθέω
JAS 4:5.11 . τὸ τὸ T-NSN 3588 ὁ ! ὁ
JAS 4:5.12 . πνεῦμα πνεῦμα N-NSN 4151 πνεῦμα ! πνεῦμα
JAS 4:5.13 . ὃ ὃ R-ASN 3739 ὅς ! ὅς
JAS 4:5.14 . κατῴκισεν κατῴκισεν V-AAI-3S 2730 κατοικέω ! κατοικίζω
JAS 4:5.15 . ἐν ἐν PREP 1722 ἐν ! ἐν
JAS 4:5.16 . ἡμῖν; ἡμῖν; P-1DP 2249 ἡμεῖς ! ἐγώ
JAS 4:6.1 . μείζονα μείζονα A-ASF-C 3173 μέγας ! μέγας
JAS 4:6.2 . δὲ δὲ CONJ 1161 δέ ! δέ
JAS 4:6.3 . δίδωσιν δίδωσιν V-PAI-3S 1325 δίδωμι ! δίδωμι
JAS 4:6.4 . χάριν· χάριν· N-ASF 5485 χάρις ! χάρις
JAS 4:6.5 . διὸ διὸ CONJ 1352 διό ! διό
JAS 4:6.6 . λέγει, λέγει, V-PAI-3S 3004 λέγω ! λέγω
JAS 4:6.7 . ὁ ὁ T-NSM 3588 ὁ ! ὁ
JAS 4:6.8 . θεὸς θεὸς N-NSM 2316 θεός ! θεός
JAS 4:6.9 . ὑπερηφάνοις ὑπερηφάνοις A-DPM 5244 ὑπερήφανος ! ὑπερήφανος
JAS 4:6.10 . ἀντιτάσσεται, ἀντιτάσσεται, V-PMI-3S 498 ἀντιτάσσομαι ! ἀντιτάσσω
JAS 4:6.11 . ταπεινοῖς ταπεινοῖς A-DPM 5011 ταπεινός ! ταπεινός
JAS 4:6.12 . δὲ δὲ CONJ 1161 δέ ! δέ
JAS 4:6.13 . δίδωσιν δίδωσιν V-PAI-3S 1325 δίδωμι ! δίδωμι
JAS 4:6.14 . χάριν. χάριν. N-ASF 5485 χάρις ! χάρις
JAS 4:7.1 . ὑποτάγητε ὑποτάγητε V-2APM-2P 5293 ὑποτάσσω ! ὑποτάσσω
JAS 4:7.2 . οὖν οὖν CONJ 3767 οὖν ! οὖν
JAS 4:7.3 . τῷ τῷ T-DSM 3588 ὁ ! ὁ
JAS 4:7.4 . θεῷ· θεῷ· N-DSM 2316 θεός ! θεός
JAS 4:7.5 . ἀντίστητε ἀντίστητε V-2AAM-2P 436 ἀνθίστημι ! ἀνθίστημι
JAS 4:7.6 . δὲ δὲ CONJ 1161 δέ ! δέ
JAS 4:7.7 . τῷ τῷ T-DSM 3588 ὁ ! ὁ
JAS 4:7.8 . διαβόλῳ, διαβόλῳ, A-DSM 1228 διάβολος ! διάβολος
JAS 4:7.9 . καὶ καὶ CONJ 2532 καί ! καί
JAS 4:7.10 . φεύξεται φεύξεται V-FDI-3S 5343 φεύγω ! φεύγω
JAS 4:7.11 . ἀφ’ ἀφ’ PREP 575 ἀπό ! ἀπό
JAS 4:7.12 . ὑμῶν· ὑμῶν· P-2GP 5210 ὑμεῖς ! σύ
JAS 4:8.1 . ἐγγίσατε ἐγγίσατε V-AAM-2P 1448 ἐγγίζω ! ἐγγίζω
JAS 4:8.2 . τῷ τῷ T-DSM 3588 ὁ ! ὁ
JAS 4:8.3 . θεῷ, θεῷ, N-DSM 2316 θεός ! θεός
JAS 4:8.4 . καὶ καὶ CONJ 2532 καί ! καί
JAS 4:8.5 . ἐγγιεῖ ἐγγιεῖ V-FAI-3S 1448 ἐγγίζω ! ἐγγίζω
JAS 4:8.6 . ὑμῖν. ὑμῖν. P-2DP 5210 ὑμεῖς ! σύ
JAS 4:8.7 . καθαρίσατε καθαρίσατε V-AAM-2P 2511 καθαρίζω ! καθαρίζω
JAS 4:8.8 . χεῖρας, χεῖρας, N-APF 5495 χείρ ! χείρ
JAS 4:8.9 . ἁμαρτωλοί, ἁμαρτωλοί, A-VPM 268 ἁμαρτωλός ! ἁμαρτωλός
JAS 4:8.10 . καὶ καὶ CONJ 2532 καί ! καί
JAS 4:8.11 . ἁγνίσατε ἁγνίσατε V-AAM-2P 48 ἁγνίζω ! ἁγνίζω
JAS 4:8.12 . καρδίας, καρδίας, N-APF 2588 καρδία ! καρδία
JAS 4:8.13 . δίψυχοι. δίψυχοι. A-VPM 1374 δίψυχος ! δίψυχος
JAS 4:9.1 . ταλαιπωρήσατε ταλαιπωρήσατε V-AAM-2P 5003 ταλαιπωρέω ! ταλαιπωρέω
JAS 4:9.2 . καὶ καὶ CONJ 2532 καί ! καί
JAS 4:9.3 . πενθήσατε, πενθήσατε, V-AAM-2P 3996 πενθέω ! πενθέω
JAS 4:9.4 . κλαύσατε· κλαύσατε· V-AAM-2P 2799 κλαίω ! κλαίω
JAS 4:9.5 . ὁ ὁ T-NSM 3588 ὁ ! ὁ
JAS 4:9.6 . γέλως γέλως N-NSM 1071 γέλως ! γέλως
JAS 4:9.7 . ὑμῶν ὑμῶν P-2GP 5210 ὑμεῖς ! σύ
JAS 4:9.8 . εἰς εἰς PREP 1519 εἰς ! εἰς
JAS 4:9.9 . πένθος πένθος N-ASN 3997 πένθος ! πένθος
JAS 4:9.10 . μετατραπήτω μετατραπήτω V-2APM-3S 3344 μεταστρέφω ! μετατρέπω
JAS 4:9.11 . καὶ καὶ CONJ 2532 καί ! καί
JAS 4:9.12 . ἡ ἡ T-NSF 3588 ὁ ! ὁ
JAS 4:9.13 . χαρὰ χαρὰ N-NSF 5479 χαρά ! χαρά
JAS 4:9.14 . εἰς εἰς PREP 1519 εἰς ! εἰς
JAS 4:9.15 . κατήφειαν. κατήφειαν. N-ASF 2726 κατήφεια ! κατήφεια
JAS 4:10.1 . ταπεινώθητε ταπεινώθητε V-APM-2P 5013 ταπεινόω ! ταπεινόω
JAS 4:10.2 . ἐνώπιον ἐνώπιον PREP 1799 ἐνώπιον ! ἐνώπιον
JAS 4:10.3 . κυρίου, κυρίου, N-GSM 2962 κύριος ! κύριος
JAS 4:10.4 . καὶ καὶ CONJ 2532 καί ! καί
JAS 4:10.5 . ὑψώσει ὑψώσει V-FAI-3S 5312 ὑψόω ! ὑψόω
JAS 4:10.6 . ὑμᾶς. ὑμᾶς. P-2AP 5210 ὑμεῖς ! σύ
JAS 4:11.1 P Μὴ Μὴ PRT-N 3361 μή ! μή
JAS 4:11.2 . καταλαλεῖτε καταλαλεῖτε V-PAM-2P 2635 καταλαλέω ! καταλαλέω
JAS 4:11.3 . ἀλλήλων, ἀλλήλων, C-GPM 240 ἀλλήλων ! ἀλλήλων
JAS 4:11.4 . ἀδελφοί· ἀδελφοί· N-VPM 80 ἀδελφός ! ἀδελφός
JAS 4:11.5 . ὁ ὁ T-NSM 3588 ὁ ! ὁ
JAS 4:11.6 . καταλαλῶν καταλαλῶν V-PAP-NSM 2635 καταλαλέω ! καταλαλέω
JAS 4:11.7 . ἀδελφοῦ ἀδελφοῦ N-GSM 80 ἀδελφός ! ἀδελφός
JAS 4:11.8 . ἢ ἢ PRT 2228 ἤ ! ἤ
JAS 4:11.9 . κρίνων κρίνων V-PAP-NSM 2919 κρίνω ! κρίνω
JAS 4:11.10 . τὸν τὸν T-ASM 3588 ὁ ! ὁ
JAS 4:11.11 . ἀδελφὸν ἀδελφὸν N-ASM 80 ἀδελφός ! ἀδελφός
JAS 4:11.12 . αὐτοῦ αὐτοῦ P-GSM 846 αὐτός ! αὐτός
JAS 4:11.13 . καταλαλεῖ καταλαλεῖ V-PAI-3S 2635 καταλαλέω ! καταλαλέω
JAS 4:11.14 . νόμου νόμου N-GSM 3551 νόμος ! νόμος
JAS 4:11.15 . καὶ καὶ CONJ 2532 καί ! καί
JAS 4:11.16 . κρίνει κρίνει V-PAI-3S 2919 κρίνω ! κρίνω
JAS 4:11.17 . νόμον· νόμον· N-ASM 3551 νόμος ! νόμος
JAS 4:11.18 . εἰ εἰ COND 1487 εἰ ! εἰ
JAS 4:11.19 . δὲ δὲ CONJ 1161 δέ ! δέ
JAS 4:11.20 . νόμον νόμον N-ASM 3551 νόμος ! νόμος
JAS 4:11.21 . κρίνεις, κρίνεις, V-PAI-2S 2919 κρίνω ! κρίνω
JAS 4:11.22 . οὐκ οὐκ PRT-N 3756 οὐ ! οὐ
JAS 4:11.23 . εἶ εἶ V-PAI-2S 1510 εἰμί ! εἰμί
JAS 4:11.24 . ποιητὴς ποιητὴς N-NSM 4163 ποιητής ! ποιητής
JAS 4:11.25 . νόμου νόμου N-GSM 3551 νόμος ! νόμος
JAS 4:11.26 . ἀλλὰ ἀλλὰ CONJ 235 ἀλλά ! ἀλλά
JAS 4:11.27 . κριτής. κριτής. N-NSM 2923 κριτής ! κριτής
JAS 4:12.1 . εἷς εἷς A-NSM 1520 εἷς ! εἷς
JAS 4:12.2 . ἐστιν ἐστιν V-PAI-3S 1510 εἰμί ! εἰμί
JAS 4:12.3 . ὁ ὁ T-NSM 3588 ὁ ! ὁ
JAS 4:12.4 . νομοθέτης νομοθέτης N-NSM 3550 νομοθέτης ! νομοθέτης
JAS 4:12.5 . καὶ καὶ CONJ 2532 καί ! καί
JAS 4:12.6 . κριτής, κριτής, N-NSM 2923 κριτής ! κριτής
JAS 4:12.7 . ὁ ὁ T-NSM 3588 ὁ ! ὁ
JAS 4:12.8 . δυνάμενος δυνάμενος V-PNP-NSM 1410 δύναμαι ! δύναμαι
JAS 4:12.9 . σῶσαι σῶσαι V-AAN 4982 σώζω ! σῴζω
JAS 4:12.10 . καὶ καὶ CONJ 2532 καί ! καί
JAS 4:12.11 . ἀπολέσαι· ἀπολέσαι· V-AAN 622 ἀπόλλυμι ! ἀπόλλυμι
JAS 4:12.12 . σὺ σὺ P-2NS 4771 σύ ! σύ
JAS 4:12.13 . δὲ δὲ CONJ 1161 δέ ! δέ
JAS 4:12.14 . τίς τίς I-NSM 5101 τίς ! τίς
JAS 4:12.15 . εἶ, εἶ, V-PAI-2S 1510 εἰμί ! εἰμί
JAS 4:12.16 . ὁ ὁ T-NSM 3588 ὁ ! ὁ
JAS 4:12.17 . κρίνων κρίνων V-PAP-NSM 2919 κρίνω ! κρίνω
JAS 4:12.18 . τὸν τὸν T-ASM 3588 ὁ ! ὁ
JAS 4:12.19 . πλησίον; πλησίον; ADV 4139 πλησίον ! πλησίον
JAS 4:13.1 P Ἄγε Ἄγε V-PAM-2S 71 ἄγω ! ἄγω
JAS 4:13.2 . νῦν νῦν ADV 3568 νῦν ! νῦν
JAS 4:13.3 . οἱ οἱ T-NPM 3588 ὁ ! ὁ
JAS 4:13.4 . λέγοντες, λέγοντες, V-PAP-NPM 3004 λέγω ! λέγω
JAS 4:13.5 . σήμερον σήμερον ADV 4594 σήμερον ! σήμερον
JAS 4:13.6 . ἢ ἢ PRT 2228 ἤ ! ἤ
JAS 4:13.7 . αὔριον αὔριον ADV 839 αὔριον ! αὔριον
JAS 4:13.8 . πορευσόμεθα πορευσόμεθα V-FDI-1P 4198 πορεύομαι ! πορεύομαι
JAS 4:13.9 . εἰς εἰς PREP 1519 εἰς ! εἰς
JAS 4:13.10 . τήνδε τήνδε D-ASF 3592 ὅδε ! ὅδε
JAS 4:13.11 . τὴν τὴν T-ASF 3588 ὁ ! ὁ
JAS 4:13.12 . πόλιν πόλιν N-ASF 4172 πόλις ! πόλις
JAS 4:13.13 . καὶ καὶ CONJ 2532 καί ! καί
JAS 4:13.14 . ποιήσομεν ποιήσομεν V-FAI-1P 4160 ποιέω ! ποιέω
JAS 4:13.15 . ἐκεῖ ἐκεῖ ADV 1563 ἐκεῖ ! ἐκεῖ
JAS 4:13.16 . ἐνιαυτὸν ἐνιαυτὸν N-ASM 1763 ἐνιαυτός ! ἐνιαυτός
JAS 4:13.17 . καὶ καὶ CONJ 2532 καί ! καί
JAS 4:13.18 . ἐμπορευσόμεθα ἐμπορευσόμεθα V-FDI-1P 1710 ἐμπορεύομαι ! ἐμπορεύομαι
JAS 4:13.19 . καὶ καὶ CONJ 2532 καί ! καί
JAS 4:13.20 . κερδήσομεν· κερδήσομεν· V-FAI-1P 2770 κερδαίνω ! κερδαίνω
JAS 4:14.1 . οἵτινες οἵτινες R-NPM 3748 ὅστις ! ὅστις
JAS 4:14.2 . οὐκ οὐκ PRT-N 3756 οὐ ! οὐ
JAS 4:14.3 . ἐπίστασθε ἐπίστασθε V-PNI-2P 1987 ἐπίσταμαι ! ἐπίσταμαι
JAS 4:14.4 . τὸ τὸ T-ASN 3588 ὁ ! ὁ
JAS 4:14.5 . τῆς τῆς T-GSF 3588 ὁ ! ὁ
JAS 4:14.6 . αὔριον αὔριον ADV 839 αὔριον ! αὔριον
JAS 4:14.7 . ποία ποία I-NSF 4169 ποῖος ! ποῖος
JAS 4:14.8 . γὰρ γὰρ CONJ 1063 γάρ ! γάρ
JAS 4:14.9 . ἡ ἡ T-NSF 3588 ὁ ! ὁ
JAS 4:14.10 . ζωὴ ζωὴ N-NSF 2222 ζωή ! ζωή
JAS 4:14.11 . ὑμῶν. ὑμῶν. P-2GP 5210 ὑμεῖς ! σύ
JAS 4:14.12 . ἀτμὶς ἀτμὶς N-NSF 822 ἀτμίς ! ἀτμίς
JAS 4:14.13 . γάρ γάρ CONJ 1063 γάρ ! γάρ
JAS 4:14.14 . ἐστε ἐστε V-PAI-2P 1510 εἰμί ! εἰμί
JAS 4:14.15 . ἡ ἡ T-NSF 3588 ὁ ! ὁ
JAS 4:14.16 . πρὸς πρὸς PREP 4314 πρός ! πρός
JAS 4:14.17 . ὀλίγον ὀλίγον A-ASN 3641 ὀλίγος ! ὀλίγος
JAS 4:14.18 . φαινομένη, φαινομένη, V-PEP-NSF 5316 φαίνω ! φαίνω
JAS 4:14.19 . ἔπειτα ἔπειτα ADV 1899 ἔπειτα ! ἔπειτα
JAS 4:14.20 . καὶ καὶ CONJ 2532 καί ! καί
JAS 4:14.21 . ἀφανιζομένη· ἀφανιζομένη· V-PPP-NSF 853 ἀφανίζω ! ἀφανίζω
JAS 4:15.1 . ἀντὶ ἀντὶ PREP 473 ἀντί ! ἀντί
JAS 4:15.2 . τοῦ τοῦ T-GSN 3588 ὁ ! ὁ
JAS 4:15.3 . λέγειν λέγειν V-PAN 3004 λέγω ! λέγω
JAS 4:15.4 . ὑμᾶς· ὑμᾶς· P-2AP 5210 ὑμεῖς ! σύ
JAS 4:15.5 . ἐὰν ἐὰν COND 1437 ἐάν ! ἐάν
JAS 4:15.6 . ὁ ὁ T-NSM 3588 ὁ ! ὁ
JAS 4:15.7 . κύριος κύριος N-NSM 2962 κύριος ! κύριος
JAS 4:15.8 . θελήσῃ, θελήσῃ, V-AAS-3S 2309 θέλω ! θέλω
JAS 4:15.9 . καὶ καὶ CONJ 2532 καί ! καί
JAS 4:15.10 . ζήσομεν ζήσομεν V-FAI-1P 2198 ζάω ! ζάω
JAS 4:15.11 . καὶ καὶ CONJ 2532 καί ! καί
JAS 4:15.12 . ποιήσομεν ποιήσομεν V-FAI-1P 4160 ποιέω ! ποιέω
JAS 4:15.13 . τοῦτο τοῦτο D-ASN 3778 οὗτος ! οὗτος
JAS 4:15.14 . ἢ ἢ PRT 2228 ἤ ! ἤ
JAS 4:15.15 . ἐκεῖνο. ἐκεῖνο. D-ASN 1565 ἐκεῖνος ! ἐκεῖνος
JAS 4:16.1 . νῦν νῦν ADV 3568 νῦν ! νῦν
JAS 4:16.2 . δὲ δὲ CONJ 1161 δέ ! δέ
JAS 4:16.3 . καυχᾶσθε καυχᾶσθε V-PNI-2P 2744 καυχάομαι ! καυχάομαι
JAS 4:16.4 . ἐν ἐν PREP 1722 ἐν ! ἐν
JAS 4:16.5 . ταῖς ταῖς T-DPF 3588 ὁ ! ὁ
JAS 4:16.6 . ἀλαζονίαις ἀλαζονίαις N-DPF 212 ἀλαζονεία ! ἀλαζονεία
JAS 4:16.7 . ὑμῶν· ὑμῶν· P-2GP 5210 ὑμεῖς ! σύ
JAS 4:16.8 . πᾶσα πᾶσα A-NSF 3956 πᾶς ! πᾶς
JAS 4:16.9 . καύχησις καύχησις N-NSF 2746 καύχησις ! καύχησις
JAS 4:16.10 . τοιαύτη τοιαύτη D-NSF 5108 τοιοῦτος ! τοιοῦτος
JAS 4:16.11 . πονηρά πονηρά A-NSF 4190 πονηρός ! πονηρός
JAS 4:16.12 . ἐστιν. ἐστιν. V-PAI-3S 1510 εἰμί ! εἰμί
JAS 4:17.1 . εἰδότι εἰδότι V-RAP-DSM 1492 εἴδω ! οἶδα
JAS 4:17.2 . οὖν οὖν CONJ 3767 οὖν ! οὖν
JAS 4:17.3 . καλὸν καλὸν A-ASN 2570 καλός ! καλός
JAS 4:17.4 . ποιεῖν ποιεῖν V-PAN 4160 ποιέω ! ποιέω
JAS 4:17.5 . καὶ καὶ CONJ 2532 καί ! καί
JAS 4:17.6 . μὴ μὴ PRT-N 3361 μή ! μή
JAS 4:17.7 . ποιοῦντι, ποιοῦντι, V-PAP-DSM 4160 ποιέω ! ποιέω
JAS 4:17.8 . ἁμαρτία ἁμαρτία N-NSF 266 ἁμαρτία ! ἁμαρτία
JAS 4:17.9 . αὐτῷ αὐτῷ P-DSM 846 αὐτός ! αὐτός
JAS 4:17.10 . ἐστιν. ἐστιν. V-PAI-3S 1510 εἰμί ! εἰμί
JAS 5:1.1 C Ἄγε Ἄγε V-PAM-2S 71 ἄγω ! ἄγω
JAS 5:1.2 . νῦν νῦν ADV 3568 νῦν ! νῦν
JAS 5:1.3 . οἱ οἱ T-NPM 3588 ὁ ! ὁ
JAS 5:1.4 . πλούσιοι, πλούσιοι, A-NPM 4145 πλούσιος ! πλούσιος
JAS 5:1.5 . κλαύσατε κλαύσατε V-AAM-2P 2799 κλαίω ! κλαίω
JAS 5:1.6 . ὀλολύζοντες ὀλολύζοντες V-PAP-NPM 3649 ὀλολύζω ! ὀλολύζω
JAS 5:1.7 . ἐπὶ ἐπὶ PREP 1909 ἐπί ! ἐπί
JAS 5:1.8 . ταῖς ταῖς T-DPF 3588 ὁ ! ὁ
JAS 5:1.9 . ταλαιπωρίαις ταλαιπωρίαις N-DPF 5004 ταλαιπωρία ! ταλαιπωρία
JAS 5:1.10 . ὑμῶν ὑμῶν P-2GP 5210 ὑμεῖς ! σύ
JAS 5:1.11 . ταῖς ταῖς T-DPF 3588 ὁ ! ὁ
JAS 5:1.12 . ἐπερχομέναις. ἐπερχομέναις. V-PNP-DPF 1904 ἐπέρχομαι ! ἐπέρχομαι
JAS 5:2.1 . ὁ ὁ T-NSM 3588 ὁ ! ὁ
JAS 5:2.2 . πλοῦτος πλοῦτος N-NSM 4149 πλοῦτος ! πλοῦτος
JAS 5:2.3 . ὑμῶν ὑμῶν P-2GP 5210 ὑμεῖς ! σύ
JAS 5:2.4 . σέσηπεν σέσηπεν V-2RAI-3S 4595 σήπω ! σήπω
JAS 5:2.5 . καὶ καὶ CONJ 2532 καί ! καί
JAS 5:2.6 . τὰ τὰ T-NPN 3588 ὁ ! ὁ
JAS 5:2.7 . ἱμάτια ἱμάτια N-NPN 2440 ἱμάτιον ! ἱμάτιον
JAS 5:2.8 . ὑμῶν ὑμῶν P-2GP 5210 ὑμεῖς ! σύ
JAS 5:2.9 . σητόβρωτα σητόβρωτα A-NPN 4598 σητόβρωτος ! σητόβρωτος
JAS 5:2.10 . γέγονεν, γέγονεν, V-2RAI-3S 1096 γίνομαι ! γίνομαι
JAS 5:3.1 . ὁ ὁ T-NSM 3588 ὁ ! ὁ
JAS 5:3.2 . χρυσὸς χρυσὸς N-NSM 5557 χρυσός ! χρυσός
JAS 5:3.3 . ὑμῶν ὑμῶν P-2GP 5210 ὑμεῖς ! σύ
JAS 5:3.4 . καὶ καὶ CONJ 2532 καί ! καί
JAS 5:3.5 . ὁ ὁ T-NSM 3588 ὁ ! ὁ
JAS 5:3.6 . ἄργυρος ἄργυρος N-NSM 696 ἄργυρος ! ἄργυρος
JAS 5:3.7 . κατίωται, κατίωται, V-RPI-3S 2728 κατιόω ! κατιόω
JAS 5:3.8 . καὶ καὶ CONJ 2532 καί ! καί
JAS 5:3.9 . ὁ ὁ T-NSM 3588 ὁ ! ὁ
JAS 5:3.10 . ἰὸς ἰὸς N-NSM 2447 ἰός ! ἰός
JAS 5:3.11 . αὐτῶν αὐτῶν P-GPM 846 αὐτός ! αὐτός
JAS 5:3.12 . εἰς εἰς PREP 1519 εἰς ! εἰς
JAS 5:3.13 . μαρτύριον μαρτύριον N-ASN 3142 μαρτύριον ! μαρτύριον
JAS 5:3.14 . ὑμῖν ὑμῖν P-2DP 5210 ὑμεῖς ! σύ
JAS 5:3.15 . ἔσται ἔσται V-FDI-3S 1510 εἰμί ! εἰμί
JAS 5:3.16 . καὶ καὶ CONJ 2532 καί ! καί
JAS 5:3.17 . φάγεται φάγεται V-FDI-3S 5315 φάγω ! ἐσθίω
JAS 5:3.18 . τὰς τὰς T-APF 3588 ὁ ! ὁ
JAS 5:3.19 . σάρκας σάρκας N-APF 4561 σάρξ ! σάρξ
JAS 5:3.20 . ὑμῶν ὑμῶν P-2GP 5210 ὑμεῖς ! σύ
JAS 5:3.21 . ὡς ὡς ADV 5613 ὡς ! ὡς
JAS 5:3.22 . πῦρ· πῦρ· N-NSN 4442 πῦρ ! πῦρ
JAS 5:3.23 . ἐθησαυρίσατε ἐθησαυρίσατε V-AAI-2P 2343 θησαυρίζω ! θησαυρίζω
JAS 5:3.24 . ἐν ἐν PREP 1722 ἐν ! ἐν
JAS 5:3.25 . ἐσχάταις ἐσχάταις A-DPF-S 2078 ἔσχατος ! ἔσχατος
JAS 5:3.26 . ἡμέραις. ἡμέραις. N-DPF 2250 ἡμέρα ! ἡμέρα
JAS 5:4.1 . ἰδοὺ ἰδοὺ V-2AMM-2S 3708 ὁράω ! ὁράω
JAS 5:4.2 . ὁ ὁ T-NSM 3588 ὁ ! ὁ
JAS 5:4.3 . μισθὸς μισθὸς N-NSM 3408 μισθός ! μισθός
JAS 5:4.4 . τῶν τῶν T-GPM 3588 ὁ ! ὁ
JAS 5:4.5 . ἐργατῶν ἐργατῶν N-GPM 2040 ἐργάτης ! ἐργάτης
JAS 5:4.6 . τῶν τῶν T-GPM 3588 ὁ ! ὁ
JAS 5:4.7 . ἀμησάντων ἀμησάντων V-AAP-GPM 270 ἀμάω ! ἀμάω
JAS 5:4.8 . τὰς τὰς T-APF 3588 ὁ ! ὁ
JAS 5:4.9 . χώρας χώρας N-APF 5561 χώρα ! χώρα
JAS 5:4.10 . ὑμῶν ὑμῶν P-2GP 5210 ὑμεῖς ! σύ
JAS 5:4.11 . ὁ ὁ T-NSM 3588 ὁ ! ὁ
JAS 5:4.12 . ἀφυστερημένος ἀφυστερημένος V-RPP-NSM 650 ἀποστερέω ! ἀφυστερέω
JAS 5:4.13 . ἀφ’ ἀφ’ PREP 575 ἀπό ! ἀπό
JAS 5:4.14 . ὑμῶν ὑμῶν P-2GP 5210 ὑμεῖς ! σύ
JAS 5:4.15 . κράζει, κράζει, V-PAI-3S 2896 κράζω ! κράζω
JAS 5:4.16 . καὶ καὶ CONJ 2532 καί ! καί
JAS 5:4.17 . αἱ αἱ T-NPF 3588 ὁ ! ὁ
JAS 5:4.18 . βοαὶ βοαὶ N-NPF 995 βοή ! βοή
JAS 5:4.19 . τῶν τῶν T-GPM 3588 ὁ ! ὁ
JAS 5:4.20 . θερισάντων θερισάντων V-AAP-GPM 2325 θερίζω ! θερίζω
JAS 5:4.21 . εἰς εἰς PREP 1519 εἰς ! εἰς
JAS 5:4.22 . τὰ τὰ T-APN 3588 ὁ ! ὁ
JAS 5:4.23 . ὦτα ὦτα N-APN 3775 οὖς ! οὖς
JAS 5:4.24 . κυρίου κυρίου N-GSM 2962 κύριος ! κύριος
JAS 5:4.25 . σαβαὼθ σαβαὼθ HEB 4519 σαβαώθ ! Σαβαώθ
JAS 5:4.26 . εἰσελήλυθαν. εἰσελήλυθαν. V-2RAI-3P 1525 εἰσέρχομαι ! εἰσέρχομαι
JAS 5:5.1 . ἐτρυφήσατε ἐτρυφήσατε V-AAI-2P 5171 τρυφάω ! τρυφάω
JAS 5:5.2 . ἐπὶ ἐπὶ PREP 1909 ἐπί ! ἐπί
JAS 5:5.3 . τῆς τῆς T-GSF 3588 ὁ ! ὁ
JAS 5:5.4 . γῆς γῆς N-GSF 1093 γῆ ! γῆ
JAS 5:5.5 . καὶ καὶ CONJ 2532 καί ! καί
JAS 5:5.6 . ἐσπαταλήσατε, ἐσπαταλήσατε, V-AAI-2P 4684 σπαταλάω ! σπαταλάω
JAS 5:5.7 . ἐθρέψατε ἐθρέψατε V-AAI-2P 5142 τρέφω ! τρέφω
JAS 5:5.8 . τὰς τὰς T-APF 3588 ὁ ! ὁ
JAS 5:5.9 . καρδίας καρδίας N-APF 2588 καρδία ! καρδία
JAS 5:5.10 . ὑμῶν ὑμῶν P-2GP 5210 ὑμεῖς ! σύ
JAS 5:5.11 . ἐν ἐν PREP 1722 ἐν ! ἐν
JAS 5:5.12 . ἡμέρᾳ ἡμέρᾳ N-DSF 2250 ἡμέρα ! ἡμέρα
JAS 5:5.13 . σφαγῆς. σφαγῆς. N-GSF 4967 σφαγή ! σφαγή
JAS 5:6.1 . κατεδικάσατε, κατεδικάσατε, V-AAI-2P 2613 καταδικάζω ! καταδικάζω
JAS 5:6.2 . ἐφονεύσατε ἐφονεύσατε V-AAI-2P 5407 φονεύω ! φονεύω
JAS 5:6.3 . τὸν τὸν T-ASM 3588 ὁ ! ὁ
JAS 5:6.4 . δίκαιον. δίκαιον. A-ASM 1342 δίκαιος ! δίκαιος
JAS 5:6.5 . οὐκ οὐκ PRT-N 3756 οὐ ! οὐ
JAS 5:6.6 . ἀντιτάσσεται ἀντιτάσσεται V-PMI-3S 498 ἀντιτάσσομαι ! ἀντιτάσσω
JAS 5:6.7 . ὑμῖν. ὑμῖν. P-2DP 5210 ὑμεῖς ! σύ
JAS 5:7.1 P Μακροθυμήσατε Μακροθυμήσατε V-AAM-2P 3114 μακροθυμέω ! μακροθυμέω
JAS 5:7.2 . οὖν, οὖν, CONJ 3767 οὖν ! οὖν
JAS 5:7.3 . ἀδελφοί, ἀδελφοί, N-VPM 80 ἀδελφός ! ἀδελφός
JAS 5:7.4 . ἕως ἕως ADV 2193 ἕως ! ἕως
JAS 5:7.5 . τῆς τῆς T-GSF 3588 ὁ ! ὁ
JAS 5:7.6 . παρουσίας παρουσίας N-GSF 3952 παρουσία ! παρουσία
JAS 5:7.7 . τοῦ τοῦ T-GSM 3588 ὁ ! ὁ
JAS 5:7.8 . κυρίου. κυρίου. N-GSM 2962 κύριος ! κύριος
JAS 5:7.9 . ἰδοὺ ἰδοὺ V-2AMM-2S 3708 ὁράω ! ὁράω
JAS 5:7.10 . ὁ ὁ T-NSM 3588 ὁ ! ὁ
JAS 5:7.11 . γεωργὸς γεωργὸς N-NSM 1092 γεωργός ! γεωργός
JAS 5:7.12 . ἐκδέχεται ἐκδέχεται V-PNI-3S 1551 ἐκδέχομαι ! ἐκδέχομαι
JAS 5:7.13 . τὸν τὸν T-ASM 3588 ὁ ! ὁ
JAS 5:7.14 . τίμιον τίμιον A-ASM 5093 τίμιος ! τίμιος
JAS 5:7.15 . καρπὸν καρπὸν N-ASM 2590 καρπός ! καρπός
JAS 5:7.16 . τῆς τῆς T-GSF 3588 ὁ ! ὁ
JAS 5:7.17 . γῆς, γῆς, N-GSF 1093 γῆ ! γῆ
JAS 5:7.18 . μακροθυμῶν μακροθυμῶν V-PAP-NSM 3114 μακροθυμέω ! μακροθυμέω
JAS 5:7.19 . ἐπ’ ἐπ’ PREP 1909 ἐπί ! ἐπί
JAS 5:7.20 . αὐτῷ αὐτῷ P-DSM 846 αὐτός ! αὐτός
JAS 5:7.21 . ἕως ἕως ADV 2193 ἕως ! ἕως
JAS 5:7.22 . λάβῃ λάβῃ V-2AAS-3S 2983 λαμβάνω ! λαμβάνω
JAS 5:7.23 . πρόϊμον πρόϊμον A-ASM 4406 πρώϊμος ! πρόϊμος
JAS 5:7.24 . καὶ καὶ CONJ 2532 καί ! καί
JAS 5:7.25 . ὄψιμον. ὄψιμον. A-ASM 3797 ὄψιμος ! ὄψιμος
JAS 5:8.1 . μακροθυμήσατε μακροθυμήσατε V-AAM-2P 3114 μακροθυμέω ! μακροθυμέω
JAS 5:8.2 . καὶ καὶ CONJ 2532 καί ! καί
JAS 5:8.3 . ὑμεῖς, ὑμεῖς, P-2NP 5210 ὑμεῖς ! σύ
JAS 5:8.4 . στηρίξατε στηρίξατε V-AAM-2P 4741 στηρίζω ! στηρίζω
JAS 5:8.5 . τὰς τὰς T-APF 3588 ὁ ! ὁ
JAS 5:8.6 . καρδίας καρδίας N-APF 2588 καρδία ! καρδία
JAS 5:8.7 . ὑμῶν, ὑμῶν, P-2GP 5210 ὑμεῖς ! σύ
JAS 5:8.8 . ὅτι ὅτι CONJ 3754 ὅτι ! ὅτι
JAS 5:8.9 . ἡ ἡ T-NSF 3588 ὁ ! ὁ
JAS 5:8.10 . παρουσία παρουσία N-NSF 3952 παρουσία ! παρουσία
JAS 5:8.11 . τοῦ τοῦ T-GSM 3588 ὁ ! ὁ
JAS 5:8.12 . κυρίου κυρίου N-GSM 2962 κύριος ! κύριος
JAS 5:8.13 . ἤγγικεν. ἤγγικεν. V-RAI-3S 1448 ἐγγίζω ! ἐγγίζω
JAS 5:9.1 . μὴ μὴ PRT-N 3361 μή ! μή
JAS 5:9.2 . στενάζετε στενάζετε V-PAM-2P 4727 στενάζω ! στενάζω
JAS 5:9.3 . κατ’ κατ’ PREP 2596 κατά ! κατά
JAS 5:9.4 . ἀλλήλων, ἀλλήλων, C-GPM 240 ἀλλήλων ! ἀλλήλων
JAS 5:9.5 . ἀδελφοί, ἀδελφοί, N-VPM 80 ἀδελφός ! ἀδελφός
JAS 5:9.6 . ἵνα ἵνα CONJ 2443 ἵνα ! ἵνα
JAS 5:9.7 . μὴ μὴ PRT-N 3361 μή ! μή
JAS 5:9.8 . κριθῆτε· κριθῆτε· V-APS-2P 2919 κρίνω ! κρίνω
JAS 5:9.9 . ἰδοὺ ἰδοὺ V-2AMM-2S 3708 ὁράω ! ὁράω
JAS 5:9.10 . ὁ ὁ T-NSM 3588 ὁ ! ὁ
JAS 5:9.11 . κριτὴς κριτὴς N-NSM 2923 κριτής ! κριτής
JAS 5:9.12 . πρὸ πρὸ PREP 4253 πρό ! πρό
JAS 5:9.13 . τῶν τῶν T-GPF 3588 ὁ ! ὁ
JAS 5:9.14 . θυρῶν θυρῶν N-GPF 2374 θύρα ! θύρα
JAS 5:9.15 . ἕστηκεν. ἕστηκεν. V-RAI-3S 2476 ἵστημι ! ἵστημι
JAS 5:10.1 . ὑπόδειγμα ὑπόδειγμα N-ASN 5262 ὑπόδειγμα ! ὑπόδειγμα
JAS 5:10.2 . λάβετε, λάβετε, V-2AAM-2P 2983 λαμβάνω ! λαμβάνω
JAS 5:10.3 . ἀδελφοί, ἀδελφοί, N-VPM 80 ἀδελφός ! ἀδελφός
JAS 5:10.4 . τῆς τῆς T-GSF 3588 ὁ ! ὁ
JAS 5:10.5 . κακοπαθείας κακοπαθείας N-GSF 2552 κακοπάθεια ! κακοπάθεια
JAS 5:10.6 . καὶ καὶ CONJ 2532 καί ! καί
JAS 5:10.7 . τῆς τῆς T-GSF 3588 ὁ ! ὁ
JAS 5:10.8 . μακροθυμίας μακροθυμίας N-GSF 3115 μακροθυμία ! μακροθυμία
JAS 5:10.9 . τοὺς τοὺς T-APM 3588 ὁ ! ὁ
JAS 5:10.10 . προφήτας, προφήτας, N-APM 4396 προφήτης ! προφήτης
JAS 5:10.11 . οἳ οἳ R-NPM 3739 ὅς ! ὅς
JAS 5:10.12 . ἐλάλησαν ἐλάλησαν V-AAI-3P 2980 λαλέω ! λαλέω
JAS 5:10.13 . ἐν ἐν PREP 1722 ἐν ! ἐν
JAS 5:10.14 . τῷ τῷ T-DSN 3588 ὁ ! ὁ
JAS 5:10.15 . ὀνόματι ὀνόματι N-DSN 3686 ὄνομα ! ὄνομα
JAS 5:10.16 . κυρίου. κυρίου. N-GSM 2962 κύριος ! κύριος
JAS 5:11.1 . ἰδοὺ ἰδοὺ V-2AMM-2S 3708 ὁράω ! ὁράω
JAS 5:11.2 . μακαρίζομεν μακαρίζομεν V-PAI-1P 3106 μακαρίζω ! μακαρίζω
JAS 5:11.3 . τοὺς τοὺς T-APM 3588 ὁ ! ὁ
JAS 5:11.4 . ὑπομείναντας· ὑπομείναντας· V-AAP-APM 5278 ὑπομένω ! ὑπομένω
JAS 5:11.5 . τὴν τὴν T-ASF 3588 ὁ ! ὁ
JAS 5:11.6 . ὑπομονὴν ὑπομονὴν N-ASF 5281 ὑπομονή ! ὑπομονή
JAS 5:11.7 . Ἰὼβ Ἰὼβ N-PRI 2492 Ἰώβ ! Ἰώβ
JAS 5:11.8 . ἠκούσατε, ἠκούσατε, V-AAI-2P 191 ἀκούω ! ἀκούω
JAS 5:11.9 . καὶ καὶ CONJ 2532 καί ! καί
JAS 5:11.10 . τὸ τὸ T-ASN 3588 ὁ ! ὁ
JAS 5:11.11 . τέλος τέλος N-ASN 5056 τέλος ! τέλος
JAS 5:11.12 . κυρίου κυρίου N-GSM 2962 κύριος ! κύριος
JAS 5:11.13 . εἴδετε, εἴδετε, V-2AAI-2P 3708 ὁράω ! ὁράω
JAS 5:11.14 . ὅτι ὅτι CONJ 3754 ὅτι ! ὅτι
JAS 5:11.15 . πολύσπλαγχνός πολύσπλαγχνός A-NSM 4184 πολύσπλαγχνος ! πολύσπλαγχνος
JAS 5:11.16 . ἐστιν ἐστιν V-PAI-3S 1510 εἰμί ! εἰμί
JAS 5:11.17 . ὁ ὁ T-NSM 3588 ὁ ! ὁ
JAS 5:11.18 . κύριος κύριος N-NSM 2962 κύριος ! κύριος
JAS 5:11.19 . καὶ καὶ CONJ 2532 καί ! καί
JAS 5:11.20 . οἰκτίρμων. οἰκτίρμων. A-NSM 3629 οἰκτίρμων ! οἰκτίρμων
JAS 5:12.1 P Πρὸ Πρὸ PREP 4253 πρό ! πρό
JAS 5:12.2 . πάντων πάντων A-GPN 3956 πᾶς ! πᾶς
JAS 5:12.3 . δέ, δέ, CONJ 1161 δέ ! δέ
JAS 5:12.4 . ἀδελφοί ἀδελφοί N-VPM 80 ἀδελφός ! ἀδελφός
JAS 5:12.5 . μου, μου, P-1GS 1473 ἐγώ ! ἐγώ
JAS 5:12.6 . μὴ μὴ PRT-N 3361 μή ! μή
JAS 5:12.7 . ὀμνύετε, ὀμνύετε, V-PAM-2P 3660 ὀμνύω ! ὀμνύω
JAS 5:12.8 . μήτε μήτε CONJ-N 3383 μήτε ! μήτε
JAS 5:12.9 . τὸν τὸν T-ASM 3588 ὁ ! ὁ
JAS 5:12.10 . οὐρανὸν οὐρανὸν N-ASM 3772 οὐρανός ! οὐρανός
JAS 5:12.11 . μήτε μήτε CONJ-N 3383 μήτε ! μήτε
JAS 5:12.12 . τὴν τὴν T-ASF 3588 ὁ ! ὁ
JAS 5:12.13 . γῆν γῆν N-ASF 1093 γῆ ! γῆ
JAS 5:12.14 . μήτε μήτε CONJ-N 3383 μήτε ! μήτε
JAS 5:12.15 . ἄλλον ἄλλον A-ASM 243 ἄλλος ! ἄλλος
JAS 5:12.16 . τινὰ τινὰ X-ASM 5100 τὶς ! τὶς
JAS 5:12.17 . ὅρκον· ὅρκον· N-ASM 3727 ὅρκος ! ὅρκος
JAS 5:12.18 . ἤτω ἤτω V-PAM-3S 1510 εἰμί ! εἰμί
JAS 5:12.19 . δὲ δὲ CONJ 1161 δέ ! δέ
JAS 5:12.20 . ὑμῶν ὑμῶν P-2GP 5210 ὑμεῖς ! σύ
JAS 5:12.21 . τὸ τὸ T-NSN 3588 ὁ ! ὁ
JAS 5:12.22 . ναὶ ναὶ PRT 3483 ναί ! ναί
JAS 5:12.23 . ναὶ ναὶ PRT 3483 ναί ! ναί
JAS 5:12.24 . καὶ καὶ CONJ 2532 καί ! καί
JAS 5:12.25 . τὸ τὸ T-NSN 3588 ὁ ! ὁ
JAS 5:12.26 . οὒ οὒ PRT-N 3756 οὐ ! οὐ
JAS 5:12.27 . οὔ, οὔ, PRT-N 3756 οὐ ! οὐ
JAS 5:12.28 . ἵνα ἵνα CONJ 2443 ἵνα ! ἵνα
JAS 5:12.29 . μὴ μὴ PRT-N 3361 μή ! μή
JAS 5:12.30 . ὑπὸ ὑπὸ PREP 5259 ὑπό ! ὑπό
JAS 5:12.31 . κρίσιν κρίσιν N-ASF 2920 κρίσις ! κρίσις
JAS 5:12.32 . πέσητε. πέσητε. V-2AAS-2P 4098 πίπτω ! πίπτω
JAS 5:13.1 P Κακοπαθεῖ Κακοπαθεῖ V-PAI-3S 2553 κακοπαθέω ! κακοπαθέω
JAS 5:13.2 . τις τις X-NSM 5100 τὶς ! τὶς
JAS 5:13.3 . ἐν ἐν PREP 1722 ἐν ! ἐν
JAS 5:13.4 . ὑμῖν; ὑμῖν; P-2DP 5210 ὑμεῖς ! σύ
JAS 5:13.5 . προσευχέσθω· προσευχέσθω· V-PNM-3S 4336 προσεύχομαι ! προσεύχομαι
JAS 5:13.6 . εὐθυμεῖ εὐθυμεῖ V-PAI-3S 2114 εὐθυμέω ! εὐθυμέω
JAS 5:13.7 . τις; τις; X-NSM 5100 τὶς ! τὶς
JAS 5:13.8 . ψαλλέτω. ψαλλέτω. V-PAM-3S 5567 ψάλλω ! ψάλλω
JAS 5:14.1 . ἀσθενεῖ ἀσθενεῖ V-PAI-3S 770 ἀσθενέω ! ἀσθενέω
JAS 5:14.2 . τις τις X-NSM 5100 τὶς ! τὶς
JAS 5:14.3 . ἐν ἐν PREP 1722 ἐν ! ἐν
JAS 5:14.4 . ὑμῖν; ὑμῖν; P-2DP 5210 ὑμεῖς ! σύ
JAS 5:14.5 . προσκαλεσάσθω προσκαλεσάσθω V-ADM-3S 4341 προσκαλέομαι ! προσκαλέω
JAS 5:14.6 . τοὺς τοὺς T-APM 3588 ὁ ! ὁ
JAS 5:14.7 . πρεσβυτέρους πρεσβυτέρους A-APM-C 4245 πρεσβύτερος ! πρεσβύτερος
JAS 5:14.8 . τῆς τῆς T-GSF 3588 ὁ ! ὁ
JAS 5:14.9 . ἐκκλησίας, ἐκκλησίας, N-GSF 1577 ἐκκλησία ! ἐκκλησία
JAS 5:14.10 . καὶ καὶ CONJ 2532 καί ! καί
JAS 5:14.11 . προσευξάσθωσαν προσευξάσθωσαν V-ADM-3P 4336 προσεύχομαι ! προσεύχομαι
JAS 5:14.12 . ἐπ’ ἐπ’ PREP 1909 ἐπί ! ἐπί
JAS 5:14.13 . αὐτὸν αὐτὸν P-ASM 846 αὐτός ! αὐτός
JAS 5:14.14 . ἀλείψαντες ἀλείψαντες V-AAP-NPM 218 ἀλείφω ! ἀλείφω
JAS 5:14.15 . ἐλαίῳ ἐλαίῳ N-DSN 1637 ἔλαιον ! ἔλαιον
JAS 5:14.16 . ἐν ἐν PREP 1722 ἐν ! ἐν
JAS 5:14.17 . τῷ τῷ T-DSN 3588 ὁ ! ὁ
JAS 5:14.18 . ὀνόματι ὀνόματι N-DSN 3686 ὄνομα ! ὄνομα
JAS 5:14.19 . τοῦ τοῦ T-GSM 3588 ὁ ! ὁ
JAS 5:14.20 . κυρίου· κυρίου· N-GSM 2962 κύριος ! κύριος
JAS 5:15.1 . καὶ καὶ CONJ 2532 καί ! καί
JAS 5:15.2 . ἡ ἡ T-NSF 3588 ὁ ! ὁ
JAS 5:15.3 . εὐχὴ εὐχὴ N-NSF 2171 εὐχή ! εὐχή
JAS 5:15.4 . τῆς τῆς T-GSF 3588 ὁ ! ὁ
JAS 5:15.5 . πίστεως πίστεως N-GSF 4102 πίστις ! πίστις
JAS 5:15.6 . σώσει σώσει V-FAI-3S 4982 σώζω ! σῴζω
JAS 5:15.7 . τὸν τὸν T-ASM 3588 ὁ ! ὁ
JAS 5:15.8 . κάμνοντα, κάμνοντα, V-PAP-ASM 2577 κάμνω ! κάμνω
JAS 5:15.9 . καὶ καὶ CONJ 2532 καί ! καί
JAS 5:15.10 . ἐγερεῖ ἐγερεῖ V-FAI-3S 1453 ἐγείρω ! ἐγείρω
JAS 5:15.11 . αὐτὸν αὐτὸν P-ASM 846 αὐτός ! αὐτός
JAS 5:15.12 . ὁ ὁ T-NSM 3588 ὁ ! ὁ
JAS 5:15.13 . κύριος· κύριος· N-NSM 2962 κύριος ! κύριος
JAS 5:15.14 . κἂν κἂν COND-K 2579 κἄν ! κἄν
JAS 5:15.15 . ἁμαρτίας ἁμαρτίας N-APF 266 ἁμαρτία ! ἁμαρτία
JAS 5:15.16 . ᾖ ᾖ V-PAS-3S 1510 εἰμί ! εἰμί
JAS 5:15.17 . πεποιηκώς, πεποιηκώς, V-RAP-NSM 4160 ποιέω ! ποιέω
JAS 5:15.18 . ἀφεθήσεται ἀφεθήσεται V-FPI-3S 863 ἀφίημι ! ἀφίημι
JAS 5:15.19 . αὐτῷ. αὐτῷ. P-DSM 846 αὐτός ! αὐτός
JAS 5:16.1 . ἐξομολογεῖσθε ἐξομολογεῖσθε V-PMM-2P 1843 ἐξομολογέω ! ἐξομολογέω
JAS 5:16.2 . οὖν οὖν CONJ 3767 οὖν ! οὖν
JAS 5:16.3 . ἀλλήλοις ἀλλήλοις C-DPM 240 ἀλλήλων ! ἀλλήλων
JAS 5:16.4 . τὰς τὰς T-APF 3588 ὁ ! ὁ
JAS 5:16.5 . ἁμαρτίας ἁμαρτίας N-APF 266 ἁμαρτία ! ἁμαρτία
JAS 5:16.6 . καὶ καὶ CONJ 2532 καί ! καί
JAS 5:16.7 . εὔχεσθε εὔχεσθε V-PNM-2P 2172 εὔχομαι ! εὔχομαι
JAS 5:16.8 . ὑπὲρ ὑπὲρ PREP 5228 ὑπέρ ! ὑπέρ
JAS 5:16.9 . ἀλλήλων, ἀλλήλων, C-GPM 240 ἀλλήλων ! ἀλλήλων
JAS 5:16.10 . ὅπως ὅπως ADV 3704 ὅπως ! ὅπως
JAS 5:16.11 . ἰαθῆτε. ἰαθῆτε. V-APS-2P 2390 ἰάομαι ! ἰάομαι
JAS 5:16.12 . πολὺ πολὺ A-ASN 4183 πολύς ! πολύς
JAS 5:16.13 . ἰσχύει ἰσχύει V-PAI-3S 2480 ἰσχύω ! ἰσχύω
JAS 5:16.14 . δέησις δέησις N-NSF 1162 δέησις ! δέησις
JAS 5:16.15 . δικαίου δικαίου A-GSM 1342 δίκαιος ! δίκαιος
JAS 5:16.16 . ἐνεργουμένη. ἐνεργουμένη. V-PMP-NSF 1754 ἐνεργέω ! ἐνεργέω
JAS 5:17.1 . Ἠλείας Ἠλείας N-NSM 2243 Ἡλίας ! Ἠλίας
JAS 5:17.2 . ἄνθρωπος ἄνθρωπος N-NSM 444 ἄνθρωπος ! ἄνθρωπος
JAS 5:17.3 . ἦν ἦν V-IAI-3S 1510 εἰμί ! εἰμί
JAS 5:17.4 . ὁμοιοπαθὴς ὁμοιοπαθὴς A-NSM 3663 ὁμοιοπαθής ! ὁμοιοπαθής
JAS 5:17.5 . ἡμῖν, ἡμῖν, P-1DP 2249 ἡμεῖς ! ἐγώ
JAS 5:17.6 . καὶ καὶ CONJ 2532 καί ! καί
JAS 5:17.7 . προσευχῇ προσευχῇ N-DSF 4335 προσευχή ! προσευχή
JAS 5:17.8 . προσηύξατο προσηύξατο V-ADI-3S 4336 προσεύχομαι ! προσεύχομαι
JAS 5:17.9 . τοῦ τοῦ T-GSN 3588 ὁ ! ὁ
JAS 5:17.10 . μὴ μὴ PRT-N 3361 μή ! μή
JAS 5:17.11 . βρέξαι, βρέξαι, V-AAN 1026 βρέχω ! βρέχω
JAS 5:17.12 . καὶ καὶ CONJ 2532 καί ! καί
JAS 5:17.13 . οὐκ οὐκ PRT-N 3756 οὐ ! οὐ
JAS 5:17.14 . ἔβρεξεν ἔβρεξεν V-AAI-3S 1026 βρέχω ! βρέχω
JAS 5:17.15 . ἐπὶ ἐπὶ PREP 1909 ἐπί ! ἐπί
JAS 5:17.16 . τῆς τῆς T-GSF 3588 ὁ ! ὁ
JAS 5:17.17 . γῆς γῆς N-GSF 1093 γῆ ! γῆ
JAS 5:17.18 . ἐνιαυτοὺς ἐνιαυτοὺς N-APM 1763 ἐνιαυτός ! ἐνιαυτός
JAS 5:17.19 . τρεῖς τρεῖς A-APM 5140 τρεῖς ! τρεῖς
JAS 5:17.20 . καὶ καὶ CONJ 2532 καί ! καί
JAS 5:17.21 . μῆνας μῆνας N-APM 3376 μήν ! μήν (II)
JAS 5:17.22 . ἕξ· ἕξ· A-NUI 1803 ἕξ ! ἕξ
JAS 5:18.1 . καὶ καὶ CONJ 2532 καί ! καί
JAS 5:18.2 . πάλιν πάλιν ADV 3825 πάλιν ! πάλιν
JAS 5:18.3 . προσηύξατο, προσηύξατο, V-ADI-3S 4336 προσεύχομαι ! προσεύχομαι
JAS 5:18.4 . καὶ καὶ CONJ 2532 καί ! καί
JAS 5:18.5 . ὁ ὁ T-NSM 3588 ὁ ! ὁ
JAS 5:18.6 . οὐρανὸς οὐρανὸς N-NSM 3772 οὐρανός ! οὐρανός
JAS 5:18.7 . ἔδωκεν ἔδωκεν V-AAI-3S 1325 δίδωμι ! δίδωμι
JAS 5:18.8 . ὑετὸν ὑετὸν N-ASM 5205 ὑετός ! ὑετός
JAS 5:18.9 . καὶ καὶ CONJ 2532 καί ! καί
JAS 5:18.10 . ἡ ἡ T-NSF 3588 ὁ ! ὁ
JAS 5:18.11 . γῆ γῆ N-NSF 1093 γῆ ! γῆ
JAS 5:18.12 . ἐβλάστησεν ἐβλάστησεν V-AAI-3S 985 βλαστάνω ! βλαστάνω
JAS 5:18.13 . τὸν τὸν T-ASM 3588 ὁ ! ὁ
JAS 5:18.14 . καρπὸν καρπὸν N-ASM 2590 καρπός ! καρπός
JAS 5:18.15 . αὐτῆς. αὐτῆς. P-GSF 846 αὐτός ! αὐτός
JAS 5:19.1 P Ἀδελφοί Ἀδελφοί N-VPM 80 ἀδελφός ! ἀδελφός
JAS 5:19.2 . μου, μου, P-1GS 1473 ἐγώ ! ἐγώ
JAS 5:19.3 . ἐάν ἐάν COND 1437 ἐάν ! ἐάν
JAS 5:19.4 . τις τις X-NSM 5100 τὶς ! τὶς
JAS 5:19.5 . ἐν ἐν PREP 1722 ἐν ! ἐν
JAS 5:19.6 . ὑμῖν ὑμῖν P-2DP 5210 ὑμεῖς ! σύ
JAS 5:19.7 . πλανηθῇ πλανηθῇ V-APS-3S 4105 πλανάω ! πλανάω
JAS 5:19.8 . ἀπὸ ἀπὸ PREP 575 ἀπό ! ἀπό
JAS 5:19.9 . τῆς τῆς T-GSF 3588 ὁ ! ὁ
JAS 5:19.10 . ἀληθείας ἀληθείας N-GSF 225 ἀλήθεια ! ἀλήθεια
JAS 5:19.11 . καὶ καὶ CONJ 2532 καί ! καί
JAS 5:19.12 . ἐπιστρέψῃ ἐπιστρέψῃ V-AAS-3S 1994 ἐπιστρέφω ! ἐπιστρέφω
JAS 5:19.13 . τις τις X-NSM 5100 τὶς ! τὶς
JAS 5:19.14 . αὐτόν, αὐτόν, P-ASM 846 αὐτός ! αὐτός
JAS 5:20.1 . γινωσκέτω γινωσκέτω V-PAM-3S 1097 γινώσκω ! γινώσκω
JAS 5:20.2 . ὅτι ὅτι CONJ 3754 ὅτι ! ὅτι
JAS 5:20.3 . ὁ ὁ T-NSM 3588 ὁ ! ὁ
JAS 5:20.4 . ἐπιστρέψας ἐπιστρέψας V-AAP-NSM 1994 ἐπιστρέφω ! ἐπιστρέφω
JAS 5:20.5 . ἁμαρτωλὸν ἁμαρτωλὸν A-ASM 268 ἁμαρτωλός ! ἁμαρτωλός
JAS 5:20.6 . ἐκ ἐκ PREP 1537 ἐκ ! ἐκ
JAS 5:20.7 . πλάνης πλάνης N-GSF 4106 πλάνη ! πλάνη
JAS 5:20.8 . ὁδοῦ ὁδοῦ N-GSF 3598 ὁδός ! ὁδός
JAS 5:20.9 . αὐτοῦ αὐτοῦ P-GSM 846 αὐτός ! αὐτός
JAS 5:20.10 . σώσει σώσει V-FAI-3S 4982 σώζω ! σῴζω
JAS 5:20.11 . ψυχὴν ψυχὴν N-ASF 5590 ψυχή ! ψυχή
JAS 5:20.12 . αὐτοῦ αὐτοῦ P-GSM 846 αὐτός ! αὐτός
JAS 5:20.13 . ἐκ ἐκ PREP 1537 ἐκ ! ἐκ
JAS 5:20.14 . θανάτου θανάτου N-GSM 2288 θάνατος ! θάνατος
JAS 5:20.15 . καὶ καὶ CONJ 2532 καί ! καί
JAS 5:20.16 . καλύψει καλύψει V-FAI-3S 2572 καλύπτω ! καλύπτω
JAS 5:20.17 . πλῆθος πλῆθος N-ASN 4128 πλῆθος ! πλῆθος
JAS 5:20.18 . ἁμαρτιῶν. ἁμαρτιῶν. N-GPF 266 ἁμαρτία ! ἁμαρτία
`