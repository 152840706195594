export const supported_fonts = [
    {
        label: 'Spline Sans',
        mode: 'light',
        size_offset: 0,
        // letter-spacing: 0rem;
        styles: `
            body {
                font-family: 'Spline Sans Variable';
                font-variation-settings: 'wght' 320;
                font-feature-settings: '';
            }
        `
    },
    {
        label: 'Satoshi',
        mode: 'light',
        size_offset: 0,
        styles: `
            body {
                font-family: 'Satoshi Variable';
                font-feature-settings: 'ss03', 'ss02';
            }
        `
    },
    {
        label: 'Mukta',
        mode: 'dark',
        size_offset: 2,
        styles: `
            body {
                font-family: 'Mukta Vaani';
                font-weight: 300;
            }
        `
    },
    {
        label: 'Recia',
        serif: 'true',
        mode: 'light',
        size_offset: 0,
        styles: `
            body {
                font-family: 'Recia';
            }
        `
    },
    {
        label: 'BG',
        mode: 'light',
        size_offset: 0,
        styles: `
            body {
                font-family: 'Bani Source Pro';
                // font-weight: 300;
            }
        `
    },
    {
        label: 'Webly S',
        mode: 'dark',
        size_offset: 0,
        styles: `
            body {
                font-family: 'WeblySleek';
                font-weight: 300;
                letter-spacing: 0rem;
            }
        `
    },
    {
        label: 'Mukta',
        mode: 'light',
        size_offset: 0.5,
        styles: `
            body {
                font-family: 'Mukta Vaani';
                font-weight: 400;
                letter-spacing: 0rem;
            }
        `
    },
    {
        label: 'Segoe',
        mode: 'light',
        size_offset: 0,
        styles: `
            body {
                font-family: 'Segoe UI';
                font-weight: 400;
            }
        `
    },
    // {
    //     label: 'Calcutta',
    //     mode: 'light',
    //     size_offset: 0.5,
    //     styles: `
    //         body {
    //             font-family: 'Calcutta';
    //             font-weight: 300;
    //             letter-spacing: -0.015rem;
    //         }
    //     `
    // },
    {
        label: 'Charter',
        mode: 'light',
        serif: true,
        size_offset: 0.5,
        styles: `
            body {
                font-family: 'Charter';
            }
        `
    },
    // {
    //     label: 'Hind',
    //     mode: 'light',
    //     size_offset: 0.2,
    //     styles: `
    //         body {
    //             font-family: 'Hind';
    //         }
    //     `
    // },
    {
        label: 'Catamaran',
        mode: 'light',
        size_offset: 0.6,
        styles: `
            body {
                font-family: 'Catamaran';
            }
        `
    },
    // {
    //     label: 'Commissioner',
    //     mode: 'light',
    //     size_offset: 0,
    //     styles: `
    //         body {
    //             font-family: 'Commissioner';
    //         }
    //     `
    // },
    {
        label: 'Georgia',
        serif: true,
        mode: 'light',
        size_offset: 0,
        styles: `
            body {
                font-family: 'Georgia';
                letter-spacing: 0rem;
            }
        `
    },
    {
        label: 'Times',
        serif: true,
        mode: 'light',
        size_offset: 1,
        styles: `
            body {
                font-family: 'Times New Roman';
                letter-spacing: 0rem;
            }
        `
    },
    {
        label: 'Georgia',
        serif: true,
        mode: 'dark',
        size_offset: 0,
        styles: `
            body {
                font-family: 'Georgia';
                letter-spacing: 0rem;
            }
        `
    },
    {
        label: 'Times',
        serif: true,
        mode: 'dark',
        size_offset: 0,
        styles: `
            body {
                font-family: 'Times New Roman';
                letter-spacing: 0rem;
            }
        `
    },
    {
        label: 'Merriweather',
        serif: true,
        mode: 'dark',
        size_offset: -1,
        styles: `
            body {
                font-family: 'merriweather';
                letter-spacing: 0rem;
            }
        `
    },
    {
        label: 'Merriweather',
        serif: true,
        mode: 'light',
        size_offset: -1,
        styles: `
            body {
                font-family: 'merriweather';
                letter-spacing: 0rem;
            }
        `
    },
    {
        label: 'Open Sans',
        mode: 'light',
        size_offset: -0.5,
        styles: `
            body {
                font-family: 'Open Sans';
                letter-spacing: -0.01rem;
            }
            Verse {
                line-height: 1.6;
            }
        `
    },
    // {
    //     label: 'Red Hat',
    //     mode: 'light',
    //     size_offset: 0,
    //     styles: `
    //         body {
    //             font-family: 'Red Hat Text';
    //             letter-spacing: 0rem;
    //         }
    //         .MuiTypography-root, .MuiChip-label {
    //             font-family: 'Red Hat Text';
    //             font-weight: 500;
    //         }
    //         h1, h2, h3, h4, h5, h6 {
    //             font-family: 'Red Hat Display' !important;
    //             letter-spacing: initial !important;
    //         }
    //         h1 {
    //             font-size: clamp(60px, 8vw, 130px) !important;
    //             letter-spacing: -3px;
    //             font-weight: 400;
    //             line-height: 1;
    //             padding-top: 100px !important;
                
    //         }
    //         h2 {
    //             font-weight: 500;
    //         }
    //         .SettingsHeader {
    //             padding-top: 88px !important;
    //         }
    //     `
    // },
    {
        label: 'Inter',
        mode: 'light',
        size_offset: -0.5714,
        styles: `
            body {
                font-family: 'Inter';
                letter-spacing: -0.015rem;
            }
            .MuiTypography-root, .MuiChip-label {
                font-family: 'Inter';
            }
            h1 {
                font-size: 4.2em !important;
                letter-spacing: -1.5px
            }
            h2 {
                letter-spacing: -0.5px;
            }
        `
    },
    // {
    //     label: 'Red Hat - Roboto Condensed',
    //     mode: 'light',
    //     pair: true,
    //     size_offset: 0,
    //     styles: `
    //         body {
    //             font-family: 'Red Hat Text';
    //             letter-spacing: 0px;
    //         }
    //         .Verse {
    //             font-family: 'Roboto Condensed';
    //         }
    //         .VN {
    //             font-family: 'Roboto Condensed';
    //         }
    //         .RelatedVerseHeader {
    //             font-family: 'Roboto Condensed';
    //         }
    //         .MuiTypography-root, .MuiChip-label {
    //             font-family: 'Red Hat Text';
    //             font-weight: 500;
    //         }
    //         h1, h2, h3, h4, h5, h6 {
    //             font-family: 'Red Hat Display' !important;
    //             letter-spacing: initial !important;
    //         }
    //         h1 {
    //             font-size: clamp(60px, 8vw, 130px) !important;
    //             letter-spacing: -3px;
    //             font-weight: 400;
    //             line-height: 1;
    //             padding-top: 100px !important;
    //         }
    //         h2 {
    //             font-weight: 500;
    //         }
    //         .SettingsHeader {
    //             padding-top: 88px !important;
    //         }
    //     `
    // },
    {
        label: 'Condensed',
        mode: 'light',
        size_offset: 0,
        styles: `
            .Verse {
                font-family: 'Roboto Condensed';
            }
            .VN {
                font-family: 'Roboto Condensed';
            }
            .RelatedVerseHeader {
                font-family: 'Roboto Condensed';
            }
        `
    },
    // {
    //     label: 'PB',
    //     mode: 'light',
    //     pair: true,
    //     size_offset: 0,
    //     styles: `
    //         body {
    //             font-family: 'Bani Source Pro';
    //         }
    //         .MuiTypography-root, .MuiChip-label {
    //             font-family: 'Bani Source Pro';
    //         }
    //         h1 {
    //             font-family: 'proxima-nova';
    //             font-size: 4.2em !important;
    //             letter-spacing: -1.5px;
    //             line-height: 1;
    //         }
    //         h2 {
    //             font-family: 'proxima-nova';
    //             letter-spacing: -0.5px;
    //         }
    //         .Section h3, h4, h5 {
    //             font-family: 'proxima-nova';
    //             font-weight: 300;
    //             font-style: normal;
    //         }
    //     `
    // },
]