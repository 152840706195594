import React, { useState, useEffect } from 'react';
// import { useHistory } from 'react-router-dom';
import { ref, onValue, push, set, serverTimestamp, remove, off, update } from 'firebase/database';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import { rtdb } from '../../firebase/setup';
import BodyLayout from '../../misc/BodyLayout';
import ListItemButton from '@mui/material/ListItemButton';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ListSubheader from '@mui/material/ListSubheader';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import Skeleton from '@mui/material/Skeleton';
import { ServerTimestamp, iBranchMetadata, iDocumentMetadata, iVersionMetadata } from '../iEditor';

// type ServerTimestamp = { seconds: number, nanoseconds: number } | FieldValue;
// type ServerTimestamp = typeof serverTimestamp | firebase.firestore.Timestamp;


export default function DocumentPicker() {

    const [newDocPopup, setNewDocPopup] = useState(false);
    const [addBranchPopup, setAddBranchPopup] = useState(false);
    const [addVersionPopup, setAddVersionPopup] = useState(false);
    const [docName, setDocName] = useState('');
    const [branchName, setBranchName] = useState('');
    const [versionName, setVersionName] = useState('');
    const [documents, setDocuments] = useState <iDocumentMetadata[] | null> (null);
    const [branches, setBranches] = useState <iBranchMetadata[]> ([]);
    const [versions, setVersions] = useState <iVersionMetadata[]> ([]);
    const [currentDocument, setCurrentDocument] = useState <iDocumentMetadata | null> (null);
    const [currentBranch, setCurrentBranch] = useState <iBranchMetadata | null> (null);
    const [view, setView] = useState <'documents' | 'branches' | 'versions'> ('documents');
    const navigate = useNavigate();

    useEffect(() => {
        const docRef = ref(rtdb, 'documents/metadata');
        const fetchData = () => {
            onValue(docRef, (snapshot) => {
                const documentsData = snapshot.val();
                if (!documentsData) return
                setDocuments(Object.entries(documentsData).map(([id, data]) => ({
                    ...(data as iDocumentMetadata)
                })));
            });
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (!currentDocument) return;

        const branchRef = ref(rtdb, `documents/metadata/${currentDocument.id}/branches`);

        const fetchData = () => {
            onValue(branchRef, (snapshot) => {
                const branchesData = snapshot.val();
                if (!branchesData) return;

                setBranches(Object.entries(branchesData).map(([id, data]) => ({
                    ...(data as iBranchMetadata),
                })));
            });
        };

        fetchData();

        // Clean up the listener when the component is unmounted or currentDocument changes
        return () => {
            off(branchRef);
        };
    }, [currentDocument]);

    useEffect(() => {
        if (!currentDocument || !currentBranch) return;

        const versionRef = ref(rtdb, `documents/metadata/${currentDocument.id}/branches/${currentBranch.id}/versions`);

        const fetchData = () => {
            onValue(versionRef, (snapshot) => {
                const versionsData = snapshot.val();
                if (!versionsData) return;

                setVersions(Object.entries(versionsData).map(([id, data]) => ({
                    ...(data as iVersionMetadata),
                })));
            });
        };

        fetchData();

        // Clean up the listener when the component is unmounted or currentBranch changes
        return () => {
            off(versionRef);
        };
    }, [currentDocument, currentBranch]);

    const handleClickOpen = () => {
        setNewDocPopup(true);
    };

    const handleClose = () => {
        setNewDocPopup(false);
    };

    const handleConfirm = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const newDocRef = push(ref(rtdb, 'documents/metadata'));
        const newDocId = newDocRef.key;
        const createdAt = serverTimestamp()as ServerTimestamp;

        await set(newDocRef, {
            id: newDocId,
            title: docName,
            created_at: createdAt,
            last_modified: createdAt,
            author: 'Bani Grisson',
            branches: {
                default: {
                    id: 'default',
                    title: 'Principal',
                    created_at: createdAt,
                    last_modified: createdAt,
                    author: 'Bani Grisson',
                    versions: {
                        1: {
                            id: '1',
                            title: 'Versión #1',
                            created_at: createdAt,
                            last_modified: createdAt,
                            author: 'Bani Grisson'
                        }
                    }
                }
            }
        } as iDocumentMetadata);

        setNewDocPopup(false);
        setDocName('');
        (e.currentTarget.ownerDocument.activeElement as HTMLElement)?.blur();
    };

    const handleDocNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDocName(event.target.value);
    };

    const handleDocumentClick = (doc: iDocumentMetadata) => {
        setCurrentDocument(doc);
        if (!doc.branches) {
            setBranches([]);
        } else {
            const arr = Object.entries(doc.branches).map(([id, data]) => ({
                ...(data as iBranchMetadata)
            }))
            setBranches(arr);
        }
        setView('branches');
    };

    const handleBranchClick = (branch: iBranchMetadata) => {
        setCurrentBranch(branch);
        setVersions(Object.entries(branch.versions).map(([id, data]) => ({
            ...(data as iVersionMetadata)
        })));
        setView('versions');
    };

    const handleVersionClick = (versionId: string) => {
        if (!currentDocument || !currentBranch) {
            console.error({currentDocument, currentBranch})
            return;
        };
        navigate(`/edit/${
            currentDocument ?. id
        }/${currentBranch.id}/${versionId}`);
    };

    const handleBackClick = () => {
        if (view === 'branches') {
            setView('documents');
            setCurrentDocument(null);
        } else if (view === 'versions') {
            setView('branches');
            setCurrentBranch(null);
        }
    };

    const handleDocumentDelete = async (event: React.MouseEvent, documentId: string) => {
        if (!documents) {
            console.error("No documents to delete");
            return;
        }
        event.stopPropagation();
        try {
            // Remove the document and its metadata from the database
            await remove(ref(rtdb, `documents/content/${documentId}`));
            await remove(ref(rtdb, `documents/metadata/${documentId}`));

            // Update the local state (remove the document from the list)
            setDocuments(documents.filter((doc) => doc.id !== documentId));
        } catch (error) {
            console.error("Error deleting document:", error);
        }
    };

    const handleBranchDelete = async (event: React.MouseEvent, documentId: string, branchId: string) => {
        event.stopPropagation();
        try {
            // Remove the branch from the database
            await remove(ref(rtdb, `documents/content/${documentId}/branches/${branchId}`));
            await remove(ref(rtdb, `documents/metadata/${documentId}/branches/${branchId}`));

            // Update the local state (remove the branch from the list)
            setBranches(branches.filter((branch) => branch.id !== branchId));
        } catch (error) {
            console.error("Error deleting branch:", error);
        }
    };

    const handleVersionDelete = async (event: React.MouseEvent, documentId: string, branchId: string, versionId: string) => {
        event.stopPropagation();
        try {
            // Remove the version from the database
            await remove(ref(rtdb, `documents/content/${documentId}/branches/${branchId}/versions/${versionId}`));
            await remove(ref(rtdb, `documents/metadata/${documentId}/branches/${branchId}/versions/${versionId}`));

            // Update the local state (remove the version from the list)
            setVersions(versions.filter((version) => version.id !== versionId));
        } catch (error) {
            console.error("Error deleting version:", error);
        }
    };

    const handleAddBranch = async (documentId: string, branchName: string) => {
        try {
            // Create a new branch with the given name
            const newBranchRef = push(ref(rtdb, `documents/metadata/${documentId}/branches`));

            if (!newBranchRef.key) {
                console.error("Error: newBranchRef.key is null");
                return;
            };
            // Create the initial version
            const newVersionRef = push(ref(rtdb, `documents/metadata/${documentId}/branches/${newBranchRef.key}/versions`));

            // Execute all updates simultaneously using Promise.all()
            await Promise.all([
                // Update the branch metadata
                set(ref(rtdb, `documents/metadata/${documentId}/branches/${newBranchRef.key}`), {
                    id: newBranchRef.key,
                    title: branchName,
                    createdAt: serverTimestamp(),
                    last_updated: serverTimestamp(),
                }),
                // Update the initial version
                set(ref(rtdb, `documents/metadata/${documentId}/branches/${newBranchRef.key}/versions/${newVersionRef.key}`), {
                    id: newVersionRef.key,
                    title: "Initial version",
                    createdAt: serverTimestamp(),
                }),
                // Update the document last_updated property
                set(ref(rtdb, `documents/metadata/${documentId}/last_updated`), serverTimestamp()),
                // Set the content for the new version
                set(ref(rtdb, `documents/content/${documentId}/branches/${newBranchRef.key}/versions/${newVersionRef.key}`), ""),
            ]);

            setAddBranchPopup(false);
            setBranchName('');
        } catch (error) {
            console.error("Error adding branch:", error);
        }
    };

    const handleAddVersion = async (documentId: string, branchId: string, versionName: string) => {
        try {
            // Create a new version with the given name
            const newVersionRef = push(ref(rtdb, `documents/metadata/${documentId}/branches/${branchId}/versions`));

            // Define the updates
            const updates: any = {
                [`documents/metadata/${documentId}/branches/${branchId}/versions/${newVersionRef.key}`]: {
                    title: versionName,
                    createdAt: serverTimestamp(),
                },
                [`documents/content/${documentId}/branches/${branchId}/versions/${newVersionRef.key}`]: "",
                [`documents/metadata/${documentId}/last_updated`]: serverTimestamp(),
                [`documents/metadata/${documentId}/branches/${branchId}/last_updated`]: serverTimestamp(),
            };

            // Execute the updates atomically
            await update(ref(rtdb), updates);

            setAddVersionPopup(false);
            setVersionName('');
        } catch (error) {
            console.error("Error adding version:", error);
        }
    };

    return (
        <BodyLayout>
            <Box sx={{
                pt: 10,
                '& .MuiListItemButton-root': {
                    borderRadius: '12px'
                }
            }}>
            <Stack spacing={2}>
                <Stack direction="row" spacing={2} sx={{ mb: 0 }}>
                    <Button
                        variant="outlined"
                        sx={{
                            width: '150px',
                            height: '150px',
                            backgroundColor: '#f5f5f5',
                            border: '0px solid #f5f5f5',
                            '&:hover': {
                                backgroundColor: '#f5f5f5',
                                border: '0px solid #f5f5f5',
                            },
                        }}
                        color="primary"
                        onClick={() => setNewDocPopup(true)}
                    >
                        Crear documento
                    </Button>
                    {
                        view === 'branches' &&
                            <Button
                                variant="outlined"
                                sx={{
                                    width: '150px',
                                    height: '150px',
                                    backgroundColor: '#f5f5f5',
                                    border: '0px solid #f5f5f5',
                                    '&:hover': {
                                        backgroundColor: '#f5f5f5',
                                        border: '0px solid #f5f5f5',
                                    },
                                }}
                                color="primary"
                                onClick={() => setAddBranchPopup(true)}
                            >
                                Crear alternativa
                            </Button>
                    }
                    {
                        view === 'versions' &&
                            <Button
                                variant="outlined"
                                sx={{
                                    width: '150px',
                                    height: '150px',
                                    backgroundColor: '#f5f5f5',
                                    border: '0px solid #f5f5f5',
                                    '&:hover': {
                                        backgroundColor: '#f5f5f5',
                                        border: '0px solid #f5f5f5',
                                    },
                                }}
                                color="primary"
                                onClick={() => setAddVersionPopup(true)}
                            >
                                Crear versión
                            </Button>
                    }
                </Stack>

                {
                    view === 'documents' && <Box sx={{ height: '51px' }} />
                }

                {
                    (view === 'branches' || view === 'versions') &&
                        <Box sx={{}}>
                            <Button onClick={handleBackClick} startIcon={<ArrowBackIosNewRoundedIcon />}>
                                Atrás
                            </Button>
                        </Box>
                }

                {view === "documents" && (
                    <>
                        <List
                            subheader={
                                <ListSubheader component="div" id="nested-list-subheader">
                                    Documentos
                                </ListSubheader>
                            }
                        >
                            {
                                documents === null &&
                                    <Skeleton animation="pulse" variant="rectangular" width={'100%'} height={56} sx={{
                                        borderRadius: '12px',
                                    }} />
                            }
                            { documents !== null && documents.map((doc) => (
                                <ListItemButton
                                    key={doc.id}
                                    onClick={() => handleDocumentClick(doc)}
                                >
                                    <ListItemText primary={doc.title} />
                                    <IconButton
                                        edge="end"
                                        aria-label="delete"
                                        onClick={(event) => handleDocumentDelete(event, doc.id)}
                                    >
                                        <DeleteOutlineRoundedIcon />
                                    </IconButton>
                                </ListItemButton>
                            ))}
                        </List>
                    </>
                )}

                {view === "branches" && branches && currentDocument && (
                <>
                    <List
                        subheader={
                            <ListSubheader component="div" id="nested-list-subheader">
                                Documentos <ArrowForwardIosRoundedIcon sx={{fontSize: 'inherit', mb: '-3px'}} /> Alternativas
                            </ListSubheader>
                        }
                    >
                    {branches.map((branch) => (
                        <ListItemButton
                            key={branch.id}
                            onClick={() => handleBranchClick(branch)}
                        >
                            <ListItemText primary={branch.title} />
                            <IconButton
                                edge="end"
                                aria-label="delete"
                                onClick={(event) => handleBranchDelete(event, currentDocument.id, branch.id)}
                            >
                                <DeleteOutlineRoundedIcon />
                            </IconButton>
                        </ListItemButton>
                    ))}
                    </List>
                </>
                )}

                {view === "versions" && currentDocument && currentBranch && (
                <>
                    <List
                        subheader={
                            <ListSubheader component="div" id="nested-list-subheader">
                                Documentos <ArrowForwardIosRoundedIcon sx={{fontSize: 'inherit', mb: '-3px'}} /> Alternativas <ArrowForwardIosRoundedIcon sx={{fontSize: 'inherit', mb: '-3px'}} /> Versiones
                            </ListSubheader>
                        }
                    >
                    {versions.map((version) => (
                        <ListItemButton
                            key={version.id}
                            onClick={() => handleVersionClick(version.id)}
                        >
                            <ListItemText primary={version.title} />
                                <IconButton
                                    edge="end"
                                    aria-label="delete"
                                    onClick={(event) => handleVersionDelete(event, currentDocument.id, currentBranch.id, version.id)}
                                >
                                <DeleteOutlineRoundedIcon />
                            </IconButton>
                        </ListItemButton>
                    ))}
                    </List>
                </>
                )}
            </Stack>

            <Dialog open={newDocPopup} onClose={() => setNewDocPopup(false)}>
                <DialogTitle>Crear nuevo documento</DialogTitle>
                <form onSubmit={handleConfirm}>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="documentName"
                            label="Título"
                            type="text"
                            fullWidth
                            value={docName}
                            onChange={(e) => setDocName(e.target.value)}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setNewDocPopup(false)} color="primary" fullWidth>
                            Cancelar
                        </Button>
                        <Button type="submit" color="primary" fullWidth>
                            Crear
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>

            <Dialog open={addBranchPopup} onClose={() => setAddBranchPopup(false)}>
                <DialogTitle>Añadir alternativa</DialogTitle>
                {/* <form onSubmit={(e) => {
                    e.preventDefault();
                    (e.currentTarget.ownerDocument.activeElement as HTMLElement)?.blur();
                    if (!currentDocument) return;
                    handleAddBranch(currentDocument.id, branchName);
                }}> */}
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="branchName"
                            label="Título"
                            type="text"
                            fullWidth
                            value={branchName}
                            onChange={(e) => setBranchName(e.target.value)}
                            onKeyUp={(e) => {
                                if (e.key === 'Enter') {
                                    console.log('enter')
                                    e.preventDefault();
                                    e.stopPropagation();
                                    e.currentTarget.blur();
                                    // @ts-ignore
                                    e.target.blur();
                                    if (!currentDocument) return;
                                    handleAddBranch(currentDocument.id, branchName);
                                    return
                                }
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setAddBranchPopup(false)} color="primary">
                            Cancelar
                        </Button>
                        <Button type="submit" color="primary">
                            Crear
                        </Button>
                    </DialogActions>
                {/* </form> */}
            </Dialog>

            <Dialog open={addVersionPopup} onClose={() => setAddVersionPopup(false)}>
                <DialogTitle>Añadir versión</DialogTitle>
                <form onSubmit={(e) => {
                    e.preventDefault();
                    if (!currentDocument || !currentBranch || !currentBranch.id) {
                        console.log({currentDocument, currentBranch, id: currentBranch?.id})
                        return;
                    };
                    handleAddVersion(currentDocument.id, currentBranch.id, versionName);
                    (e.currentTarget.ownerDocument.activeElement as HTMLElement)?.blur();
                }}>
                    <DialogContent>
                        {/* <DialogContentText>
                            Enter the name for the new version
                        </DialogContentText> */}
                        <TextField
                            autoFocus
                            margin="dense"
                            id="versuonName"
                            label="Título"
                            type="text"
                            fullWidth
                            value={versionName}
                            onChange={(e) => setVersionName(e.target.value)}
                        />
                    </DialogContent>
                </form>
                <DialogActions>
                    <Button onClick={() => setAddVersionPopup(false)} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={() => {
                        if (!currentDocument || !currentBranch || !currentBranch.id) {
                            console.log({currentDocument, currentBranch, id: currentBranch?.id})
                            return;
                        };
                        handleAddVersion(currentDocument.id, currentBranch.id, versionName)
                    }} color="primary">
                        Crear
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    </BodyLayout>
    )
}
