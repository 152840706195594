import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import React, { useState } from 'react'
import { tahmee_james } from './tahmee/james'

import { Controller, useForm } from "react-hook-form";
import TextField from '@mui/material/TextField'

export default function InterlinearB() {
    const { handleSubmit, reset, control } = useForm();
    const onSubmit = (data: any) => console.log(data);

    const [ edit, setEdit ] = useState(false)

    const props = {inputProps:{autoComplete: 'off'}}



    return (
        <>
            <Container>
                {
                    tahmee_james.map((verse_data, i) => {
                        const { verse } = verse_data
                        // const { text, word, number } = verse
                        return (
                            <Box component='span' key={i + 'a'}>
                                {
                                    verse.map((verse_content, i) => {
                                        const { text, word, number } = verse_content
                                        return (
                                            <Box
                                                key={i}
                                                sx={{
                                                    display: 'inline-flex',
                                                    flexDirection: 'column',
                                                    px: 1,
                                                    py: 1,
                                                    mr: '4px',
                                                    borderBottom: '1px solid lightgray',
                                                    '& .MuiTypography-root': {
                                                    fontFamily: "Inter, 'Piazzola', 'Source Sans 3', Inter !important",
                                                    fontSize: '2.1rem'
                                                    }
                                                }}
                                            >
                                                <Box>
                                                    <Typography>
                                                        {word}
                                                    </Typography>
                                                </Box>
                                                <Box>
                                                    <Typography>
                                                        {text}
                                                    </Typography>
                                                </Box>
                                                <Box>
                                                    <Typography>
                                                        {text}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        )
                                    })
                                }
                            </Box>
                        )
                    })
                }
            </Container>
            <Box>
                {
                    !edit ? null :
                        <Box>
                            <Controller
                                name={"Texto"}
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <TextField onChange={onChange} value={value} label={"Texto"} {...props} />
                                    )}
                                    />
                        </Box>
                }
            </Box>
        </>
    )
}

