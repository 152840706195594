import React from 'react'
import { EditProvider } from '../../contexts/EditContext'
import DocumentEditor from './DocumentEditor'

export default function EditorWrapper() {
    return (
        <EditProvider>
            <DocumentEditor />
        </EditProvider>
    )
}
