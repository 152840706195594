import React, { PropsWithChildren } from 'react'
import Footnote from './FootnotesA'
import { iFootnote } from './misc'

export type iRefProps = {
    d?: iFootnote, // data
    style?: boolean,
    jesus?: boolean,
    bible?: boolean,
    markless?: boolean,
}

export default function Ref(props: PropsWithChildren<iRefProps>) {
    const { children, d, style, jesus, bible, markless } = props
    return (
        <span className={
            `
                TestHighlightNote
                ${style ? 'InlineQuoteStyle ' : ''}
                ${jesus ? 'JesusQuoteStyle ' : ''}
                ${bible ? 'BibleQuoteStyle ' : ''}
            `
        }>
            {children}
            {d && !markless && <Footnote type={d?.type || 'citation'}>{d.content}</Footnote>}
        </span>
    )
}