import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { ReactElement, ReactNode, useEffect, useState } from 'react'
import ToolbarGeneralOne from '../../../misc/ToolbarGeneralOne'
import BlogTestK from '../styles/BlogStyleK'
import Author from '../Author'
import { BlogHelmet, iFootnote, iFootnotes } from '../misc'
import Notas from '../Notas'
import Quote from '../Quote'
import Ref from '../Ref'
import Stack from '@mui/material/Stack'
import Container from '@mui/material/Container'
import FetchVerses from '../FetchVerses'
import BibleRef from '../BibleRef'
import '../Blog.css';
import NegativeListItem from '../NegativeListItem'
import NegativeListWrapper from '../NegativeListWrapper'
import NegativeListHeader from '../NegativeListHeader'
import BlogStyleR from '../styles/BlogStyleR'
import RecommendedBox from '../RecommendedBox'
import High from '../High'

const badge = 'Estudio'
const title = 'Fe vs. Obras'
const subtitle = 'Análisis de aparentes contradicciones.'

export default function FeVsObras() {

    const Body = (): ReactElement => {

        const fn: iFootnotes = {
            a: {
                content: '',
                type: 'comment',
            },
        }

        return (
            <Box
                className='ArticleBody'
                sx={{
                    // 'b': {
                        // fontWeight: 500,
                        // fontWeight: 600,
                        // fontFamily: 'Satoshi, sans-serif'
                    // }
                }}
            >
                <Container>
                    <Typography variant='h2'>
                        Introducción
                    </Typography>    
                    <Typography>
                    La relación dialéctica entre los autores del Nuevo Testamento y sus correspondientes entornos proveen una enseñanza muy exacta sobre la salvación con fe. Este breve estudio consiste en una revisión de los textos bíblicos que hablan sobre fe, obras, y salvación, comentando los textos más significativos y anotando las conclusiones más importantes.
                    </Typography>
                    <Typography>
                        Se intentará demostrar que:
                    </Typography>
                    <Typography component='ul'>
                        <Typography component='li'>
                            <Typography>
                                Jesús, Pablo y Santiago no se contradicen uno a otro y los tres coinciden en cómo relacionan los conceptos de fe-obras-salvación. 
                            </Typography>
                        </Typography>
                        <Typography component='li'>
                            <Typography>
                                No hace falta recurrir a acrobacias semánticas o hermenéuticas para reconciliar aparentes contradicciones.
                            </Typography>
                        </Typography>
                    </Typography>
                    <Typography variant='h3'>
                        Pisteuo vs. Pistis
                    </Typography>
                    <Typography>
                        Aunque en español pueden tener connotaciones distintas, en el lenguaje e idioma bíblico “creer” y “fe” son sinónimos.
                    </Typography>
                    <Typography>
                        En griego, las palabras son pisteuo (πιστεύω, verbo, creer) y pistis (πίστις, sustantivo, fe). La única diferencia entre estas es que una es verbo y la otra es sustantivo. En español no hay forma de traducirlas a ambas con la misma raíz porque no tenemos verbo de la palabra “fe” ni sustantivo de la palabra “creer” (“creencia” no resulta en una buena traducción, p. ej. “tener creencia” no es castellano normal, ni transmite la idea claramente).
                    </Typography>
                    <Typography>
                        Todo esto es significativo porque cuando distintos textos se refieren a que “debemos tener fe” o “debemos creer” es importante entender que significan exactamente lo mismo.
                    </Typography>
                    <Typography>
                        Cualquier distinción semántica entre los términos “pistis” y “pisteuo” es completamente fabricada.
                    </Typography>
                    <Typography variant='h3'>
                        Obras de la ley
                    </Typography>
                    <Typography>
                        Se excluyen muchos textos bíblicos que hablan explícitamente de las obras de la ley. La primera razón es que tratan de un tema distinto al de este artículo:
                    </Typography>
                    <Typography component='ul'>
                        <Typography component='li'>
                            <Typography>
                                Por un lado, tenemos a “obras de la ley” que se refiere a las obras motivadas por cumplir la ley mosaica.
                            </Typography>
                        </Typography>
                        <Typography component='li'>
                            <Typography>
                                Por otro lado, tenemos a “buenas obras”, “obras de la fe”, “obras de obediencia” u “obras” que se refieren a las obras motivadas por obedecer a Dios en general. De este tipo de obras se trata este artículo.
                            </Typography>
                        </Typography>
                    </Typography>
                    {/* </Typography> */}
                    <Typography>
                        La segunda razón es que el tema “obras de la ley” ya está zanjado.
                    </Typography>
                    <Typography>
                        Cuando hablamos de “ser salvos por las obras de la ley” estamos hablando de “ser salvos por el cumplimiento de la ley”.
                    </Typography>
                    <Typography>
                        Está ampliamente establecido que es imposible ser salvos por cumplir la ley. Ni siquiera Santiago, quien hace apología de las obras, se atreve a discutir esto. Al contrario, afirma que “cualquiera que guardare toda la ley, pero ofendiere en un punto, se hace culpable de todos” lo cual se alinea con las enseñanzas de Pablo y lleva a la inevitable conclusión de que todos somos condenados según la ley, porque todos hemos incumplido por lo menos un punto.
                    </Typography>
                    <Typography>
                        La tercera razón es que el tema “el rol de la ley mosaica para la salvación en el Nuevo Pacto” es muy extenso. Añadirlo a este artículo implicaría una perdida de claridad y la adición de muchísimo contenido.
                    </Typography>
                    <Typography>
                        Por estas tres razones se excluyen muchos versículos que hablan de las “obras de la ley”, aunque no todos, ya que algunos dan información sobre el rol de la fe en la salvación y esto es pertinente al tema.
                    </Typography>
                    {/* <Typography component='ul'>
                        <Typography component='li'>
                            <Typography>
                            </Typography>
                        </Typography>
                    </Typography> */}
                    <Typography variant='h2'>
                        Jesús
                    </Typography>
                    <Typography>
                        Comenzamos revisando lo que ha dicho Jesús sobre el tema y luego continuamos analizando cada autor. Los versículos están agrupados según la perspectiva que ofrecen del tema.
                    </Typography>
                    <Typography variant='h3'>
                        Fe para salvación
                    </Typography>
                    <Typography className='SatoshiFeatureSettings' variant='h4'>
                    Juan 3:16&nbsp;    
                    </Typography>
                    <Typography>
                        <Quote>Porque de tal manera amó Dios al mundo, que ha dado a su Hijo unigénito, para que todo aquel que en él cree, no se pierda, mas tenga vida eterna.
                    </Quote></Typography>
                    <Typography className='SatoshiFeatureSettings' variant='h4'>
                    Juan 3:36&nbsp;    
                    </Typography>
                    <Typography>
                        <Quote>El que cree en el Hijo tiene vida eterna; pero el que rehúsa creer en el Hijo no verá la vida, sino que la ira de Dios está sobre él.
                    </Quote></Typography>
                    <Typography className='SatoshiFeatureSettings' variant='h4'>
                    Juan 14:6&nbsp;    
                    </Typography>
                    <Typography>
                        <Quote>Jesús le dijo: Yo soy el camino, y la verdad, y la vida; nadie viene al Padre, sino por mí.
                    </Quote></Typography>
                    <Typography className='SatoshiFeatureSettings' variant='h4'>
                    Juan 11:25-26&nbsp;    
                    </Typography>
                    <Typography>
                        <Quote>Le dijo Jesús: Yo soy la resurrección y la vida; el que cree en mí, aunque esté muerto, vivirá. Y todo aquel que vive y cree en mí, no morirá eternamente. ¿Crees esto?
                    </Quote></Typography>
                    <Typography className='SatoshiFeatureSettings' variant='h4'>
                    Juan 6:40&nbsp;    
                    </Typography>
                    <Typography>
                        <Quote>Y esta es la voluntad del que me ha enviado: Que todo aquél que ve al Hijo, y cree en él, tenga vida eterna; y yo le resucitaré en el día postrero.
                    </Quote></Typography>
                    <Typography className='SatoshiFeatureSettings' variant='h4'>
                    Juan 12:36&nbsp;    
                    </Typography>
                    <Typography>
                        <Quote>Entre tanto que tenéis la luz, creed en la luz, para que seáis hijos de luz. Estas cosas habló Jesús, y se fue y se ocultó de ellos.
                    </Quote></Typography>
                    <Typography className='SatoshiFeatureSettings' variant='h4'>
                    Juan 12:44-48&nbsp;    
                    </Typography>
                    <Typography>
                        <Quote><span className='VN'> 44 </span>Jesús clamó y dijo: El que cree en mí, no cree en mí, sino en el que me envió;<span className='VN'> 45 </span>y el que me ve, ve al que me envió.<span className='VN'> 46 </span>Yo, la luz, he venido al mundo, para que todo aquel que cree en mí no permanezca en tinieblas.<span className='VN'> 47 </span>Al que oye mis palabras, y no las guarda, yo no le juzgo; porque no he venido a juzgar al mundo, sino a salvar al mundo.<span className='VN'> 48 </span>El que me rechaza, y no recibe mis palabras, tiene quien le juzgue; la palabra que he hablado, ella le juzgará en el día postrero.</Quote>
                    </Typography>
                    <Typography variant='h3'>
                        Sin fe no se puede ser salvo
                    </Typography>
                    <Typography className='SatoshiFeatureSettings' variant='h4'>
                    Juan 3:18&nbsp;    
                    </Typography>
                    <Typography>
                        <Quote>El que en él cree, no es condenado; pero el que no cree, ya ha sido condenado, porque no ha creído en el nombre del unigénito Hijo de Dios.
                    </Quote></Typography>
                    <Typography className='SatoshiFeatureSettings' variant='h4'>
                    Marcos 16:16&nbsp;    
                    </Typography>
                    <Typography>
                        <Quote>El que creyere y fuere bautizado, será salvo; mas el que no creyere, será condenado.
                    </Quote></Typography>
                    <Typography className='SatoshiFeatureSettings' variant='h4'>
                    Juan 8:24&nbsp;    
                    </Typography>
                    <Typography>
                        <Quote>Por eso os dije que moriréis en vuestros pecados; porque si no creéis que yo soy, en vuestros pecados moriréis.</Quote>
                    </Typography>
                    <Typography variant='h3'>
                        Obras para salvación
                    </Typography>
                    <Typography className='SatoshiFeatureSettings' variant='h4'>
                    Mateo 7:21&nbsp;    
                    </Typography>
                    <Typography>
                        <Quote>No todo el que me dice: Señor, Señor, entrará en el reino de los cielos, sino el que hace la voluntad de mi Padre que está en los cielos.</Quote>
                    </Typography>
                    <Typography className='SatoshiFeatureSettings' variant='h4'>
                    Juan 15:1-2&nbsp;    
                    </Typography>
                    <Typography>
                        <Quote>Yo soy la vid verdadera, y mi Padre es el labrador. Todo pámpano que en mí no lleva fruto, lo quitará; y todo aquel que lleva fruto, lo limpiará, para que lleve más fruto.</Quote>
                    </Typography>
                    <Typography className='SatoshiFeatureSettings' variant='h4'>
                    Juan 8:31&nbsp;    
                    </Typography>
                    <Typography>
                        <Quote>Dijo entonces Jesús a los judíos que habían creído en él: Si vosotros permaneciereis en mi palabra, seréis verdaderamente mis discípulos;</Quote>
                    </Typography>
                    <Typography className='SatoshiFeatureSettings' variant='h4'>
                    Marcos 10:17-22&nbsp;    
                    </Typography>
                    <Typography>
                        <Quote><span className='VN'> 17 </span>Al salir él para seguir su camino, vino uno corriendo, e hincando la rodilla delante de él, le preguntó: Maestro bueno, ¿qué haré para heredar la vida eterna?<span className='VN'> 18 </span>Jesús le dijo: ¿Por qué me llamas bueno? Ninguno hay bueno, sino solo uno, Dios.<span className='VN'> 19 </span>Los mandamientos sabes: No adulteres. No mates. No hurtes. No digas falso testimonio. No defraudes. Honra a tu padre y a tu madre.<span className='VN'> 20 </span>Él entonces, respondiendo, le dijo: Maestro, todo esto lo he guardado desde mi juventud.<span className='VN'> 21 </span>Entonces Jesús, mirándole, le amó, y le dijo: Una cosa te falta: anda, vende todo lo que tienes, y dalo a los pobres, y tendrás tesoro en el cielo; y ven, sígueme, tomando tu cruz.<span className='VN'> 22 </span>Pero él, afligido por esta palabra, se fue triste, porque tenía muchas posesiones.</Quote>
                    </Typography>
                    <Typography component='ul'>
                        <Typography component='li'>
                            <Typography>
                            Hemos visto que según Jesús la fe es necesaria para la salvación (p. ej.: Jn 3:16) y este texto <High>no</High> afirma lo contrario. Claramente Marcos retrata a este hombre como alguien que tiene fe, aclarando que se arrodilló delante de Jesús y lo llamó “maestro bueno”. Viendo su fe, Jesús le recuerda que ya conoce parte de la respuesta a su propia pregunta, porque conoce los mandamientos. Por último, considerando su fe, y que había cumplido los mandamientos, Jesús le pide algo que el hombre no estaba dispuesto a hacer (indicando que esto era necesario para su salvación). Por lo tanto, en este texto se afirma la necesidad de las obras, pero no se niega la necesidad de la fe.
                            </Typography>
                        </Typography>
                    </Typography>
                    <Typography variant='h3'>
                    Contra la necesidad de las obras
                    </Typography>
                    <Typography className='SatoshiFeatureSettings' variant='h4'>
                    Lc 23:43&nbsp;    
                    </Typography>
                    <Typography>
                        <Quote>Entonces Él le dijo: En verdad te digo: hoy estarás conmigo en el paraíso.</Quote>
                    </Typography>
                    <Typography component='ul'>
                        <Typography component='li'>
                            <Typography>
                                En esta historia encontramos un ejemplo de salvación por fe. El ladrón no tuvo oportunidad de realizar todas las “buenas obras” u “obras de la fe” que Dios desea, pero su fe y su confesión fueron suficientes para ser salvo.
                            </Typography>
                        </Typography>
                        <Typography component='li'>
                            <Typography>
                                Alguien podría sugerir que esta persona, siendo judía, habría cumplido la ley y eso lo “ayudaría” a ser salvo. Sin embargo la Biblia dice explícitamente que era ladrón, por lo que había incumplido la ley y esta lo condenaba (Sgo 2:10). Este es un ejemplo perfecto de alguien que pecó y fue salvo por encontrarse con Jesús y creer en Él.
                            </Typography>
                        </Typography>
                        <Typography component='li'>
                            <Typography>
                                La primera conclusión que esto implica es que las obras de la fe se demandan respecto a las posibilidades del creyente. Si bien hay muchas obras de fe prescriptas en la biblia (confesión, oración, lectura de las escrituras, etc.) solo son requeridas en la medida que la persona pueda realizarlas. Por ejemplo, aunque confesar la fe es necesario para ser salvo (Rom 10:10) sería ridículo afirmar que las personas mudas no pueden ser salvas.
                            </Typography>
                        </Typography>
                        <Typography component='li'>
                            <Typography>
                                La segunda conclusión que esto implica es que, como regla, las obras son necesarias, pero hay excepciones si la persona no puede actuar por una u otra razón. Por lo tanto, técnicamente la única parte indispensable para ser salvos es la fe. No hay forma de negar esto, pero recordemos que estamos refiriéndonos a situaciones excepcionales.
                            </Typography>
                        </Typography>
                    </Typography>
                    <Typography className='SatoshiFeatureSettings' variant='h4'>
                    Juan 6:28-29&nbsp;    
                    </Typography>
                    <Typography>
                        <Quote><span className='VN'> 28 </span>Entonces le dijeron: ¿Qué debemos hacer para poner en práctica las obras de Dios?<span className='VN'> 29 </span>Respondió Jesús y les dijo: Esta es la obra de Dios, que creáis en el que él ha enviado.</Quote>
                    </Typography>
                    <Typography variant='h2'>
                        Santiago
                    </Typography>
                    <Typography variant='h3'>
                        Santiago 1:22-27
                    </Typography>
                    <Typography>
                        <Quote>
                            <span className='VN'> 22 </span>Pero sed hacedores de la palabra, y no tan solamente oidores, engañándoos a vosotros mismos.<span className='VN'> 23 </span>Porque si alguno es oidor de la palabra pero no hacedor de ella, este es semejante al hombre que considera en un espejo su rostro natural.<span className='VN'> 24 </span>Porque él se considera a sí mismo, y se va, y luego olvida cómo era.<span className='VN'> 25 </span>Mas el que mira atentamente en la perfecta ley, la de la libertad, y persevera en ella, no siendo oidor olvidadizo, sino hacedor de la obra, este será bienaventurado en lo que hace.
                        </Quote>
                    </Typography>
                    <Typography>
                        <Quote>
                            <span className='VN'> 26 </span>Si alguno se cree religioso entre vosotros, y no refrena su lengua, sino que engaña su corazón, la religión del tal es vana.<span className='VN'> 27 </span>La religión pura y sin mácula delante de Dios el Padre es esta: Visitar a los huérfanos y a las viudas en sus tribulaciones, y guardarse sin mancha del mundo.
                        </Quote>
                    </Typography>
                    {/* <Typography component='ul'>
                        <Typography component='li'>
                            <Typography>
                                Es destacable la estructura y las palabras que usa Santiago en este párrafo porque se repetirán más adelante cuando habla de la fe.
                            </Typography>
                            <Typography>
                                Primero menciona de una persona hipotética. Esta persona “se cree” religioso. Sin embargo, su religion es vana.
                            </Typography>
                        </Typography>
                    </Typography> */}
                    <Typography variant='h3'>
                        Santiago 3:8-26
                    </Typography>
                    <Typography>
                        <Quote>
                            <span className='VN'> 8 </span>Si en verdad cumplís la ley real, conforme a la Escritura: Amarás a tu prójimo como a ti mismo, bien hacéis;<span className='VN'> 9 </span>pero si hacéis acepción de personas, cometéis pecado, y quedáis convictos por la ley como transgresores.<span className='VN'> 10 </span>Porque cualquiera que guardare toda la ley, pero ofendiere en un punto, se hace culpable de todos.<span className='VN'> 11 </span>Porque el que dijo: No cometerás adulterio, también ha dicho: No matarás. Ahora bien, si no cometes adulterio, pero matas, ya te has hecho transgresor de la ley.<span className='VN'> 12 </span>Así hablad, y así haced, como los que habéis de ser juzgados por la ley de la libertad.<span className='VN'> 13 </span>Porque juicio sin misericordia se hará con aquel que no hiciere misericordia; y la misericordia triunfa sobre el juicio.
                        </Quote>
                    </Typography>
                    <Typography component='ul'>
                        <Typography component='li'>
                            <Typography>
                                Santiago hace una distinción entre la ley “real” (3:8), “de la libertad” (1:12, 25) y/o “perfecta” (1:25) y otra ley (presumiblemente la ley mosaica). Esta ley de la libertad en parte incluye la ley mosaica pero no se limita a ella. Omitir esta distinción puede llevar a una aparente tensión con Pablo (p. ej. Rom 3:20).
                            </Typography>
                        </Typography>
                        <Typography component='li'>
                            <Typography>
                                Santiago explica que incumplir un punto de la ley nos hace transgresores de la ley, por lo que es imposible cumplir la ley o ser salvo por hacer las obras de la ley (Santiago 3:10-11). Esto se alinea con las enseñanzas de Pablo al respecto (p. ej. Rom 3:20) y es importante porque establece que Santiago de ninguna forma cree que es posible la justificación solo por obras.
                            </Typography>
                        </Typography>
                    </Typography>
                    <Typography>
                        <Quote>
                            <span className='VN'> 14 </span>Hermanos míos, ¿de qué aprovechará si alguno dice que tiene fe, y no tiene obras? ¿Podrá la fe salvarle?<span className='VN'> 15 </span>Y si un hermano o una hermana están desnudos, y tienen necesidad del mantenimiento de cada día,<span className='VN'> 16 </span>y alguno de vosotros les dice: Id en paz, calentaos y saciaos, pero no les dais las cosas que son necesarias para el cuerpo, ¿de qué aprovecha?<span className='VN'> 17 </span>Así también la fe, si no tiene obras, es muerta en sí misma.
                        </Quote>
                    </Typography>
                    <Typography>
                        Esta sección puede interpretarse de dos formas ligeramente distintas. Ambas proveen formas distintas de reconciliar las aparentes contradicciones con otros textos bíblicos, pero hay otras cuatro formas que funcionan con ambas interpretaciones (ver comentario en Efesios 2:8-9).
                    </Typography>
                        <Typography component='ul'>
                            <Typography component='li'>
                                <Typography>
                                    <b>Interpretación #1</b>: Santiago llama a la fe/religión que no es acompañada con acciones “vana” (1:26), “imperfecta” (3:22) y “muerta” (3:26). Bajo este concepto, se entiende que hay otra clase de fe completa, perfecta y viva que, por definición, siempre está acompañada por obras y es suficiente para salvar. Una forma de reconciliar esta enseñanza con las de Pablo es que ambos autores se refieren a distintos tipos de fe. Pablo se refiere a que la fe completa, perfecta, y viva, salva; mientras que Santiago se refiere a que la fe vana, imperfecta y muerta no salva.
                                </Typography>
                            </Typography>
                            <Typography component='li'>
                                <Typography>
                                    <b>Interpretación #2</b>: Santiago habla de una persona que “se cree” (1:26) religiosa, pero su religion es vana y de una persona que “dice tener fe” (3:14) pero su fe esta muerta. Tanto la estructura como las expresiones de ambos párrafos son muy similares, y es notable que podría haber dicho “una persona religiosa” y “una persona que tiene fe” pero en ambas ocasiones evitó meticulosamente realizar esas afirmaciones. Tal vez esta sea una forma de expresar que las declaraciones de estas personas estaban sujetas a cuestionamiento porque tenían fe, pero una fe muerta (como afirma la <i>Interpretación #1</i>). También podría sear una forma educada de expresarse, evitando ser tan chocante o hasta impertinente (esto también estaría alineado con la <i>Interpretación #1</i>). Pero también podría indicar que la persona que “se cree” religiosa en realidad no lo es, y la persona que “dice tener fe” en realidad no la tiene. Bajo este concepto, se entiende que la persona que no tiene obras no es salva porque no tiene fe. Una forma de reconciliar esta enseñanza con las de Pablo es que este último tiene un enfoque teórico, mientras que Santiago tiene un enfoque práctico. En teoría, técnicamente, lo único que salva es la fe, como afirma Pablo. Pero en la práctica no hay fe si no hay obras, por lo que estas son igual de importantes y necesarias que la fe, como afirma Santiago. Otra forma de expresarlo es que, según Santiago, no tener obras es sinónimo de no tener fe, y no tener fe es sinónimo de no tener salvación, por lo que no tener obras es sinónimo de no tener salvación. Pablo por su parte explica que técnicamente lo único que salva es la fe, aunque en la práctica sea igual de importante tener obras.
                                </Typography>
                                <Typography>
                                    Hay una advertencia contra esta interpretación. En el próximo párrafo Santiago afirma que los demonios tienen fe y que la fe sola no salva. Estas frases estarían en conflicto con esta interpretación ya que, por ejemplo, no podría decirse que los demonios tienen fe (porque no tienen obras). Una explicación podría ser que Santiago toma una mirada práctica en este primer párrafo mientras que, en el segundo, reconoce la distinción teórica entre fe y obras para también analizar este aspecto y fortalecer su argumento desde lo práctico y lo teórico. Recordemos que los autores bíblicos suelen responder a una variedad de herejías, por lo que es común enfocar el problema desde distintas perspectivas para corregir distintas ideas o prevenir distintos contraargumentos. Sin dudas es el caso en este texto, ya que el párrafo comienza diciendo “si alguno dice tener fe” y el proximo párrafo comienza diciendo “pero alguno dirá...” por lo que ambos están diseñados como respuestas a ideas erróneas. No sería sorprendente que Santiago acepte una mala representación de su argumento (strawman) para demostrar que aún así no tiene sentido. En la actualidad diríamos “Supongamos que eso es cierto. Aún así, [...]”.
                                </Typography>
                            </Typography>
                        </Typography>
                    <Typography>
                    <Typography>
                        En síntesis, la diferencia entre ambas interpretaciones es que, en la primera, la falta de obras hace que la fe pase a estar vacía/muerta. Mientras que en la segunda, la falta de obras hace que la fe no pueda siquiera llamarse fe.
                    </Typography>
                        <Quote>
                            <span className='VN'> 18 </span>Pero alguno dirá: Tú tienes fe, y yo tengo obras. Muéstrame tu fe sin tus obras, y yo te mostraré mi fe por mis obras.<span className='VN'> 19 </span>Tú crees que Dios es uno; bien haces. También los demonios creen, y tiemblan.<span className='VN'> 20 </span>¿Mas quieres saber, hombre vano, que la fe sin obras es muerta?<span className='VN'> 21 </span>¿No fue justificado por las obras Abraham nuestro padre, cuando ofreció a su hijo Isaac sobre el altar?<span className='VN'> 22 </span>¿No ves que la fe actuó juntamente con sus obras, y que la fe se perfeccionó por las obras?<span className='VN'> 23 </span>Y se cumplió la Escritura que dice: Abraham creyó a Dios, y le fue contado por justicia, y fue llamado amigo de Dios.<span className='VN'> 24 </span>Vosotros veis, pues, que el hombre es justificado por las obras, y no solamente por la fe.<span className='VN'> 25 </span>Asimismo también Rahab la ramera, ¿no fue justificada por obras, cuando recibió a los mensajeros y los envió por otro camino?<span className='VN'> 26 </span>Porque como el cuerpo sin espíritu está muerto, así también la fe sin obras está muerta.</Quote>
                    </Typography>
                    <Typography component='ul'>
                        <Typography component='li'>
                            <Typography>
                                El comentario que hace Santiago sobre que “el hombre es justificado por las obras, y no solamente por la fe” puede malinterpretarse como que la fe es innecesaria. Por el contrario, el autor dice explícitamente “no <High>solamente</High> por la fe”, demostrando que la fe es necesaria. No hay forma de que este versículo se oponga a otros que afirman la necesidad de tener fe (p. ej. Romanos 3:28).
                            </Typography>
                        </Typography>
                        <Typography component='li'>
                            <Typography>
                                Los argumentos de Abraham y Rahab son decepcionantes porque su pertinencia es limitada. Dicho esto, Santiago estaba haciendo lo que podía con el respaldo escritural que tenía, y sus argumentos no son del todo irrelevantes. Por ejemplo, cuando Abraham está por sacrificar a su hijo, el Ángel de Jehová le dice que no lo haga “porque ya conozco que temes a Dios, por cuanto no me rehusaste tu hijo, tu único”. Esto podría interpretarse como que Dios necesitó ver las acciones de Abraham para “conocer” que lo temía, confirmando que las obras son necesarias.
                            </Typography>
                        </Typography>
                        <Typography component='li'>
                            <Typography>
                                Ninguna interpretación del texto afirma, sugiere, o implica que las obras solas, sin fe, pueden salvar. Esta sería una afirmación completamente ajena al texto y estaría en total contradicción con el resto del NT.
                            </Typography>
                        </Typography>
                        <Typography component='li'>
                            <Typography>
                                Santiago enseña que las obras son necesarias. Esto es cierto como regla general, pero no hay que olvidar las excepciones a la regla (ver comentario en Lucas 23:43).
                            </Typography>
                        </Typography>
                        <Typography component='li'>
                            <Typography>
                                Sobre la relación con versículos que afirman “somos justificados/salvos por fe” ver el comentario en Romanos 3:28.
                            </Typography>
                        </Typography>
                        <Typography component='li'>
                            <Typography>
                                Sobre la relación con Efesios 2:8-9 ver los comentarios debajo de ese texto.
                            </Typography>
                        </Typography>
                    </Typography>
                    <Typography variant='h2'>
                    Pablo
                    </Typography>
                    <Typography className='SatoshiFeatureSettings' variant='h4'>
                    Romanos 1:16-17&nbsp;    
                    </Typography>
                    <Typography>
                        <Quote><span className='VN'> 17 </span>Porque no me avergüenzo del evangelio, porque es poder de Dios para salvación a todo aquel que cree; al judío primeramente, y también al griego.<span className='VN'> 17 </span>Porque en el evangelio la justicia de Dios se revela por fe y para fe, como está escrito: Mas el justo por la fe vivirá.</Quote>
                    </Typography>
                    {/* <Typography>
                    Romanos 3:20: Porque por las obras de la ley ningún ser humano será justificado delante de Él; pues por medio de la ley viene el conocimiento del pecado
                    </Typography> */}
                    <Typography className='SatoshiFeatureSettings' variant='h4'>
                    Romanos 3:26&nbsp;    
                    </Typography>
                    <Typography>
                        <Quote>con la mira de manifestar en este tiempo su justicia, a fin de que él sea el justo, y el que justifica al que es de la fe de Jesús.</Quote>
                    </Typography>
                    <Typography className='SatoshiFeatureSettings' variant='h4'>
                    Romanos 3:28&nbsp;    
                    </Typography>
                    <Typography>
                        <Quote>Concluimos, pues, que el hombre es justificado por fe sin las obras de la ley.</Quote>
                    </Typography>
                    <Typography component='ul'>
                        <Typography component='li'>
                            <Typography>
                                Analizaremos este texto porque dice “el hombre es justificado por fe”, pero recordemos que “obras de la ley” no forman parte de este debate (ver la aclaración titulada “Obras de la ley” en la introducción).
                            </Typography>
                        </Typography>
                        <Typography component='li'>
                            <Typography>
                                Este tipo de frases donde Pablo y otros autores afirman que “somos justificados por fe” pueden parecer contradictorias a las afirmaciones de Santiago respecto a la necesidad de obras. No hay solo una sino tres formas distintas y muy sencillas de reconciliar esta posible contradicción:
                            </Typography>
                            <Typography component='ul'>
                                <Typography component='li'>
                                    {/* <Typography>
                                        <b>Opción #1</b>: Cuando los autores dicen “justificado por la fe” no están diciendo “justificado solo por la fe”. Señalar un elemento esencial de la salvación no implica que sea el único elemento.
                                    </Typography> */}
                                    <Typography>
                                        <b>Opción #1</b>: Cuando los autores dicen “justificados por fe” señalan un elemento de la justificación pero esto no implica que sea el único elemento.
                                    </Typography>
                                    <Typography>
                                        Afirmar que esta frase niega la necesidad de obras sería como afirmar que niega la necesidad de Jesús.
                                    </Typography>
                                </Typography>
                                <Typography component='li'>
                                    <Typography>
                                        <b>Opción #2</b>: Cuando los autores dicen “justificados por fe”, se están refiriendo a la fe íntegra, perfecta y viva, que por definición incluye obediencia y obras, y no se están refiriendo a la fe vana, imperfecta y muerta contra la que advierte Santiago (ver comentarios en Santiago 3:8-26).
                                    </Typography>
                                </Typography>
                                <Typography component='li'>
                                    <Typography>
                                        <b>Opción #3</b>: Cuando los autores dicen “justificados por la fe” sencillamente están empleando una sinécdoque. En lugar de decir “justificados por la fe en Jesucristo y las correspondientes obras en obediencia a Él” sencillamente resumen y dicen “justificados por la fe”, y el resto está implícito.
                                    </Typography>
                                </Typography>    
                            </Typography>
                        </Typography>
                    </Typography>
                    <Typography className='SatoshiFeatureSettings' variant='h4'>
                    Romanos 9:32&nbsp;    
                    </Typography>
                    <Typography>
                        <Quote>¿Por qué? Porque no iban tras ella por fe, sino como por obras de la ley. Tropezaron en la piedra de tropiezo</Quote>
                    </Typography>
                    <Typography className='SatoshiFeatureSettings' variant='h4'>
                    Gálatas 2:16&nbsp;    
                    </Typography>
                    <Typography>
                        <Quote>Sabiendo que el hombre no es justificado por las obras de la ley, sino por la fe de Jesucristo, nosotros también hemos creído en Jesucristo, para ser justificados por la fe de Cristo y no por las obras de la ley; por cuanto por las obras de la ley nadie será justificado.</Quote>
                    </Typography>
                    <Typography className='SatoshiFeatureSettings' variant='h4'>
                    Gálatas 3:10&nbsp;    
                    </Typography>
                    <Typography>
                        <Quote>Porque todos los que dependen de las obras de la ley están bajo maldición; pues escrito está: Maldito todo aquel que no permaneciere en todas las cosas escritas en el libro de la ley, para hacerlas.</Quote>
                    </Typography>
                    <Typography className='SatoshiFeatureSettings' variant='h4'>
                    Efesios 2:8-9&nbsp;    
                    </Typography>
                    <Typography>
                        <Quote>Porque por gracia sois salvos por medio de la fe; y esto no de vosotros, pues es don de Dios; no por obras, para que nadie se gloríe.</Quote>
                    </Typography>
                    <Typography component='ul'>
                        <Typography component='li'>
                            <Typography>
                                No solo hay una sino seis formas distintas y muy sencillas de reconciliar este texto con los otros
                            </Typography>
                            <Typography component='ul'>
                                <Typography component='li'>
                                    <Typography>
                                        <b>Opción #1</b>: no se refiere a obras de la fe sino a obras de la ley. Este definitivamente es el caso en Rom 3:20, 28, 9:32, Gál 2:16, 3:10 y es imposible afirmar con certeza que este caso es distinto.
                                    </Typography>
                                </Typography>
                                <Typography component='li'>
                                    <Typography>
                                        <b>Opción #2</b>: “no por obras” indica que no es <High>solo por obras</High>. De esta forma, no se descarta que las obras sean parte de la salvación, si bien queda descartado que sean suficiente o que sean el motivo de la salvación.
                                    </Typography>
                                </Typography>
                                <Typography component='li'>
                                    <Typography>
                                        <b>Opción #3</b>: Pablo se está refiriendo al “medio” de salvación (v. 8), por lo que indica que las obras no son el medio de la salvación, pero esto no significa que las obras no formen parte del proceso de salvación o que no sean necesarias. Simplemente no son el medio, mientras que la fe lo es. Esta opción es similar a la #2 pero con diferente fundamento. Ambas opciones pueden ser utilizadas en conjunto.
                                    </Typography>
                                </Typography>
                                <Typography component='li'>
                                    <Typography>
                                        <b>Opción #4</b>: Santiago afirma que “somos justificados por obras y no solo por fe”, pero también explica que la fe pierde su capacidad salvífica cuando no está acompañada por obras (según la <i>Interpretación #1</i> de Santiago 3:8-13). Podría afirmarse que, según Santiago, las obras no son un medio de justificación directo, sino que, al afectar la calidad de la fe se convierten en un medio de justificación indirecto (aunque requerido). De esta forma, el único medio directo de justificación es la fe (como dice Pablo) y las obras son un requerimiento para que esta sea efectiva para salvar (como dice Santiago).
                                    </Typography>
                                </Typography>
                                <Typography component='li'>
                                    <Typography>
                                        <b>Opción #5</b>: Santiago afirma que “somos justificados por obras y no solo por fe”, pero también explica que no hay fe si no hay obras (según la Interpretación #2 de Santiago 3:8-13). Podría afirmarse que, según Santiago, no tener obras es sinónimo de no tener fe, y no tener fe es sinónimo de no tener salvación, por lo que no tener obras es sinónimo de no tener salvación.
                                    </Typography>
                                </Typography>
                                <Typography component='li'>
                                    <Typography>
                                        <b>Opción #6</b>: Pablo no se está refiriendo a la regla general de “hacen falta obras para ser salvo” sino a que, en situaciones excepcionales, se puede prescindir de las obras (ver comentario sobre Lucas 23:43) mientras que la fe no es prescindible ni siquiera en excepciones.
                                    </Typography>
                                </Typography>
                            </Typography>
                        </Typography>
                    </Typography>
                    <Typography className='SatoshiFeatureSettings' variant='h4'>
                    Romanos 10:9-10&nbsp;    
                    </Typography>
                    <Typography>
                        <Quote>“Que si confesares con tu boca que Jesús es el Señor, y creyeres en tu corazón que Dios le levantó de los muertos, serás salvo. Porque con el corazón se cree para justicia, pero con la boca se confiesa para salvación”.</Quote>
                    </Typography>
                    <Typography component='ul'>
                        <Typography component='li'>
                            <Typography>
                                En este versículo, incluso Pablo, quien luchaba contra el legalismo, admite casi inadvertidamente que la mera fe no puede salvar. Esto se alinea con la frase de Santiago que no somos salvos “solo por fe” e indica que las interpretaciones absolutas y categóricas de sus enseñanzas sobre la salvación por fe no son apropiadas. No hay razón para llevar dichas frases de Pablo a un extremo irracional.
                            </Typography>
                        </Typography>
                    </Typography>
                    <Typography className='SatoshiFeatureSettings' variant='h4'>
                    Romanos 5:1-1&nbsp;    
                    </Typography>
                    <Typography>
                        <Quote><span className='VN'> 1 </span>Justificados, pues, por la fe, tenemos paz para con Dios por medio de nuestro Señor Jesucristo<span className='VN'> 2 </span>por quien también tenemos entrada por la fe a esta gracia en la cual estamos firmes, y nos gloriamos en la esperanza de la gloria de Dios.</Quote>
                    </Typography>
                    <Typography className='SatoshiFeatureSettings' variant='h4'>
                    Filipenses 3:9&nbsp;    
                    </Typography>
                    <Typography>
                        <Quote>Y ser hallado en él, no teniendo mi propia justicia, que es por la ley, sino la que es por la fe de Cristo, la justicia que es de Dios por la fe.</Quote>
                    </Typography>
                    <Typography className='SatoshiFeatureSettings' variant='h4'>
                    1 Timoteo 1:16&nbsp;    
                    </Typography>
                    <Typography>
                        <Quote>Pero por esto fui recibido a misericordia, para que Jesucristo mostrase en mí el primero toda su clemencia, para ejemplo de los que habrían de creer en él para vida eterna.</Quote>
                    </Typography>
                    <Typography className='SatoshiFeatureSettings' variant='h4'>
                    Gálatas 2:16&nbsp;    
                    </Typography>
                    <Typography>
                        <Quote>sabiendo que el hombre no es justificado por las obras de la ley, sino por la fe de Jesucristo, nosotros también hemos creído en Jesucristo, para ser justificados por la fe de Cristo y no por las obras de la ley, por cuanto por las obras de la ley nadie será justificado.</Quote>
                    </Typography>
                    <Typography className='SatoshiFeatureSettings' variant='h4'>
                    Gálatas 3:6&nbsp;    
                    </Typography>
                    <Typography>
                        <Quote>Así Abraham creyó a Dios, y le fue contado por justicia.</Quote>
                    </Typography>
                    <Typography className='SatoshiFeatureSettings' variant='h4'>
                    Gálatas 3:26&nbsp;    
                    </Typography>
                    <Typography>
                        <Quote>“Pues todos sois hijos de Dios por la fe en Cristo Jesús”.</Quote>
                    </Typography>
                    <Typography className='SatoshiFeatureSettings' variant='h4'>
                    Efesios 3:12&nbsp;    
                    </Typography>
                    <Typography>
                        <Quote>en quien tenemos seguridad y acceso con confianza por medio de la fe en él;</Quote>
                    </Typography>
                    <Typography className='SatoshiFeatureSettings' variant='h4'>
                    Filipenses 3:9&nbsp;    
                    </Typography>
                    <Typography>
                        <Quote>y ser hallado en él, no teniendo mi propia justicia, que es por la ley, sino la que es por la fe de Cristo, la justicia que es de Dios por la fe;</Quote>
                    </Typography>
                    <Typography className='SatoshiFeatureSettings' variant='h4'>
                    2 Tesalonicenses 2:13&nbsp;    
                    </Typography>
                    <Typography>
                        <Quote>Pero nosotros debemos dar siempre gracias a Dios respecto a vosotros, hermanos amados por el Señor, de que Dios os haya escogido desde el principio para salvación, mediante la santificación por el Espíritu y la fe en la verdad,</Quote>
                    </Typography>
                    <Typography className='SatoshiFeatureSettings' variant='h4'>
                    1 Timoteo 1:16&nbsp;    
                    </Typography>
                    <Typography>
                        <Quote>Pero por esto fui recibido a misericordia, para que Jesucristo mostrase en mí el primero toda su clemencia, para ejemplo de los que habrían de creer en él para vida eterna.</Quote>
                    </Typography>
                    <Typography className='SatoshiFeatureSettings' variant='h4'>
                    Tito 3:8&nbsp;    
                    </Typography>
                    <Typography>
                        <Quote>Palabra fiel es esta, y en estas cosas quiero que insistas con firmeza, para que los que creen en Dios procuren ocuparse en buenas obras. Estas cosas son buenas y útiles a los hombres.</Quote>
                    </Typography>
                    <Typography variant='h2'>
                        Lucas
                    </Typography>
                    <Typography className='SatoshiFeatureSettings' variant='h4'>
                    Hechos 10:43&nbsp;    
                    </Typography>
                    <Typography>
                        <Quote>De éste dan testimonio todos los profetas, que todos los que en él creyeren, recibirán perdón de pecados por su nombre.</Quote>
                    </Typography>
                    <Typography className='SatoshiFeatureSettings' variant='h4'>
                    Hechos 13:39&nbsp;    
                    </Typography>
                    <Typography>
                        <Quote>y que de todo aquello de que por la ley de Moisés no pudisteis ser justificados, en él es justificado todo aquel que cree.</Quote>
                    </Typography>
                    <Typography className='SatoshiFeatureSettings' variant='h4'>
                    Hechos 13:48&nbsp;    
                    </Typography>
                    <Typography>
                        <Quote>Los gentiles, oyendo esto, se regocijaban y glorificaban la palabra del Señor, y creyeron todos los que estaban ordenados para vida eterna.</Quote>
                    </Typography>
                    <Typography className='SatoshiFeatureSettings' variant='h4'>
                    Hechos 16:31&nbsp;    
                    </Typography>
                    <Typography>
                        <Quote>Ellos dijeron: Cree en el Señor Jesucristo, y serás salvo, tú y tu casa.</Quote>
                    </Typography>
                    <Typography className='SatoshiFeatureSettings' variant='h4'>
                    Hechos 26:18&nbsp;    
                    </Typography>
                    <Typography>
                        <Quote>para que abras sus ojos, para que se conviertan de las tinieblas a la luz, y de la potestad de Satanás a Dios; para que reciban, por la fe que es en mí, perdón de pecados y herencia entre los santificados.</Quote>
                    </Typography>
                    <Typography variant='h2'>
                        A los Hebreos
                    </Typography>
                    <Typography className='SatoshiFeatureSettings' variant='h4'>
                    Hebreos 4:3&nbsp;    
                    </Typography>
                    <Typography>
                        <Quote>Pero los que hemos creído entramos en el reposo, de la manera que dijo: Por tanto, juré en mi ira, No entrarán en mi reposo; aunque las obras suyas estaban acabadas desde la fundación del mundo.</Quote>
                    </Typography>
                    <Typography className='SatoshiFeatureSettings' variant='h4'>
                    Hebreos 10:38&nbsp;    
                    </Typography>
                    <Typography>
                        <Quote>Mas el justo vivirá por fe; Y si retrocediere, no agradará a mi alma.</Quote>
                    </Typography>
                    <Typography className='SatoshiFeatureSettings' variant='h4'>
                    Hebreos 10:39&nbsp;    
                    </Typography>
                    <Typography>
                        <Quote>Pero nosotros no somos de los que retroceden para perdición, sino de los que tienen fe para preservación del alma.</Quote>
                    </Typography>
                    <Typography className='SatoshiFeatureSettings' variant='h4'>
                    1 Pedro 1:5&nbsp;    
                    </Typography>
                    <Typography>
                        <Quote>que sois guardados por el poder de Dios mediante la fe, para alcanzar la salvación que está preparada para ser manifestada en el tiempo postrero.</Quote>
                    </Typography>
                    <Typography className='SatoshiFeatureSettings' variant='h4'>
                    1 Pedro 1:9&nbsp;    
                    </Typography>
                    <Typography>
                        <Quote>obteniendo el fin de vuestra fe, que es la salvación de vuestras almas.</Quote>
                    </Typography>
                    <Typography variant='h2'>
                        Juan
                    </Typography>
                    <Typography className='SatoshiFeatureSettings' variant='h4'>
                        Juan 20:31&nbsp;    
                    </Typography>
                    <Typography>
                        <Quote>Pero éstas se han escrito para que creáis que Jesús es el Cristo, el Hijo de Dios, y para que creyendo, tengáis vida en su nombre.</Quote>
                    </Typography>
                    <Typography>1 Juan 5:1&nbsp;    
                    </Typography>
                    <Typography>
                        <Quote>Todo aquel que cree que Jesús es el Cristo, es nacido de Dios; y todo aquel que ama al que engendró, ama también al que ha sido engendrado por él.</Quote>
                    </Typography>
                    <Typography className='SatoshiFeatureSettings' variant='h4'>
                        1 Juan 5:13&nbsp;    
                    </Typography>
                    <Typography>
                        <Quote>Estas cosas os he escrito a vosotros que creéis en el nombre del Hijo de Dios, para que sepáis que tenéis vida eterna, y para que creáis en el nombre del Hijo de Dios.</Quote>
                    </Typography>
                    <Typography variant='h2'>
                        Otros
                    </Typography>
                    <Typography className='SatoshiFeatureSettings' variant='h4'>
                    Isaías 28:16&nbsp;    
                    </Typography>
                    <Typography>
                        <Quote>Por tanto, así dice el Señor Dios: He aquí, pongo por fundamento en Sión una piedra, una piedra probada, angular, preciosa, fundamental, bien colocada. Él que crea en ella no será perturbado.</Quote>
                    </Typography>
                    <Typography className='SatoshiFeatureSettings' variant='h4'>
                    Mateo 21:32&nbsp;    
                    </Typography>
                    <Typography>
                        <Quote>Porque vino a vosotros Juan en camino de justicia, y no le creísteis; pero los publicanos y las rameras le creyeron; y vosotros, viendo esto, no os arrepentisteis después para creerle.</Quote>
                    </Typography>
                    <Typography variant='h2'>
                        Conclusión
                    </Typography>
                    <Typography>
                        Si la persona puede actuar y no lo hace, su fe está vacía y esta fe no puede salvar.
                    </Typography>
                    <Typography>
                        Si la persona tiene una fe íntegra, completa, y viva, que por definición está acompañada por obediencia y las obras correspondientes, esta persona es salva.
                    </Typography>
                    {/* <Typography>
                        El que obedece tiene fe, y el que tiene fe es salvo.
                    </Typography> */}
                    {/* <Notas footnotes={fn} /> */}
                    <Author />
                </Container>
            </Box>
        )
    }
    
    const body_css = {
        title: {
            fontSize: {
                us: '9rem',
                md: '11rem',
            },
            fontWeight: 600,
            ml: {
                us: '-5px',
                md: '-7px',
            },
        }
    }

    const body_css_larken = {
        title: {
            fontSize: {
                us: '9rem',
                md: '11rem',
            },
            fontWeight: 600,
            ml: {
                xs: '-0.01em',
                md: '0em',
            },
        },
        body: {
            '& .NegativeListLi': {
                pb: '0px',
            },
            '& .YellowList': {
                pb: '0px',
            },
        }
    }

    return (
    <>
      <ToolbarGeneralOne breadcrumbs={[{text: 'Artículos', path: 'articulos'}, {text: title, path: title}]} />
      <BlogHelmet title={title} />
      {/* <BlogTestK badge={badge} title={title} subtitle={subtitle} Body={<Body/>}/> */}
      {/* <StyleSwitcher badge={badge} title={title} subtitle={subtitle} Body={<Body/>} others={{
        title: {
            fontSize: {
                us: '9rem',
                md: '11rem',
            },
            ml: {
                us: '-5px',
                md: '-7px',
            },
        }
      }}/> */}
      <BlogStyleR text_font='Spline Sans Variable' display_font='Satoshi' badge={badge} title={title} subtitle={subtitle} Body={<Body />} others={body_css} />
      
      {/* <BlogStyleQ text_font='Larken' display_font='Larken' badge={badge} title={title} subtitle={subtitle} Body={<Body />} others={body_css_larken} /> */}
      {/* <FetchVerses /> */}
    </>
  )
}