import { Helmet } from 'react-helmet-async'
import Footnote from './FootnotesA'
import { PropsWithChildren } from 'react'

export type iFootnoteTypes = 'comment' | 'citation'

export type iFootnotes = {
    [key: string]: iFootnote,
}
export type iFootnote = {
  content: string | JSX.Element,
  type?: iFootnoteTypes
}

// export function setUpFootnotes(setState: React.Dispatch<any>, footnotes: iFootnote[]) {
//     setState(() => {
//         const n = {}
//         footnotes.forEach((v) => {
//           Object.assign(n, {[v.code]: <Footnote type={v.type}>{v.content}</Footnote>})
//         })
//         return n
//       })
// }

type iBlogHelmetProps = {
    title: string
}

export function BlogHelmet(props: iBlogHelmetProps) {
    const { title } = props
    return(
        <Helmet>
            {/* <title>{title} – Bani Grisson</title> */}
            <title>{title} | Bani Grisson</title>
            <style>
                {`
                    ::-webkit-scrollbar { background-color: #fff; width: 10px; }
                    ::-webkit-scrollbar-thumb { background: rgb(177, 177, 177); }
                    ::-webkit-scrollbar-thumb:hover { background: rgb(141, 141, 141); }
                `}
            </style>
        </Helmet>
    )
}

export {}