import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import React from 'react'
import { iFootnote } from './misc'

export default function Author(props: {footnotes: {[key: string]: iFootnote}}) {
    const { footnotes } = props
    return (
        <>
            <Typography variant='h2'>Notas</Typography>
            <Typography component='ol'
                sx={{
                    wordBreak: 'break-word',
                }}
            >
            {
                Object.entries(footnotes).map((thing) => {
                    return <Typography component='li'
                        sx={{
                            pb: '0.6em !important',
                        }}
                    >{thing[1].content}</Typography>
                })
            }
            </Typography>
        </>
    )
}