import Tooltip from '@mui/material/Tooltip'
import Box from '@mui/material/Box'
import React, { ReactElement, PropsWithChildren } from 'react'
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import Typography from '@mui/material/Typography';
import { iFootnoteTypes } from './misc';

type iFootnoteProps = {
    type: iFootnoteTypes
    // id: string,
    // addFootnote: (code: string, content: any) => void
    // fn: ReactElement
}

export default function Footnote(props: PropsWithChildren<iFootnoteProps>) {
    const { children, type } = props
    // setFootnotes(p => {
    //   return [...p, {i: p.length, id}]
    // })
    const content = <Typography>{children}</Typography>
    return (
        <Tooltip
            title={content}
            enterTouchDelay={0}
            leaveTouchDelay={10000}
        >
            <Box
                component='span'
                sx={{
                    display: 'inline-block',
                    float: 'right',
                    // float: {
                    //     xs: 'none',
                    //     md: 'right',
                    // },
                    mr: {
                        us: '0px',
                        md: '-42px',
                    },
                    mt: '0.2em',
                    // mt: '0.05em',
                    // opacity: .8,
                    // opacity: .3,
                    '& sup': {
                        fontVariationSettings: '"wght" 200',
                        // fontWeight: 300,
                        // fontSize: '1rem'
                        fontSize: {
                            us: '1.4rem',
                            md: 'inherit'
                        },
                        pl: '1px',
                    }
                }}
            >
                <svg  width={0} height={0}>
                <linearGradient id="CitationGradient" x1={1} y1={0} x2={1} y2={1}>
                    <stop offset={0} stopColor="var(--source-top)" />
                    <stop offset={1} stopColor="var(--source-bottom)" />
                </linearGradient>
                <linearGradient id="linearTwo" x1={1} y1={0} x2={1} y2={1}>
                    <stop offset={0} stopColor="var(--greenlist-top)" />
                    <stop offset={1} stopColor="var(--greenlist-bottom)" />
                </linearGradient>
                </svg>
                { type === 'citation' && <AlternateEmailIcon sx={{ fill: "url(#CitationGradient)" }}/>}
                { type === 'comment' && <ChatBubbleOutlineIcon sx={{ fill: "url(#linearTwo)" }}/>}
            </Box>
        </Tooltip>
    )
}
