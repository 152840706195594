import React, { ReactElement, useState, useContext } from 'react';

//@ts-ignore
const EditContext = React.createContext<iEditContextData>()

export function useEditContext() {
    return useContext(EditContext)
}

type UpdateMethod = 'live' | 'onBlur' | 'onInterval' | 'manual';
type LiveType = 'continuous' | 'throttled';

interface UpdateOptions {
    liveType: LiveType;
    throttledDelay: number;
    interval: number;
}

interface iUpdateSettings {
    updateMethod: UpdateMethod;
    updateOptions: UpdateOptions;
}

interface iEditContextData {
    updateSettings: iUpdateSettings;
    setUpdateSettings: (newSettings: iUpdateSettings) => void;
    requestDatabaseSave: number;
    setRequestDatabaseSave: React.Dispatch<React.SetStateAction<number>>,
}

export function EditProvider({ children }: { children: ReactElement }) {

    const [ loading, setLoading ] = useState(false)
    const [updateSettings, setUpdateSettings] = useState<iUpdateSettings>({
        updateMethod: 'manual', // Default value, can be 'live', 'onBlur', 'onInterval', or 'manual'
        updateOptions: {
          liveType: 'continuous', // 'continuous' or 'throttled'
          throttledDelay: 1000, // Delay in milliseconds for throttled live updates
          interval: 5, // Time interval in minutes for updates on time interval
        },
    });

    // when incremented, will trigger a database save
    const [ requestDatabaseSave, setRequestDatabaseSave ] = useState<number>(0)

    const value: iEditContextData = {
        updateSettings,
        setUpdateSettings,
        requestDatabaseSave,
        setRequestDatabaseSave,
    }

    function page() {
        if (loading) return null
        // else if (record?.group === null) return 'Se le otorgará acceso a la plataforma cuando sea asignado a un grupo'
        else {
            return children
        }
    }

    return (
        <EditContext.Provider value={value as iEditContextData}>
            {
                page()
            }
        </EditContext.Provider>
    )
}