import Box from "@mui/material/Box";
import { PropsWithChildren } from "react";

export default function High(props: PropsWithChildren) {

    const { children } = props
    return (
        <Box
            component='span'
            className='HighlighterBlog'
            sx={{
                // textDecorationColor: 'yellow',
                // textDecorationColor: '#c7ff00',
                textDecorationColor: '#d3ff0061',
                textDecorationStyle: 'solid',
                textDecorationThickness: '7px',
                textDecorationLine: 'underline',
                textUnderlineOffset: '-3px',
                textDecorationSkipInk: 'none',
            }}
        >
            { children }
        </Box>
    )
}