import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import './Editor.css'
import 'devextreme/dist/css/dx.light.css';
// import { HtmlEditor } from 'devextreme-react/html-editor';
import AddIcon from '@mui/icons-material/Add';
import { ref as storageRef, uploadBytes, getBlob } from 'firebase/storage';
import { storage } from '../firebase/setup';
import SaveIcon from '@mui/icons-material/Save';
import { Controller, FieldValues, SubmitHandler, useForm } from "react-hook-form";
import SourceIcon from '@mui/icons-material/Source';
import DeleteIcon from '@mui/icons-material/Delete';

// import {
    // ...
    // Toolbar,
    // Item,
// } from "devextreme-react/html-editor";

import HtmlEditor, {
    Toolbar,
    Item,
    TableContextMenu,
    TableResizing
} from 'devextreme-react/html-editor';
import Quill from 'devextreme-quill';

import Fab from '@mui/material/Fab';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import { useParams } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';

type iEditorProps = {
    sources_fab?: boolean
}

export type iSource = {
    text: string,
    year: string,
    full: string
}

const parchment = Quill.import('parchment');
const ClassAttributor = parchment.ClassAttributor;
const bible_text_config = {
  scope: parchment.Scope.INLINE
};
const bible_reference_config = {
    scope: parchment.Scope.INLINE
};
const BibleText = new ClassAttributor('bible', 'bible-text', bible_text_config);
const BibleReference = new ClassAttributor('reference', 'bible-reference', bible_reference_config);
Quill.register({ 'formats/bible-text': BibleText }, true);
Quill.register({ 'formats/bible-reference': BibleReference }, true);

export default function Editor(props: iEditorProps) {

    const { sources_fab } = props

    const { id } = useParams()

    const [ document, setDocument ] = useState<undefined | string>()
    const editorState = useRef<any>()

    const [ sources, setSources ] = useState<iSource[]>()
    const [ addSourcePopup, setAddSourcePopup ] = useState(false)
    const [ allSourcesPopup, setAllSourcesPopup ] = useState(false)
    const [ htmlPopup, setHtmlPopup ] = useState(false)
    const [ rawHtmlEditor, setRawHtmlEditor ] = useState('')

    const { handleSubmit, reset, control } = useForm({
        // defaultValues: form_default_values
    });

    const [ fetchingDocument, setFetchingDocument ] = useState(false)
    useEffect(() => {
        console.log('get from storage triggered')
        const element = storageRef(storage, `documents/${id}`)
        getBlob(element).then(async (b) => {
            console.log(b)
            // const json = JSON.parse(String.fromCharCode.apply(null, [...new Uint8Array(b)]))
            // const json = JSON.parse(await b.text())
            // localStorage.setItem(`tl_ls_${key}`, await b.text())
            console.log(await b.text())
            const data = await b.text()
            const parsed_data = JSON.parse(data)
            if (parsed_data.sources) {
                setSources(parsed_data.sources)
            }
            setDocument(parsed_data.content)
            // setFinishedUpdate((p) => p + 1)
        }).catch((e) => {
            console.log(e)
            if (e.toString().includes('storage/object-not-found')) {
                console.log('Document doesnt exist.')
                setDocument('<p>El documento no existe</p>')
                setSources([])
                return
            }
            throw new Error(e)

        })
    }, [id])

    const sizeValues = [ "8px", "10px", "12px", "14px", "18px", "21px", "24px", "36px" ];
    const fontValues = [ "Arial", "Georgia", "Tahoma", "Times New Roman", "Verdana", 'Satoshi Variable', 'Inter' ];
    const headerValues = [ false, 1, 2, 3, 4, 5 ];
    const mentionsConfig = [{
        dataSource: sources,
        searchExpr: 'text',
        displayExpr: 'text',
        valueExpr: 'text',
    }];

    useEffect(() => {
        console.log(id)
    }, [])

    function onChange(e: any) {
        editorState.current = e
        console.log(e)
    }

    function addSource() {
        setAddSourcePopup(true)
    }

    function upload(get_from_raw_html_editor?: boolean) {
        const from_raw = JSON.stringify({content: rawHtmlEditor || '', sources: sources})
        const from_expressdev = JSON.stringify({content: editorState.current, sources: sources})
        const updated_stringified = get_from_raw_html_editor ? from_raw : from_expressdev

        const listRef = storageRef(storage, `documents/${id}`)
        let blob = new Blob([updated_stringified], {type: "application/json"})
        uploadBytes(listRef, blob).then(() => {
            console.log('uploaded')
            // const version_control_ref = ref(rtdb, `documents/${category}`)
            // set(version_control_ref, increment(1))
        })
    }

    function updateSources(form_data: FieldValues) {
        const data = form_data as unknown as iSource
        setSources((p) => {
            const narrative = {
                text: `${data.text} (${data.year})`,
                year: data.year,
                full: data.full,
            }
            const direct = {
                text: `(${data.text}, ${data.year})`,
                year: data.year,
                full: data.full,
            }
            if (!p) return [narrative, direct]
            return [...p, narrative, direct]
        })
        setAddSourcePopup(false)
        console.log(data)
    }

    function deleteSource(index: number) {
        setSources((p) => {
            if (!p) return p
            const filter = p.filter((v, i) => {
                if (i === index) {
                    return false
                }
                return true
            })

            return filter
        })
    }

    const highlightOptions = {
		icon: 'contains',
        text: 'Texto bíblico',
        // stylingMode: 'text',
        hint: 'Bible text' 
    };

    const referenceOptions = {
		icon: 'contains',
        text: 'Referencia bíblica',
        // stylingMode: 'text',
        hint: 'Bible reference' 
    };

    function parse() {
        // parse
        const data = editorState.current
        if (!data) return
        const url = (string: string) => encodeURI(string)
        let parse_bible_references = data
        const bible_reference_matches = data.match(/<span class="bible-reference-true">(.*?)<\/span>/g)
        if (bible_reference_matches) {
            bible_reference_matches.forEach((match: string, i: number) => {
                const new_string = () => {
                    const clean_reference = match
                        .replace('<span class="bible-reference-true">', '')
                        .replace('</span>', '')
                    const url = encodeURIComponent(clean_reference)
                    const construct = `<a href="https://www.biblegateway.com/passage/?search=${url}&version=LBLA" target="_blank">${clean_reference}</a>`
                    return construct
                }
                parse_bible_references = parse_bible_references.replace(match, new_string())
            })
        }
        const parseBibleText = (text_block: string) => {
            let result = text_block
            Array(100).fill(undefined).forEach((v, i) => {
                if (result.includes(`<span class="verse-number">${i} </span>`)) {
                    return
                }
                let de_trail = result.replaceAll(`${i} `, `${i}`)
                result = de_trail.replaceAll(`${i}`, `<span class="verse-number">${i} </span>`)
            })
            return result
        }
        let parse_bible_text = parse_bible_references
        const bible_text_matches = data.match(/<span class="bible-text-true">(.*?)<\/span>/g)
        bible_text_matches.forEach((match: string, index:number) => {
            parse_bible_text = parse_bible_text.replace(match, parseBibleText(match))
        })
        // parse_bible_text = parseBibleText()
        editorState.current = parse_bible_text
        console.log(parse_bible_text)
        // update editor
        
    }

    const input_props = {inputProps:{autoComplete: 'off'}}
    return (
        <Box
            sx={{
                height: '100%'
            }}
            suppressContentEditableWarning={true}
        >
            <Helmet>
                <title>Bani Grisson – Editor</title>
            </Helmet>
            <Fab aria-label="save" onClick={parse}
                sx={{
                    position: 'fixed',
                    right: '270px',
                    bottom: '10px',
                }}
            >
                parse
            </Fab>
            <Fab aria-label="save" onClick={() => {
                if (!editorState.current) return
                setRawHtmlEditor(editorState.current)
                setHtmlPopup(true)

            }}
                sx={{
                    position: 'fixed',
                    right: '205px',
                    bottom: '10px',
                }}
            >
                {`</>`}
            </Fab>
            <Fab aria-label="add" onClick={() => setAllSourcesPopup(true)}
                sx={{
                    position: 'fixed',
                    right: '140px',
                    bottom: '10px',
                }}
            >
                <SourceIcon />
            </Fab>
            <Fab aria-label="add" onClick={addSource}
                sx={{
                    position: 'fixed',
                    right: '75px',
                    bottom: '10px',
                }}
            >
                <AddIcon />
            </Fab>
            <Fab aria-label="save" onClick={() => upload()}
                sx={{
                    position: 'fixed',
                    right: '10px',
                    bottom: '10px',
                }}
            >
                <SaveIcon />
            </Fab>
            
            <Dialog open={addSourcePopup} onBackdropClick={() => setAddSourcePopup(false)}
                sx={{
                    '& .MuiDialog-paper': {
                        minWidth: '700px'
                    }
                }}
            >
                <Box sx={{p: 2}}>
                    <form onSubmit={handleSubmit(updateSources)}>
                        <Stack spacing={1}>
                            <Controller
                                name={"text"}
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <TextField onChange={onChange} value={value || ''} label={"Nombre"} {...input_props} />
                                )}
                            />
                            <Controller
                                name={"year"}
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <TextField onChange={onChange} value={value || ''} label={"Año"} {...input_props} />
                                )}
                            />
                            <Controller
                                name={"full"}
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <TextField onChange={onChange} value={value || ''} label={"Completo"} {...input_props} />
                                )}
                            />
                            <Button type='submit' variant='outlined'>
                                AÑADIR
                            </Button>
                        </Stack>
                    </form>
                </Box>
            </Dialog>
            <Dialog open={allSourcesPopup} onBackdropClick={() => setAllSourcesPopup(false)}
                sx={{
                    '& .MuiDialog-container': {
                        minWidth: '700px'
                    },
                    '& .MuiDialog-paper': {
                        minWidth: '700px'

                    }
                }}
            >
                <Box sx={{p: 2, overflow: 'auto'}}>
                    <Stack spacing={1}>
                        {
                            sources && sources.map((v, i) => {
                                return (
                                    <Box key={i}>
                                        <IconButton
                                            onClick={() => deleteSource(i)}
                                            sx={{
                                                float: 'right'
                                            }}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                        <Typography
                                            sx={{
                                                fontVariationSettings: '"wght" 600 !important',
                                                // pb: 1,
                                            }}
                                        >{v.text}</Typography>
                                        <Typography>{v.full}</Typography>
                                        <Divider sx={{mt: 1}} />
                                    </Box>
                                )
                            })
                        }
                        <Button type='submit' variant='outlined'>
                            GUARDAR
                        </Button>
                    </Stack>
                </Box>
            </Dialog>
            <Dialog
                open={htmlPopup}
                onBackdropClick={() => setHtmlPopup(false)}
                sx={{
                    '& .MuiDialog-paper': {
                        minWidth: '1000px',
                        minHeight: '100%'
                    }
                }}
            >
                <Paper
                    sx={{
                        overflow: 'auto',
                        p: 3,
                    }}
                >

                    <TextField
                        multiline
                        variant='filled'
                        value={rawHtmlEditor}
                        onChange={(e) => setRawHtmlEditor(e.target.value)}
                        style={{
                            whiteSpace: 'pre',
                            minHeight: '100%',
                            minWidth: '100%',
                            wordBreak: 'break-all',
                            wordWrap: 'break-word',
                        }}
                    />
                    <Button
                        variant='outlined'
                        sx={{mt: '24px'}}
                        fullWidth
                        onClick={() => upload(true)}
                    >
                        GUARDAR
                    </Button>
                </Paper>
            </Dialog>
            {
                document !== undefined &&
                <Container>
                    <Box
                        id='toolbar-box'
                        sx={{
                            position: 'fixed',
                            top: '0px',
                            backgroundColor: '#fffffff2',
                            pt: 1,
                            pb: 1,
                            zIndex: 1,
                            maxWidth: '1150px'
                        }}
                    >

                    </Box>
                <HtmlEditor
                // @ts-ignore
                    mentions={mentionsConfig}
                    onValueChange={onChange}
                    stylingMode='underlined'
                    defaultValue={document}
                    elementAttr={{
                        // class: 'ql-editor-'
                        style: {
                            // paddingTop: '200px'
                        //     height: '100%',
                        //     '& .qu-editor': {
                        //         height: '100%',
                        //     }
                        }
                    }}
                >
                    <Toolbar multiline={true} 
                        container='#toolbar-box'
                    >
                        <Item name="undo" />
                        <Item name="redo" />
                        <Item name="separator" />
                        <Item name="size" acceptedValues={sizeValues} />
                        <Item name="font" acceptedValues={fontValues} />
                        <Item name="separator" />
                        <Item name="bold" />
                        <Item name="italic" />
                        <Item name="strike" />
                        <Item name="underline" />
                        <Item name="separator" />
                        <Item name="alignLeft" />
                        <Item name="alignCenter" />
                        <Item name="alignRight" />
                        <Item name="alignJustify" />
                        <Item name="separator" />
                        <Item name="orderedList" />
                        <Item name="bulletList" />
                        <Item name="separator" />
                        <Item name="header" acceptedValues={headerValues} />
                        <Item name="separator" />
                        <Item name="color" />
                        <Item name="background" />
                        <Item name="separator" />
                        <Item name="link" />
                        <Item name="image" />
                        <Item name="separator" />
                        <Item name="clear" />
                        <Item name="codeBlock" />
                        <Item name="blockquote" />
                        <Item name="separator" />
                        <TableContextMenu enabled={true} />
                        <TableResizing enabled={true} />
                        <Item name="insertTable" />
                        <Item name="insertHeaderRow" />
                        <Item name="insertRowAbove" />
                        <Item name="insertRowBelow" />
                        <Item name="separator" />
                        <Item name="insertColumnLeft" />
                        <Item name="insertColumnRight" />
                        <Item name="separator" />
                        <Item name="deleteColumn" />
                        <Item name="deleteRow" />
                        <Item name="deleteTable" />
                        <Item name="separator" />
                        <Item name="cellProperties" />
                        <Item name="tableProperties" />
                        <Item
                            name="bible-text"
                            options={highlightOptions}
                        />
                        <Item
                            name='bible-reference'
                            options={referenceOptions}
                        />
                    </Toolbar>
                </HtmlEditor>
                </Container>
            }
        </Box>
    )
}
