import Box from '@mui/material/Box'
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';
import React from 'react'
import IMG from '../resources/bonbon-line-web-design-1.png';
import ToolbarGeneralOne from './ToolbarGeneralOne';
import { motion } from "framer-motion"

export default function Contacto() {

    const variants = {
        hidden: {
            opacity: 0,
            y: '-20px',
        },
        visible: {
            opacity: 1,
            y: '0px',
        },
    }


    return (
        <Box>
            <ToolbarGeneralOne />
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    pt: '100px',
                }}
            >
                <motion.div initial='hidden' animate='visible' variants={variants}>
                    <Typography
                        sx={{
                            fontSize: {
                                us: '3.5rem',
                            },
                            color: '#433f4a',
                        }}
                    >
                        Página en construcción
                    </Typography>
                </motion.div>
                <motion.img initial='hidden' animate='visible' variants={variants} src={IMG} alt="ilustracion"
                    style={{
                        maxWidth: '100%'
                    }}
                />
            </Box>
        </Box>
    )
}
