export const morphgnt_sbl_james = [
    '200101 N- ----NSM- Ἰάκωβος Ἰάκωβος Ἰάκωβος Ἰάκωβος',
    '200101 N- ----GSM- θεοῦ θεοῦ θεοῦ θεός',
    '200101 C- -------- καὶ καὶ καί καί',
    '200101 N- ----GSM- κυρίου κυρίου κυρίου κύριος',
    '200101 N- ----GSM- Ἰησοῦ Ἰησοῦ Ἰησοῦ Ἰησοῦς',
    '200101 N- ----GSM- Χριστοῦ Χριστοῦ Χριστοῦ Χριστός',
    '200101 N- ----NSM- δοῦλος δοῦλος δοῦλος δοῦλος',
    '200101 RA ----DPF- ταῖς ταῖς ταῖς ὁ',
    '200101 A- ----DPF- δώδεκα δώδεκα δώδεκα δώδεκα',
    '200101 N- ----DPF- φυλαῖς φυλαῖς φυλαῖς φυλή',
    '200101 RA ----DPF- ταῖς ταῖς ταῖς ὁ',
    '200101 P- -------- ἐν ἐν ἐν ἐν',
    '200101 RA ----DSF- τῇ τῇ τῇ ὁ',
    '200101 N- ----DSF- διασπορᾷ διασπορᾷ διασπορᾷ διασπορά',
    '200101 V- -PAN---- χαίρειν. χαίρειν χαίρειν χαίρω',
    '200102 A- ----ASF- Πᾶσαν Πᾶσαν πᾶσαν πᾶς',
    '200102 N- ----ASF- χαρὰν χαρὰν χαράν χαρά',
    '200102 V- 2AMD-P-- ἡγήσασθε, ἡγήσασθε ἡγήσασθε ἡγέομαι',
    '200102 N- ----VPM- ἀδελφοί ἀδελφοί ἀδελφοί ἀδελφός',
    '200102 RP ----GS-- μου, μου μου ἐγώ',
    '200102 C- -------- ὅταν ὅταν ὅταν ὅταν',
    '200102 N- ----DPM- πειρασμοῖς πειρασμοῖς πειρασμοῖς πειρασμός',
    '200102 V- 2AAS-P-- περιπέσητε περιπέσητε περιπέσητε περιπίπτω',
    '200102 A- ----DPM- ποικίλοις, ποικίλοις ποικίλοις ποικίλος',
    '200103 V- -PAPNPM- γινώσκοντες γινώσκοντες γινώσκοντες γινώσκω',
    '200103 C- -------- ὅτι ὅτι ὅτι ὅτι',
    '200103 RA ----NSN- τὸ τὸ τό ὁ',
    '200103 N- ----NSN- δοκίμιον δοκίμιον δοκίμιον δοκίμιον',
    '200103 RP ----GP-- ὑμῶν ὑμῶν ὑμῶν σύ',
    '200103 RA ----GSF- τῆς τῆς τῆς ὁ',
    '200103 N- ----GSF- πίστεως πίστεως πίστεως πίστις',
    '200103 V- 3PMI-S-- κατεργάζεται κατεργάζεται κατεργάζεται κατεργάζομαι',
    '200103 N- ----ASF- ὑπομονήν· ὑπομονήν ὑπομονήν ὑπομονή',
    '200104 RA ----NSF- ἡ ἡ ἡ ὁ',
    '200104 C- -------- δὲ δὲ δέ δέ',
    '200104 N- ----NSF- ὑπομονὴ ὑπομονὴ ὑπομονή ὑπομονή',
    '200104 N- ----ASN- ἔργον ἔργον ἔργον ἔργον',
    '200104 A- ----ASN- τέλειον τέλειον τέλειον τέλειος',
    '200104 V- 3PAD-S-- ἐχέτω, ἐχέτω ἐχέτω ἔχω',
    '200104 C- -------- ἵνα ἵνα ἵνα ἵνα',
    '200104 V- 2PAS-P-- ἦτε ἦτε ἦτε εἰμί',
    '200104 A- ----NPM- τέλειοι τέλειοι τέλειοι τέλειος',
    '200104 C- -------- καὶ καὶ καί καί',
    '200104 A- ----NPM- ὁλόκληροι, ὁλόκληροι ὁλόκληροι ὁλόκληρος',
    '200104 P- -------- ἐν ἐν ἐν ἐν',
    '200104 A- ----DSN- μηδενὶ μηδενὶ μηδενί μηδείς',
    '200104 V- -PMPNPM- λειπόμενοι. λειπόμενοι λειπόμενοι λείπω',
    '200105 C- -------- Εἰ Εἰ εἰ εἰ',
    '200105 C- -------- δέ δέ δέ δέ',
    '200105 RI ----NSM- τις τις τὶς τις',
    '200105 RP ----GP-- ὑμῶν ὑμῶν ὑμῶν σύ',
    '200105 V- 3PMI-S-- λείπεται λείπεται λείπεται λείπω',
    '200105 N- ----GSF- σοφίας, σοφίας σοφίας σοφία',
    '200105 V- 3PAD-S-- αἰτείτω αἰτείτω αἰτείτω αἰτέω',
    '200105 P- -------- παρὰ παρὰ παρά παρά',
    '200105 RA ----GSM- τοῦ τοῦ τοῦ ὁ',
    '200105 V- -PAPGSM- διδόντος διδόντος διδόντος δίδωμι',
    '200105 N- ----GSM- θεοῦ θεοῦ θεοῦ θεός',
    '200105 A- ----DPM- πᾶσιν πᾶσιν πᾶσι(ν) πᾶς',
    '200105 D- -------- ἁπλῶς ἁπλῶς ἁπλῶς ἁπλῶς',
    '200105 C- -------- καὶ καὶ καί καί',
    '200105 D- -------- ⸀μὴ μὴ μή μή',
    '200105 V- -PAPGSM- ὀνειδίζοντος, ὀνειδίζοντος ὀνειδίζοντος ὀνειδίζω',
    '200105 C- -------- καὶ καὶ καί καί',
    '200105 V- 3FPI-S-- δοθήσεται δοθήσεται δοθήσεται δίδωμι',
    '200105 RP ----DSM- αὐτῷ· αὐτῷ αὐτῷ αὐτός',
    '200106 V- 3PAD-S-- αἰτείτω αἰτείτω αἰτείτω αἰτέω',
    '200106 C- -------- δὲ δὲ δέ δέ',
    '200106 P- -------- ἐν ἐν ἐν ἐν',
    '200106 N- ----DSF- πίστει, πίστει πίστει πίστις',
    '200106 A- ----ASN- μηδὲν μηδὲν μηδέν μηδείς',
    '200106 V- -PMPNSM- διακρινόμενος, διακρινόμενος διακρινόμενος διακρίνω',
    '200106 RA ----NSM- ὁ ὁ ὁ ὁ',
    '200106 C- -------- γὰρ γὰρ γάρ γάρ',
    '200106 V- -PMPNSM- διακρινόμενος διακρινόμενος διακρινόμενος διακρίνω',
    '200106 V- 3XAI-S-- ἔοικεν ἔοικεν ἔοικε(ν) ἔοικα',
    '200106 N- ----DSM- κλύδωνι κλύδωνι κλύδωνι κλύδων',
    '200106 N- ----GSF- θαλάσσης θαλάσσης θαλάσσης θάλασσα',
    '200106 V- -PPPDSM- ἀνεμιζομένῳ ἀνεμιζομένῳ ἀνεμιζομένῳ ἀνεμίζομαι',
    '200106 C- -------- καὶ καὶ καί καί',
    '200106 V- -PPPDSM- ῥιπιζομένῳ· ῥιπιζομένῳ ῥιπιζομένῳ ῥιπίζομαι',
    '200107 D- -------- μὴ μὴ μή μή',
    '200107 C- -------- γὰρ γὰρ γάρ γάρ',
    '200107 V- 3PMD-S-- οἰέσθω οἰέσθω οἰέσθω οἶμαι',
    '200107 RA ----NSM- ὁ ὁ ὁ ὁ',
    '200107 N- ----NSM- ἄνθρωπος ἄνθρωπος ἄνθρωπος ἄνθρωπος',
    '200107 RD ----NSM- ἐκεῖνος ἐκεῖνος ἐκεῖνος ἐκεῖνος',
    '200107 C- -------- ὅτι ὅτι ὅτι ὅτι',
    '200107 V- 3FMI-S-- λήμψεταί λήμψεταί λήμψεται λαμβάνω',
    '200107 RI ----ASN- τι τι τὶ τις',
    '200107 P- -------- παρὰ παρὰ παρά παρά',
    '200107 RA ----GSM- τοῦ τοῦ τοῦ ὁ',
    '200107 N- ----GSM- κυρίου κυρίου κυρίου κύριος',
    '200108 N- ----NSM- ἀνὴρ ἀνὴρ ἀνήρ ἀνήρ',
    '200108 A- ----NSM- δίψυχος, δίψυχος δίψυχος δίψυχος',
    '200108 A- ----NSM- ἀκατάστατος ἀκατάστατος ἀκατάστατος ἀκατάστατος',
    '200108 P- -------- ἐν ἐν ἐν ἐν',
    '200108 A- ----DPF- πάσαις πάσαις πάσαις πᾶς',
    '200108 RA ----DPF- ταῖς ταῖς ταῖς ὁ',
    '200108 N- ----DPF- ὁδοῖς ὁδοῖς ὁδοῖς ὁδός',
    '200108 RP ----GSM- αὐτοῦ. αὐτοῦ αὐτοῦ αὐτός',
    '200109 V- 3PMD-S-- Καυχάσθω Καυχάσθω καυχάσθω καυχάομαι',
    '200109 C- -------- δὲ δὲ δέ δέ',
    '200109 RA ----NSM- ὁ ὁ ὁ ὁ',
    '200109 N- ----NSM- ἀδελφὸς ἀδελφὸς ἀδελφός ἀδελφός',
    '200109 RA ----NSM- ὁ ὁ ὁ ὁ',
    '200109 A- ----NSM- ταπεινὸς ταπεινὸς ταπεινός ταπεινός',
    '200109 P- -------- ἐν ἐν ἐν ἐν',
    '200109 RA ----DSN- τῷ τῷ τῷ ὁ',
    '200109 N- ----DSN- ὕψει ὕψει ὕψει ὕψος',
    '200109 RP ----GSM- αὐτοῦ, αὐτοῦ αὐτοῦ αὐτός',
    '200110 RA ----NSM- ὁ ὁ ὁ ὁ',
    '200110 C- -------- δὲ δὲ δέ δέ',
    '200110 A- ----NSM- πλούσιος πλούσιος πλούσιος πλούσιος',
    '200110 P- -------- ἐν ἐν ἐν ἐν',
    '200110 RA ----DSF- τῇ τῇ τῇ ὁ',
    '200110 N- ----DSF- ταπεινώσει ταπεινώσει ταπεινώσει ταπείνωσις',
    '200110 RP ----GSM- αὐτοῦ, αὐτοῦ αὐτοῦ αὐτός',
    '200110 C- -------- ὅτι ὅτι ὅτι ὅτι',
    '200110 C- -------- ὡς ὡς ὡς ὡς',
    '200110 N- ----NSN- ἄνθος ἄνθος ἄνθος ἄνθος',
    '200110 N- ----GSM- χόρτου χόρτου χόρτου χόρτος',
    '200110 V- 3FMI-S-- παρελεύσεται. παρελεύσεται παρελεύσεται παρέρχομαι',
    '200111 V- 3AAI-S-- ἀνέτειλεν ἀνέτειλεν ἀνέτειλε(ν) ἀνατέλλω',
    '200111 C- -------- γὰρ γὰρ γάρ γάρ',
    '200111 RA ----NSM- ὁ ὁ ὁ ὁ',
    '200111 N- ----NSM- ἥλιος ἥλιος ἥλιος ἥλιος',
    '200111 P- -------- σὺν σὺν σύν σύν',
    '200111 RA ----DSM- τῷ τῷ τῷ ὁ',
    '200111 N- ----DSM- καύσωνι καύσωνι καύσωνι καύσων',
    '200111 C- -------- καὶ καὶ καί καί',
    '200111 V- 3AAI-S-- ἐξήρανεν ἐξήρανεν ἐξήρανε(ν) ξηραίνω',
    '200111 RA ----ASM- τὸν τὸν τόν ὁ',
    '200111 N- ----ASM- χόρτον, χόρτον χόρτον χόρτος',
    '200111 C- -------- καὶ καὶ καί καί',
    '200111 RA ----NSN- τὸ τὸ τό ὁ',
    '200111 N- ----NSN- ἄνθος ἄνθος ἄνθος ἄνθος',
    '200111 RP ----GSM- αὐτοῦ αὐτοῦ αὐτοῦ αὐτός',
    '200111 V- 3AAI-S-- ἐξέπεσεν ἐξέπεσεν ἐξέπεσε(ν) ἐκπίπτω',
    '200111 C- -------- καὶ καὶ καί καί',
    '200111 RA ----NSF- ἡ ἡ ἡ ὁ',
    '200111 N- ----NSF- εὐπρέπεια εὐπρέπεια εὐπρέπεια εὐπρέπεια',
    '200111 RA ----GSN- τοῦ τοῦ τοῦ ὁ',
    '200111 N- ----GSN- προσώπου προσώπου προσώπου πρόσωπον',
    '200111 RP ----GSN- αὐτοῦ αὐτοῦ αὐτοῦ αὐτός',
    '200111 V- 3AMI-S-- ἀπώλετο· ἀπώλετο ἀπώλετο ἀπόλλυμι',
    '200111 D- -------- οὕτως οὕτως οὕτω(ς) οὕτω(ς)',
    '200111 D- -------- καὶ καὶ καί καί',
    '200111 RA ----NSM- ὁ ὁ ὁ ὁ',
    '200111 A- ----NSM- πλούσιος πλούσιος πλούσιος πλούσιος',
    '200111 P- -------- ἐν ἐν ἐν ἐν',
    '200111 RA ----DPF- ταῖς ταῖς ταῖς ὁ',
    '200111 N- ----DPF- πορείαις πορείαις πορείαις πορεία',
    '200111 RP ----GSM- αὐτοῦ αὐτοῦ αὐτοῦ αὐτός',
    '200111 V- 3FPI-S-- μαρανθήσεται. μαρανθήσεται μαρανθήσεται μαραίνομαι',
    '200112 A- ----NSM- Μακάριος Μακάριος μακάριος μακάριος',
    '200112 N- ----NSM- ἀνὴρ ἀνὴρ ἀνήρ ἀνήρ',
    '200112 RR ----NSM- ὃς ὃς ὅς ὅς',
    '200112 V- 3PAI-S-- ὑπομένει ὑπομένει ὑπομένει ὑπομένω',
    '200112 N- ----ASM- πειρασμόν, πειρασμόν πειρασμόν πειρασμός',
    '200112 C- -------- ὅτι ὅτι ὅτι ὅτι',
    '200112 A- ----NSM- δόκιμος δόκιμος δόκιμος δόκιμος',
    '200112 V- -AMPNSM- γενόμενος γενόμενος γενόμενος γίνομαι',
    '200112 V- 3FMI-S-- λήμψεται λήμψεται λήμψεται λαμβάνω',
    '200112 RA ----ASM- τὸν τὸν τόν ὁ',
    '200112 N- ----ASM- στέφανον στέφανον στέφανον στέφανος',
    '200112 RA ----GSF- τῆς τῆς τῆς ὁ',
    '200112 N- ----GSF- ζωῆς, ζωῆς ζωῆς ζωή',
    '200112 RR ----ASM- ὃν ὃν ὅν ὅς',
    '200112 V- 3AMI-S-- ⸀ἐπηγγείλατο ἐπηγγείλατο ἐπηγγείλατο ἐπαγγέλλομαι',
    '200112 RA ----DPM- τοῖς τοῖς τοῖς ὁ',
    '200112 V- -PAPDPM- ἀγαπῶσιν ἀγαπῶσιν ἀγαπῶσι(ν) ἀγαπάω',
    '200112 RP ----ASM- αὐτόν. αὐτόν αὐτόν αὐτός',
    '200113 A- ----NSM- μηδεὶς μηδεὶς μηδείς μηδείς',
    '200113 V- -PPPNSM- πειραζόμενος πειραζόμενος πειραζόμενος πειράζω',
    '200113 V- 3PAD-S-- λεγέτω λεγέτω λεγέτω λέγω',
    '200113 C- -------- ὅτι ὅτι ὅτι ὅτι',
    '200113 P- -------- Ἀπὸ Ἀπὸ ἀπό ἀπό',
    '200113 N- ----GSM- θεοῦ θεοῦ θεοῦ θεός',
    '200113 V- 1PPI-S-- πειράζομαι· πειράζομαι πειράζομαι πειράζω',
    '200113 RA ----NSM- ὁ ὁ ὁ ὁ',
    '200113 C- -------- γὰρ γὰρ γάρ γάρ',
    '200113 N- ----NSM- θεὸς θεὸς θεός θεός',
    '200113 A- ----NSM- ἀπείραστός ἀπείραστός ἀπείραστος ἀπείραστος',
    '200113 V- 3PAI-S-- ἐστιν ἐστιν ἐστί(ν) εἰμί',
    '200113 A- ----GPN- κακῶν, κακῶν κακῶν κακός',
    '200113 V- 3PAI-S-- πειράζει πειράζει πειράζει πειράζω',
    '200113 C- -------- δὲ δὲ δέ δέ',
    '200113 RP ----NSM- αὐτὸς αὐτὸς αὐτός αὐτός',
    '200113 A- ----ASM- οὐδένα. οὐδένα οὐδένα οὐδείς',
    '200114 A- ----NSM- ἕκαστος ἕκαστος ἕκαστος ἕκαστος',
    '200114 C- -------- δὲ δὲ δέ δέ',
    '200114 V- 3PPI-S-- πειράζεται πειράζεται πειράζεται πειράζω',
    '200114 P- -------- ὑπὸ ὑπὸ ὑπό ὑπό',
    '200114 RA ----GSF- τῆς τῆς τῆς ὁ',
    '200114 A- ----GSF- ἰδίας ἰδίας ἰδίας ἴδιος',
    '200114 N- ----GSF- ἐπιθυμίας ἐπιθυμίας ἐπιθυμίας ἐπιθυμία',
    '200114 V- -PPPNSM- ἐξελκόμενος ἐξελκόμενος ἐξελκόμενος ἐξέλκω',
    '200114 C- -------- καὶ καὶ καί καί',
    '200114 V- -PPPNSM- δελεαζόμενος· δελεαζόμενος δελεαζόμενος δελεάζω',
    '200115 D- -------- εἶτα εἶτα εἶτα εἶτα',
    '200115 RA ----NSF- ἡ ἡ ἡ ὁ',
    '200115 N- ----NSF- ἐπιθυμία ἐπιθυμία ἐπιθυμία ἐπιθυμία',
    '200115 V- -AAPNSF- συλλαβοῦσα συλλαβοῦσα συλλαβοῦσα συλλαμβάνω',
    '200115 V- 3PAI-S-- τίκτει τίκτει τίκτει τίκτω',
    '200115 N- ----ASF- ἁμαρτίαν, ἁμαρτίαν ἁμαρτίαν ἁμαρτία',
    '200115 RA ----NSF- ἡ ἡ ἡ ὁ',
    '200115 C- -------- δὲ δὲ δέ δέ',
    '200115 N- ----NSF- ἁμαρτία ἁμαρτία ἁμαρτία ἁμαρτία',
    '200115 V- -APPNSF- ἀποτελεσθεῖσα ἀποτελεσθεῖσα ἀποτελεσθεῖσα ἀποτελέω',
    '200115 V- 3PAI-S-- ἀποκύει ἀποκύει ἀποκύει ἀποκυέω',
    '200115 N- ----ASM- θάνατον. θάνατον θάνατον θάνατος',
    '200116 D- -------- μὴ μὴ μή μή',
    '200116 V- 2PPD-P-- πλανᾶσθε, πλανᾶσθε πλανᾶσθε πλανάω',
    '200116 N- ----VPM- ἀδελφοί ἀδελφοί ἀδελφοί ἀδελφός',
    '200116 RP ----GS-- μου μου μου ἐγώ',
    '200116 A- ----VPM- ἀγαπητοί. ἀγαπητοί ἀγαπητοί ἀγαπητός',
    '200117 A- ----NSF- Πᾶσα Πᾶσα πᾶσα πᾶς',
    '200117 N- ----NSF- δόσις δόσις δόσις δόσις',
    '200117 A- ----NSF- ἀγαθὴ ἀγαθὴ ἀγαθή ἀγαθός',
    '200117 C- -------- καὶ καὶ καί καί',
    '200117 A- ----NSN- πᾶν πᾶν πᾶν πᾶς',
    '200117 N- ----NSN- δώρημα δώρημα δώρημα δώρημα',
    '200117 A- ----NSN- τέλειον τέλειον τέλειον τέλειος',
    '200117 D- -------- ἄνωθέν ἄνωθέν ἄνωθεν ἄνωθεν',
    '200117 V- 3PAI-S-- ἐστιν, ἐστιν ἐστί(ν) εἰμί',
    '200117 V- -PAPNSN- καταβαῖνον καταβαῖνον καταβαῖνον καταβαίνω',
    '200117 P- -------- ἀπὸ ἀπὸ ἀπό ἀπό',
    '200117 RA ----GSM- τοῦ τοῦ τοῦ ὁ',
    '200117 N- ----GSM- πατρὸς πατρὸς πατρός πατήρ',
    '200117 RA ----GPN- τῶν τῶν τῶν ὁ',
    '200117 N- ----GPN- φώτων, φώτων φώτων φῶς',
    '200117 P- -------- παρ’ παρ’ παρά παρά',
    '200117 RR ----DSM- ᾧ ᾧ ᾧ ὅς',
    '200117 D- -------- οὐκ οὐκ οὐ οὐ',
    '200117 V- 3PAI-S-- ἔνι ἔνι ἔνι ἔνι',
    '200117 N- ----NSF- παραλλαγὴ παραλλαγὴ παραλλαγή παραλλαγή',
    '200117 C- -------- ἢ ἢ ἤ ἤ',
    '200117 N- ----GSF- τροπῆς τροπῆς τροπῆς τροπή',
    '200117 N- ----NSN- ἀποσκίασμα. ἀποσκίασμα ἀποσκίασμα ἀποσκίασμα',
    '200118 V- -APPNSM- βουληθεὶς βουληθεὶς βουληθείς βούλομαι',
    '200118 V- 3AAI-S-- ἀπεκύησεν ἀπεκύησεν ἀπεκύησε(ν) ἀποκυέω',
    '200118 RP ----AP-- ἡμᾶς ἡμᾶς ἡμᾶς ἐγώ',
    '200118 N- ----DSM- λόγῳ λόγῳ λόγῳ λόγος',
    '200118 N- ----GSF- ἀληθείας, ἀληθείας ἀληθείας ἀλήθεια',
    '200118 P- -------- εἰς εἰς εἰς εἰς',
    '200118 RA ----ASN- τὸ τὸ τό ὁ',
    '200118 V- -PAN---- εἶναι εἶναι εἶναι εἰμί',
    '200118 RP ----AP-- ἡμᾶς ἡμᾶς ἡμᾶς ἐγώ',
    '200118 N- ----ASF- ἀπαρχήν ἀπαρχήν ἀπαρχήν ἀπαρχή',
    '200118 RI ----ASF- τινα τινα τινά τις',
    '200118 RA ----GPN- τῶν τῶν τῶν ὁ',
    '200118 RP ----GSM- αὐτοῦ αὐτοῦ αὐτοῦ αὐτός',
    '200118 N- ----GPN- κτισμάτων. κτισμάτων κτισμάτων κτίσμα',
    '200119 V- 2XAD-P-- ⸀Ἴστε, Ἴστε ἴστε οἶδα',
    '200119 N- ----VPM- ἀδελφοί ἀδελφοί ἀδελφοί ἀδελφός',
    '200119 RP ----GS-- μου μου μου ἐγώ',
    '200119 A- ----VPM- ἀγαπητοί. ἀγαπητοί ἀγαπητοί ἀγαπητός',
    '200119 V- 3PAD-S-- ἔστω ἔστω ἔστω εἰμί',
    '200119 C- -------- ⸀δὲ δὲ δέ δέ',
    '200119 A- ----NSM- πᾶς πᾶς πᾶς πᾶς',
    '200119 N- ----NSM- ἄνθρωπος ἄνθρωπος ἄνθρωπος ἄνθρωπος',
    '200119 A- ----NSM- ταχὺς ταχὺς ταχύς ταχύς',
    '200119 P- -------- εἰς εἰς εἰς εἰς',
    '200119 RA ----ASN- τὸ τὸ τό ὁ',
    '200119 V- -AAN---- ἀκοῦσαι, ἀκοῦσαι ἀκοῦσαι ἀκούω',
    '200119 A- ----NSM- βραδὺς βραδὺς βραδύς βραδύς',
    '200119 P- -------- εἰς εἰς εἰς εἰς',
    '200119 RA ----ASN- τὸ τὸ τό ὁ',
    '200119 V- -AAN---- λαλῆσαι, λαλῆσαι λαλῆσαι λαλέω',
    '200119 A- ----NSM- βραδὺς βραδὺς βραδύς βραδύς',
    '200119 P- -------- εἰς εἰς εἰς εἰς',
    '200119 N- ----ASF- ὀργήν, ὀργήν ὀργήν ὀργή',
    '200120 N- ----NSF- ὀργὴ ὀργὴ ὀργή ὀργή',
    '200120 C- -------- γὰρ γὰρ γάρ γάρ',
    '200120 N- ----GSM- ἀνδρὸς ἀνδρὸς ἀνδρός ἀνήρ',
    '200120 N- ----ASF- δικαιοσύνην δικαιοσύνην δικαιοσύνην δικαιοσύνη',
    '200120 N- ----GSM- θεοῦ θεοῦ θεοῦ θεός',
    '200120 D- -------- ⸂οὐκ οὐκ οὐ οὐ',
    '200120 V- 3PMI-S-- ἐργάζεται⸃. ἐργάζεται ἐργάζεται ἐργάζομαι',
    '200121 C- -------- διὸ διὸ διό διό',
    '200121 V- -AMPNPM- ἀποθέμενοι ἀποθέμενοι ἀποθέμενοι ἀποτίθημι',
    '200121 A- ----ASF- πᾶσαν πᾶσαν πᾶσαν πᾶς',
    '200121 N- ----ASF- ῥυπαρίαν ῥυπαρίαν ῥυπαρίαν ῥυπαρία',
    '200121 C- -------- καὶ καὶ καί καί',
    '200121 N- ----ASF- περισσείαν περισσείαν περισσείαν περισσεία',
    '200121 N- ----GSF- κακίας κακίας κακίας κακία',
    '200121 P- -------- ἐν ἐν ἐν ἐν',
    '200121 N- ----DSF- πραΰτητι πραΰτητι πραΰτητι πραΰτης',
    '200121 V- 2AMD-P-- δέξασθε δέξασθε δέξασθε δέχομαι',
    '200121 RA ----ASM- τὸν τὸν τόν ὁ',
    '200121 A- ----ASM- ἔμφυτον ἔμφυτον ἔμφυτον ἔμφυτος',
    '200121 N- ----ASM- λόγον λόγον λόγον λόγος',
    '200121 RA ----ASM- τὸν τὸν τόν ὁ',
    '200121 V- -PMPASM- δυνάμενον δυνάμενον δυνάμενον δύναμαι',
    '200121 V- -AAN---- σῶσαι σῶσαι σῶσαι σῴζω',
    '200121 RA ----APF- τὰς τὰς τάς ὁ',
    '200121 N- ----APF- ψυχὰς ψυχὰς ψυχάς ψυχή',
    '200121 RP ----GP-- ὑμῶν. ὑμῶν ὑμῶν σύ',
    '200122 V- 2PMD-P-- Γίνεσθε Γίνεσθε γίνεσθε γίνομαι',
    '200122 C- -------- δὲ δὲ δέ δέ',
    '200122 N- ----NPM- ποιηταὶ ποιηταὶ ποιηταί ποιητής',
    '200122 N- ----GSM- λόγου λόγου λόγου λόγος',
    '200122 C- -------- καὶ καὶ καί καί',
    '200122 D- -------- μὴ μὴ μή μή',
    '200122 N- ----NPM- ⸂ἀκροαταὶ ἀκροαταὶ ἀκροαταί ἀκροατής',
    '200122 A- ----ASN- μόνον⸃ μόνον μόνον μόνος',
    '200122 V- -PMPNPM- παραλογιζόμενοι παραλογιζόμενοι παραλογιζόμενοι παραλογίζομαι',
    '200122 RP ----APM- ἑαυτούς. ἑαυτούς ἑαυτούς ἑαυτοῦ',
    '200123 C- -------- ὅτι ὅτι ὅτι ὅτι',
    '200123 C- -------- εἴ εἴ εἰ εἰ',
    '200123 RI ----NSM- τις τις τὶς τις',
    '200123 N- ----NSM- ἀκροατὴς ἀκροατὴς ἀκροατής ἀκροατής',
    '200123 N- ----GSM- λόγου λόγου λόγου λόγος',
    '200123 V- 3PAI-S-- ἐστὶν ἐστὶν ἐστί(ν) εἰμί',
    '200123 C- -------- καὶ καὶ καί καί',
    '200123 D- -------- οὐ οὐ οὐ οὐ',
    '200123 N- ----NSM- ποιητής, ποιητής ποιητής ποιητής',
    '200123 RD ----NSM- οὗτος οὗτος οὗτος οὗτος',
    '200123 V- 3XAI-S-- ἔοικεν ἔοικεν ἔοικε(ν) ἔοικα',
    '200123 N- ----DSM- ἀνδρὶ ἀνδρὶ ἀνδρί ἀνήρ',
    '200123 V- -PAPDSM- κατανοοῦντι κατανοοῦντι κατανοοῦντι κατανοέω',
    '200123 RA ----ASN- τὸ τὸ τό ὁ',
    '200123 N- ----ASN- πρόσωπον πρόσωπον πρόσωπον πρόσωπον',
    '200123 RA ----GSF- τῆς τῆς τῆς ὁ',
    '200123 N- ----GSF- γενέσεως γενέσεως γενέσεως γένεσις',
    '200123 RP ----GSM- αὐτοῦ αὐτοῦ αὐτοῦ αὐτός',
    '200123 P- -------- ἐν ἐν ἐν ἐν',
    '200123 N- ----DSN- ἐσόπτρῳ, ἐσόπτρῳ ἐσόπτρῳ ἔσοπτρον',
    '200124 V- 3AAI-S-- κατενόησεν κατενόησεν κατενόησε(ν) κατανοέω',
    '200124 C- -------- γὰρ γὰρ γάρ γάρ',
    '200124 RP ----ASM- ἑαυτὸν ἑαυτὸν ἑαυτόν ἑαυτοῦ',
    '200124 C- -------- καὶ καὶ καί καί',
    '200124 V- 3XAI-S-- ἀπελήλυθεν ἀπελήλυθεν ἀπελήλυθε(ν) ἀπέρχομαι',
    '200124 C- -------- καὶ καὶ καί καί',
    '200124 D- -------- εὐθέως εὐθέως εὐθέως εὐθέως',
    '200124 V- 3AMI-S-- ἐπελάθετο ἐπελάθετο ἐπελάθετο ἐπιλανθάνομαι',
    '200124 RI ----NSM- ὁποῖος ὁποῖος ὁποῖος ὁποῖος',
    '200124 V- 3IAI-S-- ἦν. ἦν ἦν εἰμί',
    '200125 RA ----NSM- ὁ ὁ ὁ ὁ',
    '200125 C- -------- δὲ δὲ δέ δέ',
    '200125 V- -AAPNSM- παρακύψας παρακύψας παρακύψας παρακύπτω',
    '200125 P- -------- εἰς εἰς εἰς εἰς',
    '200125 N- ----ASM- νόμον νόμον νόμον νόμος',
    '200125 A- ----ASM- τέλειον τέλειον τέλειον τέλειος',
    '200125 RA ----ASM- τὸν τὸν τόν ὁ',
    '200125 RA ----GSF- τῆς τῆς τῆς ὁ',
    '200125 N- ----GSF- ἐλευθερίας ἐλευθερίας ἐλευθερίας ἐλευθερία',
    '200125 C- -------- καὶ καὶ καί καί',
    '200125 V- -AAPNSM- παραμείνας, παραμείνας παραμείνας παραμένω',
    '200125 D- -------- ⸀οὐκ οὐκ οὐ οὐ',
    '200125 N- ----NSM- ἀκροατὴς ἀκροατὴς ἀκροατής ἀκροατής',
    '200125 N- ----GSF- ἐπιλησμονῆς ἐπιλησμονῆς ἐπιλησμονῆς ἐπιλησμονή',
    '200125 V- -AMPNSM- γενόμενος γενόμενος γενόμενος γίνομαι',
    '200125 C- -------- ἀλλὰ ἀλλὰ ἀλλά ἀλλά',
    '200125 N- ----NSM- ποιητὴς ποιητὴς ποιητής ποιητής',
    '200125 N- ----GSN- ἔργου, ἔργου ἔργου ἔργον',
    '200125 RD ----NSM- οὗτος οὗτος οὗτος οὗτος',
    '200125 A- ----NSM- μακάριος μακάριος μακάριος μακάριος',
    '200125 P- -------- ἐν ἐν ἐν ἐν',
    '200125 RA ----DSF- τῇ τῇ τῇ ὁ',
    '200125 N- ----DSF- ποιήσει ποιήσει ποιήσει ποίησις',
    '200125 RP ----GSM- αὐτοῦ αὐτοῦ αὐτοῦ αὐτός',
    '200125 V- 3FMI-S-- ἔσται. ἔσται ἔσται εἰμί',
    '200126 C- -------- Εἴ Εἴ εἰ εἰ',
    '200126 RI ----NSM- τις τις τὶς τις',
    '200126 V- 3PAI-S-- δοκεῖ δοκεῖ δοκεῖ δοκέω',
    '200126 A- ----NSM- θρησκὸς θρησκὸς θρησκός θρησκός',
    '200126 V- -PAN---- ⸀εἶναι εἶναι εἶναι εἰμί',
    '200126 D- -------- μὴ μὴ μή μή',
    '200126 V- -PAPNSM- χαλιναγωγῶν χαλιναγωγῶν χαλιναγωγῶν χαλιναγωγέω',
    '200126 N- ----ASF- γλῶσσαν γλῶσσαν γλῶσσαν γλῶσσα',
    '200126 RP ----GSM- ⸀αὐτοῦ αὐτοῦ αὐτοῦ αὐτός',
    '200126 C- -------- ἀλλὰ ἀλλὰ ἀλλά ἀλλά',
    '200126 V- -PAPNSM- ἀπατῶν ἀπατῶν ἀπατῶν ἀπατάω',
    '200126 N- ----ASF- καρδίαν καρδίαν καρδίαν καρδία',
    '200126 RP ----GSM- ⸀αὐτοῦ, αὐτοῦ αὐτοῦ αὐτός',
    '200126 RD ----GSM- τούτου τούτου τούτου οὗτος',
    '200126 A- ----NSF- μάταιος μάταιος μάταιος μάταιος',
    '200126 RA ----NSF- ἡ ἡ ἡ ὁ',
    '200126 N- ----NSF- θρησκεία. θρησκεία θρησκεία θρησκεία',
    '200127 N- ----NSF- θρησκεία θρησκεία θρησκεία θρησκεία',
    '200127 A- ----NSF- καθαρὰ καθαρὰ καθαρά καθαρός',
    '200127 C- -------- καὶ καὶ καί καί',
    '200127 A- ----NSF- ἀμίαντος ἀμίαντος ἀμίαντος ἀμίαντος',
    '200127 P- -------- παρὰ παρὰ παρά παρά',
    '200127 RA ----DSM- ⸀τῷ τῷ τῷ ὁ',
    '200127 N- ----DSM- θεῷ θεῷ θεῷ θεός',
    '200127 C- -------- καὶ καὶ καί καί',
    '200127 N- ----DSM- πατρὶ πατρὶ πατρί πατήρ',
    '200127 RD ----NSF- αὕτη αὕτη αὕτη οὗτος',
    '200127 V- 3PAI-S-- ἐστίν, ἐστίν ἐστί(ν) εἰμί',
    '200127 V- -PMN---- ἐπισκέπτεσθαι ἐπισκέπτεσθαι ἐπισκέπτεσθαι ἐπισκέπτομαι',
    '200127 A- ----APM- ὀρφανοὺς ὀρφανοὺς ὀρφανούς ὀρφανός',
    '200127 C- -------- καὶ καὶ καί καί',
    '200127 A- ----APF- χήρας χήρας χήρας χήρα',
    '200127 P- -------- ἐν ἐν ἐν ἐν',
    '200127 RA ----DSF- τῇ τῇ τῇ ὁ',
    '200127 N- ----DSF- θλίψει θλίψει θλίψει θλῖψις',
    '200127 RP ----GPM- αὐτῶν, αὐτῶν αὐτῶν αὐτός',
    '200127 A- ----ASM- ἄσπιλον ἄσπιλον ἄσπιλον ἄσπιλος',
    '200127 RP ----ASM- ἑαυτὸν ἑαυτὸν ἑαυτόν ἑαυτοῦ',
    '200127 V- -PAN---- τηρεῖν τηρεῖν τηρεῖν τηρέω',
    '200127 P- -------- ἀπὸ ἀπὸ ἀπό ἀπό',
    '200127 RA ----GSM- τοῦ τοῦ τοῦ ὁ',
    '200127 N- ----GSM- κόσμου. κόσμου κόσμου κόσμος',
    '200201 N- ----VPM- Ἀδελφοί Ἀδελφοί ἀδελφοί ἀδελφός',
    '200201 RP ----GS-- μου, μου μου ἐγώ',
    '200201 D- -------- μὴ μὴ μή μή',
    '200201 P- -------- ἐν ἐν ἐν ἐν',
    '200201 N- ----DPF- προσωπολημψίαις προσωπολημψίαις προσωπολημψίαις προσωπολημψία',
    '200201 V- 2PAD-P-- ἔχετε ἔχετε ἔχετε ἔχω',
    '200201 RA ----ASF- τὴν τὴν τήν ὁ',
    '200201 N- ----ASF- πίστιν πίστιν πίστιν πίστις',
    '200201 RA ----GSM- τοῦ τοῦ τοῦ ὁ',
    '200201 N- ----GSM- κυρίου κυρίου κυρίου κύριος',
    '200201 RP ----GP-- ἡμῶν ἡμῶν ἡμῶν ἐγώ',
    '200201 N- ----GSM- Ἰησοῦ Ἰησοῦ Ἰησοῦ Ἰησοῦς',
    '200201 N- ----GSM- Χριστοῦ Χριστοῦ Χριστοῦ Χριστός',
    '200201 RA ----GSF- τῆς τῆς τῆς ὁ',
    '200201 N- ----GSF- δόξης; δόξης δόξης δόξα',
    '200202 C- -------- ἐὰν ἐὰν ἐάν ἐάν',
    '200202 C- -------- γὰρ γὰρ γάρ γάρ',
    '200202 V- 3AAS-S-- εἰσέλθῃ εἰσέλθῃ εἰσέλθῃ εἰσέρχομαι',
    '200202 P- -------- ⸀εἰς εἰς εἰς εἰς',
    '200202 N- ----ASF- συναγωγὴν συναγωγὴν συναγωγήν συναγωγή',
    '200202 RP ----GP-- ὑμῶν ὑμῶν ὑμῶν σύ',
    '200202 N- ----NSM- ἀνὴρ ἀνὴρ ἀνήρ ἀνήρ',
    '200202 A- ----NSM- χρυσοδακτύλιος χρυσοδακτύλιος χρυσοδακτύλιος χρυσοδακτύλιος',
    '200202 P- -------- ἐν ἐν ἐν ἐν',
    '200202 N- ----DSF- ἐσθῆτι ἐσθῆτι ἐσθῆτι ἐσθής',
    '200202 A- ----DSF- λαμπρᾷ, λαμπρᾷ λαμπρᾷ λαμπρός',
    '200202 V- 3AAS-S-- εἰσέλθῃ εἰσέλθῃ εἰσέλθῃ εἰσέρχομαι',
    '200202 C- -------- δὲ δὲ δέ δέ',
    '200202 D- -------- καὶ καὶ καί καί',
    '200202 A- ----NSM- πτωχὸς πτωχὸς πτωχός πτωχός',
    '200202 P- -------- ἐν ἐν ἐν ἐν',
    '200202 A- ----DSF- ῥυπαρᾷ ῥυπαρᾷ ῥυπαρᾷ ῥυπαρός',
    '200202 N- ----DSF- ἐσθῆτι, ἐσθῆτι ἐσθῆτι ἐσθής',
    '200203 V- 2AAS-P-- ⸂ἐπιβλέψητε ἐπιβλέψητε ἐπιβλέψητε ἐπιβλέπω',
    '200203 C- -------- δὲ⸃ δὲ δέ δέ',
    '200203 P- -------- ἐπὶ ἐπὶ ἐπί ἐπί',
    '200203 RA ----ASM- τὸν τὸν τόν ὁ',
    '200203 V- -PAPASM- φοροῦντα φοροῦντα φοροῦντα φορέω',
    '200203 RA ----ASF- τὴν τὴν τήν ὁ',
    '200203 N- ----ASF- ἐσθῆτα ἐσθῆτα ἐσθῆτα ἐσθής',
    '200203 RA ----ASF- τὴν τὴν τήν ὁ',
    '200203 A- ----ASF- λαμπρὰν λαμπρὰν λαμπράν λαμπρός',
    '200203 C- -------- καὶ καὶ καί καί',
    '200203 V- 2AAS-P-- ⸀εἴπητε· εἴπητε εἴπητε λέγω',
    '200203 RP ----NS-- Σὺ Σὺ σύ σύ',
    '200203 V- 2PMD-S-- κάθου κάθου κάθου κάθημαι',
    '200203 D- -------- ὧδε ὧδε ὧδε ὧδε',
    '200203 D- -------- καλῶς, καλῶς καλῶς καλῶς',
    '200203 C- -------- καὶ καὶ καί καί',
    '200203 RA ----DSM- τῷ τῷ τῷ ὁ',
    '200203 A- ----DSM- πτωχῷ πτωχῷ πτωχῷ πτωχός',
    '200203 V- 2AAS-P-- εἴπητε· εἴπητε εἴπητε λέγω',
    '200203 RP ----NS-- Σὺ Σὺ σύ σύ',
    '200203 V- 2AAD-S-- στῆθι στῆθι στῆθι ἵστημι',
    '200203 C- -------- ⸂ἢ ἢ ἤ ἤ',
    '200203 V- 2PMD-S-- κάθου κάθου κάθου κάθημαι',
    '200203 D- -------- ἐκεῖ⸃ ἐκεῖ ἐκεῖ ἐκεῖ',
    '200203 P- -------- ὑπὸ ὑπὸ ὑπό ὑπό',
    '200203 RA ----ASN- τὸ τὸ τό ὁ',
    '200203 N- ----ASN- ὑποπόδιόν ὑποπόδιόν ὑποπόδιον ὑποπόδιον',
    '200203 RP ----GS-- μου, μου μου ἐγώ',
    '200204 X- -------- ⸀οὐ οὐ οὐ οὐ',
    '200204 V- 2API-P-- διεκρίθητε διεκρίθητε διεκρίθητε διακρίνω',
    '200204 P- -------- ἐν ἐν ἐν ἐν',
    '200204 RP ----DPM- ἑαυτοῖς ἑαυτοῖς ἑαυτοῖς ἑαυτοῦ',
    '200204 C- -------- καὶ καὶ καί καί',
    '200204 V- 2AMI-P-- ἐγένεσθε ἐγένεσθε ἐγένεσθε γίνομαι',
    '200204 N- ----NPM- κριταὶ κριταὶ κριταί κριτής',
    '200204 N- ----GPM- διαλογισμῶν διαλογισμῶν διαλογισμῶν διαλογισμός',
    '200204 A- ----GPM- πονηρῶν; πονηρῶν πονηρῶν πονηρός',
    '200205 V- 2AAD-P-- ἀκούσατε, ἀκούσατε ἀκούσατε ἀκούω',
    '200205 N- ----VPM- ἀδελφοί ἀδελφοί ἀδελφοί ἀδελφός',
    '200205 RP ----GS-- μου μου μου ἐγώ',
    '200205 A- ----VPM- ἀγαπητοί. ἀγαπητοί ἀγαπητοί ἀγαπητός',
    '200205 X- -------- οὐχ οὐχ οὐ οὐ',
    '200205 RA ----NSM- ὁ ὁ ὁ ὁ',
    '200205 N- ----NSM- θεὸς θεὸς θεός θεός',
    '200205 V- 3AMI-S-- ἐξελέξατο ἐξελέξατο ἐξελέξατο ἐκλέγομαι',
    '200205 RA ----APM- τοὺς τοὺς τούς ὁ',
    '200205 A- ----APM- πτωχοὺς πτωχοὺς πτωχούς πτωχός',
    '200205 RA ----DSM- ⸂τῷ τῷ τῷ ὁ',
    '200205 N- ----DSM- κόσμῳ⸃ κόσμῳ κόσμῳ κόσμος',
    '200205 A- ----APM- πλουσίους πλουσίους πλουσίους πλούσιος',
    '200205 P- -------- ἐν ἐν ἐν ἐν',
    '200205 N- ----DSF- πίστει πίστει πίστει πίστις',
    '200205 C- -------- καὶ καὶ καί καί',
    '200205 N- ----APM- κληρονόμους κληρονόμους κληρονόμους κληρονόμος',
    '200205 RA ----GSF- τῆς τῆς τῆς ὁ',
    '200205 N- ----GSF- βασιλείας βασιλείας βασιλείας βασιλεία',
    '200205 RR ----GSF- ἧς ἧς ἧς ὅς',
    '200205 V- 3AMI-S-- ἐπηγγείλατο ἐπηγγείλατο ἐπηγγείλατο ἐπαγγέλλομαι',
    '200205 RA ----DPM- τοῖς τοῖς τοῖς ὁ',
    '200205 V- -PAPDPM- ἀγαπῶσιν ἀγαπῶσιν ἀγαπῶσι(ν) ἀγαπάω',
    '200205 RP ----ASM- αὐτόν; αὐτόν αὐτόν αὐτός',
    '200206 RP ----NP-- ὑμεῖς ὑμεῖς ὑμεῖς σύ',
    '200206 C- -------- δὲ δὲ δέ δέ',
    '200206 V- 2AAI-P-- ἠτιμάσατε ἠτιμάσατε ἠτιμάσατε ἀτιμάζω',
    '200206 RA ----ASM- τὸν τὸν τόν ὁ',
    '200206 A- ----ASM- πτωχόν. πτωχόν πτωχόν πτωχός',
    '200206 X- -------- οὐχ οὐχ οὐ οὐ',
    '200206 RA ----NPM- οἱ οἱ οἱ ὁ',
    '200206 A- ----NPM- πλούσιοι πλούσιοι πλούσιοι πλούσιος',
    '200206 V- 3PAI-P-- καταδυναστεύουσιν καταδυναστεύουσιν καταδυναστεύουσι(ν) καταδυναστεύω',
    '200206 RP ----GP-- ὑμῶν, ὑμῶν ὑμῶν σύ',
    '200206 C- -------- καὶ καὶ καί καί',
    '200206 RP ----NPM- αὐτοὶ αὐτοὶ αὐτοί αὐτός',
    '200206 V- 3PAI-P-- ἕλκουσιν ἕλκουσιν ἕλκουσι(ν) ἕλκω',
    '200206 RP ----AP-- ὑμᾶς ὑμᾶς ὑμᾶς σύ',
    '200206 P- -------- εἰς εἰς εἰς εἰς',
    '200206 N- ----APN- κριτήρια; κριτήρια κριτήρια κριτήριον',
    '200207 X- -------- οὐκ οὐκ οὐ οὐ',
    '200207 RP ----NPM- αὐτοὶ αὐτοὶ αὐτοί αὐτός',
    '200207 V- 3PAI-P-- βλασφημοῦσιν βλασφημοῦσιν βλασφημοῦσι(ν) βλασφημέω',
    '200207 RA ----ASN- τὸ τὸ τό ὁ',
    '200207 A- ----ASN- καλὸν καλὸν καλόν καλός',
    '200207 N- ----ASN- ὄνομα ὄνομα ὄνομα ὄνομα',
    '200207 RA ----ASN- τὸ τὸ τό ὁ',
    '200207 V- -APPASN- ἐπικληθὲν ἐπικληθὲν ἐπικληθέν ἐπικαλέω',
    '200207 P- -------- ἐφ’ ἐφ’ ἐπί ἐπί',
    '200207 RP ----AP-- ὑμᾶς; ὑμᾶς ὑμᾶς σύ',
    '200208 C- -------- Εἰ Εἰ εἰ εἰ',
    '200208 C- -------- μέντοι μέντοι μέντοι μέντοι',
    '200208 N- ----ASM- νόμον νόμον νόμον νόμος',
    '200208 V- 2PAI-P-- τελεῖτε τελεῖτε τελεῖτε τελέω',
    '200208 A- ----ASM- βασιλικὸν βασιλικὸν βασιλικόν βασιλικός',
    '200208 P- -------- κατὰ κατὰ κατά κατά',
    '200208 RA ----ASF- τὴν τὴν τήν ὁ',
    '200208 N- ----ASF- γραφήν γραφήν γραφήν γραφή',
    '200208 V- 2FAI-S-- Ἀγαπήσεις Ἀγαπήσεις ἀγαπήσεις ἀγαπάω',
    '200208 RA ----ASM- τὸν τὸν τόν ὁ',
    '200208 D- -------- πλησίον πλησίον πλησίον πλησίον',
    '200208 RP ----GS-- σου σου σου σύ',
    '200208 C- -------- ὡς ὡς ὡς ὡς',
    '200208 RP ----ASM- σεαυτόν, σεαυτόν σεαυτόν σεαυτοῦ',
    '200208 D- -------- καλῶς καλῶς καλῶς καλῶς',
    '200208 V- 2PAI-P-- ποιεῖτε· ποιεῖτε ποιεῖτε ποιέω',
    '200209 C- -------- εἰ εἰ εἰ εἰ',
    '200209 C- -------- δὲ δὲ δέ δέ',
    '200209 V- 2PAI-P-- προσωπολημπτεῖτε, προσωπολημπτεῖτε προσωπολημπτεῖτε προσωπολημπτέω',
    '200209 N- ----ASF- ἁμαρτίαν ἁμαρτίαν ἁμαρτίαν ἁμαρτία',
    '200209 V- 2PMI-P-- ἐργάζεσθε, ἐργάζεσθε ἐργάζεσθε ἐργάζομαι',
    '200209 V- -PPPNPM- ἐλεγχόμενοι ἐλεγχόμενοι ἐλεγχόμενοι ἐλέγχω',
    '200209 P- -------- ὑπὸ ὑπὸ ὑπό ὑπό',
    '200209 RA ----GSM- τοῦ τοῦ τοῦ ὁ',
    '200209 N- ----GSM- νόμου νόμου νόμου νόμος',
    '200209 C- -------- ὡς ὡς ὡς ὡς',
    '200209 N- ----NPM- παραβάται. παραβάται παραβάται παραβάτης',
    '200210 RR ----NSM- ὅστις ὅστις ὅστις ὅστις',
    '200210 C- -------- γὰρ γὰρ γάρ γάρ',
    '200210 A- ----ASM- ὅλον ὅλον ὅλον ὅλος',
    '200210 RA ----ASM- τὸν τὸν τόν ὁ',
    '200210 N- ----ASM- νόμον νόμον νόμον νόμος',
    '200210 V- 3AAS-S-- ⸂τηρήσῃ, τηρήσῃ τηρήσῃ τηρέω',
    '200210 V- 3AAS-S-- πταίσῃ⸃ πταίσῃ πταίσῃ πταίω',
    '200210 C- -------- δὲ δὲ δέ δέ',
    '200210 P- -------- ἐν ἐν ἐν ἐν',
    '200210 A- ----DSN- ἑνί, ἑνί ἑνί εἷς',
    '200210 V- 3XAI-S-- γέγονεν γέγονεν γέγονε(ν) γίνομαι',
    '200210 A- ----GPN- πάντων πάντων πάντων πᾶς',
    '200210 A- ----NSM- ἔνοχος. ἔνοχος ἔνοχος ἔνοχος',
    '200211 RA ----NSM- ὁ ὁ ὁ ὁ',
    '200211 C- -------- γὰρ γὰρ γάρ γάρ',
    '200211 V- -AAPNSM- εἰπών εἰπών εἰπών λέγω',
    '200211 D- -------- Μὴ Μὴ μή μή',
    '200211 V- 2AAS-S-- ⸀μοιχεύσῃς μοιχεύσῃς μοιχεύσῃς μοιχεύω',
    '200211 V- 3AAI-S-- εἶπεν εἶπεν εἶπε(ν) λέγω',
    '200211 D- -------- καί καί καί καί',
    '200211 D- -------- Μὴ Μὴ μή μή',
    '200211 V- 2AAS-S-- ⸀φονεύσῃς· φονεύσῃς φονεύσῃς φονεύω',
    '200211 C- -------- εἰ εἰ εἰ εἰ',
    '200211 C- -------- δὲ δὲ δέ δέ',
    '200211 D- -------- οὐ οὐ οὐ οὐ',
    '200211 V- 2PAI-S-- ⸂μοιχεύεις μοιχεύεις μοιχεύεις μοιχεύω',
    '200211 V- 2PAI-S-- φονεύεις⸃ φονεύεις φονεύεις φονεύω',
    '200211 C- -------- δέ, δέ δέ δέ',
    '200211 V- 2XAI-S-- γέγονας γέγονας γέγονας γίνομαι',
    '200211 N- ----NSM- παραβάτης παραβάτης παραβάτης παραβάτης',
    '200211 N- ----GSM- νόμου. νόμου νόμου νόμος',
    '200212 D- -------- οὕτως οὕτως οὕτω(ς) οὕτω(ς)',
    '200212 V- 2PAD-P-- λαλεῖτε λαλεῖτε λαλεῖτε λαλέω',
    '200212 C- -------- καὶ καὶ καί καί',
    '200212 D- -------- οὕτως οὕτως οὕτω(ς) οὕτω(ς)',
    '200212 V- 2PAD-P-- ποιεῖτε ποιεῖτε ποιεῖτε ποιέω',
    '200212 C- -------- ὡς ὡς ὡς ὡς',
    '200212 P- -------- διὰ διὰ διά διά',
    '200212 N- ----GSM- νόμου νόμου νόμου νόμος',
    '200212 N- ----GSF- ἐλευθερίας ἐλευθερίας ἐλευθερίας ἐλευθερία',
    '200212 V- -PAPNPM- μέλλοντες μέλλοντες μέλλοντες μέλλω',
    '200212 V- -PPN---- κρίνεσθαι. κρίνεσθαι κρίνεσθαι κρίνω',
    '200213 RA ----NSF- ἡ ἡ ἡ ὁ',
    '200213 C- -------- γὰρ γὰρ γάρ γάρ',
    '200213 N- ----NSF- κρίσις κρίσις κρίσις κρίσις',
    '200213 A- ----NSF- ἀνέλεος ἀνέλεος ἀνέλεος ἀνέλεος',
    '200213 RA ----DSM- τῷ τῷ τῷ ὁ',
    '200213 D- -------- μὴ μὴ μή μή',
    '200213 V- -AAPDSM- ποιήσαντι ποιήσαντι ποιήσαντι ποιέω',
    '200213 N- ----ASN- ἔλεος· ἔλεος ἔλεος ἔλεος',
    '200213 V- 3PMI-S-- κατακαυχᾶται κατακαυχᾶται κατακαυχᾶται κατακαυχάομαι',
    '200213 N- ----NSN- ⸀ἔλεος ἔλεος ἔλεος ἔλεος',
    '200213 N- ----GSF- κρίσεως. κρίσεως κρίσεως κρίσις',
    '200214 RI ----NSN- ⸀Τί Τί τί τίς',
    '200214 N- ----NSN- ὄφελος, ὄφελος ὄφελος ὄφελος',
    '200214 N- ----VPM- ἀδελφοί ἀδελφοί ἀδελφοί ἀδελφός',
    '200214 RP ----GS-- μου, μου μου ἐγώ',
    '200214 C- -------- ἐὰν ἐὰν ἐάν ἐάν',
    '200214 N- ----ASF- πίστιν πίστιν πίστιν πίστις',
    '200214 V- 3PAS-S-- λέγῃ λέγῃ λέγῃ λέγω',
    '200214 RI ----NSM- τις τις τὶς τις',
    '200214 V- -PAN---- ἔχειν ἔχειν ἔχειν ἔχω',
    '200214 N- ----APN- ἔργα ἔργα ἔργα ἔργον',
    '200214 C- -------- δὲ δὲ δέ δέ',
    '200214 D- -------- μὴ μὴ μή μή',
    '200214 V- 3PAS-S-- ἔχῃ; ἔχῃ ἔχῃ ἔχω',
    '200214 X- -------- μὴ μὴ μή μή',
    '200214 V- 3PMI-S-- δύναται δύναται δύναται δύναμαι',
    '200214 RA ----NSF- ἡ ἡ ἡ ὁ',
    '200214 N- ----NSF- πίστις πίστις πίστις πίστις',
    '200214 V- -AAN---- σῶσαι σῶσαι σῶσαι σῴζω',
    '200214 RP ----ASM- αὐτόν; αὐτόν αὐτόν αὐτός',
    '200215 C- -------- ⸀ἐὰν ἐὰν ἐάν ἐάν',
    '200215 N- ----NSM- ἀδελφὸς ἀδελφὸς ἀδελφός ἀδελφός',
    '200215 C- -------- ἢ ἢ ἤ ἤ',
    '200215 N- ----NSF- ἀδελφὴ ἀδελφὴ ἀδελφή ἀδελφή',
    '200215 A- ----NPM- γυμνοὶ γυμνοὶ γυμνοί γυμνός',
    '200215 V- 3PAS-P-- ὑπάρχωσιν ὑπάρχωσιν ὑπάρχωσι(ν) ὑπάρχω',
    '200215 C- -------- καὶ καὶ καί καί',
    '200215 V- -PMPNPM- ⸀λειπόμενοι λειπόμενοι λειπόμενοι λείπω',
    '200215 RA ----GSF- τῆς τῆς τῆς ὁ',
    '200215 A- ----GSF- ἐφημέρου ἐφημέρου ἐφημέρου ἐφήμερος',
    '200215 N- ----GSF- τροφῆς, τροφῆς τροφῆς τροφή',
    '200216 V- 3AAS-S-- εἴπῃ εἴπῃ εἴπῃ λέγω',
    '200216 C- -------- δέ δέ δέ δέ',
    '200216 RI ----NSM- τις τις τὶς τις',
    '200216 RP ----DPM- αὐτοῖς αὐτοῖς αὐτοῖς αὐτός',
    '200216 P- -------- ἐξ ἐξ ἐκ ἐκ',
    '200216 RP ----GP-- ὑμῶν· ὑμῶν ὑμῶν σύ',
    '200216 V- 2PAD-P-- Ὑπάγετε Ὑπάγετε ὑπάγετε ὑπάγω',
    '200216 P- -------- ἐν ἐν ἐν ἐν',
    '200216 N- ----DSF- εἰρήνῃ, εἰρήνῃ εἰρήνῃ εἰρήνη',
    '200216 V- 2PMD-P-- θερμαίνεσθε θερμαίνεσθε θερμαίνεσθε θερμαίνομαι',
    '200216 C- -------- καὶ καὶ καί καί',
    '200216 V- 2PMD-P-- χορτάζεσθε, χορτάζεσθε χορτάζεσθε χορτάζω',
    '200216 D- -------- μὴ μὴ μή μή',
    '200216 V- 2AAS-P-- δῶτε δῶτε δῶτε δίδωμι',
    '200216 C- -------- δὲ δὲ δέ δέ',
    '200216 RP ----DPM- αὐτοῖς αὐτοῖς αὐτοῖς αὐτός',
    '200216 RA ----APN- τὰ τὰ τά ὁ',
    '200216 A- ----APN- ἐπιτήδεια ἐπιτήδεια ἐπιτήδεια ἐπιτήδειος',
    '200216 RA ----GSN- τοῦ τοῦ τοῦ ὁ',
    '200216 N- ----GSN- σώματος, σώματος σώματος σῶμα',
    '200216 RI ----NSN- ⸀τί τί τί τίς',
    '200216 N- ----NSN- ὄφελος; ὄφελος ὄφελος ὄφελος',
    '200217 D- -------- οὕτως οὕτως οὕτω(ς) οὕτω(ς)',
    '200217 D- -------- καὶ καὶ καί καί',
    '200217 RA ----NSF- ἡ ἡ ἡ ὁ',
    '200217 N- ----NSF- πίστις, πίστις πίστις πίστις',
    '200217 C- -------- ἐὰν ἐὰν ἐάν ἐάν',
    '200217 D- -------- μὴ μὴ μή μή',
    '200217 V- 3PAS-S-- ⸂ἔχῃ ἔχῃ ἔχῃ ἔχω',
    '200217 N- ----APN- ἔργα⸃, ἔργα ἔργα ἔργον',
    '200217 A- ----NSF- νεκρά νεκρά νεκρά νεκρός',
    '200217 V- 3PAI-S-- ἐστιν ἐστιν ἐστί(ν) εἰμί',
    '200217 P- -------- καθ’ καθ’ κατά κατά',
    '200217 RP ----ASF- ἑαυτήν. ἑαυτήν ἑαυτήν ἑαυτοῦ',
    '200218 C- -------- Ἀλλ’ Ἀλλ’ ἀλλά ἀλλά',
    '200218 V- 3FAI-S-- ἐρεῖ ἐρεῖ ἐρεῖ λέγω',
    '200218 RI ----NSM- τις· τις τὶς τις',
    '200218 RP ----NS-- Σὺ Σὺ σύ σύ',
    '200218 N- ----ASF- πίστιν πίστιν πίστιν πίστις',
    '200218 V- 2PAI-S-- ἔχεις ἔχεις ἔχεις ἔχω',
    '200218 RP ----NS-- κἀγὼ κἀγὼ κἀγώ κἀγώ',
    '200218 N- ----APN- ἔργα ἔργα ἔργα ἔργον',
    '200218 V- 1PAI-S-- ἔχω. ἔχω ἔχω ἔχω',
    '200218 V- 2AAD-S-- δεῖξόν δεῖξόν δεῖξον δείκνυμι',
    '200218 RP ----DS-- μοι μοι μοι ἐγώ',
    '200218 RA ----ASF- τὴν τὴν τήν ὁ',
    '200218 N- ----ASF- πίστιν πίστιν πίστιν πίστις',
    '200218 RP ----GS-- σου σου σου σύ',
    '200218 P- -------- ⸀χωρὶς χωρὶς χωρίς χωρίς',
    '200218 RA ----GPN- τῶν τῶν τῶν ὁ',
    '200218 N- ----GPN- ⸀ἔργων, ἔργων ἔργων ἔργον',
    '200218 RP ----NS-- κἀγώ κἀγώ κἀγώ κἀγώ',
    '200218 RP ----DS-- ⸂σοι σοι σοι σύ',
    '200218 V- 1FAI-S-- δείξω⸃ δείξω δείξω δείκνυμι',
    '200218 P- -------- ἐκ ἐκ ἐκ ἐκ',
    '200218 RA ----GPN- τῶν τῶν τῶν ὁ',
    '200218 N- ----GPN- ἔργων ἔργων ἔργων ἔργον',
    '200218 RP ----GS-- μου μου μου ἐγώ',
    '200218 RA ----ASF- τὴν τὴν τήν ὁ',
    '200218 N- ----ASF- ⸀πίστιν. πίστιν πίστιν πίστις',
    '200219 RP ----NS-- σὺ σὺ σύ σύ',
    '200219 V- 2PAI-S-- πιστεύεις πιστεύεις πιστεύεις πιστεύω',
    '200219 C- -------- ὅτι ὅτι ὅτι ὅτι',
    '200219 A- ----NSM- ⸂εἷς εἷς εἷς εἷς',
    '200219 V- 3PAI-S-- ἐστιν ἐστιν ἐστί(ν) εἰμί',
    '200219 RA ----NSM- ὁ ὁ ὁ ὁ',
    '200219 N- ----NSM- θεός⸃; θεός θεός θεός',
    '200219 D- -------- καλῶς καλῶς καλῶς καλῶς',
    '200219 V- 2PAI-S-- ποιεῖς· ποιεῖς ποιεῖς ποιέω',
    '200219 D- -------- καὶ καὶ καί καί',
    '200219 RA ----NPN- τὰ τὰ τά ὁ',
    '200219 N- ----NPN- δαιμόνια δαιμόνια δαιμόνια δαιμόνιον',
    '200219 V- 3PAI-P-- πιστεύουσιν πιστεύουσιν πιστεύουσι(ν) πιστεύω',
    '200219 C- -------- καὶ καὶ καί καί',
    '200219 V- 3PAI-P-- φρίσσουσιν. φρίσσουσιν φρίσσουσι(ν) φρίσσω',
    '200220 V- 2PAI-S-- θέλεις θέλεις θέλεις θέλω',
    '200220 C- -------- δὲ δὲ δέ δέ',
    '200220 V- -AAN---- γνῶναι, γνῶναι γνῶναι γινώσκω',
    '200220 I- -------- ὦ ὦ ὦ ὦ',
    '200220 N- ----VSM- ἄνθρωπε ἄνθρωπε ἄνθρωπε ἄνθρωπος',
    '200220 A- ----VSM- κενέ, κενέ κενέ κενός',
    '200220 C- -------- ὅτι ὅτι ὅτι ὅτι',
    '200220 RA ----NSF- ἡ ἡ ἡ ὁ',
    '200220 N- ----NSF- πίστις πίστις πίστις πίστις',
    '200220 P- -------- χωρὶς χωρὶς χωρίς χωρίς',
    '200220 RA ----GPN- τῶν τῶν τῶν ὁ',
    '200220 N- ----GPN- ἔργων ἔργων ἔργων ἔργον',
    '200220 A- ----NSF- ⸀ἀργή ἀργή ἀργή ἀργός',
    '200220 V- 3PAI-S-- ἐστιν; ἐστιν ἐστί(ν) εἰμί',
    '200221 N- ----NSM- Ἀβραὰμ Ἀβραὰμ Ἀβραάμ Ἀβραάμ',
    '200221 RA ----NSM- ὁ ὁ ὁ ὁ',
    '200221 N- ----NSM- πατὴρ πατὴρ πατήρ πατήρ',
    '200221 RP ----GP-- ἡμῶν ἡμῶν ἡμῶν ἐγώ',
    '200221 X- -------- οὐκ οὐκ οὐ οὐ',
    '200221 P- -------- ἐξ ἐξ ἐκ ἐκ',
    '200221 N- ----GPN- ἔργων ἔργων ἔργων ἔργον',
    '200221 V- 3API-S-- ἐδικαιώθη, ἐδικαιώθη ἐδικαιώθη δικαιόω',
    '200221 V- -AAPNSM- ἀνενέγκας ἀνενέγκας ἀνενέγκας ἀναφέρω',
    '200221 N- ----ASM- Ἰσαὰκ Ἰσαὰκ Ἰσαάκ Ἰσαάκ',
    '200221 RA ----ASM- τὸν τὸν τόν ὁ',
    '200221 N- ----ASM- υἱὸν υἱὸν υἱόν υἱός',
    '200221 RP ----GSM- αὐτοῦ αὐτοῦ αὐτοῦ αὐτός',
    '200221 P- -------- ἐπὶ ἐπὶ ἐπί ἐπί',
    '200221 RA ----ASN- τὸ τὸ τό ὁ',
    '200221 N- ----ASN- θυσιαστήριον; θυσιαστήριον θυσιαστήριον θυσιαστήριον',
    '200222 V- 2PAI-S-- βλέπεις βλέπεις βλέπεις βλέπω',
    '200222 C- -------- ὅτι ὅτι ὅτι ὅτι',
    '200222 RA ----NSF- ἡ ἡ ἡ ὁ',
    '200222 N- ----NSF- πίστις πίστις πίστις πίστις',
    '200222 V- 3IAI-S-- συνήργει συνήργει συνήργει συνεργέω',
    '200222 RA ----DPN- τοῖς τοῖς τοῖς ὁ',
    '200222 N- ----DPN- ἔργοις ἔργοις ἔργοις ἔργον',
    '200222 RP ----GSM- αὐτοῦ αὐτοῦ αὐτοῦ αὐτός',
    '200222 C- -------- καὶ καὶ καί καί',
    '200222 P- -------- ἐκ ἐκ ἐκ ἐκ',
    '200222 RA ----GPN- τῶν τῶν τῶν ὁ',
    '200222 N- ----GPN- ἔργων ἔργων ἔργων ἔργον',
    '200222 RA ----NSF- ἡ ἡ ἡ ὁ',
    '200222 N- ----NSF- πίστις πίστις πίστις πίστις',
    '200222 V- 3API-S-- ἐτελειώθη, ἐτελειώθη ἐτελειώθη τελειόω',
    '200223 C- -------- καὶ καὶ καί καί',
    '200223 V- 3API-S-- ἐπληρώθη ἐπληρώθη ἐπληρώθη πληρόω',
    '200223 RA ----NSF- ἡ ἡ ἡ ὁ',
    '200223 N- ----NSF- γραφὴ γραφὴ γραφή γραφή',
    '200223 RA ----NSF- ἡ ἡ ἡ ὁ',
    '200223 V- -PAPNSF- λέγουσα· λέγουσα λέγουσα λέγω',
    '200223 V- 3AAI-S-- Ἐπίστευσεν Ἐπίστευσεν ἐπίστευσε(ν) πιστεύω',
    '200223 C- -------- δὲ δὲ δέ δέ',
    '200223 N- ----NSM- Ἀβραὰμ Ἀβραὰμ Ἀβραάμ Ἀβραάμ',
    '200223 RA ----DSM- τῷ τῷ τῷ ὁ',
    '200223 N- ----DSM- θεῷ, θεῷ θεῷ θεός',
    '200223 C- -------- καὶ καὶ καί καί',
    '200223 V- 3API-S-- ἐλογίσθη ἐλογίσθη ἐλογίσθη λογίζομαι',
    '200223 RP ----DSM- αὐτῷ αὐτῷ αὐτῷ αὐτός',
    '200223 P- -------- εἰς εἰς εἰς εἰς',
    '200223 N- ----ASF- δικαιοσύνην, δικαιοσύνην δικαιοσύνην δικαιοσύνη',
    '200223 C- -------- καὶ καὶ καί καί',
    '200223 A- ----NSM- φίλος φίλος φίλος φίλος',
    '200223 N- ----GSM- θεοῦ θεοῦ θεοῦ θεός',
    '200223 V- 3API-S-- ἐκλήθη. ἐκλήθη ἐκλήθη καλέω',
    '200224 V- 2PAI-P-- ⸀ὁρᾶτε ὁρᾶτε ὁρᾶτε ὁράω',
    '200224 C- -------- ὅτι ὅτι ὅτι ὅτι',
    '200224 P- -------- ἐξ ἐξ ἐκ ἐκ',
    '200224 N- ----GPN- ἔργων ἔργων ἔργων ἔργον',
    '200224 V- 3PPI-S-- δικαιοῦται δικαιοῦται δικαιοῦται δικαιόω',
    '200224 N- ----NSM- ἄνθρωπος ἄνθρωπος ἄνθρωπος ἄνθρωπος',
    '200224 C- -------- καὶ καὶ καί καί',
    '200224 D- -------- οὐκ οὐκ οὐ οὐ',
    '200224 P- -------- ἐκ ἐκ ἐκ ἐκ',
    '200224 N- ----GSF- πίστεως πίστεως πίστεως πίστις',
    '200224 A- ----ASN- μόνον. μόνον μόνον μόνος',
    '200225 D- -------- ὁμοίως ὁμοίως ὁμοίως ὁμοίως',
    '200225 C- -------- δὲ δὲ δέ δέ',
    '200225 D- -------- καὶ καὶ καί καί',
    '200225 N- ----NSF- Ῥαὰβ Ῥαὰβ Ῥαάβ Ῥαάβ',
    '200225 RA ----NSF- ἡ ἡ ἡ ὁ',
    '200225 N- ----NSF- πόρνη πόρνη πόρνη πόρνη',
    '200225 X- -------- οὐκ οὐκ οὐ οὐ',
    '200225 P- -------- ἐξ ἐξ ἐκ ἐκ',
    '200225 N- ----GPN- ἔργων ἔργων ἔργων ἔργον',
    '200225 V- 3API-S-- ἐδικαιώθη, ἐδικαιώθη ἐδικαιώθη δικαιόω',
    '200225 V- -AMPNSF- ὑποδεξαμένη ὑποδεξαμένη ὑποδεξαμένη ὑποδέχομαι',
    '200225 RA ----APM- τοὺς τοὺς τούς ὁ',
    '200225 N- ----APM- ἀγγέλους ἀγγέλους ἀγγέλους ἄγγελος',
    '200225 C- -------- καὶ καὶ καί καί',
    '200225 A- ----DSF- ἑτέρᾳ ἑτέρᾳ ἑτέρᾳ ἕτερος',
    '200225 N- ----DSF- ὁδῷ ὁδῷ ὁδῷ ὁδός',
    '200225 V- -AAPNSF- ἐκβαλοῦσα; ἐκβαλοῦσα ἐκβαλοῦσα ἐκβάλλω',
    '200226 C- -------- ὥσπερ ὥσπερ ὥσπερ ὥσπερ',
    '200226 C- -------- ⸀γὰρ γὰρ γάρ γάρ',
    '200226 RA ----NSN- τὸ τὸ τό ὁ',
    '200226 N- ----NSN- σῶμα σῶμα σῶμα σῶμα',
    '200226 P- -------- χωρὶς χωρὶς χωρίς χωρίς',
    '200226 N- ----GSN- πνεύματος πνεύματος πνεύματος πνεῦμα',
    '200226 A- ----NSN- νεκρόν νεκρόν νεκρόν νεκρός',
    '200226 V- 3PAI-S-- ἐστιν, ἐστιν ἐστί(ν) εἰμί',
    '200226 D- -------- οὕτως οὕτως οὕτω(ς) οὕτω(ς)',
    '200226 D- -------- καὶ καὶ καί καί',
    '200226 RA ----NSF- ἡ ἡ ἡ ὁ',
    '200226 N- ----NSF- πίστις πίστις πίστις πίστις',
    '200226 P- -------- ⸀χωρὶς χωρὶς χωρίς χωρίς',
    '200226 N- ----GPN- ἔργων ἔργων ἔργων ἔργον',
    '200226 A- ----NSF- νεκρά νεκρά νεκρά νεκρός',
    '200226 V- 3PAI-S-- ἐστιν. ἐστιν ἐστί(ν) εἰμί',
    '200301 D- -------- Μὴ Μὴ μή μή',
    '200301 A- ----NPM- πολλοὶ πολλοὶ πολλοί πολύς',
    '200301 N- ----NPM- διδάσκαλοι διδάσκαλοι διδάσκαλοι διδάσκαλος',
    '200301 V- 2PMD-P-- γίνεσθε, γίνεσθε γίνεσθε γίνομαι',
    '200301 N- ----VPM- ἀδελφοί ἀδελφοί ἀδελφοί ἀδελφός',
    '200301 RP ----GS-- μου, μου μου ἐγώ',
    '200301 V- -XAPNPM- εἰδότες εἰδότες εἰδότες οἶδα',
    '200301 C- -------- ὅτι ὅτι ὅτι ὅτι',
    '200301 A- ----ASNC μεῖζον μεῖζον μεῖζον μέγας',
    '200301 N- ----ASN- κρίμα κρίμα κρίμα κρίμα',
    '200301 V- 1FMI-P-- λημψόμεθα· λημψόμεθα λημψόμεθα λαμβάνω',
    '200302 A- ----APN- πολλὰ πολλὰ πολλά πολύς',
    '200302 C- -------- γὰρ γὰρ γάρ γάρ',
    '200302 V- 1PAI-P-- πταίομεν πταίομεν πταίομεν πταίω',
    '200302 A- ----NPM- ἅπαντες. ἅπαντες ἅπαντες ἅπας',
    '200302 C- -------- εἴ εἴ εἰ εἰ',
    '200302 RI ----NSM- τις τις τὶς τις',
    '200302 P- -------- ἐν ἐν ἐν ἐν',
    '200302 N- ----DSM- λόγῳ λόγῳ λόγῳ λόγος',
    '200302 D- -------- οὐ οὐ οὐ οὐ',
    '200302 V- 3PAI-S-- πταίει, πταίει πταίει πταίω',
    '200302 RD ----NSM- οὗτος οὗτος οὗτος οὗτος',
    '200302 A- ----NSM- τέλειος τέλειος τέλειος τέλειος',
    '200302 N- ----NSM- ἀνήρ, ἀνήρ ἀνήρ ἀνήρ',
    '200302 A- ----NSM- δυνατὸς δυνατὸς δυνατός δυνατός',
    '200302 V- -AAN---- χαλιναγωγῆσαι χαλιναγωγῆσαι χαλιναγωγῆσαι χαλιναγωγέω',
    '200302 D- -------- καὶ καὶ καί καί',
    '200302 A- ----ASN- ὅλον ὅλον ὅλον ὅλος',
    '200302 RA ----ASN- τὸ τὸ τό ὁ',
    '200302 N- ----ASN- σῶμα. σῶμα σῶμα σῶμα',
    '200303 C- -------- ⸂εἰ εἰ εἰ εἰ',
    '200303 C- -------- δὲ⸃ δὲ δέ δέ',
    '200303 RA ----GPM- τῶν τῶν τῶν ὁ',
    '200303 N- ----GPM- ἵππων ἵππων ἵππων ἵππος',
    '200303 RA ----APM- τοὺς τοὺς τούς ὁ',
    '200303 N- ----APM- χαλινοὺς χαλινοὺς χαλινούς χαλινός',
    '200303 P- -------- εἰς εἰς εἰς εἰς',
    '200303 RA ----APN- τὰ τὰ τά ὁ',
    '200303 N- ----APN- στόματα στόματα στόματα στόμα',
    '200303 V- 1PAI-P-- βάλλομεν βάλλομεν βάλλομεν βάλλω',
    '200303 P- -------- ⸀εἰς εἰς εἰς εἰς',
    '200303 RA ----ASN- τὸ τὸ τό ὁ',
    '200303 V- -PPN---- πείθεσθαι πείθεσθαι πείθεσθαι πείθω',
    '200303 RP ----APM- αὐτοὺς αὐτοὺς αὐτούς αὐτός',
    '200303 RP ----DP-- ἡμῖν, ἡμῖν ἡμῖν ἐγώ',
    '200303 D- -------- καὶ καὶ καί καί',
    '200303 A- ----ASN- ὅλον ὅλον ὅλον ὅλος',
    '200303 RA ----ASN- τὸ τὸ τό ὁ',
    '200303 N- ----ASN- σῶμα σῶμα σῶμα σῶμα',
    '200303 RP ----GPM- αὐτῶν αὐτῶν αὐτῶν αὐτός',
    '200303 V- 1PAI-P-- μετάγομεν. μετάγομεν μετάγομεν μετάγω',
    '200304 X- -------- ἰδοὺ ἰδοὺ ἰδού ἰδού',
    '200304 D- -------- καὶ καὶ καί καί',
    '200304 RA ----NPN- τὰ τὰ τά ὁ',
    '200304 N- ----NPN- πλοῖα, πλοῖα πλοῖα πλοῖον',
    '200304 RD ----NPN- τηλικαῦτα τηλικαῦτα τηλικαῦτα τηλικοῦτος',
    '200304 V- -PAPNPN- ὄντα ὄντα ὄντα εἰμί',
    '200304 C- -------- καὶ καὶ καί καί',
    '200304 P- -------- ὑπὸ ὑπὸ ὑπό ὑπό',
    '200304 N- ----GPM- ⸂ἀνέμων ἀνέμων ἀνέμων ἄνεμος',
    '200304 A- ----GPM- σκληρῶν⸃ σκληρῶν σκληρῶν σκληρός',
    '200304 V- -PPPNPN- ἐλαυνόμενα, ἐλαυνόμενα ἐλαυνόμενα ἐλαύνω',
    '200304 V- 3PPI-S-- μετάγεται μετάγεται μετάγεται μετάγω',
    '200304 P- -------- ὑπὸ ὑπὸ ὑπό ὑπό',
    '200304 A- ----GSNS ἐλαχίστου ἐλαχίστου ἐλαχίστου ἐλάχιστος',
    '200304 N- ----GSN- πηδαλίου πηδαλίου πηδαλίου πηδάλιον',
    '200304 C- -------- ὅπου ὅπου ὅπου ὅπου',
    '200304 RA ----NSF- ⸂ἡ ἡ ἡ ὁ',
    '200304 N- ----NSF- ὁρμὴ ὁρμὴ ὁρμή ὁρμή',
    '200304 RA ----GSM- τοῦ τοῦ τοῦ ὁ',
    '200304 V- -PAPGSM- εὐθύνοντος εὐθύνοντος εὐθύνοντος εὐθύνω',
    '200304 V- 3PMI-S-- βούλεται⸃· βούλεται βούλεται βούλομαι',
    '200305 D- -------- οὕτως οὕτως οὕτω(ς) οὕτω(ς)',
    '200305 D- -------- καὶ καὶ καί καί',
    '200305 RA ----NSF- ἡ ἡ ἡ ὁ',
    '200305 N- ----NSF- γλῶσσα γλῶσσα γλῶσσα γλῶσσα',
    '200305 A- ----NSN- μικρὸν μικρὸν μικρόν μικρός',
    '200305 N- ----NSN- μέλος μέλος μέλος μέλος',
    '200305 V- 3PAI-S-- ἐστὶν ἐστὶν ἐστί(ν) εἰμί',
    '200305 C- -------- καὶ καὶ καί καί',
    '200305 A- ----APN- ⸂μεγάλα μεγάλα μεγάλα μέγας',
    '200305 V- 3PAI-S-- αὐχεῖ⸃. αὐχεῖ αὐχεῖ αὐχέω',
    '200305 X- -------- Ἰδοὺ Ἰδοὺ ἰδού ἰδού',
    '200305 RI ----NSN- ⸀ἡλίκον ἡλίκον ἡλίκον ἡλίκος',
    '200305 N- ----NSN- πῦρ πῦρ πῦρ πῦρ',
    '200305 RI ----ASF- ἡλίκην ἡλίκην ἡλίκην ἡλίκος',
    '200305 N- ----ASF- ὕλην ὕλην ὕλην ὕλη',
    '200305 V- 3PAI-S-- ἀνάπτει· ἀνάπτει ἀνάπτει ἀνάπτω',
    '200306 C- -------- καὶ καὶ καί καί',
    '200306 RA ----NSF- ἡ ἡ ἡ ὁ',
    '200306 N- ----NSF- γλῶσσα γλῶσσα γλῶσσα γλῶσσα',
    '200306 N- ----NSN- πῦρ, πῦρ πῦρ πῦρ',
    '200306 RA ----NSM- ὁ ὁ ὁ ὁ',
    '200306 N- ----NSM- κόσμος κόσμος κόσμος κόσμος',
    '200306 RA ----GSF- τῆς τῆς τῆς ὁ',
    '200306 N- ----GSF- ⸀ἀδικίας ἀδικίας ἀδικίας ἀδικία',
    '200306 RA ----NSF- ἡ ἡ ἡ ὁ',
    '200306 N- ----NSF- γλῶσσα γλῶσσα γλῶσσα γλῶσσα',
    '200306 V- 3PPI-S-- καθίσταται καθίσταται καθίσταται καθίστημι',
    '200306 P- -------- ἐν ἐν ἐν ἐν',
    '200306 RA ----DPN- τοῖς τοῖς τοῖς ὁ',
    '200306 N- ----DPN- μέλεσιν μέλεσιν μέλεσι(ν) μέλος',
    '200306 RP ----GP-- ἡμῶν, ἡμῶν ἡμῶν ἐγώ',
    '200306 RA ----NSF- ἡ ἡ ἡ ὁ',
    '200306 V- -PAPNSF- σπιλοῦσα σπιλοῦσα σπιλοῦσα σπιλόω',
    '200306 A- ----ASN- ὅλον ὅλον ὅλον ὅλος',
    '200306 RA ----ASN- τὸ τὸ τό ὁ',
    '200306 N- ----ASN- σῶμα σῶμα σῶμα σῶμα',
    '200306 C- -------- καὶ καὶ καί καί',
    '200306 V- -PAPNSF- φλογίζουσα φλογίζουσα φλογίζουσα φλογίζω',
    '200306 RA ----ASM- τὸν τὸν τόν ὁ',
    '200306 N- ----ASM- τροχὸν τροχὸν τροχόν τροχός',
    '200306 RA ----GSF- τῆς τῆς τῆς ὁ',
    '200306 N- ----GSF- γενέσεως γενέσεως γενέσεως γένεσις',
    '200306 C- -------- καὶ καὶ καί καί',
    '200306 V- -PPPNSF- φλογιζομένη φλογιζομένη φλογιζομένη φλογίζω',
    '200306 P- -------- ὑπὸ ὑπὸ ὑπό ὑπό',
    '200306 RA ----GSF- τῆς τῆς τῆς ὁ',
    '200306 N- ----GSF- γεέννης. γεέννης γεέννης γέεννα',
    '200307 A- ----NSF- πᾶσα πᾶσα πᾶσα πᾶς',
    '200307 C- -------- γὰρ γὰρ γάρ γάρ',
    '200307 N- ----NSF- φύσις φύσις φύσις φύσις',
    '200307 N- ----GPN- θηρίων θηρίων θηρίων θηρίον',
    '200307 C- -------- τε τε τέ τέ',
    '200307 C- -------- καὶ καὶ καί καί',
    '200307 A- ----GPN- πετεινῶν πετεινῶν πετεινῶν πετεινόν',
    '200307 N- ----GPN- ἑρπετῶν ἑρπετῶν ἑρπετῶν ἑρπετόν',
    '200307 C- -------- τε τε τέ τέ',
    '200307 C- -------- καὶ καὶ καί καί',
    '200307 A- ----GPN- ἐναλίων ἐναλίων ἐναλίων ἐνάλιον',
    '200307 V- 3PPI-S-- δαμάζεται δαμάζεται δαμάζεται δαμάζω',
    '200307 C- -------- καὶ καὶ καί καί',
    '200307 V- 3XPI-S-- δεδάμασται δεδάμασται δεδάμασται δαμάζω',
    '200307 RA ----DSF- τῇ τῇ τῇ ὁ',
    '200307 N- ----DSF- φύσει φύσει φύσει φύσις',
    '200307 RA ----DSF- τῇ τῇ τῇ ὁ',
    '200307 A- ----DSF- ἀνθρωπίνῃ· ἀνθρωπίνῃ ἀνθρωπίνῃ ἀνθρώπινος',
    '200308 RA ----ASF- τὴν τὴν τήν ὁ',
    '200308 C- -------- δὲ δὲ δέ δέ',
    '200308 N- ----ASF- γλῶσσαν γλῶσσαν γλῶσσαν γλῶσσα',
    '200308 A- ----NSM- οὐδεὶς οὐδεὶς οὐδείς οὐδείς',
    '200308 V- -AAN---- ⸂δαμάσαι δαμάσαι δαμάσαι δαμάζω',
    '200308 V- 3PMI-S-- δύναται δύναται δύναται δύναμαι',
    '200308 N- ----GPM- ἀνθρώπων⸃· ἀνθρώπων ἀνθρώπων ἄνθρωπος',
    '200308 A- ----NSN- ⸀ἀκατάστατον ἀκατάστατον ἀκατάστατον ἀκατάστατος',
    '200308 A- ----NSN- κακόν, κακόν κακόν κακός',
    '200308 A- ----NSF- μεστὴ μεστὴ μεστή μεστός',
    '200308 N- ----GSM- ἰοῦ ἰοῦ ἰοῦ ἰός',
    '200308 A- ----GSM- θανατηφόρου. θανατηφόρου θανατηφόρου θανατηφόρος',
    '200309 P- -------- ἐν ἐν ἐν ἐν',
    '200309 RP ----DSF- αὐτῇ αὐτῇ αὐτῇ αὐτός',
    '200309 V- 1PAI-P-- εὐλογοῦμεν εὐλογοῦμεν εὐλογοῦμεν εὐλογέω',
    '200309 RA ----ASM- τὸν τὸν τόν ὁ',
    '200309 N- ----ASM- ⸀κύριον κύριον κύριον κύριος',
    '200309 C- -------- καὶ καὶ καί καί',
    '200309 N- ----ASM- πατέρα, πατέρα πατέρα πατήρ',
    '200309 C- -------- καὶ καὶ καί καί',
    '200309 P- -------- ἐν ἐν ἐν ἐν',
    '200309 RP ----DSF- αὐτῇ αὐτῇ αὐτῇ αὐτός',
    '200309 V- 1PMI-P-- καταρώμεθα καταρώμεθα καταρώμεθα καταράομαι',
    '200309 RA ----APM- τοὺς τοὺς τούς ὁ',
    '200309 N- ----APM- ἀνθρώπους ἀνθρώπους ἀνθρώπους ἄνθρωπος',
    '200309 RA ----APM- τοὺς τοὺς τούς ὁ',
    '200309 P- -------- καθ’ καθ’ κατά κατά',
    '200309 N- ----ASF- ὁμοίωσιν ὁμοίωσιν ὁμοίωσιν ὁμοίωσις',
    '200309 N- ----GSM- θεοῦ θεοῦ θεοῦ θεός',
    '200309 V- -XAPAPM- γεγονότας· γεγονότας γεγονότας γίνομαι',
    '200310 P- -------- ἐκ ἐκ ἐκ ἐκ',
    '200310 RA ----GSN- τοῦ τοῦ τοῦ ὁ',
    '200310 A- ----GSN- αὐτοῦ αὐτοῦ αὐτοῦ αὐτός',
    '200310 N- ----GSN- στόματος στόματος στόματος στόμα',
    '200310 V- 3PMI-S-- ἐξέρχεται ἐξέρχεται ἐξέρχεται ἐξέρχομαι',
    '200310 N- ----NSF- εὐλογία εὐλογία εὐλογία εὐλογία',
    '200310 C- -------- καὶ καὶ καί καί',
    '200310 N- ----NSF- κατάρα. κατάρα κατάρα κατάρα',
    '200310 D- -------- οὐ οὐ οὐ οὐ',
    '200310 V- 3PAI-S-- χρή, χρή χρή χρή',
    '200310 N- ----VPM- ἀδελφοί ἀδελφοί ἀδελφοί ἀδελφός',
    '200310 RP ----GS-- μου, μου μου ἐγώ',
    '200310 RD ----NPN- ταῦτα ταῦτα ταῦτα οὗτος',
    '200310 D- -------- οὕτως οὕτως οὕτω(ς) οὕτω(ς)',
    '200310 V- -PMN---- γίνεσθαι. γίνεσθαι γίνεσθαι γίνομαι',
    '200311 X- -------- μήτι μήτι μήτι μήτι',
    '200311 RA ----NSF- ἡ ἡ ἡ ὁ',
    '200311 N- ----NSF- πηγὴ πηγὴ πηγή πηγή',
    '200311 P- -------- ἐκ ἐκ ἐκ ἐκ',
    '200311 RA ----GSF- τῆς τῆς τῆς ὁ',
    '200311 A- ----GSF- αὐτῆς αὐτῆς αὐτῆς αὐτός',
    '200311 N- ----GSF- ὀπῆς ὀπῆς ὀπῆς ὀπή',
    '200311 V- 3PAI-S-- βρύει βρύει βρύει βρύω',
    '200311 RA ----ASN- τὸ τὸ τό ὁ',
    '200311 A- ----ASN- γλυκὺ γλυκὺ γλυκύ γλυκύς',
    '200311 C- -------- καὶ καὶ καί καί',
    '200311 RA ----ASN- τὸ τὸ τό ὁ',
    '200311 A- ----ASN- πικρόν; πικρόν πικρόν πικρός',
    '200312 X- -------- μὴ μὴ μή μή',
    '200312 V- 3PMI-S-- δύναται, δύναται δύναται δύναμαι',
    '200312 N- ----VPM- ἀδελφοί ἀδελφοί ἀδελφοί ἀδελφός',
    '200312 RP ----GS-- μου, μου μου ἐγώ',
    '200312 N- ----NSF- συκῆ συκῆ συκῆ συκῆ',
    '200312 N- ----APF- ἐλαίας ἐλαίας ἐλαίας ἐλαία',
    '200312 V- -AAN---- ποιῆσαι ποιῆσαι ποιῆσαι ποιέω',
    '200312 C- -------- ἢ ἢ ἤ ἤ',
    '200312 N- ----NSF- ἄμπελος ἄμπελος ἄμπελος ἄμπελος',
    '200312 N- ----APN- σῦκα; σῦκα σῦκα σῦκον',
    '200312 C- -------- ⸂οὔτε οὔτε οὔτε οὔτε',
    '200312 A- ----NSN- ἁλυκὸν⸃ ἁλυκὸν ἁλυκόν ἁλυκός',
    '200312 A- ----ASN- γλυκὺ γλυκὺ γλυκύ γλυκύς',
    '200312 V- -AAN---- ποιῆσαι ποιῆσαι ποιῆσαι ποιέω',
    '200312 N- ----ASN- ὕδωρ. ὕδωρ ὕδωρ ὕδωρ',
    '200313 RI ----NSM- Τίς Τίς τίς τίς',
    '200313 A- ----NSM- σοφὸς σοφὸς σοφός σοφός',
    '200313 C- -------- καὶ καὶ καί καί',
    '200313 A- ----NSM- ἐπιστήμων ἐπιστήμων ἐπιστήμων ἐπιστήμων',
    '200313 P- -------- ἐν ἐν ἐν ἐν',
    '200313 RP ----DP-- ὑμῖν; ὑμῖν ὑμῖν σύ',
    '200313 V- 3AAD-S-- δειξάτω δειξάτω δειξάτω δείκνυμι',
    '200313 P- -------- ἐκ ἐκ ἐκ ἐκ',
    '200313 RA ----GSF- τῆς τῆς τῆς ὁ',
    '200313 A- ----GSF- καλῆς καλῆς καλῆς καλός',
    '200313 N- ----GSF- ἀναστροφῆς ἀναστροφῆς ἀναστροφῆς ἀναστροφή',
    '200313 RA ----APN- τὰ τὰ τά ὁ',
    '200313 N- ----APN- ἔργα ἔργα ἔργα ἔργον',
    '200313 RP ----GSM- αὐτοῦ αὐτοῦ αὐτοῦ αὐτός',
    '200313 P- -------- ἐν ἐν ἐν ἐν',
    '200313 N- ----DSF- πραΰτητι πραΰτητι πραΰτητι πραΰτης',
    '200313 N- ----GSF- σοφίας. σοφίας σοφίας σοφία',
    '200314 C- -------- εἰ εἰ εἰ εἰ',
    '200314 C- -------- δὲ δὲ δέ δέ',
    '200314 N- ----ASM- ζῆλον ζῆλον ζῆλον ζῆλος',
    '200314 A- ----ASM- πικρὸν πικρὸν πικρόν πικρός',
    '200314 V- 2PAI-P-- ἔχετε ἔχετε ἔχετε ἔχω',
    '200314 C- -------- καὶ καὶ καί καί',
    '200314 N- ----ASF- ἐριθείαν ἐριθείαν ἐριθείαν ἐριθεία',
    '200314 P- -------- ἐν ἐν ἐν ἐν',
    '200314 RA ----DSF- τῇ τῇ τῇ ὁ',
    '200314 N- ----DSF- καρδίᾳ καρδίᾳ καρδίᾳ καρδία',
    '200314 RP ----GP-- ὑμῶν, ὑμῶν ὑμῶν σύ',
    '200314 D- -------- μὴ μὴ μή μή',
    '200314 V- 2PMD-P-- κατακαυχᾶσθε κατακαυχᾶσθε κατακαυχᾶσθε κατακαυχάομαι',
    '200314 C- -------- καὶ καὶ καί καί',
    '200314 V- 2PMD-P-- ψεύδεσθε ψεύδεσθε ψεύδεσθε ψεύδομαι',
    '200314 P- -------- κατὰ κατὰ κατά κατά',
    '200314 RA ----GSF- τῆς τῆς τῆς ὁ',
    '200314 N- ----GSF- ἀληθείας. ἀληθείας ἀληθείας ἀλήθεια',
    '200315 D- -------- οὐκ οὐκ οὐ οὐ',
    '200315 V- 3PAI-S-- ἔστιν ἔστιν ἐστί(ν) εἰμί',
    '200315 RD ----NSF- αὕτη αὕτη αὕτη οὗτος',
    '200315 RA ----NSF- ἡ ἡ ἡ ὁ',
    '200315 N- ----NSF- σοφία σοφία σοφία σοφία',
    '200315 D- -------- ἄνωθεν ἄνωθεν ἄνωθεν ἄνωθεν',
    '200315 V- -PMPNSF- κατερχομένη, κατερχομένη κατερχομένη κατέρχομαι',
    '200315 C- -------- ἀλλὰ ἀλλὰ ἀλλά ἀλλά',
    '200315 A- ----NSF- ἐπίγειος, ἐπίγειος ἐπίγειος ἐπίγειος',
    '200315 A- ----NSF- ψυχική, ψυχική ψυχική ψυχικός',
    '200315 A- ----NSF- δαιμονιώδης· δαιμονιώδης δαιμονιώδης δαιμονιώδης',
    '200316 C- -------- ὅπου ὅπου ὅπου ὅπου',
    '200316 C- -------- γὰρ γὰρ γάρ γάρ',
    '200316 N- ----NSM- ζῆλος ζῆλος ζῆλος ζῆλος',
    '200316 C- -------- καὶ καὶ καί καί',
    '200316 N- ----NSF- ἐριθεία, ἐριθεία ἐριθεία ἐριθεία',
    '200316 D- -------- ἐκεῖ ἐκεῖ ἐκεῖ ἐκεῖ',
    '200316 N- ----NSF- ἀκαταστασία ἀκαταστασία ἀκαταστασία ἀκαταστασία',
    '200316 C- -------- καὶ καὶ καί καί',
    '200316 A- ----NSN- πᾶν πᾶν πᾶν πᾶς',
    '200316 A- ----NSN- φαῦλον φαῦλον φαῦλον φαῦλος',
    '200316 N- ----NSN- πρᾶγμα. πρᾶγμα πρᾶγμα πρᾶγμα',
    '200317 RA ----NSF- ἡ ἡ ἡ ὁ',
    '200317 C- -------- δὲ δὲ δέ δέ',
    '200317 D- -------- ἄνωθεν ἄνωθεν ἄνωθεν ἄνωθεν',
    '200317 N- ----NSF- σοφία σοφία σοφία σοφία',
    '200317 A- ----ASN- πρῶτον πρῶτον πρῶτον πρῶτος',
    '200317 C- -------- μὲν μὲν μέν μέν',
    '200317 A- ----NSF- ἁγνή ἁγνή ἁγνή ἁγνός',
    '200317 V- 3PAI-S-- ἐστιν, ἐστιν ἐστί(ν) εἰμί',
    '200317 D- -------- ἔπειτα ἔπειτα ἔπειτα ἔπειτα',
    '200317 A- ----NSF- εἰρηνική, εἰρηνική εἰρηνική εἰρηνικός',
    '200317 A- ----NSF- ἐπιεικής, ἐπιεικής ἐπιεικής ἐπιεικής',
    '200317 A- ----NSF- εὐπειθής, εὐπειθής εὐπειθής εὐπειθής',
    '200317 A- ----NSF- μεστὴ μεστὴ μεστή μεστός',
    '200317 N- ----GSN- ἐλέους ἐλέους ἐλέους ἔλεος',
    '200317 C- -------- καὶ καὶ καί καί',
    '200317 N- ----GPM- καρπῶν καρπῶν καρπῶν καρπός',
    '200317 A- ----GPM- ἀγαθῶν, ἀγαθῶν ἀγαθῶν ἀγαθός',
    '200317 A- ----NSF- ⸀ἀδιάκριτος, ἀδιάκριτος ἀδιάκριτος ἀδιάκριτος',
    '200317 A- ----NSF- ἀνυπόκριτος· ἀνυπόκριτος ἀνυπόκριτος ἀνυπόκριτος',
    '200318 N- ----NSM- καρπὸς καρπὸς καρπός καρπός',
    '200318 C- -------- ⸀δὲ δὲ δέ δέ',
    '200318 N- ----GSF- δικαιοσύνης δικαιοσύνης δικαιοσύνης δικαιοσύνη',
    '200318 P- -------- ἐν ἐν ἐν ἐν',
    '200318 N- ----DSF- εἰρήνῃ εἰρήνῃ εἰρήνῃ εἰρήνη',
    '200318 V- 3PPI-S-- σπείρεται σπείρεται σπείρεται σπείρω',
    '200318 RA ----DPM- τοῖς τοῖς τοῖς ὁ',
    '200318 V- -PAPDPM- ποιοῦσιν ποιοῦσιν ποιοῦσι(ν) ποιέω',
    '200318 N- ----ASF- εἰρήνην. εἰρήνην εἰρήνην εἰρήνη',
    '200401 D- -------- Πόθεν Πόθεν πόθεν πόθεν',
    '200401 N- ----NPM- πόλεμοι πόλεμοι πόλεμοι πόλεμος',
    '200401 C- -------- καὶ καὶ καί καί',
    '200401 D- -------- ⸀πόθεν πόθεν πόθεν πόθεν',
    '200401 N- ----NPF- μάχαι μάχαι μάχαι μάχη',
    '200401 P- -------- ἐν ἐν ἐν ἐν',
    '200401 RP ----DP-- ὑμῖν; ὑμῖν ὑμῖν σύ',
    '200401 X- -------- οὐκ οὐκ οὐ οὐ',
    '200401 D- -------- ἐντεῦθεν, ἐντεῦθεν ἐντεῦθεν ἐντεῦθεν',
    '200401 P- -------- ἐκ ἐκ ἐκ ἐκ',
    '200401 RA ----GPF- τῶν τῶν τῶν ὁ',
    '200401 N- ----GPF- ἡδονῶν ἡδονῶν ἡδονῶν ἡδονή',
    '200401 RP ----GP-- ὑμῶν ὑμῶν ὑμῶν σύ',
    '200401 RA ----GPF- τῶν τῶν τῶν ὁ',
    '200401 V- -PMPGPF- στρατευομένων στρατευομένων στρατευομένων στρατεύομαι',
    '200401 P- -------- ἐν ἐν ἐν ἐν',
    '200401 RA ----DPN- τοῖς τοῖς τοῖς ὁ',
    '200401 N- ----DPN- μέλεσιν μέλεσιν μέλεσι(ν) μέλος',
    '200401 RP ----GP-- ὑμῶν; ὑμῶν ὑμῶν σύ',
    '200402 V- 2PAI-P-- ἐπιθυμεῖτε, ἐπιθυμεῖτε ἐπιθυμεῖτε ἐπιθυμέω',
    '200402 C- -------- καὶ καὶ καί καί',
    '200402 D- -------- οὐκ οὐκ οὐ οὐ',
    '200402 V- 2PAI-P-- ἔχετε· ἔχετε ἔχετε ἔχω',
    '200402 V- 2PAI-P-- φονεύετε φονεύετε φονεύετε φονεύω',
    '200402 C- -------- καὶ καὶ καί καί',
    '200402 V- 2PAI-P-- ζηλοῦτε, ζηλοῦτε ζηλοῦτε ζηλόω',
    '200402 C- -------- καὶ καὶ καί καί',
    '200402 D- -------- οὐ οὐ οὐ οὐ',
    '200402 V- 2PMI-P-- δύνασθε δύνασθε δύνασθε δύναμαι',
    '200402 V- -AAN---- ἐπιτυχεῖν· ἐπιτυχεῖν ἐπιτυχεῖν ἐπιτυγχάνω',
    '200402 V- 2PMI-P-- μάχεσθε μάχεσθε μάχεσθε μάχομαι',
    '200402 C- -------- καὶ καὶ καί καί',
    '200402 V- 2PAI-P-- πολεμεῖτε. πολεμεῖτε πολεμεῖτε πολεμέω',
    '200402 D- -------- οὐκ οὐκ οὐ οὐ',
    '200402 V- 2PAI-P-- ἔχετε ἔχετε ἔχετε ἔχω',
    '200402 P- -------- διὰ διὰ διά διά',
    '200402 RA ----ASN- τὸ τὸ τό ὁ',
    '200402 D- -------- μὴ μὴ μή μή',
    '200402 V- -PMN---- αἰτεῖσθαι αἰτεῖσθαι αἰτεῖσθαι αἰτέω',
    '200402 RP ----AP-- ὑμᾶς· ὑμᾶς ὑμᾶς σύ',
    '200403 V- 2PAI-P-- αἰτεῖτε αἰτεῖτε αἰτεῖτε αἰτέω',
    '200403 C- -------- καὶ καὶ καί καί',
    '200403 D- -------- οὐ οὐ οὐ οὐ',
    '200403 V- 2PAI-P-- λαμβάνετε, λαμβάνετε λαμβάνετε λαμβάνω',
    '200403 C- -------- διότι διότι διότι διότι',
    '200403 D- -------- κακῶς κακῶς κακῶς κακῶς',
    '200403 V- 2PMI-P-- αἰτεῖσθε, αἰτεῖσθε αἰτεῖσθε αἰτέω',
    '200403 C- -------- ἵνα ἵνα ἵνα ἵνα',
    '200403 P- -------- ἐν ἐν ἐν ἐν',
    '200403 RA ----DPF- ταῖς ταῖς ταῖς ὁ',
    '200403 N- ----DPF- ἡδοναῖς ἡδοναῖς ἡδοναῖς ἡδονή',
    '200403 RP ----GP-- ὑμῶν ὑμῶν ὑμῶν σύ',
    '200403 V- 2AAS-P-- δαπανήσητε. δαπανήσητε δαπανήσητε δαπανάω',
    '200404 A- ----VPF- ⸀μοιχαλίδες, μοιχαλίδες μοιχαλίδες μοιχαλίς',
    '200404 X- -------- οὐκ οὐκ οὐ οὐ',
    '200404 V- 2XAI-P-- οἴδατε οἴδατε οἴδατε οἶδα',
    '200404 C- -------- ὅτι ὅτι ὅτι ὅτι',
    '200404 RA ----NSF- ἡ ἡ ἡ ὁ',
    '200404 N- ----NSF- φιλία φιλία φιλία φιλία',
    '200404 RA ----GSM- τοῦ τοῦ τοῦ ὁ',
    '200404 N- ----GSM- κόσμου κόσμου κόσμου κόσμος',
    '200404 N- ----NSF- ἔχθρα ἔχθρα ἔχθρα ἔχθρα',
    '200404 RA ----GSM- τοῦ τοῦ τοῦ ὁ',
    '200404 N- ----GSM- θεοῦ θεοῦ θεοῦ θεός',
    '200404 V- 3PAI-S-- ἐστιν; ἐστιν ἐστί(ν) εἰμί',
    '200404 RR ----NSM- ὃς ὃς ὅς ὅς',
    '200404 X- -------- ⸀ἐὰν ἐὰν ἐάν ἐάν',
    '200404 C- -------- οὖν οὖν οὖν οὖν',
    '200404 V- 3APS-S-- βουληθῇ βουληθῇ βουληθῇ βούλομαι',
    '200404 A- ----NSM- φίλος φίλος φίλος φίλος',
    '200404 V- -PAN---- εἶναι εἶναι εἶναι εἰμί',
    '200404 RA ----GSM- τοῦ τοῦ τοῦ ὁ',
    '200404 N- ----GSM- κόσμου, κόσμου κόσμου κόσμος',
    '200404 A- ----NSM- ἐχθρὸς ἐχθρὸς ἐχθρός ἐχθρός',
    '200404 RA ----GSM- τοῦ τοῦ τοῦ ὁ',
    '200404 N- ----GSM- θεοῦ θεοῦ θεοῦ θεός',
    '200404 V- 3PPI-S-- καθίσταται. καθίσταται καθίσταται καθίστημι',
    '200405 C- -------- ἢ ἢ ἤ ἤ',
    '200405 V- 2PAI-P-- δοκεῖτε δοκεῖτε δοκεῖτε δοκέω',
    '200405 C- -------- ὅτι ὅτι ὅτι ὅτι',
    '200405 D- -------- κενῶς κενῶς κενῶς κενῶς',
    '200405 RA ----NSF- ἡ ἡ ἡ ὁ',
    '200405 N- ----NSF- γραφὴ γραφὴ γραφή γραφή',
    '200405 V- 3PAI-S-- λέγει· λέγει λέγει λέγω',
    '200405 P- -------- Πρὸς Πρὸς πρός πρός',
    '200405 N- ----ASM- φθόνον φθόνον φθόνον φθόνος',
    '200405 V- 3PAI-S-- ἐπιποθεῖ ἐπιποθεῖ ἐπιποθεῖ ἐπιποθέω',
    '200405 RA ----ASN- τὸ τὸ τό ὁ',
    '200405 N- ----ASN- πνεῦμα πνεῦμα πνεῦμα πνεῦμα',
    '200405 RR ----ASN- ὃ ὃ ὅ ὅς',
    '200405 V- 3AAI-S-- ⸀κατῴκισεν κατῴκισεν κατῴκισε(ν) κατοικίζω',
    '200405 P- -------- ἐν ἐν ἐν ἐν',
    '200405 RP ----DP-- ἡμῖν; ἡμῖν ἡμῖν ἐγώ',
    '200406 A- ----ASFC μείζονα μείζονα μείζονα μέγας',
    '200406 C- -------- δὲ δὲ δέ δέ',
    '200406 V- 3PAI-S-- δίδωσιν δίδωσιν δίδωσι(ν) δίδωμι',
    '200406 N- ----ASF- χάριν· χάριν χάριν χάρις',
    '200406 C- -------- διὸ διὸ διό διό',
    '200406 V- 3PAI-S-- λέγει· λέγει λέγει λέγω',
    '200406 RA ----NSM- Ὁ Ὁ ὁ ὁ',
    '200406 N- ----NSM- θεὸς θεὸς θεός θεός',
    '200406 A- ----DPM- ὑπερηφάνοις ὑπερηφάνοις ὑπερηφάνοις ὑπερήφανος',
    '200406 V- 3PMI-S-- ἀντιτάσσεται ἀντιτάσσεται ἀντιτάσσεται ἀντιτάσσομαι',
    '200406 A- ----DPM- ταπεινοῖς ταπεινοῖς ταπεινοῖς ταπεινός',
    '200406 C- -------- δὲ δὲ δέ δέ',
    '200406 V- 3PAI-S-- δίδωσιν δίδωσιν δίδωσι(ν) δίδωμι',
    '200406 N- ----ASF- χάριν. χάριν χάριν χάρις',
    '200407 V- 2APD-P-- ὑποτάγητε ὑποτάγητε ὑποτάγητε ὑποτάσσω',
    '200407 C- -------- οὖν οὖν οὖν οὖν',
    '200407 RA ----DSM- τῷ τῷ τῷ ὁ',
    '200407 N- ----DSM- θεῷ· θεῷ θεῷ θεός',
    '200407 V- 2AAD-P-- ἀντίστητε ἀντίστητε ἀντίστητε ἀνθίστημι',
    '200407 C- -------- δὲ δὲ δέ δέ',
    '200407 RA ----DSM- τῷ τῷ τῷ ὁ',
    '200407 A- ----DSM- διαβόλῳ, διαβόλῳ διαβόλῳ διάβολος',
    '200407 C- -------- καὶ καὶ καί καί',
    '200407 V- 3FMI-S-- φεύξεται φεύξεται φεύξεται φεύγω',
    '200407 P- -------- ἀφ’ ἀφ’ ἀπό ἀπό',
    '200407 RP ----GP-- ὑμῶν· ὑμῶν ὑμῶν σύ',
    '200408 V- 2AAD-P-- ἐγγίσατε ἐγγίσατε ἐγγίσατε ἐγγίζω',
    '200408 RA ----DSM- τῷ τῷ τῷ ὁ',
    '200408 N- ----DSM- θεῷ, θεῷ θεῷ θεός',
    '200408 C- -------- καὶ καὶ καί καί',
    '200408 V- 3FAI-S-- ⸀ἐγγιεῖ ἐγγιεῖ ἐγγιεῖ ἐγγίζω',
    '200408 RP ----DP-- ὑμῖν. ὑμῖν ὑμῖν σύ',
    '200408 V- 2AAD-P-- καθαρίσατε καθαρίσατε καθαρίσατε καθαρίζω',
    '200408 N- ----APF- χεῖρας, χεῖρας χεῖρας χείρ',
    '200408 A- ----VPM- ἁμαρτωλοί, ἁμαρτωλοί ἁμαρτωλοί ἁμαρτωλός',
    '200408 C- -------- καὶ καὶ καί καί',
    '200408 V- 2AAD-P-- ἁγνίσατε ἁγνίσατε ἁγνίσατε ἁγνίζω',
    '200408 N- ----APF- καρδίας, καρδίας καρδίας καρδία',
    '200408 A- ----VPM- δίψυχοι. δίψυχοι δίψυχοι δίψυχος',
    '200409 V- 2AAD-P-- ταλαιπωρήσατε ταλαιπωρήσατε ταλαιπωρήσατε ταλαιπωρέω',
    '200409 C- -------- καὶ καὶ καί καί',
    '200409 V- 2AAD-P-- πενθήσατε πενθήσατε πενθήσατε πενθέω',
    '200409 C- -------- καὶ καὶ καί καί',
    '200409 V- 2AAD-P-- κλαύσατε· κλαύσατε κλαύσατε κλαίω',
    '200409 RA ----NSM- ὁ ὁ ὁ ὁ',
    '200409 N- ----NSM- γέλως γέλως γέλως γέλως',
    '200409 RP ----GP-- ὑμῶν ὑμῶν ὑμῶν σύ',
    '200409 P- -------- εἰς εἰς εἰς εἰς',
    '200409 N- ----ASN- πένθος πένθος πένθος πένθος',
    '200409 V- 3APD-S-- ⸀μετατραπήτω μετατραπήτω μετατραπήτω μετατρέπω',
    '200409 C- -------- καὶ καὶ καί καί',
    '200409 RA ----NSF- ἡ ἡ ἡ ὁ',
    '200409 N- ----NSF- χαρὰ χαρὰ χαρά χαρά',
    '200409 P- -------- εἰς εἰς εἰς εἰς',
    '200409 N- ----ASF- κατήφειαν· κατήφειαν κατήφειαν κατήφεια',
    '200410 V- 2APD-P-- ταπεινώθητε ταπεινώθητε ταπεινώθητε ταπεινόω',
    '200410 P- -------- ἐνώπιον ἐνώπιον ἐνώπιον ἐνώπιον',
    '200410 N- ----GSM- ⸀κυρίου, κυρίου κυρίου κύριος',
    '200410 C- -------- καὶ καὶ καί καί',
    '200410 V- 3FAI-S-- ὑψώσει ὑψώσει ὑψώσει ὑψόω',
    '200410 RP ----AP-- ὑμᾶς. ὑμᾶς ὑμᾶς σύ',
    '200411 D- -------- Μὴ Μὴ μή μή',
    '200411 V- 2PAD-P-- καταλαλεῖτε καταλαλεῖτε καταλαλεῖτε καταλαλέω',
    '200411 RP ----GPM- ἀλλήλων, ἀλλήλων ἀλλήλων ἀλλήλων',
    '200411 N- ----VPM- ἀδελφοί· ἀδελφοί ἀδελφοί ἀδελφός',
    '200411 RA ----NSM- ὁ ὁ ὁ ὁ',
    '200411 V- -PAPNSM- καταλαλῶν καταλαλῶν καταλαλῶν καταλαλέω',
    '200411 N- ----GSM- ἀδελφοῦ ἀδελφοῦ ἀδελφοῦ ἀδελφός',
    '200411 C- -------- ⸀ἢ ἢ ἤ ἤ',
    '200411 V- -PAPNSM- κρίνων κρίνων κρίνων κρίνω',
    '200411 RA ----ASM- τὸν τὸν τόν ὁ',
    '200411 N- ----ASM- ἀδελφὸν ἀδελφὸν ἀδελφόν ἀδελφός',
    '200411 RP ----GSM- αὐτοῦ αὐτοῦ αὐτοῦ αὐτός',
    '200411 V- 3PAI-S-- καταλαλεῖ καταλαλεῖ καταλαλεῖ καταλαλέω',
    '200411 N- ----GSM- νόμου νόμου νόμου νόμος',
    '200411 C- -------- καὶ καὶ καί καί',
    '200411 V- 3PAI-S-- κρίνει κρίνει κρίνει κρίνω',
    '200411 N- ----ASM- νόμον· νόμον νόμον νόμος',
    '200411 C- -------- εἰ εἰ εἰ εἰ',
    '200411 C- -------- δὲ δὲ δέ δέ',
    '200411 N- ----ASM- νόμον νόμον νόμον νόμος',
    '200411 V- 2PAI-S-- κρίνεις, κρίνεις κρίνεις κρίνω',
    '200411 D- -------- οὐκ οὐκ οὐ οὐ',
    '200411 V- 2PAI-S-- εἶ εἶ εἶ εἰμί',
    '200411 N- ----NSM- ποιητὴς ποιητὴς ποιητής ποιητής',
    '200411 N- ----GSM- νόμου νόμου νόμου νόμος',
    '200411 C- -------- ἀλλὰ ἀλλὰ ἀλλά ἀλλά',
    '200411 N- ----NSM- κριτής. κριτής κριτής κριτής',
    '200412 A- ----NSM- εἷς εἷς εἷς εἷς',
    '200412 V- 3PAI-S-- ⸀ἐστιν ἐστιν ἐστί(ν) εἰμί',
    '200412 N- ----NSM- νομοθέτης νομοθέτης νομοθέτης νομοθέτης',
    '200412 C- -------- ⸂καὶ καὶ καί καί',
    '200412 N- ----NSM- κριτής⸃, κριτής κριτής κριτής',
    '200412 RA ----NSM- ὁ ὁ ὁ ὁ',
    '200412 V- -PMPNSM- δυνάμενος δυνάμενος δυνάμενος δύναμαι',
    '200412 V- -AAN---- σῶσαι σῶσαι σῶσαι σῴζω',
    '200412 C- -------- καὶ καὶ καί καί',
    '200412 V- -AAN---- ἀπολέσαι· ἀπολέσαι ἀπολέσαι ἀπόλλυμι',
    '200412 RP ----NS-- σὺ σὺ σύ σύ',
    '200412 C- -------- δὲ δὲ δέ δέ',
    '200412 RI ----NSM- τίς τίς τίς τίς',
    '200412 V- 2PAI-S-- εἶ, εἶ εἶ εἰμί',
    '200412 RA ----NSM- ⸂ὁ ὁ ὁ ὁ',
    '200412 V- -PAPNSM- κρίνων⸃ κρίνων κρίνων κρίνω',
    '200412 RA ----ASM- τὸν τὸν τόν ὁ',
    '200412 D- -------- ⸀πλησίον; πλησίον πλησίον πλησίον',
    '200413 V- 2PAD-S-- Ἄγε Ἄγε ἄγε ἄγω',
    '200413 D- -------- νῦν νῦν νῦν νῦν',
    '200413 RA ----NPM- οἱ οἱ οἱ ὁ',
    '200413 V- -PAPNPM- λέγοντες· λέγοντες λέγοντες λέγω',
    '200413 D- -------- Σήμερον Σήμερον σήμερον σήμερον',
    '200413 C- -------- ⸀ἢ ἢ ἤ ἤ',
    '200413 D- -------- αὔριον αὔριον αὔριον αὔριον',
    '200413 V- 1FMI-P-- ⸀πορευσόμεθα πορευσόμεθα πορευσόμεθα πορεύομαι',
    '200413 P- -------- εἰς εἰς εἰς εἰς',
    '200413 RD ----ASF- τήνδε τήνδε τήνδε ὅδε',
    '200413 RA ----ASF- τὴν τὴν τήν ὁ',
    '200413 N- ----ASF- πόλιν πόλιν πόλιν πόλις',
    '200413 C- -------- καὶ καὶ καί καί',
    '200413 V- 1FAI-P-- ⸀ποιήσομεν ποιήσομεν ποιήσομεν ποιέω',
    '200413 D- -------- ἐκεῖ ἐκεῖ ἐκεῖ ἐκεῖ',
    '200413 N- ----ASM- ⸀ἐνιαυτὸν ἐνιαυτὸν ἐνιαυτόν ἐνιαυτός',
    '200413 C- -------- καὶ καὶ καί καί',
    '200413 V- 1FMI-P-- ⸀ἐμπορευσόμεθα ἐμπορευσόμεθα ἐμπορευσόμεθα ἐμπορεύομαι',
    '200413 C- -------- καὶ καὶ καί καί',
    '200413 V- 1FAI-P-- ⸀κερδήσομεν· κερδήσομεν κερδήσομεν κερδαίνω',
    '200414 RR ----NPM- οἵτινες οἵτινες οἵτινες ὅστις',
    '200414 D- -------- οὐκ οὐκ οὐ οὐ',
    '200414 V- 2PMI-P-- ἐπίστασθε ἐπίστασθε ἐπίστασθε ἐπίσταμαι',
    '200414 RA ----ASN- ⸀τὸ τὸ τό ὁ',
    '200414 RA ----GSF- τῆς τῆς τῆς ὁ',
    '200414 D- -------- αὔριον αὔριον αὔριον αὔριον',
    '200414 RI ----NSF- ⸀ποία ποία ποία ποῖος',
    '200414 RA ----NSF- ἡ ἡ ἡ ὁ',
    '200414 N- ----NSF- ζωὴ ζωὴ ζωή ζωή',
    '200414 RP ----GP-- ὑμῶν· ὑμῶν ὑμῶν σύ',
    '200414 N- ----NSF- ἀτμὶς ἀτμὶς ἀτμίς ἀτμίς',
    '200414 C- -------- γάρ γάρ γάρ γάρ',
    '200414 V- 2PAI-P-- ⸀ἐστε ἐστε ἐστέ εἰμί',
    '200414 RA ----NSF- ⸀ἡ ἡ ἡ ὁ',
    '200414 P- -------- πρὸς πρὸς πρός πρός',
    '200414 A- ----ASN- ὀλίγον ὀλίγον ὀλίγον ὀλίγος',
    '200414 V- -PMPNSF- φαινομένη, φαινομένη φαινομένη φαίνω',
    '200414 D- -------- ⸀ἔπειτα ἔπειτα ἔπειτα ἔπειτα',
    '200414 D- -------- καὶ καὶ καί καί',
    '200414 V- -PPPNSF- ἀφανιζομένη· ἀφανιζομένη ἀφανιζομένη ἀφανίζω',
    '200415 P- -------- ἀντὶ ἀντὶ ἀντί ἀντί',
    '200415 RA ----GSN- τοῦ τοῦ τοῦ ὁ',
    '200415 V- -PAN---- λέγειν λέγειν λέγειν λέγω',
    '200415 RP ----AP-- ὑμᾶς· ὑμᾶς ὑμᾶς σύ',
    '200415 C- -------- Ἐὰν Ἐὰν ἐάν ἐάν',
    '200415 RA ----NSM- ὁ ὁ ὁ ὁ',
    '200415 N- ----NSM- κύριος κύριος κύριος κύριος',
    '200415 V- 3AAS-S-- ⸀θελήσῃ, θελήσῃ θελήσῃ θέλω',
    '200415 C- -------- καὶ καὶ καί καί',
    '200415 V- 1FAI-P-- ⸀ζήσομεν ζήσομεν ζήσομεν ζάω',
    '200415 C- -------- καὶ καὶ καί καί',
    '200415 V- 1FAI-P-- ⸀ποιήσομεν ποιήσομεν ποιήσομεν ποιέω',
    '200415 RD ----ASN- τοῦτο τοῦτο τοῦτο οὗτος',
    '200415 C- -------- ἢ ἢ ἤ ἤ',
    '200415 RD ----ASN- ἐκεῖνο. ἐκεῖνο ἐκεῖνο ἐκεῖνος',
    '200416 D- -------- νῦν νῦν νῦν νῦν',
    '200416 C- -------- δὲ δὲ δέ δέ',
    '200416 V- 2PMI-P-- καυχᾶσθε καυχᾶσθε καυχᾶσθε καυχάομαι',
    '200416 P- -------- ἐν ἐν ἐν ἐν',
    '200416 RA ----DPF- ταῖς ταῖς ταῖς ὁ',
    '200416 N- ----DPF- ἀλαζονείαις ἀλαζονείαις ἀλαζονείαις ἀλαζονεία',
    '200416 RP ----GP-- ὑμῶν· ὑμῶν ὑμῶν σύ',
    '200416 A- ----NSF- πᾶσα πᾶσα πᾶσα πᾶς',
    '200416 N- ----NSF- καύχησις καύχησις καύχησις καύχησις',
    '200416 RD ----NSF- τοιαύτη τοιαύτη τοιαύτη τοιοῦτος',
    '200416 A- ----NSF- πονηρά πονηρά πονηρά πονηρός',
    '200416 V- 3PAI-S-- ἐστιν. ἐστιν ἐστί(ν) εἰμί',
    '200417 V- -XAPDSM- εἰδότι εἰδότι εἰδότι οἶδα',
    '200417 C- -------- οὖν οὖν οὖν οὖν',
    '200417 A- ----ASN- καλὸν καλὸν καλόν καλός',
    '200417 V- -PAN---- ποιεῖν ποιεῖν ποιεῖν ποιέω',
    '200417 C- -------- καὶ καὶ καί καί',
    '200417 D- -------- μὴ μὴ μή μή',
    '200417 V- -PAPDSM- ποιοῦντι, ποιοῦντι ποιοῦντι ποιέω',
    '200417 N- ----NSF- ἁμαρτία ἁμαρτία ἁμαρτία ἁμαρτία',
    '200417 RP ----DSM- αὐτῷ αὐτῷ αὐτῷ αὐτός',
    '200417 V- 3PAI-S-- ἐστιν. ἐστιν ἐστί(ν) εἰμί',
    '200501 V- 2PAD-S-- Ἄγε Ἄγε ἄγε ἄγω',
    '200501 D- -------- νῦν νῦν νῦν νῦν',
    '200501 RA ----NPM- οἱ οἱ οἱ ὁ',
    '200501 A- ----NPM- πλούσιοι, πλούσιοι πλούσιοι πλούσιος',
    '200501 V- 2AAD-P-- κλαύσατε κλαύσατε κλαύσατε κλαίω',
    '200501 V- -PAPNPM- ὀλολύζοντες ὀλολύζοντες ὀλολύζοντες ὀλολύζω',
    '200501 P- -------- ἐπὶ ἐπὶ ἐπί ἐπί',
    '200501 RA ----DPF- ταῖς ταῖς ταῖς ὁ',
    '200501 N- ----DPF- ταλαιπωρίαις ταλαιπωρίαις ταλαιπωρίαις ταλαιπωρία',
    '200501 RP ----GP-- ὑμῶν ὑμῶν ὑμῶν σύ',
    '200501 RA ----DPF- ταῖς ταῖς ταῖς ὁ',
    '200501 V- -PMPDPF- ἐπερχομέναις. ἐπερχομέναις ἐπερχομέναις ἐπέρχομαι',
    '200502 RA ----NSM- ὁ ὁ ὁ ὁ',
    '200502 N- ----NSM- πλοῦτος πλοῦτος πλοῦτος πλοῦτος',
    '200502 RP ----GP-- ὑμῶν ὑμῶν ὑμῶν σύ',
    '200502 V- 3XAI-S-- σέσηπεν, σέσηπεν σέσηπε(ν) σήπω',
    '200502 C- -------- καὶ καὶ καί καί',
    '200502 RA ----NPN- τὰ τὰ τά ὁ',
    '200502 N- ----NPN- ἱμάτια ἱμάτια ἱμάτια ἱμάτιον',
    '200502 RP ----GP-- ὑμῶν ὑμῶν ὑμῶν σύ',
    '200502 A- ----NPN- σητόβρωτα σητόβρωτα σητόβρωτα σητόβρωτος',
    '200502 V- 3XAI-S-- γέγονεν, γέγονεν γέγονε(ν) γίνομαι',
    '200503 RA ----NSM- ὁ ὁ ὁ ὁ',
    '200503 N- ----NSM- χρυσὸς χρυσὸς χρυσός χρυσός',
    '200503 RP ----GP-- ὑμῶν ὑμῶν ὑμῶν σύ',
    '200503 C- -------- καὶ καὶ καί καί',
    '200503 RA ----NSM- ὁ ὁ ὁ ὁ',
    '200503 N- ----NSM- ἄργυρος ἄργυρος ἄργυρος ἄργυρος',
    '200503 V- 3XPI-S-- κατίωται, κατίωται κατίωται κατιόομαι',
    '200503 C- -------- καὶ καὶ καί καί',
    '200503 RA ----NSM- ὁ ὁ ὁ ὁ',
    '200503 N- ----NSM- ἰὸς ἰὸς ἰός ἰός',
    '200503 RP ----GPM- αὐτῶν αὐτῶν αὐτῶν αὐτός',
    '200503 P- -------- εἰς εἰς εἰς εἰς',
    '200503 N- ----ASN- μαρτύριον μαρτύριον μαρτύριον μαρτύριον',
    '200503 RP ----DP-- ὑμῖν ὑμῖν ὑμῖν σύ',
    '200503 V- 3FMI-S-- ἔσται ἔσται ἔσται εἰμί',
    '200503 C- -------- καὶ καὶ καί καί',
    '200503 V- 3FMI-S-- φάγεται φάγεται φάγεται ἐσθίω',
    '200503 RA ----APF- τὰς τὰς τάς ὁ',
    '200503 N- ----APF- σάρκας σάρκας σάρκας σάρξ',
    '200503 RP ----GP-- ὑμῶν· ὑμῶν ὑμῶν σύ',
    '200503 C- -------- ὡς ὡς ὡς ὡς',
    '200503 N- ----NSN- πῦρ πῦρ πῦρ πῦρ',
    '200503 V- 2AAI-P-- ἐθησαυρίσατε ἐθησαυρίσατε ἐθησαυρίσατε θησαυρίζω',
    '200503 P- -------- ἐν ἐν ἐν ἐν',
    '200503 A- ----DPF- ἐσχάταις ἐσχάταις ἐσχάταις ἔσχατος',
    '200503 N- ----DPF- ἡμέραις. ἡμέραις ἡμέραις ἡμέρα',
    '200504 X- -------- ἰδοὺ ἰδοὺ ἰδού ἰδού',
    '200504 RA ----NSM- ὁ ὁ ὁ ὁ',
    '200504 N- ----NSM- μισθὸς μισθὸς μισθός μισθός',
    '200504 RA ----GPM- τῶν τῶν τῶν ὁ',
    '200504 N- ----GPM- ἐργατῶν ἐργατῶν ἐργατῶν ἐργάτης',
    '200504 RA ----GPM- τῶν τῶν τῶν ὁ',
    '200504 V- -AAPGPM- ἀμησάντων ἀμησάντων ἀμησάντων ἀμάω',
    '200504 RA ----APF- τὰς τὰς τάς ὁ',
    '200504 N- ----APF- χώρας χώρας χώρας χώρα',
    '200504 RP ----GP-- ὑμῶν ὑμῶν ὑμῶν σύ',
    '200504 RA ----NSM- ὁ ὁ ὁ ὁ',
    '200504 V- -XPPNSM- ⸀ἀφυστερημένος ἀφυστερημένος ἀφυστερημένος ἀφυστερέω',
    '200504 P- -------- ἀφ’ ἀφ’ ἀπό ἀπό',
    '200504 RP ----GP-- ὑμῶν ὑμῶν ὑμῶν σύ',
    '200504 V- 3PAI-S-- κράζει, κράζει κράζει κράζω',
    '200504 C- -------- καὶ καὶ καί καί',
    '200504 RA ----NPF- αἱ αἱ αἱ ὁ',
    '200504 N- ----NPF- βοαὶ βοαὶ βοαί βοή',
    '200504 RA ----GPM- τῶν τῶν τῶν ὁ',
    '200504 V- -AAPGPM- θερισάντων θερισάντων θερισάντων θερίζω',
    '200504 P- -------- εἰς εἰς εἰς εἰς',
    '200504 RA ----APN- τὰ τὰ τά ὁ',
    '200504 N- ----APN- ὦτα ὦτα ὦτα οὖς',
    '200504 N- ----GSM- Κυρίου Κυρίου κυρίου κύριος',
    '200504 N- ----GPM- Σαβαὼθ Σαβαὼθ Σαβαώθ Σαβαώθ',
    '200504 V- 3XAI-P-- ⸀εἰσεληλύθασιν· εἰσεληλύθασιν εἰσεληλύθασι(ν) εἰσέρχομαι',
    '200505 V- 2AAI-P-- ἐτρυφήσατε ἐτρυφήσατε ἐτρυφήσατε τρυφάω',
    '200505 P- -------- ἐπὶ ἐπὶ ἐπί ἐπί',
    '200505 RA ----GSF- τῆς τῆς τῆς ὁ',
    '200505 N- ----GSF- γῆς γῆς γῆς γῆ',
    '200505 C- -------- καὶ καὶ καί καί',
    '200505 V- 2AAI-P-- ἐσπαταλήσατε, ἐσπαταλήσατε ἐσπαταλήσατε σπαταλάω',
    '200505 V- 2AAI-P-- ἐθρέψατε ἐθρέψατε ἐθρέψατε τρέφω',
    '200505 RA ----APF- τὰς τὰς τάς ὁ',
    '200505 N- ----APF- καρδίας καρδίας καρδίας καρδία',
    '200505 RP ----GP-- ⸀ὑμῶν ὑμῶν ὑμῶν σύ',
    '200505 P- -------- ἐν ἐν ἐν ἐν',
    '200505 N- ----DSF- ἡμέρᾳ ἡμέρᾳ ἡμέρᾳ ἡμέρα',
    '200505 N- ----GSF- σφαγῆς. σφαγῆς σφαγῆς σφαγή',
    '200506 V- 2AAI-P-- κατεδικάσατε, κατεδικάσατε κατεδικάσατε καταδικάζω',
    '200506 V- 2AAI-P-- ἐφονεύσατε ἐφονεύσατε ἐφονεύσατε φονεύω',
    '200506 RA ----ASM- τὸν τὸν τόν ὁ',
    '200506 A- ----ASM- δίκαιον. δίκαιον δίκαιον δίκαιος',
    '200506 D- -------- οὐκ οὐκ οὐ οὐ',
    '200506 V- 3PMI-S-- ἀντιτάσσεται ἀντιτάσσεται ἀντιτάσσεται ἀντιτάσσομαι',
    '200506 RP ----DP-- ὑμῖν; ὑμῖν ὑμῖν σύ',
    '200507 V- 2AAD-P-- Μακροθυμήσατε Μακροθυμήσατε μακροθυμήσατε μακροθυμέω',
    '200507 C- -------- οὖν, οὖν οὖν οὖν',
    '200507 N- ----VPM- ἀδελφοί, ἀδελφοί ἀδελφοί ἀδελφός',
    '200507 P- -------- ἕως ἕως ἕως ἕως',
    '200507 RA ----GSF- τῆς τῆς τῆς ὁ',
    '200507 N- ----GSF- παρουσίας παρουσίας παρουσίας παρουσία',
    '200507 RA ----GSM- τοῦ τοῦ τοῦ ὁ',
    '200507 N- ----GSM- κυρίου. κυρίου κυρίου κύριος',
    '200507 X- -------- ἰδοὺ ἰδοὺ ἰδού ἰδού',
    '200507 RA ----NSM- ὁ ὁ ὁ ὁ',
    '200507 N- ----NSM- γεωργὸς γεωργὸς γεωργός γεωργός',
    '200507 V- 3PMI-S-- ἐκδέχεται ἐκδέχεται ἐκδέχεται ἐκδέχομαι',
    '200507 RA ----ASM- τὸν τὸν τόν ὁ',
    '200507 A- ----ASM- τίμιον τίμιον τίμιον τίμιος',
    '200507 N- ----ASM- καρπὸν καρπὸν καρπόν καρπός',
    '200507 RA ----GSF- τῆς τῆς τῆς ὁ',
    '200507 N- ----GSF- γῆς, γῆς γῆς γῆ',
    '200507 V- -PAPNSM- μακροθυμῶν μακροθυμῶν μακροθυμῶν μακροθυμέω',
    '200507 P- -------- ἐπ’ ἐπ’ ἐπί ἐπί',
    '200507 RP ----DSM- ⸀αὐτῷ αὐτῷ αὐτῷ αὐτός',
    '200507 C- -------- ἕως ἕως ἕως ἕως',
    '200507 V- 3AAS-S-- ⸀λάβῃ λάβῃ λάβῃ λαμβάνω',
    '200507 A- ----ASM- πρόϊμον πρόϊμον πρόϊμον πρόϊμος',
    '200507 C- -------- καὶ καὶ καί καί',
    '200507 A- ----ASM- ὄψιμον. ὄψιμον ὄψιμον ὄψιμος',
    '200508 V- 2AAD-P-- μακροθυμήσατε μακροθυμήσατε μακροθυμήσατε μακροθυμέω',
    '200508 D- -------- καὶ καὶ καί καί',
    '200508 RP ----NP-- ὑμεῖς, ὑμεῖς ὑμεῖς σύ',
    '200508 V- 2AAD-P-- στηρίξατε στηρίξατε στηρίξατε στηρίζω',
    '200508 RA ----APF- τὰς τὰς τάς ὁ',
    '200508 N- ----APF- καρδίας καρδίας καρδίας καρδία',
    '200508 RP ----GP-- ὑμῶν, ὑμῶν ὑμῶν σύ',
    '200508 C- -------- ὅτι ὅτι ὅτι ὅτι',
    '200508 RA ----NSF- ἡ ἡ ἡ ὁ',
    '200508 N- ----NSF- παρουσία παρουσία παρουσία παρουσία',
    '200508 RA ----GSM- τοῦ τοῦ τοῦ ὁ',
    '200508 N- ----GSM- κυρίου κυρίου κυρίου κύριος',
    '200508 V- 3XAI-S-- ἤγγικεν. ἤγγικεν ἤγγικε(ν) ἐγγίζω',
    '200509 D- -------- μὴ μὴ μή μή',
    '200509 V- 2PAD-P-- στενάζετε, στενάζετε στενάζετε στενάζω',
    '200509 N- ----VPM- ⸂ἀδελφοί, ἀδελφοί ἀδελφοί ἀδελφός',
    '200509 P- -------- κατ’ κατ’ κατά κατά',
    '200509 RP ----GPM- ἀλλήλων⸃, ἀλλήλων ἀλλήλων ἀλλήλων',
    '200509 C- -------- ἵνα ἵνα ἵνα ἵνα',
    '200509 D- -------- μὴ μὴ μή μή',
    '200509 V- 2APS-P-- κριθῆτε· κριθῆτε κριθῆτε κρίνω',
    '200509 X- -------- ἰδοὺ ἰδοὺ ἰδού ἰδού',
    '200509 RA ----NSM- ὁ ὁ ὁ ὁ',
    '200509 N- ----NSM- κριτὴς κριτὴς κριτής κριτής',
    '200509 P- -------- πρὸ πρὸ πρό πρό',
    '200509 RA ----GPF- τῶν τῶν τῶν ὁ',
    '200509 N- ----GPF- θυρῶν θυρῶν θυρῶν θύρα',
    '200509 V- 3XAI-S-- ἕστηκεν. ἕστηκεν ἕστηκε(ν) ἵστημι',
    '200510 N- ----ASN- ὑπόδειγμα ὑπόδειγμα ὑπόδειγμα ὑπόδειγμα',
    '200510 V- 2AAD-P-- λάβετε, λάβετε λάβετε λαμβάνω',
    '200510 N- ----VPM- ⸀ἀδελφοί, ἀδελφοί ἀδελφοί ἀδελφός',
    '200510 RA ----GSF- τῆς τῆς τῆς ὁ',
    '200510 N- ----GSF- κακοπαθίας κακοπαθίας κακοπαθίας κακοπαθία',
    '200510 C- -------- καὶ καὶ καί καί',
    '200510 RA ----GSF- τῆς τῆς τῆς ὁ',
    '200510 N- ----GSF- μακροθυμίας μακροθυμίας μακροθυμίας μακροθυμία',
    '200510 RA ----APM- τοὺς τοὺς τούς ὁ',
    '200510 N- ----APM- προφήτας, προφήτας προφήτας προφήτης',
    '200510 RR ----NPM- οἳ οἳ οἵ ὅς',
    '200510 V- 3AAI-P-- ἐλάλησαν ἐλάλησαν ἐλάλησαν λαλέω',
    '200510 P- -------- ⸀ἐν ἐν ἐν ἐν',
    '200510 RA ----DSN- τῷ τῷ τῷ ὁ',
    '200510 N- ----DSN- ὀνόματι ὀνόματι ὀνόματι ὄνομα',
    '200510 N- ----GSM- κυρίου. κυρίου κυρίου κύριος',
    '200511 X- -------- ἰδοὺ ἰδοὺ ἰδού ἰδού',
    '200511 V- 1PAI-P-- μακαρίζομεν μακαρίζομεν μακαρίζομεν μακαρίζω',
    '200511 RA ----APM- τοὺς τοὺς τούς ὁ',
    '200511 V- -AAPAPM- ⸀ὑπομείναντας· ὑπομείναντας ὑπομείναντας ὑπομένω',
    '200511 RA ----ASF- τὴν τὴν τήν ὁ',
    '200511 N- ----ASF- ὑπομονὴν ὑπομονὴν ὑπομονήν ὑπομονή',
    '200511 N- ----GSM- Ἰὼβ Ἰὼβ Ἰώβ Ἰώβ',
    '200511 V- 2AAI-P-- ἠκούσατε, ἠκούσατε ἠκούσατε ἀκούω',
    '200511 C- -------- καὶ καὶ καί καί',
    '200511 RA ----ASN- τὸ τὸ τό ὁ',
    '200511 N- ----ASN- τέλος τέλος τέλος τέλος',
    '200511 N- ----GSM- κυρίου κυρίου κυρίου κύριος',
    '200511 V- 2AAI-P-- ⸀εἴδετε, εἴδετε εἴδετε ὁράω',
    '200511 D- -------- ὅτι ὅτι ὅτι ὅτι',
    '200511 A- ----NSM- πολύσπλαγχνός πολύσπλαγχνός πολύσπλαγχνος πολύσπλαγχνος',
    '200511 V- 3PAI-S-- ἐστιν ἐστιν ἐστί(ν) εἰμί',
    '200511 RA ----NSM- ⸂ὁ ὁ ὁ ὁ',
    '200511 N- ----NSM- κύριος⸃ κύριος κύριος κύριος',
    '200511 C- -------- καὶ καὶ καί καί',
    '200511 A- ----NSM- οἰκτίρμων. οἰκτίρμων οἰκτίρμων οἰκτίρμων',
    '200512 P- -------- Πρὸ Πρὸ πρό πρό',
    '200512 A- ----GPN- πάντων πάντων πάντων πᾶς',
    '200512 C- -------- δέ, δέ δέ δέ',
    '200512 N- ----VPM- ἀδελφοί ἀδελφοί ἀδελφοί ἀδελφός',
    '200512 RP ----GS-- μου, μου μου ἐγώ',
    '200512 D- -------- μὴ μὴ μή μή',
    '200512 V- 2PAD-P-- ὀμνύετε, ὀμνύετε ὀμνύετε ὀμνύω',
    '200512 C- -------- μήτε μήτε μήτε μήτε',
    '200512 RA ----ASM- τὸν τὸν τόν ὁ',
    '200512 N- ----ASM- οὐρανὸν οὐρανὸν οὐρανόν οὐρανός',
    '200512 C- -------- μήτε μήτε μήτε μήτε',
    '200512 RA ----ASF- τὴν τὴν τήν ὁ',
    '200512 N- ----ASF- γῆν γῆν γῆν γῆ',
    '200512 C- -------- μήτε μήτε μήτε μήτε',
    '200512 A- ----ASM- ἄλλον ἄλλον ἄλλον ἄλλος',
    '200512 RI ----ASM- τινὰ τινὰ τινά τις',
    '200512 N- ----ASM- ὅρκον· ὅρκον ὅρκον ὅρκος',
    '200512 V- 3PAD-S-- ἤτω ἤτω ἤτω εἰμί',
    '200512 C- -------- δὲ δὲ δέ δέ',
    '200512 RP ----GP-- ὑμῶν ὑμῶν ὑμῶν σύ',
    '200512 RA ----NSN- τὸ τὸ τό ὁ',
    '200512 X- -------- Ναὶ Ναὶ ναί ναί',
    '200512 X- -------- ναὶ ναὶ ναί ναί',
    '200512 C- -------- καὶ καὶ καί καί',
    '200512 RA ----NSN- τὸ τὸ τό ὁ',
    '200512 X- -------- Οὒ Οὒ οὔ οὔ',
    '200512 X- -------- οὔ, οὔ οὔ οὔ',
    '200512 C- -------- ἵνα ἵνα ἵνα ἵνα',
    '200512 D- -------- μὴ μὴ μή μή',
    '200512 P- -------- ⸂ὑπὸ ὑπὸ ὑπό ὑπό',
    '200512 N- ----ASF- κρίσιν⸃ κρίσιν κρίσιν κρίσις',
    '200512 V- 2AAS-P-- πέσητε. πέσητε πέσητε πίπτω',
    '200513 V- 3PAI-S-- Κακοπαθεῖ Κακοπαθεῖ κακοπαθεῖ κακοπαθέω',
    '200513 RI ----NSM- τις τις τὶς τις',
    '200513 P- -------- ἐν ἐν ἐν ἐν',
    '200513 RP ----DP-- ὑμῖν; ὑμῖν ὑμῖν σύ',
    '200513 V- 3PMD-S-- προσευχέσθω· προσευχέσθω προσευχέσθω προσεύχομαι',
    '200513 V- 3PAI-S-- εὐθυμεῖ εὐθυμεῖ εὐθυμεῖ εὐθυμέω',
    '200513 RI ----NSM- τις; τις τὶς τις',
    '200513 V- 3PAD-S-- ψαλλέτω. ψαλλέτω ψαλλέτω ψάλλω',
    '200514 V- 3PAI-S-- ἀσθενεῖ ἀσθενεῖ ἀσθενεῖ ἀσθενέω',
    '200514 RI ----NSM- τις τις τὶς τις',
    '200514 P- -------- ἐν ἐν ἐν ἐν',
    '200514 RP ----DP-- ὑμῖν; ὑμῖν ὑμῖν σύ',
    '200514 V- 3AMD-S-- προσκαλεσάσθω προσκαλεσάσθω προσκαλεσάσθω προσκαλέομαι',
    '200514 RA ----APM- τοὺς τοὺς τούς ὁ',
    '200514 A- ----APM- πρεσβυτέρους πρεσβυτέρους πρεσβυτέρους πρεσβύτερος',
    '200514 RA ----GSF- τῆς τῆς τῆς ὁ',
    '200514 N- ----GSF- ἐκκλησίας, ἐκκλησίας ἐκκλησίας ἐκκλησία',
    '200514 C- -------- καὶ καὶ καί καί',
    '200514 V- 3AMD-P-- προσευξάσθωσαν προσευξάσθωσαν προσευξάσθωσαν προσεύχομαι',
    '200514 P- -------- ἐπ’ ἐπ’ ἐπί ἐπί',
    '200514 RP ----ASM- αὐτὸν αὐτὸν αὐτόν αὐτός',
    '200514 V- -AAPNPM- ἀλείψαντες ἀλείψαντες ἀλείψαντες ἀλείφω',
    '200514 RP ----ASM- ⸀αὐτὸν αὐτὸν αὐτόν αὐτός',
    '200514 N- ----DSN- ἐλαίῳ ἐλαίῳ ἐλαίῳ ἔλαιον',
    '200514 P- -------- ἐν ἐν ἐν ἐν',
    '200514 RA ----DSN- τῷ τῷ τῷ ὁ',
    '200514 N- ----DSN- ὀνόματι ὀνόματι ὀνόματι ὄνομα',
    '200514 RA ----GSM- τοῦ τοῦ τοῦ ὁ',
    '200514 N- ----GSM- κυρίου· κυρίου κυρίου κύριος',
    '200515 C- -------- καὶ καὶ καί καί',
    '200515 RA ----NSF- ἡ ἡ ἡ ὁ',
    '200515 N- ----NSF- εὐχὴ εὐχὴ εὐχή εὐχή',
    '200515 RA ----GSF- τῆς τῆς τῆς ὁ',
    '200515 N- ----GSF- πίστεως πίστεως πίστεως πίστις',
    '200515 V- 3FAI-S-- σώσει σώσει σώσει σῴζω',
    '200515 RA ----ASM- τὸν τὸν τόν ὁ',
    '200515 V- -PAPASM- κάμνοντα, κάμνοντα κάμνοντα κάμνω',
    '200515 C- -------- καὶ καὶ καί καί',
    '200515 V- 3FAI-S-- ἐγερεῖ ἐγερεῖ ἐγερεῖ ἐγείρω',
    '200515 RP ----ASM- αὐτὸν αὐτὸν αὐτόν αὐτός',
    '200515 RA ----NSM- ὁ ὁ ὁ ὁ',
    '200515 N- ----NSM- κύριος· κύριος κύριος κύριος',
    '200515 C- -------- κἂν κἂν κἄν κἄν',
    '200515 N- ----APF- ἁμαρτίας ἁμαρτίας ἁμαρτίας ἁμαρτία',
    '200515 V- 3PAS-S-- ᾖ ᾖ ᾖ εἰμί',
    '200515 V- -XAPNSM- πεποιηκώς, πεποιηκώς πεποιηκώς ποιέω',
    '200515 V- 3FPI-S-- ἀφεθήσεται ἀφεθήσεται ἀφεθήσεται ἀφίημι',
    '200515 RP ----DSM- αὐτῷ. αὐτῷ αὐτῷ αὐτός',
    '200516 V- 2PMD-P-- ἐξομολογεῖσθε ἐξομολογεῖσθε ἐξομολογεῖσθε ἐξομολογέω',
    '200516 C- -------- ⸀οὖν οὖν οὖν οὖν',
    '200516 RP ----DPM- ἀλλήλοις ἀλλήλοις ἀλλήλοις ἀλλήλων',
    '200516 RA ----APF- ⸂τὰς τὰς τάς ὁ',
    '200516 N- ----APF- ἁμαρτίας⸃ ἁμαρτίας ἁμαρτίας ἁμαρτία',
    '200516 C- -------- καὶ καὶ καί καί',
    '200516 V- 2PMD-P-- ⸀εὔχεσθε εὔχεσθε εὔχεσθε εὔχομαι',
    '200516 P- -------- ὑπὲρ ὑπὲρ ὑπέρ ὑπέρ',
    '200516 RP ----GPM- ἀλλήλων, ἀλλήλων ἀλλήλων ἀλλήλων',
    '200516 C- -------- ὅπως ὅπως ὅπως ὅπως',
    '200516 V- 2APS-P-- ἰαθῆτε. ἰαθῆτε ἰαθῆτε ἰάομαι',
    '200516 A- ----ASN- πολὺ πολὺ πολύ πολύς',
    '200516 V- 3PAI-S-- ἰσχύει ἰσχύει ἰσχύει ἰσχύω',
    '200516 N- ----NSF- δέησις δέησις δέησις δέησις',
    '200516 A- ----GSM- δικαίου δικαίου δικαίου δίκαιος',
    '200516 V- -PMPNSF- ἐνεργουμένη. ἐνεργουμένη ἐνεργουμένη ἐνεργέω',
    '200517 N- ----NSM- Ἠλίας Ἠλίας Ἠλίας Ἠλίας',
    '200517 N- ----NSM- ἄνθρωπος ἄνθρωπος ἄνθρωπος ἄνθρωπος',
    '200517 V- 3IAI-S-- ἦν ἦν ἦν εἰμί',
    '200517 A- ----NSM- ὁμοιοπαθὴς ὁμοιοπαθὴς ὁμοιοπαθής ὁμοιοπαθής',
    '200517 RP ----DP-- ἡμῖν, ἡμῖν ἡμῖν ἐγώ',
    '200517 C- -------- καὶ καὶ καί καί',
    '200517 N- ----DSF- προσευχῇ προσευχῇ προσευχῇ προσευχή',
    '200517 V- 3AMI-S-- προσηύξατο προσηύξατο προσηύξατο προσεύχομαι',
    '200517 RA ----GSN- τοῦ τοῦ τοῦ ὁ',
    '200517 D- -------- μὴ μὴ μή μή',
    '200517 V- -AAN---- βρέξαι, βρέξαι βρέξαι βρέχω',
    '200517 C- -------- καὶ καὶ καί καί',
    '200517 D- -------- οὐκ οὐκ οὐ οὐ',
    '200517 V- 3AAI-S-- ἔβρεξεν ἔβρεξεν ἔβρεξε(ν) βρέχω',
    '200517 P- -------- ἐπὶ ἐπὶ ἐπί ἐπί',
    '200517 RA ----GSF- τῆς τῆς τῆς ὁ',
    '200517 N- ----GSF- γῆς γῆς γῆς γῆ',
    '200517 N- ----APM- ἐνιαυτοὺς ἐνιαυτοὺς ἐνιαυτούς ἐνιαυτός',
    '200517 A- ----APM- τρεῖς τρεῖς τρεῖς τρεῖς',
    '200517 C- -------- καὶ καὶ καί καί',
    '200517 N- ----APM- μῆνας μῆνας μῆνας μήν',
    '200517 A- ----APM- ἕξ· ἕξ ἕξ ἕξ',
    '200518 C- -------- καὶ καὶ καί καί',
    '200518 D- -------- πάλιν πάλιν πάλιν πάλιν',
    '200518 V- 3AMI-S-- προσηύξατο, προσηύξατο προσηύξατο προσεύχομαι',
    '200518 C- -------- καὶ καὶ καί καί',
    '200518 RA ----NSM- ὁ ὁ ὁ ὁ',
    '200518 N- ----NSM- οὐρανὸς οὐρανὸς οὐρανός οὐρανός',
    '200518 N- ----ASM- ⸂ὑετὸν ὑετὸν ὑετόν ὑετός',
    '200518 V- 3AAI-S-- ἔδωκεν⸃ ἔδωκεν ἔδωκε(ν) δίδωμι',
    '200518 C- -------- καὶ καὶ καί καί',
    '200518 RA ----NSF- ἡ ἡ ἡ ὁ',
    '200518 N- ----NSF- γῆ γῆ γῆ γῆ',
    '200518 V- 3AAI-S-- ἐβλάστησεν ἐβλάστησεν ἐβλάστησε(ν) βλαστάνω',
    '200518 RA ----ASM- τὸν τὸν τόν ὁ',
    '200518 N- ----ASM- καρπὸν καρπὸν καρπόν καρπός',
    '200518 RP ----GSF- αὐτῆς. αὐτῆς αὐτῆς αὐτός',
    '200519 N- ----VPM- Ἀδελφοί Ἀδελφοί ἀδελφοί ἀδελφός',
    '200519 RP ----GS-- ⸀μου, μου μου ἐγώ',
    '200519 C- -------- ἐάν ἐάν ἐάν ἐάν',
    '200519 RI ----NSM- τις τις τὶς τις',
    '200519 P- -------- ἐν ἐν ἐν ἐν',
    '200519 RP ----DP-- ὑμῖν ὑμῖν ὑμῖν σύ',
    '200519 V- 3APS-S-- πλανηθῇ πλανηθῇ πλανηθῇ πλανάω',
    '200519 P- -------- ἀπὸ ἀπὸ ἀπό ἀπό',
    '200519 RA ----GSF- τῆς τῆς τῆς ὁ',
    '200519 N- ----GSF- ἀληθείας ἀληθείας ἀληθείας ἀλήθεια',
    '200519 C- -------- καὶ καὶ καί καί',
    '200519 V- 3AAS-S-- ἐπιστρέψῃ ἐπιστρέψῃ ἐπιστρέψῃ ἐπιστρέφω',
    '200519 RI ----NSM- τις τις τὶς τις',
    '200519 RP ----ASM- αὐτόν, αὐτόν αὐτόν αὐτός',
    '200520 V- 3PAD-S-- ⸀γινωσκέτω γινωσκέτω γινωσκέτω γινώσκω',
    '200520 C- -------- ὅτι ὅτι ὅτι ὅτι',
    '200520 RA ----NSM- ὁ ὁ ὁ ὁ',
    '200520 V- -AAPNSM- ἐπιστρέψας ἐπιστρέψας ἐπιστρέψας ἐπιστρέφω',
    '200520 A- ----ASM- ἁμαρτωλὸν ἁμαρτωλὸν ἁμαρτωλόν ἁμαρτωλός',
    '200520 P- -------- ἐκ ἐκ ἐκ ἐκ',
    '200520 N- ----GSF- πλάνης πλάνης πλάνης πλάνη',
    '200520 N- ----GSF- ὁδοῦ ὁδοῦ ὁδοῦ ὁδός',
    '200520 RP ----GSM- αὐτοῦ αὐτοῦ αὐτοῦ αὐτός',
    '200520 V- 3FAI-S-- σώσει σώσει σώσει σῴζω',
    '200520 N- ----ASF- ψυχὴν ψυχὴν ψυχήν ψυχή',
    '200520 RP ----GSM- ⸀αὐτοῦ αὐτοῦ αὐτοῦ αὐτός',
    '200520 P- -------- ἐκ ἐκ ἐκ ἐκ',
    '200520 N- ----GSM- θανάτου θανάτου θανάτου θάνατος',
    '200520 C- -------- καὶ καὶ καί καί',
    '200520 V- 3FAI-S-- καλύψει καλύψει καλύψει καλύπτω',
    '200520 N- ----ASN- πλῆθος πλῆθος πλῆθος πλῆθος',
    '200520 N- ----GPF- ἁμαρτιῶν. ἁμαρτιῶν ἁμαρτιῶν ἁμαρτία'
]