import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { ReactElement, ReactNode, useEffect, useState } from 'react'
import ToolbarGeneralOne from '../../../misc/ToolbarGeneralOne'
import BlogTestK from '../styles/BlogStyleK'
import Author from '../Author'
import { BlogHelmet, iFootnote, iFootnotes } from '../misc'
import Notas from '../Notas'
import Quote from '../Quote'
import Ref from '../Ref'
import Stack from '@mui/material/Stack'
import Container from '@mui/material/Container'
import FetchVerses from '../FetchVerses'
import BibleRef from '../BibleRef'
import '../Blog.css';
import NegativeListItem from '../NegativeListItem'
import NegativeListWrapper from '../NegativeListWrapper'
import NegativeListHeader from '../NegativeListHeader'
import BlogStyleR from '../styles/BlogStyleR'
import RecommendedBox from '../RecommendedBox'
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'

const badge = 'Hermenéutica'
const title = 'Dardos del enemigo'
const subtitle = '¿Qué son y cómo apagarlos con la fe?'

export default function DardosEnemigo() {

    const Body = (): ReactElement => {

        const fn: iFootnotes = {
            a: {
                content: '',
                type: 'comment',
            },
        }

        return (
            <Box
                className='ArticleBody'
                sx={{
                    'ol ol': {
                        pl: {
                            md: '2.2em',
                        },
                    }
                }}
            >
                <Container>
                    <Typography>
                        En la antigua roma los dardos o flechas de fuego eran un arma incendiaria que funcionaba en dos etapas: primero clavándose y luego iniciando un fuego.
                    </Typography>
                    <Typography>
                        Muchas armas medievales demuestran ser elaboradas con habilidad y técnica, pero pocas demuestran tanta astucia como los dardos de fuego. Además, comparado con otras armas medievales como espadas, lanzas, o catapultas, los dardos de fuego son más bien sutiles (aunque igual de destructivos).
                    </Typography>
                    <Typography>
                        Estas tres características de los dardos (astucia, sutileza y destructividad) y su funcionamiento (en dos etapas) pueden compararse con cierto tipo de ataques de Satanás.
                    </Typography>
                    <Typography>
                        Algunos ataques del enemigo son ideas y pensamientos que aparecen en nuestra mente y corroen sistemáticamente nuestra vida espiritual. Estos dardos siempre intentarán generar dudas y conflictos internos en los creyentes para que estos se alejen de Dios.
                    </Typography>
                    <Typography>
                        La fe servirá de muchas maneras para defendernos de estos ataques astutos, sutiles y destructivos. En definitiva, cuando tenemos dudas, podemos afirmarnos en lo que sí conocemos de Dios, teniendo fe en su bondad y amor, aún si no tenemos todas las respuestas.
                    </Typography>
                    <Typography>
                        En el siguiente video encontramos un ejemplo bíblico de un dardo de Satanás, cuando astuta y sutilmente intenta destruir el ministerio de Jesús:
                    </Typography>
                    <LiteYouTubeEmbed 
                        id="_1SPBaOc4h8"
                        title="¿Qué son los dardos de fuego de Satanás?"
                    />
                    <Author />
                </Container>
            </Box>
        )
    }
    
    const body_css = {
        title: {
            fontSize: {
                us: '8.5rem',
                md: '11rem',
            },
            fontWeight: 600,
            ml: {
                us: '-5px',
                md: '-7px',
            },
        }
    }

    const body_css_larken = {
        title: {
            fontSize: {
                us: '9rem',
                md: '11rem',
            },
            fontWeight: 600,
            ml: {
                xs: '-0.01em',
                md: '0em',
            },
        },
        body: {
            '& .NegativeListLi': {
                pb: '0px',
            },
            '& .YellowList': {
                pb: '0px',
            },
        }
    }

    return (
    <>
      <ToolbarGeneralOne breadcrumbs={[{text: 'Artículos', path: 'articulos'}, {text: title, path: title}]} />
      <BlogHelmet title={title} />
      {/* <BlogTestK badge={badge} title={title} subtitle={subtitle} Body={<Body/>}/> */}
      {/* <StyleSwitcher badge={badge} title={title} subtitle={subtitle} Body={<Body/>} others={{
        title: {
            fontSize: {
                us: '9rem',
                md: '11rem',
            },
            ml: {
                us: '-5px',
                md: '-7px',
            },
        }
      }}/> */}
      <BlogStyleR text_font='Spline Sans Variable' display_font="Satoshi, sans" badge={badge} title={title} subtitle={subtitle} Body={<Body />} others={body_css} />
      
      {/* <BlogStyleQ text_font='Larken' display_font='Larken' badge={badge} title={title} subtitle={subtitle} Body={<Body />} others={body_css_larken} /> */}
      {/* <FetchVerses /> */}
    </>
  )
}